import { NgZone } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { getPlatform, isNullOrUndefined, Platform } from '../../../utils/misc';
import { NGXLogger } from 'ngx-logger';
var AUDIO_RECORDING_LIMIT = 2 * 60;
export var MediaStatus;
(function (MediaStatus) {
    MediaStatus[MediaStatus["READY"] = 0] = "READY";
    MediaStatus[MediaStatus["RECORDING"] = 1] = "RECORDING";
    MediaStatus[MediaStatus["RECORDED"] = 2] = "RECORDED";
    MediaStatus[MediaStatus["PLAYING"] = 3] = "PLAYING";
    MediaStatus[MediaStatus["COMPLETED"] = 4] = "COMPLETED";
})(MediaStatus || (MediaStatus = {}));
export var CordovaMediaStatus;
(function (CordovaMediaStatus) {
    CordovaMediaStatus[CordovaMediaStatus["MEDIA_NONE"] = 0] = "MEDIA_NONE";
    CordovaMediaStatus[CordovaMediaStatus["MEDIA_STARTING"] = 1] = "MEDIA_STARTING";
    CordovaMediaStatus[CordovaMediaStatus["MEDIA_RUNNING"] = 2] = "MEDIA_RUNNING";
    CordovaMediaStatus[CordovaMediaStatus["MEDIA_PAUSED"] = 3] = "MEDIA_PAUSED";
    CordovaMediaStatus[CordovaMediaStatus["MEDIA_STOPPED"] = 4] = "MEDIA_STOPPED";
})(CordovaMediaStatus || (CordovaMediaStatus = {}));
var AudioFieldComponent = /** @class */ (function () {
    function AudioFieldComponent(ngZone, logger) {
        this.ngZone = ngZone;
        this.logger = logger;
        this.progressValue = 0;
        this.audioLength = 0;
        this.audioCurrentSecs = AUDIO_RECORDING_LIMIT;
        this.MediaStatus = MediaStatus;
        this.mediaStatus = MediaStatus.READY;
        this.audioBase64 = null;
        this.timeouts = [];
        // Cordova variables
        this.cordovaMediaRecord = null;
        // Native
        this.audioBlob = null;
        this.mediaRecorder = null;
        this.audio = null;
        this.onValueChange = function (_) { };
    }
    Object.defineProperty(AudioFieldComponent, "audioFilePath", {
        get: function () {
            var platform = getPlatform();
            switch (platform) {
                case Platform.iOS:
                    return 'cdvfile://localhost/temporary/recording.wav';
                case Platform.Android:
                    return window.cordova.file.externalCacheDirectory + "recording.mp3";
                case Platform.Windows:
                    return window.cordova.file.tempDirectory + "recording.wma";
                default:
                    return window.cordova.file.cacheDirectory + "recording.mp3";
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Updates the UI values using ngZone
     * @param progressValue: Progress bar value
     * @param audioCurrentSecs: Current seconds for the audio
     * @param mediaStatus: MediaStatus i.e PLAYING
     */
    AudioFieldComponent.prototype.updateUITimer = function (progressValue, audioCurrentSecs, mediaStatus) {
        var _this = this;
        this.ngZone.run(function () {
            _this.progressValue = progressValue;
            _this.audioCurrentSecs = audioCurrentSecs;
            _this.mediaStatus = mediaStatus;
        });
    };
    AudioFieldComponent.prototype.cancelTimeouts = function () {
        this.timeouts.forEach(function (index, timeout) {
            window.clearTimeout(timeout);
        });
    };
    AudioFieldComponent.prototype.deleteAudioFile = function () {
        var self = this;
        if (this.audioFileEntry) {
            this.audioFileEntry.remove(function () {
                self.logger.debug('Successfully removed ' + self.audioFileEntry);
            });
        }
    };
    AudioFieldComponent.prototype.getNativeFilePath = function (filePath, successCallback, errorCallback) {
        var self = this;
        window.resolveLocalFileSystemURL(filePath, function (entry) {
            successCallback(entry);
        }, function (error) {
            self.logger.error('Error: ', error);
            errorCallback('Error retrieving file at path: ' + filePath);
        });
    };
    AudioFieldComponent.prototype.convertFileToBase64 = function (fileEntry, successCallback, errorCallback) {
        this.logger.debug(fileEntry);
        fileEntry.file(function (file) {
            var reader = new FileReader();
            reader.onloadend = function () {
                if (reader.result === undefined || reader.result === null)
                    errorCallback('Error converting to base64');
                else
                    successCallback(reader.result);
            };
            reader.readAsDataURL(file);
        });
    };
    AudioFieldComponent.prototype.startCordovaRecording = function () {
        var _this = this;
        var self = this;
        var startTime = new Date();
        function onSuccess() {
            self.logger.debug("Audio recorded successfully");
            self.mediaStatus = MediaStatus.RECORDED;
            var currentTime = ((new Date()).getTime() - startTime.getTime());
            self.audioLength = Math.floor(currentTime / 1000);
            self.getNativeFilePath(AudioFieldComponent.audioFilePath, function (fileEntry) {
                self.audioFileEntry = fileEntry;
                self.convertFileToBase64(fileEntry, function (base64) {
                    self.ngZone.run(function () {
                        self.audioBase64 = base64;
                        self.mediaStatus = MediaStatus.COMPLETED;
                        self.onValueChange(self.audioBase64);
                    });
                }, function (error) {
                    self.logger.debug(error);
                });
            }, function (error) {
                self.logger.debug(error);
            });
        }
        function onError(error) {
            self.logger.error("Failed to record audio: " + error.code);
        }
        function onMediaStatus(status) {
            self.logger.debug("Media status callback: " + CordovaMediaStatus[status]);
            if (status === CordovaMediaStatus.MEDIA_RUNNING) {
                self.mediaStatus = MediaStatus.RECORDING;
                self.startRecordingProgressTimer();
            }
        }
        self.cordovaMediaRecord = new Media(AudioFieldComponent.audioFilePath, onSuccess, onError, onMediaStatus);
        self.cordovaMediaRecord.startRecord();
        var timeout = setTimeout(function () {
            _this.stopRecording();
            _this.progressValue = 100;
        }, (AUDIO_RECORDING_LIMIT * 1000));
        this.timeouts.push(timeout);
    };
    AudioFieldComponent.prototype.startRecording = function () {
        this.cancelTimeouts();
        if (window.cordova !== undefined)
            this.startCordovaRecording();
        else if (navigator.mediaDevices !== undefined)
            this.startNativeRecording();
        else
            alert('Your browser does not appear to support HTML5 audio recording.');
    };
    AudioFieldComponent.prototype.startNativeRecording = function () {
        var _this = this;
        navigator.mediaDevices.getUserMedia({ audio: {} }).then(function (stream) {
            var startTime = new Date();
            _this.mediaRecorder = new MediaRecorder(stream);
            var chunks = [];
            _this.mediaRecorder.ondataavailable = function (event) {
                var currentTime = ((new Date()).getTime() - startTime.getTime());
                _this.audioLength = Math.floor(currentTime / 1000);
                var audioCurrentSecs = AUDIO_RECORDING_LIMIT - _this.audioLength;
                var progressValue = (100 / AUDIO_RECORDING_LIMIT) * _this.audioLength;
                var mediaStatus = (_this.mediaRecorder.state === 'recording') ? MediaStatus.RECORDING : MediaStatus.RECORDED;
                _this.updateUITimer(progressValue, audioCurrentSecs, mediaStatus);
                chunks.push(event.data);
                if (_this.mediaRecorder.state === 'inactive') {
                    _this.mediaStatus = MediaStatus.RECORDED;
                    var mimeType = _this.mediaRecorder.mimeType;
                    if (isNullOrUndefined(mimeType)) {
                        mimeType = 'audio/mp3';
                    }
                    _this.audioBlob = new Blob(chunks, { type: mimeType });
                    var reader_1 = new FileReader();
                    reader_1.readAsDataURL(_this.audioBlob);
                    reader_1.onloadend = function () {
                        _this.ngZone.run(function () {
                            _this.audioBase64 = reader_1.result;
                            _this.mediaStatus = MediaStatus.COMPLETED;
                            _this.onValueChange(reader_1.result);
                        });
                    };
                    // stop all the tracks in the stream to remove the red recording icon
                    stream.getTracks().forEach(function (track) { return track.stop(); });
                    _this.mediaRecorder = null;
                }
            };
            _this.mediaRecorder.start(1000);
            // setTimeout to stop recording after 30 seconds
            var timeout = setTimeout(function () {
                _this.stopRecording();
                _this.progressValue = 100;
            }, AUDIO_RECORDING_LIMIT * 1000);
            _this.timeouts.push(timeout);
        }).catch(function (error) {
            _this.logger.error(error);
            alert("Cannot start recording: " + error);
        });
    };
    AudioFieldComponent.prototype.stopRecording = function () {
        if (this.cordovaMediaRecord)
            this.cordovaMediaRecord.stopRecord();
        if (this.mediaRecorder !== null)
            this.mediaRecorder.stop();
    };
    AudioFieldComponent.prototype.playRecording = function () {
        this.mediaStatus = MediaStatus.PLAYING;
        if (this.audioBase64) {
            this.mediaStatus = MediaStatus.PLAYING;
            this.audio = new Audio(this.audioBase64);
            this.logger.debug("Audio length: " + this.audioLength);
            var self_1 = this;
            this.audio.onloadedmetadata = function () {
                self_1.audio.play();
                self_1.startPlaybackProgressTimer();
            };
        }
    };
    AudioFieldComponent.prototype.pausePlayback = function () {
        if (this.audio !== null) {
            this.audio.pause();
        }
        this.mediaStatus = MediaStatus.COMPLETED;
    };
    AudioFieldComponent.prototype.startPlaybackProgressTimer = function () {
        var _this = this;
        this.logger.debug("startProgressTimer: current time: " + this.audio.currentTime);
        this.logger.debug("startProgressTimer: duration: " + this.audio.duration);
        var updateProgressBar = function () {
            if (_this.audio.currentTime < _this.audio.duration && _this.mediaStatus === MediaStatus.PLAYING) {
                var progressValue = (100 / _this.audio.duration) * _this.audio.currentTime;
                var audioCurrentSecs = (_this.audio.duration - _this.audio.currentTime);
                _this.updateUITimer(progressValue, audioCurrentSecs, MediaStatus.PLAYING);
                _this.logger.debug("updateProgressBar - (duration: " + _this.audio.duration + ") (currentTime: " + _this.audio.currentTime + ")\n       (progress: " + _this.progressValue + ") (audioCurrentSecs: " + _this.audioCurrentSecs + ")");
                var timerId = window.setTimeout(updateProgressBar, 500);
                _this.timeouts.push(timerId);
            }
            else {
                _this.updateUITimer(100, _this.audio.duration, MediaStatus.COMPLETED);
            }
        };
        updateProgressBar();
    };
    AudioFieldComponent.prototype.startRecordingProgressTimer = function () {
        var _this = this;
        var seconds = 0;
        var updateProgressBar = function () {
            if (_this.mediaStatus === MediaStatus.RECORDING) {
                _this.logger.debug(seconds, _this.progressValue);
                var progressValue = (100 / AUDIO_RECORDING_LIMIT) * seconds;
                seconds += 1;
                _this.updateUITimer(progressValue, AUDIO_RECORDING_LIMIT - seconds, MediaStatus.RECORDING);
                setTimeout(updateProgressBar, 1000);
            }
        };
        updateProgressBar();
    };
    AudioFieldComponent.prototype.closeAudioCapture = function () {
        this.pausePlayback();
        this.cancelTimeouts();
        this.deleteAudioFile();
    };
    AudioFieldComponent.prototype.saveAndCloseAudioCapture = function () {
        this.pausePlayback();
        this.cancelTimeouts();
        if (this.cordovaMediaRecord) {
            // Releases the underlying operating system's audio resources. This is particularly important for Android, since there are a finite
            // amount of OpenCore instances for media playback. Applications should call the release function for any Media
            // resource that is no longer needed
            this.cordovaMediaRecord.release();
        }
        this.deleteAudioFile();
    };
    AudioFieldComponent.prototype.registerOnChange = function (fn) {
        this.onValueChange = fn;
    };
    AudioFieldComponent.prototype.registerOnTouched = function (fn) {
    };
    AudioFieldComponent.prototype.writeValue = function (obj) {
        this.logger.debug('writeValue', obj);
        if (isNullOrUndefined(obj)) {
            this.audioBase64 = null;
            this.mediaStatus = MediaStatus.READY;
            this.audio = null;
        }
        else {
            this.audioBase64 = obj;
            this.audio = new Audio(this.audioBase64 || undefined);
            this.mediaStatus = MediaStatus.COMPLETED;
        }
    };
    return AudioFieldComponent;
}());
export { AudioFieldComponent };
