import {Component, OnInit, ViewChild} from '@angular/core';
import {AuditForm} from '../api/models/audit-form';
import {Audit} from '../audit-form/audit';
import {Ward} from '../api/models/ward';
import {AuditFormSchema} from '../api/models/audit-form-schema';
import {InstitutionService} from '../institution.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuditService} from '../audit-form/audit.service';
import {Location} from '@angular/common';
import {ComplianceService} from '../compliance/compliance.service';
import {BaseLoginRequiredComponent} from '../base';
import {AccountService} from '../accounts.service';
import {AnalyticsService} from '../analytics.service';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {LocalizedDatePipe} from '../localized-date.pipe';
import {NGXLogger} from 'ngx-logger';
import {SubmitPreviewFormComponent} from './submit-preview-form/submit-preview-form.component';

@Component({
  selector: 'meg-submit-preview',
  templateUrl: './submit-preview.component.html',
  styleUrls: ['./submit-preview.component.css'],
  providers: [LocalizedDatePipe],
})
export class SubmitPreviewComponent extends BaseLoginRequiredComponent implements OnInit {

  public audit: Audit;
  public auditForm: AuditForm | null = null;
  public auditFormSchema: AuditFormSchema;
  public ward: Ward | null = null;
  public complianceScore: number | null;
  public complianceImage$: Observable<string>;
  public auditId: number | null = null;
  @ViewChild(SubmitPreviewFormComponent) previewForm: SubmitPreviewFormComponent;

  constructor(accountService: AccountService,
              router: Router,
              private auditService: AuditService,
              private route: ActivatedRoute,
              private institutionService: InstitutionService,
              private location: Location,
              private analytics: AnalyticsService,
              private complianceService: ComplianceService,
              private translateService: TranslateService,
              logger: NGXLogger) {
    super(router, route, accountService, logger);
  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.auditId = +params['audit_id'];
      if (isNaN(this.auditId)) throw new Error('Audit ID is NaN');

      this.auditService.setAuditId(this.auditId);

      this.auditService.getAuditWithFormSchema(this.auditId).subscribe((result: [Audit, AuditForm, AuditFormSchema]) => {
        [this.audit, this.auditForm, this.auditFormSchema] = result;
        this.institutionService.getWard(this.audit.wardId).subscribe((ward: Ward) => this.ward = ward);
        this.complianceScore = this.complianceService.calculateAuditCompliance(this.auditFormSchema, this.audit);
        this.complianceImage$ = this.complianceService.getComplianceImageUrl(this.auditForm, this.complianceScore);
      });
    });
  }

  onCancelClicked() {
    this.analytics.trackButtonClick('Cancel');
    this.analytics.trackAction('Audit submit preview cancelled');
    this.save().subscribe(() => this.location.back());
  }

  public onSubmitClicked() {

    const config = (this.auditForm as AuditForm).config;
    if (config && config.require_signature && this.audit.auditSession.signatures.length === 0) {
      alert(this.translateService.instant('audit-submission.signature-required-error'));
      return;
    }
    this.analytics.trackButtonClick('Submit');
    this.analytics.trackAction('Audit preview submit');
    if (this.previewForm.submit()) {
      this.save().subscribe(
        () => this.router.navigate(['audit-submit', this.audit.id])
      );
    }
  }

  private save(): Observable<boolean> {
    return this.auditService.saveAudit(this.audit);
  }
}
