import {Model} from './model';

// values for `dashboard widget` field

// exist in backend and client app
// Update meg_forms/dashboard_widgets/constants.py:APP_SUPPORTED_WIDGETS when adding support for new widgets
export const DASHBOARD_WIDGET_PIE = 'dashboard_widgets.widgets.pie_chart.PieChartWidget';
export const DASHBOARD_WIDGET_BAR = 'dashboard_widgets.widgets.bar_chart.BarChartWidget';
export const DASHBOARD_WIDGET_LINE = 'dashboard_widgets.widgets.line_chart.LineChartWidget';
export const DASHBOARD_WIDGET_SIMPLE_COMPLIANCE = 'dashboard_widgets.widgets.compliance.SimpleComplianceWidget';
export const DASHBOARD_WIDGET_SUB_FORM_BAR = 'dashboard_widgets.widgets.bar_chart.SubformBarChartWidget';

// exist only in the client app
export const DASHBOARD_WIDGET_FIELD_TABLE = 'dashboard_widgets.widgets.table.FieldTable';

export class Dashboard extends Model {
  widgets: Widget[];
  type: string;
  audit_form: number;
}

export class Widget extends Model {
  title: string;
  subtitle: string | null;
  widget: string;
  size: number;
  config: WidgetConfig;
  dashboard: number;

  public toString(): string {
    return this.title;
  }
}

export class WidgetConfig extends Model {
  compliance: boolean |  null;
  field_name: string | null;
  field_names: string[] | null;
  count_entries: boolean | null;
  show_meg: boolean | undefined;
}
