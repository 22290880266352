import {Component, Input, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Audit} from '../../audit-form/audit';
import {AuditForm, SubmissionDateRestriction} from '../../api/models/audit-form';
import {AuditFormSchema} from '../../api/models/audit-form-schema';
import {AnalyticsService, CATEGORY_UI} from '../../analytics.service';
import {AccountService} from '../../accounts.service';
import {AuditService} from '../../audit-form/audit.service';
import {TranslateService} from '@ngx-translate/core';
import {Errors} from '../../api/models/errors';
import {QipService} from '../../qip/qip.service';
import {StorageService} from '../../storage.service';
import {SignatureDialogComponent} from '../../signature-dialog/signature-dialog.component';
import {AuditSignature} from '../../api/models/signature';
import {MatDialog} from '@angular/material';
import {Issue} from '../../api/models/issue';
import {isNullOrUndefined} from '../../utils/misc';
import {AuditFormService} from '../../audit-form.service';
import {LocalizedDatePipe} from '../../localized-date.pipe';

@Component({
  selector: 'meg-submit-preview-form',
  templateUrl: './submit-preview-form.component.html',
  styleUrls: ['./submit-preview-form.component.css', '../submit-preview.component.css'],
  providers: [LocalizedDatePipe],
})
export class SubmitPreviewFormComponent implements OnInit {
  @Input() audit: Audit;
  @Input() auditForm: AuditForm;
  @Input() auditFormSchema: AuditFormSchema;
  public allowSubmissionDateChange$ = false;
  public startDateFormControl = new FormControl(Validators.required);
  public endDateFormControl = new FormControl(Validators.required);
  public allIssues: Issue[] = [];
  public auditSize = 0;
  public issue_label_plural: Observable<string>;

  constructor(private accountService: AccountService,
              private analytics: AnalyticsService,
              private auditFormService: AuditFormService,
              private auditService: AuditService,
              private dialog: MatDialog,
              private localizedDatePipe: LocalizedDatePipe,
              private qipService: QipService,
              private storageService: StorageService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.startDateFormControl.valueChanges.subscribe(
      (date: string) => this.startDateChange(date)
    );
    this.endDateFormControl.valueChanges.subscribe(
      (date: string) => this.endDateChange(date)
    );
    if (this.auditForm && this.auditForm.config) {
      const restriction = this.auditForm.config.submission_date_restriction;
      if (restriction === SubmissionDateRestriction.Everyone) {
        this.allowSubmissionDateChange$ = true;
      } else if (restriction === SubmissionDateRestriction.Nobody) {
        this.allowSubmissionDateChange$ = false;
      } else if (restriction === SubmissionDateRestriction.LeadAuditors) {
        this.accountService.hasPermission('megforms.change_submission_date').subscribe(
          (hasPermission: boolean) => this.allowSubmissionDateChange$ = hasPermission,
        );
      } else {
          this.allowSubmissionDateChange$ = true;
      }
      this.issue_label_plural = this.auditService.getIssueLabel(this.auditForm, true);
    }
    if (this.audit && this.audit.auditSession) {
      this.startDateFormControl.setValue(this.localizedDatePipe.transform(this.audit.auditSession.start_time));
      if (isNullOrUndefined(this.audit.auditSession.end_time)) {
        this.audit.auditSession.end_time = new Date();
      }
      this.endDateFormControl.setValue(this.localizedDatePipe.transform(this.audit.auditSession.end_time));

      this.auditFormService.getAuditFormSchema(this.audit.auditFormId).pipe(
        map((schema: AuditFormSchema) => {
          // Get all issues attached to the audit
          return this.qipService.getAllIssuesForAudit(schema, this.audit.auditSession.observations);
        })).subscribe((issues: Issue[]) => {
          this.allIssues = issues;
      });
    }
    if (this.audit) {
      this.calculateAuditSize(this.audit);
    }
  }

  startDateChange(dateString: string) {
    if (this.audit) {
      this.audit.auditSession.start_time = dateString.toDate() || new Date();
      this.validateSubmissionDates();
      this.analytics.trackEvent(CATEGORY_UI, 'start date change');
    }
  }

  endDateChange(dateString: string) {
    if (this.audit) {
      this.audit.auditSession.end_time = dateString.toDate() || new Date();
      this.validateSubmissionDates();
      this.analytics.trackEvent(CATEGORY_UI, 'end date change');
    }
  }

  private validateSubmissionDates() {
    if (this.audit.auditSession.end_time) {
      if (this.audit.auditSession.start_time > this.audit.auditSession.end_time) {
        this.setFieldError('start_time', this.translateService.instant('audit-submission.start-time-error'));
        this.setFieldError('end_time', this.translateService.instant('audit-submission.end-time-error'));
      } else {
        this.removeFieldError('start_time');
        this.removeFieldError('end_time');
      }
    }
  }

  public submit(): boolean {
    if (isNaN(this.audit.auditSession.start_time.getDate())) {
      this.setFieldError('start_time', this.translateService.instant('audit-form.field.field-empty'));
      return false;
    }
    if (!this.audit.auditSession.end_time || isNaN(this.audit.auditSession.end_time.getDate())) {
      this.setFieldError('end_time', this.translateService.instant('audit-form.field.field-empty'));
      return false;
    }
    if (this.hasError('start_time') || this.hasError('end_time')) {
      return false;
    }
    const config = (this.auditForm as AuditForm).config;
    if (config && config.require_signature && this.audit.auditSession.signatures.length === 0) {
      alert(this.translateService.instant('audit-submission.signature-required-error'));
      return false;
    }
    return true;
  }

  private hasError(key: string): boolean {
    if (this.audit.errors) return this.audit.errors[key] !== undefined;
    else return false;
  }

  private setFieldError(key: string, errorValue: string) {
    if (this.audit.errors) {
      this.audit.errors[key] = [errorValue];
    } else {
      this.audit.errors = {};
      this.audit.errors[key] = [errorValue];
    }
  }

  private removeFieldError(key: string) {
    if (this.audit.errors) this.audit.errors[key] = undefined;
  }

  public get errors(): Errors {
    return this.audit.errors || <Errors>{};
  }

  private calculateAuditSize(audit: Audit) {
    this.qipService.getAuditPhotoKeys(audit).pipe(
      mergeMap((keys: string[]): Observable<number> => this.storageService.getItemSizes(...keys)),
      map((size: number): number => size + JSON.stringify(audit.auditSession).length),
    ).subscribe((size: number) => this.auditSize = size);
  }

  /**
   * Opens signature dialog and adds resulting signature to audit when clicked
   */
  public addSignature() {
    this.dialog.open(SignatureDialogComponent).afterClosed().subscribe((result: any) => {
      if (result instanceof AuditSignature) {
        this.audit.auditSession.signatures = [result];
      }
    });
  }
}
