import {Observation} from './observation';
import {AuditSignature} from './signature';
import {Model} from './model';

export class AuditSession {
  constructor(
    public start_time: Date,
    public end_time: Date | null = null,
    public comment: string | null = null,
    public send_email = true,
    public observations: Observation[] = [],
    public signatures: AuditSignature[] = [],
    public report_auditors: number[] = [],
    public id?: number,
    public label?: string,
    public object_label?: string,
    public audit_form?: number,
    public auditor?: number,
    public ward?: number,
    // id of the AuditBackup object stored on the backend for this audit session
    public backupId?: number,
    public stage?: number,
  ) {
  }
}
