var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit, QueryList } from '@angular/core';
import { MatCheckboxChange, MatDialogRef, MatDialog } from '@angular/material';
import { IssueDialogArguments } from '../issue-dialog-arguments';
import { Issue } from '../../../api/models/issue';
import { QipService } from '../../qip.service';
import { IssueComponent } from '../issue/issue.component';
import { BaseComponent } from '../../../base.component';
import { isNullOrUndefined, isObjectEmpty } from '../../../utils/misc';
import { AuditFormService } from '../../../audit-form.service';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
var IssuesDialogComponent = /** @class */ (function (_super) {
    __extends(IssuesDialogComponent, _super);
    function IssuesDialogComponent(data, qipService, dialogRef, dialog, auditFormService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.data = data;
        _this.qipService = qipService;
        _this.dialogRef = dialogRef;
        _this.dialog = dialog;
        _this.auditFormService = auditFormService;
        _this.injector = injector;
        // list of issues that have been added, that is linked to the observation
        _this.originalIssues = [];
        // contains a copy of the original issues along with any new issues added in this component
        _this.issues = [];
        // list of available issues, including custom issues and not-added common issues
        _this.items = [];
        _this.isDisabled = true;
        _this.commentsErrors = {};
        _this.fieldNameObservables = {};
        _this.showNewIssue = false;
        _this.newIssue = new Issue('');
        _this.showNewIssueButton = true;
        _this.hideUnselectedCommonIssues = false;
        _this.commonIssues = data.commonIssues;
        _this.originalIssues = data.issues;
        _this.audit = data.audit;
        _this.field_name = data.field_name;
        _this.dialogRef.disableClose = true;
        _this.errors = data.errors;
        _this.showNewIssueButton = data.showNewIssueButton;
        _this.title = data.title;
        _this.hideUnselectedCommonIssues = data.hideUnselectedCommonIssues;
        _this.issue_label = data.issue_label;
        _this.issue_label_plural = data.issue_label_plural;
        return _this;
    }
    IssuesDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Makes a copy of original issues by creating a new object for each issue
        this.issues = this.originalIssues.map(function (x) { return Object.assign({}, x); });
        // Add any created custom issues to the list
        var commonIssues = this.commonIssues
            .map(function (commonIssue) {
            var issue = new Issue(commonIssue.comment);
            issue.field_name = _this.field_name;
            return issue;
        })
            .filter(function (commonIssue) { return !_this.isAdded(commonIssue); });
        if (this.hideUnselectedCommonIssues) {
            this.items = this.issues.filter(function (issue) { return _this.field_name === null || issue.field_name === _this.field_name; });
        }
        else {
            this.items = this.issues.filter(function (issue) { return _this.field_name === null || issue.field_name === _this.field_name; }).concat(commonIssues);
        }
        this.sortItemsByComment();
    };
    /** Sort common issues and newly added issues */
    IssuesDialogComponent.prototype.sortItemsByComment = function () {
        this.items.sort(function (a, b) {
            var commentA = a.comment.toLowerCase();
            var commentB = b.comment.toLowerCase();
            if (commentA < commentB) {
                return -1;
            }
            else if (commentA > commentB) {
                return 1;
            }
            else {
                return 0;
            }
        });
    };
    /** Checks whether issue is added to the observation */
    IssuesDialogComponent.prototype.isAdded = function (issue) {
        return this.issues.some(function (i) { return i.comment === issue.comment && i.field_name === issue.field_name; });
    };
    /**
     * Adds removes Issue based on checkbox state
     * @param {Issue} issue issue to be added/removed from the observation
     * @param {MatCheckboxChange} event checkbox click event
     */
    IssuesDialogComponent.prototype.toggleIssue = function (issue, event) {
        if (event.checked) {
            this.addIssue(issue);
        }
        else {
            event.source.checked = !this.removeIssue(issue);
        }
    };
    IssuesDialogComponent.prototype.toggleNewIssue = function (newIssue, event) {
        if (event.checked) {
            this.addNewIssue(newIssue);
        }
        else {
            var removed = !this.removeIssue(newIssue);
            event.source.checked = removed;
            this.newIssue = new Issue('');
            this.showNewIssue = removed;
        }
    };
    /**
     * Adds issue to observation, checks beforehand to make sure issue isn't duplicated
     * @param issue
     */
    IssuesDialogComponent.prototype.addIssue = function (issue) {
        if (!this.isAdded(issue)) {
            issue.field_name = this.field_name;
            this.issues.unshift(issue);
            this.logger.debug('Issue added', issue);
        }
    };
    IssuesDialogComponent.prototype.addNewIssue = function (newIssue) {
        this.addIssue(newIssue);
        this.newIssueComponent.updateMedia();
        this.newIssue = new Issue('');
        this.showNewIssue = false;
        this.items = [newIssue].concat(this.items);
        this.sortItemsByComment();
    };
    /**
     * Removes issue and related pics from observation, checks beforehand to make sure issue exists
     * @param issue
     */
    IssuesDialogComponent.prototype.removeIssue = function (issue) {
        var _this = this;
        var index = this.issues
            .findIndex(function (currentIssue) { return currentIssue.comment === issue.comment && currentIssue.field_name === issue.field_name; });
        if (index > -1) {
            // also remove the item from the list if it's a duplicate
            var itemIndex = this.items
                .findIndex(function (currentIssue) { return currentIssue.comment === issue.comment && currentIssue.field_name === issue.field_name; });
            var isDuplicate = this.items
                .some(function (i) { return i.comment === issue.comment && i !== issue && (i.field_name === null || !_this.isAdded(i)); });
            this.issues.splice(index, 1);
            if (isDuplicate && itemIndex > -1)
                this.items.splice(itemIndex, 1);
        }
        return (index > -1);
    };
    /**
     * Invoked when issue details have been edited inside the accordion.
     */
    IssuesDialogComponent.prototype.onEdited = function (issue) {
        this.addIssue(issue);
    };
    /**
     * Finds any issues that have been deleted, and delete the photos attached
     */
    IssuesDialogComponent.prototype.removeDeletedIssuePhotos = function () {
        var _this = this;
        var deletedIssues = this.originalIssues.filter(function (issue) {
            return _this.issues.findIndex(function (findIssue) { return findIssue.comment === issue.comment; }) === -1;
        });
        for (var _i = 0, deletedIssues_1 = deletedIssues; _i < deletedIssues_1.length; _i++) {
            var deletedIssue = deletedIssues_1[_i];
            this.qipService.removeIssuePhotos(this.audit, deletedIssue).subscribe(function () {
                return _this.issueComponents.forEach(function (issueComponent) { return issueComponent.updateMedia(); });
            });
            this.logger.debug("Issue removed: " + deletedIssue.comment);
        }
    };
    IssuesDialogComponent.prototype.onCommentChange = function (comment, index) {
        if (comment.isEmpty())
            this.commentsErrors["comment_" + index] = this.translateService.instant('audit-form.field.field-required');
        else {
            delete this.commentsErrors["comment_" + index];
            delete this.errors[index];
        }
    };
    IssuesDialogComponent.prototype.onNewIssueCommentChange = function (comment) {
        if (comment.isEmpty())
            this.commentsErrors["comment_new_issue"] = this.translateService.instant('audit-form.field.field-required');
        else {
            delete this.commentsErrors["comment_new_issue"];
        }
    };
    /**
     * Checks if issues has errors at index
     * @param index
     * @return boolean result
     */
    IssuesDialogComponent.prototype.hasError = function (index) {
        if (isNullOrUndefined(this.errors))
            return false;
        var errors = this.errors[index];
        return !isNullOrUndefined(errors) && !isObjectEmpty(errors);
    };
    IssuesDialogComponent.prototype.getIssueQuestion = function (issue) {
        var _this = this;
        var result = this.fieldNameObservables[issue.field_name || ''];
        if (result === undefined) {
            if (issue.field_name) {
                result = this.auditFormService.getFieldByName(this.audit.auditFormId, issue.field_name).pipe(tap(function (field) { return _this.logger.debug('field', field); }), map(function (field) { return field.label; }));
                this.fieldNameObservables[issue.field_name] = result;
            }
            else {
                result = of(null);
                this.fieldNameObservables[''] = result;
            }
        }
        return result;
    };
    /**
     * First it removes any deleted issue photos.
     * Then it removes all original issues and assigns the issues added from this component back.
     */
    IssuesDialogComponent.prototype.onConfirm = function () {
        var issues = this.issues.filter(function (issue) { return issue.comment.isEmpty(); });
        if (issues.length > 0) {
            this.modalService.openErrorModal(this.translateService.get('audit-form.required-fields-error')).subscribe();
        }
        else if (this.showNewIssue && this.newIssue.comment.isEmpty()) {
            this.onNewIssueCommentChange(this.newIssue.comment);
            this.modalService.openErrorModal(this.translateService.get('audit-form.required-fields-error')).subscribe();
        }
        else {
            if (this.newIssue.comment)
                this.addNewIssue(this.newIssue);
            if (this.newIssue.comment || this.issues.length > 0) {
                this.issueComponents.forEach(function (issueComponent) { return issueComponent.updateMedia(); });
                this.removeDeletedIssuePhotos();
                this.originalIssues.splice(0, this.originalIssues.length);
                for (var _i = 0, _a = this.issues; _i < _a.length; _i++) {
                    var currentIssue = _a[_i];
                    this.originalIssues.unshift(currentIssue);
                }
            }
            this.logger.debug("Issue count: " + this.originalIssues.length);
            this.dialogRef.close();
        }
    };
    IssuesDialogComponent.prototype.openNewIssue = function () {
        this.showNewIssue = true;
    };
    IssuesDialogComponent.prototype.cancelNewIssue = function () {
        this.showNewIssue = false;
        this.newIssue = new Issue('');
    };
    IssuesDialogComponent.prototype.isNewIssue = function (newIssue) {
        return this.commonIssues.findIndex(function (findIssue) { return findIssue.comment === newIssue.comment; }) === -1;
    };
    return IssuesDialogComponent;
}(BaseComponent));
export { IssuesDialogComponent };
