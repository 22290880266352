var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnDestroy, OnInit, QueryList } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditFormSchema, FIELD_WIDGET_ACCORDION, InlineFieldGroup } from '../../api/models/audit-form-schema';
import { AuditForm, LayoutType } from '../../api/models/audit-form';
import { Audit } from '../audit';
import { AuditService } from '../audit.service';
import { AccordionItem } from './accordion-item';
import { isFieldRequired, isFieldVisible } from '../../utils/conditionals';
import { TranslateService } from '@ngx-translate/core';
import { isArrayOfType, isNullOrUndefined } from '../../utils/misc';
import { createFormFieldValidators } from './validators';
import { ApiService } from '../../api/api.service';
import { map, switchMap, filter } from 'rxjs/operators';
import { tap } from 'rxjs/internal/operators/tap';
import { combineLatest, merge, of, Subscription, timer } from 'rxjs';
import { AccountService } from '../../accounts.service';
import { InstitutionService } from '../../institution.service';
import { WrappedFieldComponent } from '../wrapped-field/wrapped-field.component';
/**
 * Represents a subset of questions within the form.
 * If the group has a heading field, it becomes an accordion.
 */
var QuestionGroup = /** @class */ (function () {
    function QuestionGroup(component, heading) {
        this.component = component;
        this.heading = heading || null;
        this.fields = [];
    }
    Object.defineProperty(QuestionGroup.prototype, "allFields", {
        get: function () {
            var fields = [];
            this.fields.forEach(function (field) {
                if (field instanceof InlineFieldGroup)
                    fields.push.apply(fields, field.fields);
                else
                    fields.push(field);
            });
            return fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuestionGroup.prototype, "required", {
        get: function () {
            var _this = this;
            if (this.allFields.find(function (field) { return field.required; }) !== undefined)
                return true;
            if (this.component === undefined)
                return false;
            return this.allFields.find(function (field) {
                return isFieldRequired(field, _this.component.form, _this.component.audit, _this.component.auditFormSchema);
            }) !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    return QuestionGroup;
}());
/**
 * Form component responsible for rendering forms for a single observation
 */
var ObservationComponent = /** @class */ (function () {
    function ObservationComponent(auditService, translateService, apiService, accountService, institutionService) {
        this.auditService = auditService;
        this.translateService = translateService;
        this.apiService = apiService;
        this.accountService = accountService;
        this.institutionService = institutionService;
        this.observationId = null;
        this.fieldErrors = null;
        this.validated = false;
        this.groupedFields = [];
        this.relatedData = [];
        this.subscriptions = [];
        this.clearClicked = new EventEmitter();
        this.deleteClicked = new EventEmitter();
        this.progressSaved = new EventEmitter();
        this.nextClicked = new EventEmitter();
        this.backClicked = new EventEmitter();
        this.isAccordionSubObservation = false;
        this.invalidObservationsCount = 0;
        this.setValuePlaceholders = {};
        this.accordionAnswers = [];
    }
    ObservationComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                combineLatest([
                    this.accountService.currentUser$,
                    this.institutionService.getWard(this.audit.wardId)
                ]).pipe(tap(function (_a) {
                    var user = _a[0], ward = _a[1];
                    var auditorName = null;
                    if (user && user.first_name && user.last_name) {
                        auditorName = user.first_name + " " + user.last_name;
                    }
                    else if (user && user.username) {
                        auditorName = user.username;
                    }
                    _this.setValuePlaceholders = {
                        '{auditor.id}': user ? user.auditor.id : null,
                        '{auditor.name}': auditorName,
                        '{auditor.email}': user ? user.email : null,
                        '{ward.manager}': ward ? ward.managed_by_id : null
                    };
                    _this.init();
                })).subscribe();
                return [2 /*return*/];
            });
        });
    };
    ObservationComponent.prototype.init = function () {
        var _this = this;
        this.buildFormGroup();
        this.createAccordionItems();
        this.setupLinkedFormsData();
        if (this.isAccordionSubObservation) {
            var accordionAnswersSubscription = this.auditService.accordionAnswers$.subscribe(function (fieldAnswers) {
                _this.accordionAnswers = fieldAnswers;
            });
            this.subscriptions.push(accordionAnswersSubscription);
            this.accordionAnswers = this.auditService.getCachedAccordionAnswers();
        }
    };
    ObservationComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
        if (!this.isAccordionSubObservation) {
            this.auditService.resetAccordionAnswers();
        }
        else if (this.accordionIndex) {
            this.auditService.deleteAccordionAnswers(this.accordionIndex);
        }
    };
    ObservationComponent.prototype.setupLinkedFormsData = function () {
        var _this = this;
        var relatedDataSources = this.auditFormSchema.related_data_sources;
        if (relatedDataSources) {
            relatedDataSources.forEach(function (source) {
                var sourceData = {
                    source: source,
                    data: [],
                };
                _this.relatedData.push(sourceData);
                // prepare observables
                var observables = source.matching_fields.map(function (fieldName) {
                    return _this.form.controls[fieldName].valueChanges.pipe(map(function (value) {
                        var _a;
                        return (_a = {}, _a[fieldName] = value, _a);
                    }));
                });
                // subscribe to all observables at once
                var subscription = merge.apply(void 0, observables).pipe(tap(function (values) { return console.log('received values', values); }), map(function (values) {
                    source.matching_fields.forEach(function (fieldName) {
                        // add other answers from this form to the request
                        if (values[fieldName] === undefined)
                            values[fieldName] = _this.form.value[fieldName];
                    });
                    return values;
                }), tap(function (values) { return console.log('sending request with values', values); }), switchMap(function (values) { return _this.apiService.fetchRelatedFormData(source, values); }), tap(function (value) { return console.log('Received related data', value); }), tap(function (value) { return sourceData.data = value; })).subscribe();
                _this.subscriptions.push(subscription);
            });
        }
    };
    Object.defineProperty(ObservationComponent.prototype, "subFormSchema", {
        get: function () {
            if (this.schema.sub_forms === undefined)
                return this.schema;
            else
                return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ObservationComponent.prototype, "fields", {
        get: function () {
            if (this.audit.auditSession.id)
                return this.schema.fields;
            return this.schema.fields.filter(function (field) { return field.show_in_app !== false; });
        },
        enumerable: true,
        configurable: true
    });
    ObservationComponent.prototype.buildFormGroup = function () {
        var _this = this;
        var formControls = {};
        this.fields.forEach(function (field) {
            var validators = createFormFieldValidators(field.validators || [], false, _this.apiService);
            var asyncValidators = createFormFieldValidators(field.validators || [], true, _this.apiService);
            if (field.required)
                validators.push(Validators.required);
            formControls[field.field_name] = new FormControl(_this.observation[field.field_name], validators, asyncValidators);
            if (_this.observation.answer_comments === undefined)
                _this.observation.answer_comments = [];
            var comments = _this.observation.answer_comments.filter(function (comment) { return comment.field_name === field.field_name; });
            formControls['comment-' + field.field_name] = new FormControl(comments.length > 0 ? comments[0].comment : '');
        });
        this.form = new FormGroup(formControls);
        this.buildFieldGroups();
    };
    ObservationComponent.prototype.buildFieldGroups = function () {
        var _this = this;
        this.groupedFields = [];
        var currentGroup = new QuestionGroup(this);
        var currentInlineGroup = new InlineFieldGroup();
        this.fields.forEach(function (field) {
            if (!field.field_group && currentInlineGroup.fields.length > 0) {
                currentGroup.fields.push(currentInlineGroup);
                currentInlineGroup = new InlineFieldGroup();
            }
            if (field.widget === FIELD_WIDGET_ACCORDION) {
                if (currentGroup.fields.length > 0)
                    _this.groupedFields.push(currentGroup);
                currentGroup = new QuestionGroup(_this, field);
            }
            else if (field.field_group) {
                currentInlineGroup.fields.push(field);
                if (!currentInlineGroup.layout_columns)
                    currentInlineGroup.layout_columns = _this.getInlineGroupLayoutColumns(field.field_group);
            }
            else {
                currentGroup.fields.push(field);
            }
        });
        if (currentInlineGroup.fields.length > 0)
            currentGroup.fields.push(currentInlineGroup);
        if (currentGroup.fields.length > 0)
            this.groupedFields.push(currentGroup);
    };
    ObservationComponent.prototype.getInlineGroupLayoutColumns = function (fieldGroupId) {
        return this.auditFormSchema.field_groups.filter(function (group) { return group.id === fieldGroupId; })[0].layout_columns;
    };
    ObservationComponent.prototype.isAccordionHeadingVisible = function (field) {
        return isFieldVisible(field, this.form, this.audit, this.auditFormSchema, this.accordionAnswers, this.isAccordionSubObservation);
    };
    /**
     * Checks whether there are errors in given field group
     * @param group
     */
    ObservationComponent.prototype.getAccordionHeadingError = function (group) {
        var _this = this;
        var numErrors = group.fields.map(function (field) {
            var formControl = _this.form.controls[field.field_name];
            if (formControl.touched && !formControl.valid)
                return 1;
            if (_this.fieldErrors !== null && _this.fieldErrors !== undefined) {
                var errors = _this.fieldErrors[field.field_name];
                if (errors instanceof Array)
                    return errors.length;
            }
            return 0;
        }).reduce(function (a, b) { return a + b; }, 0);
        return numErrors > 0;
    };
    ObservationComponent.prototype.createAccordionItems = function () {
        var _this = this;
        this.accordionItems = [];
        var fieldErrors = this.fieldErrors || {};
        this.subforms.forEach(function (subform) {
            var _a;
            var subformFieldErrors = fieldErrors[subform.name];
            if (subform.many) {
                var subObservations = _this.observation[subform.name]
                    || [_this.auditService.createSubObservation()];
                var subformErrors_1 = subformFieldErrors || [];
                var items = subObservations.map(function (subObs, index) {
                    return new AccordionItem(subform, subObs, subformErrors_1.length > index ? subformErrors_1[index] : null);
                });
                (_a = _this.accordionItems).push.apply(_a, items);
            }
            else {
                var item = new AccordionItem(subform, _this.getSubObservation(subform), subformFieldErrors);
                _this.accordionItems.push(item);
            }
        });
    };
    /**
     * Checks whether user input any data into the form
     * Filters out answer comment values.
     */
    ObservationComponent.prototype.isEmpty = function () {
        var _this = this;
        if (this.observation.issues.length > 0)
            return false;
        var answers = Object.keys(this.form.controls)
            .filter(function (val) { return val.indexOf('comment-') < 0; })
            .map(function (key) { return _this.form.value[key]; })
            .filter(function (value) { return value !== null; });
        return answers.length === 0;
    };
    ObservationComponent.prototype.addError = function (key, error) {
        if (key) {
            if (!this.audit.errors) {
                this.audit.errors = {};
            }
            this.audit.errors[key] = error;
        }
    };
    /**
     * Checks whether form is valid;
     * if yes, copies values from each field into the observation
     */
    ObservationComponent.prototype.onSave = function (validate, index) {
        var _this = this;
        var schema = this.schema;
        // sub-observation can be ignored if its not required (and is not an observation)
        var ignore = this.auditForm.config.form_layout === LayoutType.Accordion && schema.required !== undefined
            && !schema.required && this.isEmpty();
        if (!ignore) {
            // mark all fields as touched to show error messages
            Object.keys(this.form.controls)
                .map(function (key) { return _this.form.controls[key]; })
                .forEach(function (field) {
                field.markAsTouched({ onlySelf: true });
                field.updateValueAndValidity({ onlySelf: true });
            });
        }
        if (this.form.pending) {
            // Only wait for status change when form is "pending"
            return this.form.statusChanges.pipe(filter(function (status) { return status !== 'PENDING'; }), switchMap(function (status) {
                _this.save(validate, index);
                return of(true);
            }));
        }
        else {
            // Add small-wait time to keep loading spinner from flashing
            return timer(250).pipe(switchMap(function () {
                _this.save(validate, index);
                return of(true);
            }));
        }
    };
    ObservationComponent.prototype.save = function (validate, index) {
        var _this = this;
        var schema = this.schema;
        // sub-observation can be ignored if it's not required (and is not an observation)
        var ignore = this.auditForm.config.form_layout === LayoutType.Accordion && schema.required !== undefined
            && !schema.required && this.isEmpty();
        var schemaKey = schema.name;
        if (schemaKey && index !== undefined) {
            schemaKey += "-" + index;
        }
        if (!ignore) {
            if (this.form.valid || !validate) {
                var value_1 = this.form.value;
                this.schema.fields
                    .forEach(function (field) {
                    if (isFieldVisible(field, _this.form, _this.audit, _this.auditFormSchema, _this.accordionAnswers, _this.isAccordionSubObservation)) {
                        _this.observation[field.field_name] = value_1[field.field_name];
                        var answerComment = value_1['comment-' + field.field_name];
                        if (answerComment)
                            answerComment = answerComment.trim();
                        if (_this.observation.answer_comments === undefined)
                            _this.observation.answer_comments = [];
                        var answerIndex = _this.observation.answer_comments.findIndex(function (comment) { return comment.field_name === field.field_name; });
                        if (answerComment && answerIndex === -1) {
                            _this.observation.answer_comments.push({
                                field_name: field.field_name,
                                comment: answerComment
                            });
                        }
                        else if (answerComment && answerIndex !== -1) {
                            _this.observation.answer_comments[answerIndex].comment = answerComment;
                        }
                        else if (!answerComment && answerIndex !== -1) {
                            _this.observation.answer_comments.splice(answerIndex, 1);
                        }
                    }
                    else {
                        delete _this.observation[field.field_name];
                    }
                });
            }
            else {
                var firstInvalid = this.schema.fields.find(function (field) { return _this.form.controls[field.field_name].valid === false; });
                if (firstInvalid !== undefined) {
                    var fieldElement = document.getElementById("question-" + firstInvalid.field_name);
                    if (fieldElement !== null)
                        fieldElement.scrollIntoView();
                    this.addError(schemaKey, []);
                }
                throw new Error(this.translateService.instant('audit-form.required-fields-error'));
            }
        }
        // For accordion layout
        if (this.accordionItems.length > 0) {
            // Get the first observation where a question has been answered
            var firstAnsweredObservation = this.childObservations.find(function (subObservationComponent) {
                var subFormSchema = subObservationComponent.schema;
                return !isNullOrUndefined(subFormSchema.fields.find(function (field) {
                    return !isNullOrUndefined(subObservationComponent.form.controls[field.field_name].value);
                }));
            });
            // If no observation with questions answered then throw an error
            if (isNullOrUndefined(firstAnsweredObservation)) {
                throw new Error(this.translateService.instant('audit-form.empty-forms-error'));
            }
        }
        else if (validate) {
            var formIsEmpty = this.isEmpty();
            // Checks if no question has been answered and if the schema is required.
            if (formIsEmpty && schema.required) {
                this.addError(schemaKey, [this.translateService.instant('audit-form.form-required')]);
                throw new Error(this.translateService.instant('audit-form.form-required'));
            }
            // If no question has been answered then throw an error
            if (formIsEmpty && this.auditForm.config.form_layout === LayoutType.Default) {
                this.addError(schemaKey, [this.translateService.instant('audit-form.empty-questions-error')]);
                throw new Error(this.translateService.instant('audit-form.empty-questions-error'));
            }
        }
        this.childObservations.forEach(function (subObservationComponent, childIndex) {
            var subFormSchema = subObservationComponent.schema;
            subObservationComponent.save(validate, childIndex);
            var subObservation = subObservationComponent.observation;
            if (subObservationComponent.isEmpty()) {
                if (Array.isArray(_this.observation[subFormSchema.name])) {
                    var subObservations = _this.observation[subFormSchema.name];
                    var pos = subObservations.indexOf(subObservation);
                    if (pos > -1)
                        subObservations.splice(pos, 1);
                }
                else {
                    delete _this.observation[subFormSchema.name];
                }
            }
            else {
                if (_this.auditForm.config.form_layout === LayoutType.Accordion && subFormSchema.many) {
                    if (Array.isArray(_this.observation[subFormSchema.name])) {
                        var subObservations = _this.observation[subFormSchema.name];
                        // Don't add subobservation to the list if already added (editing observation)
                        if (!subObservations.includes(subObservation))
                            subObservations.push(subObservation);
                    }
                    else {
                        _this.observation[subFormSchema.name] = [subObservation];
                    }
                }
                else if (!_this.observation[subFormSchema.name]) {
                    _this.observation[subFormSchema.name] = subObservation;
                }
            }
        });
        this.validated = true;
    };
    Object.defineProperty(ObservationComponent.prototype, "numChoiceFields", {
        get: function () {
            return this.schema.fields.filter(function (field) { return field.choices.length > 0; }).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ObservationComponent.prototype, "showYesToAllButton", {
        get: function () {
            var config = this.auditForm.config;
            if (config === null)
                return false;
            var minFields = config.show_yes_to_all;
            return minFields !== null && this.numChoiceFields >= minFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ObservationComponent.prototype, "showNaToAllButton", {
        get: function () {
            var config = this.auditForm.config;
            if (config === null)
                return false;
            var minFields = config.show_na_to_all;
            return minFields !== null && this.numChoiceFields >= minFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ObservationComponent.prototype, "showQip", {
        get: function () {
            if (this.schema instanceof AuditFormSchema && this.schema.sub_forms.length > 0)
                return false;
            var config = this.auditForm.config;
            if (config === null)
                return false;
            return config.enable_qip;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Applies first compliant answer to all questions
     */
    ObservationComponent.prototype.answerYesToAll = function () {
        var _this = this;
        this.schema.fields.filter(function (field) { return field.compliant_value; }).forEach(function (field) {
            var patch = {};
            patch[field.field_name] = field.compliant_value;
            _this.form.patchValue(patch);
        });
    };
    /**
     * Answers "N/A" to all questions
     */
    ObservationComponent.prototype.answerNaToAll = function () {
        var _this = this;
        this.schema.fields.filter(function (field) { return field.ignored_value; }).forEach(function (field) {
            var patch = {};
            patch[field.field_name] = field.ignored_value;
            _this.form.patchValue(patch);
        });
    };
    Object.defineProperty(ObservationComponent.prototype, "subforms", {
        get: function () {
            return this.schema.sub_forms || [];
        },
        enumerable: true,
        configurable: true
    });
    ObservationComponent.prototype.getSubObservation = function (subform) {
        var subObservation = this.observation[subform.name];
        if (!subObservation) {
            subObservation = this.auditService.createSubObservation();
        }
        return subObservation;
    };
    ObservationComponent.prototype.clearFormFields = function () {
        var formFields = this.wrappedFormFieldComponents.reduce(function (arr, f) { return (arr.concat(f.formFieldComponents.toArray())); }, []);
        formFields.map(function (formField) { return formField.clearField(); });
    };
    ObservationComponent.prototype.clearForm = function () {
        this.form.reset();
        this.form.markAsPristine();
        this.clearFormFields();
        this.childObservations.forEach(function (component) { return component.clearForm(); });
    };
    ObservationComponent.prototype.clearSubform = function (observation) {
        var _this = this;
        this.childObservations
            .filter(function (component) { return component.observation === observation; })
            .forEach(function (component) {
            var subform = component.schema;
            if (confirm(_this.translateService.instant('clear-prompt') + " \"" + subform.display_name + "\"?")) {
                component.clearForm();
            }
        });
    };
    ObservationComponent.prototype.addSubObservation = function (subform) {
        if (!subform.many)
            throw new Error("Cannot add new subform \"" + subform.name + "\" because only one is allowed");
        var subObs = this.auditService.createSubObservation();
        var position = 0;
        this.accordionItems.forEach(function (item, index) {
            if (item.subform === subform)
                position = index + 1;
        });
        var accordionItem = new AccordionItem(subform, subObs);
        this.accordionItems.splice(position, 0, accordionItem);
    };
    ObservationComponent.prototype.deleteSubObservation = function (accordionItem, formIndex) {
        if (!accordionItem.subform.many)
            throw new Error("Cannot delete subform \"" + accordionItem.subform.name + "\"");
        if (confirm(this.translateService.instant('delete-prompt') + " \"" + accordionItem.subform.display_name + "\"?")) {
            this.audit.auditSession.observations.forEach(function (observation) {
                var subObservation = observation[accordionItem.subform.name];
                if (subObservation !== undefined && subObservation instanceof Array) {
                    observation[accordionItem.subform.name] = subObservation.filter(function (subObservationValue) { return subObservationValue !== accordionItem.subObservation; });
                }
                return observation;
            });
            this.accordionItems.splice(formIndex, 1);
        }
    };
    ObservationComponent.prototype.isFormDirty = function () {
        return this.form.dirty || this.childObservations
            .map(function (child) { return child.isFormDirty(); })
            .reduce(function (child1, child2) { return child1 || child2; }, false);
    };
    ObservationComponent.prototype.isFormValid = function () {
        return this.form.valid || this.childObservations
            .map(function (child) { return child.isFormValid(); })
            .reduce(function (child1, child2) { return child1 || child2; }, false);
    };
    /**
     * Retrieves errors on the sub-observation level.
     * Ignores question-level errors.
     * @param accordionItem
     * @returns list of subform error messages. Empty list is returned if subform has errors bu they're on a higher (question) level.
     */
    ObservationComponent.prototype.getSubObservationErrorMessages = function (accordionItem, index) {
        var errors = this.audit.errors;
        if (!errors)
            return null;
        // errors.observations will exist if the errors are returned from the server
        // else locally added errors will exist on errors
        if (!isNullOrUndefined(errors.observations)) {
            errors = errors.observations
                .find(function (observationErrors) { return observationErrors[accordionItem.subform.name] !== undefined; });
        }
        var schemaKey = accordionItem.subform.name + "-" + index;
        if (errors === null || errors === undefined)
            return null;
        if (!isNullOrUndefined(this.childObservations)) {
            // Checks if the current sub-observation is dirty before continuing.
            // This fixes the issue where two observations can have the subform but only one has errors
            var isFormValid = this.childObservations
                .filter(function (component) { return component.observation === accordionItem.subObservation; })
                .map(function (child) { return child.isFormValid(); })
                .reduce(function (child1, child2) { return child1 || child2; }, false);
            if (!isFormValid && !errors[schemaKey]) {
                return null;
            }
        }
        var subformErrors = errors[schemaKey];
        if (isArrayOfType(subformErrors, 'string')) {
            // The subform has subform-level errors
            return subformErrors;
        }
        else if (isNullOrUndefined(subformErrors)) {
            // The subform does not have any errors at all
            return null;
        }
        else {
            // Subform has errors, but on on the top level.
            // Return empty list if errors to show that there are errors, but no messages to be displayed in accordion
            return [];
        }
    };
    /**
     * Checks to see if there exists another subobservation the same name
     * @param accordionItem: the item to check
     * @return true if the number of forms with the same name is greater than 1
     */
    ObservationComponent.prototype.hasMultipleForms = function (accordionItem) {
        return this.accordionItems.filter(function (accordionItemVal) {
            return accordionItemVal.subform.name === accordionItem.subform.name;
        }).length > 1;
    };
    ObservationComponent.prototype.onClearClicked = function () {
        this.clearClicked.emit();
    };
    ObservationComponent.prototype.onDeleteClicked = function () {
        this.deleteClicked.emit();
    };
    ObservationComponent.prototype.onProgressSave = function () {
        this.progressSaved.emit();
    };
    ObservationComponent.prototype.onNextClicked = function () {
        this.nextClicked.emit();
    };
    ObservationComponent.prototype.onBackClicked = function () {
        this.backClicked.emit();
    };
    return ObservationComponent;
}());
export { ObservationComponent };
