import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {merge} from 'rxjs';

import {filter, map, pairwise, switchMap, takeUntil, tap} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material';
import {AuditSignature} from '../api/models/signature';
import {CreateIssueDialogComponent} from '../qip/issues-widget/create-issue-dialog/create-issue-dialog.component';
import {fromEvent} from 'rxjs';
import {NGXLogger} from 'ngx-logger';

class Point {
  constructor(public x: number, public y: number) {
  }
}

@Component({
  selector: 'meg-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.css']
})
export class SignatureDialogComponent implements AfterViewInit {
  @ViewChild('canvas') public canvas: ElementRef;
  public width = 296;
  public height = 200;
  public signed = false;

  constructor(private dialogRef: MatDialogRef<CreateIssueDialogComponent>, private logger: NGXLogger) { }

  ngAfterViewInit(): void {
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;

    canvas.width = this.width;
    canvas.height = this.height;

    const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
    if (context === null) {
      this.logger.error('Context is null', canvas);
    } else {
      context.lineWidth = 3;
      context.lineCap = 'round';
      context.strokeStyle = '#000';

      this.captureEvents(context, canvas);
    }
  }

  /**
   * Creates observables to capture touch and mouse move events
   * and invokes draw method to plot the lines on the canvas.
   */
  private captureEvents(context: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    merge(
      fromEvent(canvas, 'mousedown'),
      fromEvent(canvas, 'touchstart'),
    ).pipe(
      switchMap(() => {
        return merge(
          fromEvent(canvas, 'mousemove'),
          fromEvent(canvas, 'touchmove'),
        ).pipe(
          takeUntil(merge(
            fromEvent(canvas, 'mouseup'),
            fromEvent(canvas, 'mouseleave'),
            fromEvent(canvas, 'touchend'),
          ).pipe(
            // enable Confirm button after any event that interrupts drawing
            tap(() => this.signed = true),
          )),
          // map touch and mouse events to X/Y coordinates
          map((event: MouseEvent | TouchEvent): Point | null => {
            if (event instanceof MouseEvent) {
              // mouse event
              return new Point(event.clientX, event.clientY);
            } else {
              // touch event
              const touch = event.touches.item(0);
              if (touch === null) return null;
              return new Point(touch.clientX, touch.clientY);
            }
          }),
          filter((point: Point | null) => point !== null),
          // offset points by canvas position:
          tap((point: Point) => {
            const rect = canvas.getBoundingClientRect();
            point.x -= rect.left;
            point.y -= rect.top;
          }),
          pairwise(),
        );
      }),
    ).subscribe((result: [Point, Point]) => {
      const [from, to] = result;
      this.draw(context, from, to);
    });
  }

  private draw(context: CanvasRenderingContext2D, fromPos: Point, toPos: Point) {
    context.beginPath();
    if (fromPos) {
      context.moveTo(fromPos.x, fromPos.y);
      context.lineTo(toPos.x, toPos.y);
      context.stroke();
    }
  }

  /**
   * Saves signature to a result object and closes dialog
   */
  public save() {
    const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    const signatureData: string = canvas.toDataURL('image/png');
    const signature = new AuditSignature(signatureData);
    this.dialogRef.close(signature);
  }
}
