<div *ngIf="!sendingRequest">
<div *ngIf="user === null">
  <h1>{{'contact.title' | translate}}</h1>
  <h3 class="contactus-subtitle subtitle">{{'contact.subtitle' | translate}}</h3>
</div>
<div *ngIf="user !== null"><h1>{{'contact.developer-feedback' | translate}}</h1></div>
<form class="form-height" [formGroup]="form">

  <mat-form-field class="form-inputs">
    <input matInput formControlName="username" [placeholder]="namePlaceholder" required [(ngModel)]="feedback.name" name="username" autocapitalize="none">
    <mat-error class="form-error" *ngIf="errorMessage('username')">{{errorMessage('username')}}</mat-error>
  </mat-form-field>

  <mat-form-field class="form-inputs">
    <input type="email" formControlName="email" matInput placeholder="{{'email' | translate}}" required [(ngModel)]="feedback.email" name="password">
    <mat-error class="form-error" *ngIf="errorMessage('email')">{{errorMessage('email')}}</mat-error>
  </mat-form-field>

  <label>{{'contact.message-body-label' | translate}}*</label>
  <div>
    <textarea class="mat-input-element feedback-textarea"
              formControlName="message"
              required
              [(ngModel)]="feedback.message"
              name="message"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="2"
              cdkAutosizeMaxRows="5">
    </textarea>
    <mat-error class="form-error" *ngIf="errorMessage('message')">{{errorMessage('message')}}</mat-error>
  </div>

</form>
</div>

<mat-progress-spinner mode="indeterminate" *ngIf="sendingRequest"></mat-progress-spinner>

<mat-dialog-actions>
  <button mat-raised-button color="primary" class="dialog-btn-left" [mat-dialog-close]="false"><mat-icon>clear</mat-icon> {{'cancel' | translate}}</button>
  <button mat-raised-button color="accent" class="dialog-btn-right" [disabled]="form.invalid && !sendingRequest" (click)="sendFeedback()"><mat-icon>send</mat-icon> {{'send' | translate}}</button>
</mat-dialog-actions>
