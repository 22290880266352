<div class="center mat-card submit-card" *ngIf="audit">
  <mat-dialog-actions class="submit-card-actions">
    <button mat-raised-button class="dialog-btn-left" color="primary" (click)="onCancelClicked()"><mat-icon>arrow_back</mat-icon> {{ 'back' | translate }}</button>
    <button mat-raised-button class="dialog-btn-right" color="accent" (click)="onSubmitClicked()"><mat-icon>send</mat-icon> {{ 'submit' | translate }}</button>
  </mat-dialog-actions>

  <meg-charts *ngIf="audit && auditForm && auditFormSchema" [audit]="audit" [auditForm]="auditForm" [auditFormSchema]="auditFormSchema"></meg-charts>

  <br>
  <meg-submit-preview-form [audit]="audit" [auditForm]="auditForm" [auditFormSchema]="auditFormSchema"></meg-submit-preview-form>
  <mat-dialog-actions class="submit-card-actions">
    <button mat-raised-button class="dialog-btn-left" color="primary" (click)="onCancelClicked()"><mat-icon>arrow_back</mat-icon> {{ 'back' | translate }}</button>
    <button mat-raised-button class="dialog-btn-right" color="accent" (click)="onSubmitClicked()"><mat-icon>send</mat-icon> {{ 'submit' | translate }}</button>
  </mat-dialog-actions>
</div>
