import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {AnalyticsService} from './analytics.service';
import {BackbuttonService} from './backbutton.service';
import {SettingsService} from './settings/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {AccountService} from './accounts.service';
import {IdleService} from './idle.service';
import {DEFAULT_LANGUAGE} from './api/constants';
import {User} from './api/models/user';
import {DOCUMENT} from '@angular/common';
import {NGXLogger} from 'ngx-logger';
import {isCordovaProject} from './utils/misc';
import {ModalService} from './alert/service/modal.service';
import {PushService} from './push-messages/push.service';
import {InsightsService} from './insights/insights.service';

@Component({
  selector: 'meg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'MAT Client';
  isIE = /msie\s|trident\//i.test(window.navigator.userAgent);
  constructor(private idle: IdleService, private analytics: AnalyticsService,
              private backButtonService: BackbuttonService, public settingsService: SettingsService,
              private translate: TranslateService, private accountService: AccountService, @Inject(DOCUMENT) private document: Document,
              private logger: NGXLogger, private translateService: TranslateService,
              private modalService: ModalService, private push: PushService, private insights: InsightsService) {
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
    let language = this.accountService.getLanguage() || this.translate.getBrowserLang();
    this.translate.use(language);
    this.logger.debug('Language', language);

    accountService.currentUser$.subscribe((user: User | null) => {
      // update language when user changes (login / logout)
      if (user === null) language = this.translate.getBrowserLang();
      else language = user.auditor.default_language || this.translate.getBrowserLang();
      this.translate.use(language);
      this.logger.debug('Language updated', language);
    });
  }

  ngOnInit(): void {
    this.settingsService.scaleLevel$.subscribe(
      level => {
        this.document.body.style.zoom = level.toString();
        // add the corresponding zoom level className to body
        const classLevel = Math.round((level - 1) * 10);
        const zoomClass = `zoom${classLevel > 0 ? classLevel : 1}`;
        Array.from(this.document.body.classList).forEach(className => {
          if (className.indexOf('zoom') === 0 && className !== zoomClass) {
            this.document.body.classList.remove(className);
          }
        });
        if (!this.document.body.classList.contains(zoomClass)) {
          this.document.body.classList.add(zoomClass);
        }
      }
    );
    this.backButtonService.installBackListener();
    if (this.isIE) {
      this.modalService.openWarningModal(this.translateService.get('old-browsers'));
    }
    this.push.setupPushNotifications();
    this.idle.startWatching();
  }

  ngAfterViewInit(): void {
    if (!isCordovaProject()) {
      // tslint:disable-next-line:no-unused-expression
      new SmartBanner({
        daysHidden: 15,   // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        // appStoreLanguage: 'us', // language code for the App Store (defaults to user's browser language)
        title: 'MEG Audit Tool',
        author: 'MEG Support Tools',
        button: 'VIEW',
        store: {
          ios: 'On the App Store',
          android: 'In Google Play',
          windows: 'In Windows store',
        },
        price: {
          ios: 'FREE',
          android: 'FREE',
          windows: 'FREE',
        },
        // theme: '', // put platform type ('ios', 'android', etc.) here to force single theme on all device
        icon: 'assets/images/store-icon.png', // full path to icon image if not using website icon image
        // force: 'ios' // Uncomment for platform emulation
      });
    }
  }
}
