var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseChart } from './base_chart';
var PieChart = /** @class */ (function (_super) {
    __extends(PieChart, _super);
    function PieChart() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PieChart.prototype.buildChart = function (widget) {
        var chartDataValues;
        if (widget.config.count_entries) {
            chartDataValues = this.chartCalculator.calculateNoOfEntries(widget);
        }
        else {
            chartDataValues = this.chartCalculator.getPieChartData(widget);
        }
        var chartValues = this.buildChartData(chartDataValues);
        var dataSets = [{ data: chartValues.data, backgroundColor: chartValues.colors }];
        var chartData = { labels: chartValues.labels, datasets: dataSets };
        var options = {
            title: {
                display: true,
                text: this.getChartTitles(widget),
            },
            legend: {
                display: true
            },
        };
        return { type: 'pie', data: chartData, options: options };
    };
    return PieChart;
}(BaseChart));
export { PieChart };
