import {Component, Injector, OnInit} from '@angular/core';
import {DownloadService} from '../loading/download.service';
import {Settings} from './settings';
import {SettingsService} from './settings.service';
import {MatCheckboxChange, MatSelectChange} from '@angular/material';
import {EXTRA_HIGH_SCALE, HIGH_SCALE, MEDIUM_SCALE, NORMAL_SCALE, ScaleLevel} from './scale_level';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {AccountService} from '../accounts.service';
import {LanguageService} from '../language.service';
import {Language} from '../api/models/language';
import {ProfileService} from '../profile/profile.service';
import {User} from '../api/models/user';
import {AuditService} from '../audit-form/audit.service';
import {Audit} from '../audit-form/audit';
import {BackupService} from '../backup.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {of} from 'rxjs/internal/observable/of';
import {AppVersion} from '../api/models/app-version';
import {AppUpdateService} from '../app-update.service';
import {BaseComponent} from '../base.component';
import {appData} from '../../../appData';
import {isNullOrUndefined} from '../utils/misc';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'meg-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css', '../../animations.css']
})

export class SettingsComponent extends BaseComponent implements OnInit {
  public downloadingForms = false;
  public settings: Settings;
  public scaleLevels$: Observable<ScaleLevel[]>;
  public availableLanguages: Language[] = [];
  public prefLanguageUpdating = false;
  public backupNumForms = 0;
  public backupProgress = 0;
  public appData = appData;
  public appVersionChecking = false;
  public user$: Observable<User>;

  constructor(public downloadService: DownloadService, public settingsService: SettingsService,
              private accountService: AccountService, private languageService: LanguageService,
              private profileService: ProfileService, private auditService: AuditService,
              private backupService: BackupService, public appUpdateService: AppUpdateService,
              protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.user$ = this.accountService.getUser();
    this.settingsService.readSettings().subscribe((settings: Settings) => this.settings = settings);
    this.subscriptions.push( this.languageService.getAllLanguages()
      .subscribe((languages: Language[]) => this.availableLanguages = languages));

    this.scaleLevels$ = this.translateService.stream([
      'settings.text-sizes.normal',
      'settings.text-sizes.medium',
      'settings.text-sizes.large',
      'settings.text-sizes.extra-large',
    ]).pipe(
      map((translations: { [id: string]: string }) => [
        new ScaleLevel(translations['settings.text-sizes.normal'], NORMAL_SCALE),
        new ScaleLevel(translations['settings.text-sizes.medium'], MEDIUM_SCALE),
        new ScaleLevel(translations['settings.text-sizes.large'], HIGH_SCALE),
        new ScaleLevel(translations['settings.text-sizes.extra-large'], EXTRA_HIGH_SCALE),
      ]),
    );
  }

  public downloadForms() {
    this.downloadFormsObservable().subscribe(
      () => {},
      (e) => {
        this.logger.debug(e);
        alert(this.translateService.instant('settings.refresh-audits-error'));
      },
    );
  }

  public downloadFormsObservable(): Observable<boolean[]> {
    this.downloadingForms = true;
    return this.downloadService.loadData().pipe(
      tap(() => this.downloadingForms = false),
      catchError((e) => {
        this.downloadingForms = false;
        return throwError(e);
      }),
    );
  }

  public changeShowOptionalFields(changeEvent: MatCheckboxChange) {
    this.settings.showOptionalFields = changeEvent.checked;
    this.saveSettings();
  }

  public changeZoom(scaleLevel: ScaleLevel) {
    this.settings.scaleValue = scaleLevel.value;
    this.saveSettings();
  }

  public changeLanguageSelection(changeEvent: MatSelectChange) {
    this.updateUserLanguage(changeEvent.source.value);
  }

  private updateUserLanguage(language: string) {
    const currentLanguage = this.translateService.currentLang;
    if (language === currentLanguage) return;

    this.logger.debug('Language change', language);
    this.prefLanguageUpdating = true;
    this.user$.pipe(
      tap((user: User) => user.auditor.language = language),
      mergeMap((user: User) => this.profileService.saveProfile(user).pipe(
        catchError((e) => {
          // Reset the selection
          user.auditor.language = currentLanguage;
          return throwError(e);
        }),
        tap(() => {
          this.logger.debug('Language changed', language);
          this.accountService.setLanguage(language);
          this.translateService.use(language);
        }),
        mergeMap(() => this.settingsService.saveSettings(this.settings)),
        mergeMap(() => this.downloadFormsObservable()),
        tap(() => this.prefLanguageUpdating = false),
        catchError((e) => {
          this.prefLanguageUpdating = false;
          this.logger.error(e);
          return this.translateService.get(['settings.update-language-error', 'retry']).pipe(
            mergeMap((translations: { [id: string]: string }) => this.snackbar.open(
              translations['settings.update-language-error'], translations['retry'], {duration: 2000}
            ).onAction()),
            tap(() => this.updateUserLanguage(language)),
          );
        }),
      )),
    ).subscribe();
  }

  public backupAudits() {
    // Set num forms to 1 immediately to prevent double-clicking before actual number of forms is known
    this.backupNumForms = 1;
    this.backupProgress = 0;
    this.accountService.getUser().pipe(
      map((user) => user.id),
      mergeMap((userId: number) => this.auditService.getAuditsByUser(userId)),
      tap((audits: Audit[]) => this.backupNumForms = audits.length),
      tap(() => {
        if (this.backupNumForms === 0) throw new Error(this.translateService.instant('settings.backup-audits-none'));
      }),
      map((audits: Audit[]): Observable<boolean>[] => audits.map((audit: Audit) => this.backupService.backup(audit).pipe(
        // Capture errors and flag as FALSE
        catchError((error) => {
          return of(false);
        }),
        tap(() => this.backupProgress++),
        map(() => true),
      ))),
      mergeMap((observables: Observable<boolean>[]): Observable<boolean[]> =>
        observables.length === 0 ? of(<boolean[]>[]) : forkJoin(observables)),
      // Count number of successful backups and create message
      map((results: boolean[]): number => results.filter((result) => result).length),
      mergeMap((numSuccessful: number) =>
        this.translateService.get('settings.backup-audits-complete', {
          num: numSuccessful,
        })),
    ).subscribe(
      (message: string) => this.snackbar.open(message, this.translateService.instant('ok'), {
        'duration': 5000,
      }),
      (error) => {
        this.logger.error('Backup error', error);
        this.snackbar.open(error, this.translateService.instant('ok'), {
          'duration': 5000,
        });
      },
    );
  }

  public checkAppVersion() {
    this.appVersionChecking = true;
    const subscription = this.appUpdateService.checkAppVersion().pipe(
      mergeMap((version: AppVersion ) => {
        const newVersionAvailable: boolean = !isNullOrUndefined(version);
        const interpolateParams: undefined | {} = newVersionAvailable ? {'version': version.app_version} : undefined;
        const buttonKey: string = newVersionAvailable ? 'login.update.update' : 'ok';
        const messageKey: string = newVersionAvailable ? 'login.update.update-available' : 'settings.check-app-version.no-update';
        return this.translateService.get( [messageKey, buttonKey], interpolateParams).pipe(
            map((result: { [id: string]: string }) => [result[messageKey], result[buttonKey]])
        ).pipe(
          tap(() => this.appVersionChecking = false),
          mergeMap((result: [string, string]) => {
            const [message, button] = result;
            return this.snackbar.open(message, button).onAction().pipe(
              tap(() => { if (newVersionAvailable) this.appUpdateService.openAppStore(); })
            );
          })
        );
      }),
      catchError((error: HttpErrorResponse, responseObservable: Observable<any>) => {
        this.logger.error(error);
        this.appVersionChecking = false;
        return this.snackbar.open(error.error.detail, this.translateService.instant('ok'), {'duration': 5000}).onAction();
      })
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  private saveSettings() {
    if (this.settings === undefined) this.logger.error('Cannot save settings: settings object is undefined');
    else this.settingsService.saveSettings(this.settings).subscribe();
  }
}
