import {Model} from './model';
import {IssueStatus} from '../../qip/issue-list/issue.service';

export class Room {
  id?: number;

  constructor(public name: string) {
  }

  public toString() {
    return name;
  }
}

export class IssuePhoto {
  constructor(
    // uri with content type and base64 repr of the image
    public photo: string,
    public issue: number | null = null,
  ) {
  }
}

export class IssueHandler extends Model {
  constructor(public name: string, public institution: number, public institutions?: number[], public audit_forms?: number[]) {
    super();
  }

  public toString(): string {
    return this.name;
  }
}

export const QIP_STATUS_OPEN_SLUG = 'open';
export const QIP_STATUS_IN_PROGRESS_SLUG = 'in_progress';

export class Issue {
  constructor(public comment: string, public room: Room | null = null, public client_key: string | null = null,
              public handler: number | null = null, public send_email: boolean = false, public id: number | null = null,
              public field_name: string| null = null, public audio: string | null = null, public actiontaken: string | null = null,
              public risk_level: string | null = null, public duedate: string | null = null,
              public qipstatus: string = QIP_STATUS_OPEN_SLUG,
              public status: IssueStatus = {slug: QIP_STATUS_OPEN_SLUG, label: 'Open', color: '#ea3d2b'},
              public photos: IssuePhoto[] = [], public audit_form?: number, public institution?: number,
              public recommendation: string | null = null) {
    if (this.duedate === null) {
      const defaultDueDate = new Date();
      defaultDueDate.setDate(defaultDueDate.getDate() + 14);
      this.duedate = defaultDueDate.toString();
    }
  }

  public toString(): string {
    return this.comment;
  }
}
