<mat-toolbar class="ios-container" color="primary">
  <button mat-icon-button [class.rotate]="!sideMenuChange" [class.rotateAgain]="sideMenuChange"  *ngIf="showMenu" (click)="onToggleMenu()">
    <div [class.burgerTop]="!sideMenuChange" [class.arrowTop]="sideMenuChange"></div>
    <div class="mid"></div>
    <div [class.burgerBot]="!sideMenuChange" [class.arrowBot]="sideMenuChange"></div>
  </button>

  <button mat-icon-button class="back-button" *ngIf="showBackButton" (click)="onBackClicked()">
    <mat-icon class="back-button-icon">arrow_back</mat-icon>
  </button>

  <span class="toolbar-spacer"></span>
    <img [src]="fullMatLogoPath" class="mat-placeholder">
  <span class="toolbar-spacer"></span>
  <button mat-icon-button *ngIf="showInformation" routerLink="/about-app">
    <mat-icon>info_outline</mat-icon>
  </button>
  <button mat-icon-button
          megTooltip="{{'audit-form.hint-review' | translate}}"
          [megTooltipKey]="hints.SubmitAudit"
          [megTooltipPosition]="tooltipPosition.BOTTOM_RIGHT"
          [megTooltipShowHint]="numObservations > 0"
          [megTooltipShow]="true"
          *ngIf="showAuditReviewButton"
          (click)="onAuditReviewPressed()">
    <mat-icon>file_upload</mat-icon>
    <span class="badge" *ngIf="numObservations">{{numObservations}}</span>
  </button>
  <div class="logo-container ios-container">
    <img [src]="fullMatLogoPath" class="mat-logo">
  </div>
</mat-toolbar>
