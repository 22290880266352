import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Feedback} from '../api/models/feedback';
import {User} from '../api/models/user';
import {AccountService} from '../accounts.service';
import {MatDialogRef} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'meg-feedback',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.css']
})
export class FeedbackDialogComponent implements OnInit {

  public form: FormGroup;
  public sendingRequest = false;
  public feedback = new Feedback(null, null, null);
  public user: User | null = null;

  constructor(
    public apiService: ApiService,
    private dialogRef: MatDialogRef<FeedbackDialogComponent>,
    public accountService: AccountService,
    private translateService: TranslateService,
  ) {
    this.accountService.getUser().subscribe((user: User) => {
      this.user = user;
      if (this.user) {
        this.feedback.email = this.user.email;
        this.feedback.name = this.user.username;
      }
    });
  }

  get namePlaceholder() {
    if (this.user) return this.translateService.instant('login.username');
    else return this.translateService.instant('contact.full-name');
  }

  ngOnInit() {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [Validators.required]),
    });
  }

  public sendFeedback() {
    if (this.form.valid) {
      this.sendingRequest = true;
      this.apiService.sendFeedback(this.feedback).subscribe(
        feedback => this.dialogRef.close(true),
        error => {
          this.sendingRequest = false;
          alert(this.translateService.instant('contact.error-message'));
        }
      );
    } else {
      Object.keys(this.form.controls)
        .map((key: string) => this.form.controls[key])
        .forEach((field: AbstractControl) => field.markAsTouched({onlySelf: true}));
    }
  }

  public errorMessage(controlName: string): string | null {
    const formControl = this.form.controls[controlName];
    if (formControl.invalid && formControl.touched && formControl.errors) {
      switch (controlName) {
        case 'username':
          return this.translateService.instant('audit-form.field.field-required');
        case 'message':
          return this.translateService.instant('audit-form.field.field-required');
        case 'email':
          return this.translateService.instant('contact.email-incorrect');
      }
    }
    return null;
  }
}
