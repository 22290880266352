<h1 class="title">{{auditForm?.name}}</h1>
<mat-card class="limit-width center subform-card subforms" *ngIf="auditFormSchema">
  <mat-nav-list>
    <a mat-list-item (click)="onFormSelect(form)" *ngFor="let form of auditFormSchema.sub_forms" [ngClass]="{'done': getObservationsForSubform(form).length > 0, 'allow-multiple': form.many}">
      <mat-icon class="mat-done-icon" *ngIf="getObservationsForSubform(form).length > 0" color="accent">done</mat-icon>
      <div class="form-completion-number" *ngIf="getObservationsForSubform(form).length > 0 && form.many"> {{ getObservationsForSubform(form).length }} </div>
      <span *ngIf="form.required">*</span><div class="ellipsis-3-lines">{{ form.display_name }}</div>
    </a>
  </mat-nav-list>
</mat-card>
