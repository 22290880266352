
export class Feedback {
  constructor(
    public email: string | null,
    public name: string | null,
    public message: string | null
  ) {
  }
}


export class FeedbackErrorResponse {
  constructor(
    public non_field_errors: string,
    public email: string,
    public name: string,
    public message: string,
  ) {
  }
}
