import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Subject} from 'rxjs';
import {WebcamImage} from 'ngx-webcam';
import {MatDialogRef} from '@angular/material';
import {NGXLogger} from 'ngx-logger';
import {addNameToBase64} from '../../utils/misc';

@Component({
  selector: 'meg-photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PhotoDialogComponent implements OnInit {
  public trigger = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<PhotoDialogComponent>, private logger: NGXLogger) {
  }

  ngOnInit() {
  }

  public onImageCapture(image: WebcamImage) {
    const filename = `capture_${new Date()}.jpeg`;
    const result = addNameToBase64(image.imageAsDataUrl, filename);
    this.dialogRef.close(result);
  }
}
