<div mat-dialog-title [ngClass]="alertClass">
  <mat-icon>{{getAlertIcon}}</mat-icon>
  {{ data.title|async }}
</div>

<mat-dialog-content>
  <div [innerHTML]="data.content|async"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <!--  Neutral -->
  <button *ngIf="data.neutralButton" mat-button color="primary" (click)="onClicked(data.neutralButton)" mat-dialog-close="null">
    <mat-icon *ngIf="data.neutralButton?.icon">{{data.neutralButton?.icon }}</mat-icon> {{ data.neutralButton?.label | async }}
  </button>

  <!--  Negative -->
  <button *ngIf="data.negativeButton" mat-button color="warn" (click)="onClicked(data.negativeButton)" mat-dialog-close="false">
    <mat-icon>{{data.negativeButton?.icon || 'clear' }}</mat-icon> {{ data.negativeButton?.label|async }}
  </button>

  <!--  Positive button -->
  <button mat-button color="accent" [class.single-button]="!data.negativeButton && !data.neutralButton" (click)="onClicked(data.positiveButton)" mat-dialog-close="true">
    <mat-icon>{{data.positiveButton.icon || 'check' }}</mat-icon> {{ data.positiveButton.label|async }}
  </button>
</mat-dialog-actions>

