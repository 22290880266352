import { Error } from 'tslint/lib/error';
import { DB_KEY_QIP_FILTER_STATE, StorageService } from './storage.service';
import { combineLatest, concat, forkJoin, of, ReplaySubject } from 'rxjs';
import { DB_KEY_AUDIT_FORMS } from './audit-form.service';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { DB_KEY_INSTITUTIONS } from './institution.service';
import { AuditService, DB_KEY_AUDIT_FORM_ID } from './audit-form/audit.service';
import { DB_KEY_DASHBOARDS } from './charts/dashboard.service';
import { DB_KEY_ISSUE_HANDLERS } from './qip/qip.service';
import { DB_KEY_INFORMATIONS, DEFAULT_LANGUAGE } from './api/constants';
import { NGXLogger } from 'ngx-logger';
import { ACCOUNT_TYPE_PUBLIC } from './api/models/auditor';
var STORAGE_KEY_API_TOKEN = 'api-token';
var STORAGE_KEY_USER = 'user';
var STORAGE_KEY_REGION = 'region';
var STORAGE_KEY_PASSWORD_POLICY = 'password_policy';
var STORAGE_LANGUAGE = 'language';
var COOKIES_CLEARED_ON_LOGOUT = [
    STORAGE_KEY_API_TOKEN,
    DB_KEY_AUDIT_FORM_ID,
];
var ITEMS_CLEARED_ON_LOGOUT = [
    STORAGE_KEY_USER,
    STORAGE_KEY_REGION,
    STORAGE_KEY_PASSWORD_POLICY,
    DB_KEY_AUDIT_FORMS,
    DB_KEY_INSTITUTIONS,
    DB_KEY_INFORMATIONS,
    DB_KEY_DASHBOARDS,
    DB_KEY_ISSUE_HANDLERS,
    DB_KEY_QIP_FILTER_STATE,
];
// Django permissions
export var PERMISSION_CHANGE_ISSUE = 'megforms.change_issue';
export var PERMISSION_DOCS_VIEW = [
    'megdocs.view_document',
    'megdocs.view_folder',
    'megdocs.view_folderpermissionrule'
];
export var PERMISSION_DOCS_MANAGE = PERMISSION_DOCS_VIEW.concat([
    'comments.view_comment',
    'comments.add_comment',
    'megdocs.add_bookmark',
    'megdocs.change_bookmark',
    'megdocs.delete_bookmark',
    'megdocs.view_bookmark',
    'megdocs.add_document',
    'megdocs.change_document',
    'megdocs.delete_document',
    'megdocs.change_document_owner',
    'megdocs.add_folder',
    'megdocs.change_folder',
    'megdocs.delete_folder',
    'megdocs.add_version',
    'megdocs.approve_version',
    'megdocs.change_version',
    'megdocs.delete_version',
]);
/**
 * Keeps user authentication information
 */
var AccountService = /** @class */ (function () {
    function AccountService(storageService, auditService, logger, document) {
        this.storageService = storageService;
        this.auditService = auditService;
        this.logger = logger;
        this.document = document;
        this.user$ = new ReplaySubject(0);
        this.token = storageService.getString(STORAGE_KEY_API_TOKEN);
        if (this.token) {
            this.getUser().subscribe(function () {
            }, function (error) { return logger.error(error); });
        }
    }
    AccountService.prototype.setLoggedIn = function (response) {
        var _this = this;
        if (!response.token)
            return of(false);
        var user = response.user;
        var token = response.token;
        this.token = token;
        this.storageService.setString(STORAGE_KEY_API_TOKEN, token);
        return combineLatest([
            this.storageService.setItem(STORAGE_KEY_REGION, response.region),
            this.storageService.setItem(STORAGE_KEY_PASSWORD_POLICY, response.policy)
        ]).pipe(mergeMap(function () { return _this.saveUserData(user); }), tap(function () {
            var result = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                result[_i] = arguments[_i];
            }
            if (result.every(function (x) { return x; })) {
                _this.token = response.token;
                _this.storageService.setString(STORAGE_KEY_API_TOKEN, token);
            }
            _this.user$.next(response.user);
        }));
    };
    AccountService.prototype.saveUserData = function (user) {
        return this.storageService.setItem(STORAGE_KEY_USER, user);
    };
    /**
     * Marks user as not logged in
     * @returns {Observable<boolean>}
     */
    AccountService.prototype.clearLogin = function () {
        var _this = this;
        this.auditService.setAuditId(null);
        this.auditService.clearAuditFormId();
        this.token = null;
        this.storageService.removeString(STORAGE_LANGUAGE);
        this.user$.next(null);
        COOKIES_CLEARED_ON_LOGOUT.forEach(function (key) { return _this.storageService.removeString(key); });
        var observables = ITEMS_CLEARED_ON_LOGOUT.map(function (key) { return _this.storageService.removeItem(key); });
        return forkJoin.apply(void 0, observables).pipe(map(function (results) { return results.every(function (value) { return value; }); }));
    };
    /**
     * Logs user out and performs related actions like clearing out empty audit and showing the loging screen
     */
    AccountService.prototype.logOut = function (auditService, router) {
        var _this = this;
        forkJoin(this.getUser().pipe(mergeMap(function (user) {
            if (user.auditor.account_type === ACCOUNT_TYPE_PUBLIC)
                return _this.auditService.deleteUserAudits(user).pipe(map(function () { return user; }));
            return _this.auditService.deleteEmptyAudits(user).pipe(map(function () { return user; }));
        })), this.clearLogin()).pipe(map(function (result) {
            var user = result[0];
            return user.logout_redirect || null;
        }), tap(function (url) {
            router.navigateByUrl('/login').then(function () {
                if (url)
                    _this.document.location.href = url;
            });
        })).subscribe();
    };
    /**
     * Retrieves currently logged in user from the database
     */
    AccountService.prototype.getUser = function () {
        return this.storageService.getItem(STORAGE_KEY_USER);
    };
    AccountService.prototype.getRegion = function () {
        return this.storageService.getItem(STORAGE_KEY_REGION);
    };
    AccountService.prototype.getPasswordPolicy = function () {
        return this.storageService.getItem(STORAGE_KEY_PASSWORD_POLICY);
    };
    AccountService.prototype.getUserPermissions = function () {
        return this.getUser().pipe(map(function (user) { return user.permissions || []; }));
    };
    AccountService.prototype.hasPermission = function (permission) {
        var _this = this;
        return this.getUserPermissions().pipe(map(function (userPermissions) { return userPermissions.indexOf(permission) > -1; }), tap(function (hasEditPermission) { return _this.logger.debug('Has permission', permission, hasEditPermission); }));
    };
    AccountService.prototype.hasPermissions = function (permissions) {
        var _this = this;
        return this.getUserPermissions().pipe(map(function (userPermissions) { return permissions.every(function (permission) {
            return userPermissions.indexOf(permission) > -1;
        }); }), tap(function (hasEditPermission) { return _this.logger.debug('Has permissions', permissions, hasEditPermission); }));
    };
    Object.defineProperty(AccountService.prototype, "currentUser$", {
        /**
         * an observable emitting current user, followed by updated user object if user changes.
         * Emits null when user is/changes to null (not logged in)
         */
        get: function () {
            return concat(this.getUser().pipe(take(1), 
            // emit null if no user is currently logged in
            catchError(function (e) { return of(null); })), this.user$);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Checks whether user is logged in
     */
    AccountService.prototype.isAuthenticated = function () {
        return this.token != null;
    };
    AccountService.prototype.getAuthorizationHeader = function () {
        if (!this.isAuthenticated()) {
            throw new Error('Not logged in');
        }
        return "Token " + this.token;
    };
    /**
     * Sets current user's preferred language.
     * The preference is stored locally for the duration of the login session.
     */
    AccountService.prototype.setLanguage = function (language) {
        var value = language || DEFAULT_LANGUAGE;
        this.logger.debug('User language set to', value);
        this.storageService.setString(STORAGE_LANGUAGE, value);
    };
    /**
     * Gets user-preferred language.
     * This reads local preference as set by setLanguage()
     */
    AccountService.prototype.getLanguage = function () {
        return this.storageService.getString(STORAGE_LANGUAGE);
    };
    AccountService.prototype.updateCaptchaToken = function (newToken) {
        this.captchaTokenSubj = newToken;
    };
    AccountService.prototype.clearCaptchaToken = function () {
        this.captchaTokenSubj = null;
    };
    return AccountService;
}());
export { AccountService };
