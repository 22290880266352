var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SelectAvatarDialogComponent } from './select-avatar-dialog/select-avatar-dialog.component';
import { DEFAULT_AVATAR_PATH } from '../api/models/avatar';
import { ProfileService } from './profile.service';
import { BaseComponent } from '../base.component';
import { catchError, map, tap } from 'rxjs/operators';
import { AccountService } from '../accounts.service';
import { of } from 'rxjs/internal/observable/of';
import { isNullOrUndefined } from '../utils/misc';
import { AppConfigService } from '../app-config.service';
var ProfileComponent = /** @class */ (function (_super) {
    __extends(ProfileComponent, _super);
    function ProfileComponent(dialog, profileService, accountService, injector, appConfig) {
        var _this = _super.call(this, injector) || this;
        _this.dialog = dialog;
        _this.profileService = profileService;
        _this.accountService = accountService;
        _this.injector = injector;
        _this.appConfig = appConfig;
        _this.institution = null;
        _this.updatingAvatar = false;
        _this.defaultAvatarPath = DEFAULT_AVATAR_PATH;
        _this.region = null;
        return _this;
    }
    Object.defineProperty(ProfileComponent.prototype, "region$", {
        get: function () {
            var _this = this;
            return this.accountService.getRegion().pipe(map(function (region) {
                if (isNullOrUndefined(region))
                    return _this.appConfig.getDefaultRegion();
                else
                    return region;
            }), catchError(function () { return of(_this.appConfig.getDefaultRegion()); }));
        },
        enumerable: true,
        configurable: true
    });
    ProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.profileService.getUserAndInstitution().subscribe(function (result) { return _this.user = result[0], _this.institution = result[1], result; }, function (error) { return _this.logger.error('Error loading user and institution data', error); });
        this.profileService.getUserAvatar().subscribe(function (avatar) { return _this.userAvatar = avatar; }, function (error) { return _this.logger.error('Error loading user avatar', error); });
        this.region$.subscribe(function (region) { return _this.region = region; });
    };
    ProfileComponent.prototype.editAvatar = function () {
        var _this = this;
        var dialog = this.dialog.open(SelectAvatarDialogComponent, {
            data: this.userAvatar,
        });
        dialog.afterClosed().subscribe(function (result) {
            if (result !== false)
                _this.changeAvatar(result);
        });
    };
    /**
     * Updates UI with the new avatar and saves changes
     * @param {Avatar | null} avatar
     */
    ProfileComponent.prototype.changeAvatar = function (avatar) {
        var _this = this;
        this.updatingAvatar = true;
        this.user.auditor.avatar = avatar !== null ? avatar.id : null;
        var profileSubscription = this.profileService.saveProfile(this.user).pipe(tap(function () {
            _this.updatingAvatar = false;
            _this.userAvatar = avatar;
        }), catchError(function (error, caught) {
            _this.updatingAvatar = false;
            _this.logger.error(error);
            var message = _this.translateService.instant('profile.update-error');
            var action = _this.translateService.instant('retry');
            return _this.snackbar.open(message, action, {}).onAction().pipe(tap(function () { return _this.changeAvatar(avatar); }));
        })).subscribe();
        this.subscriptions.push(profileSubscription);
    };
    return ProfileComponent;
}(BaseComponent));
export { ProfileComponent };
