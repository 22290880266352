export const environment = {
  production: true,
  baseURL: 'audits.megsupporttools.com',
  storagePrefix: '',
  urbanairship_keys: {
    appKey: 'h4HtMf5lSFy3619Ep5rrbg',
    appSecret: 'xNN3VqLiQWOnk825yVHjNA'
  },
  appInsights: {
    instrumentationKey: 'b96cdd9b-7ab9-414d-b56b-4c478bc83280',
  },
};
