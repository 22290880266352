<section class="qip-issues-dialog">
  <div mat-dialog-title class="qip-issues-list-header">
    <h1 class="left">{{ issue_label_plural | async |titlecase }}</h1>
    <button mat-raised-button color="primary" (click)="openNewIssue()" *ngIf="showNewIssueButton && !showNewIssue"><mat-icon>add</mat-icon> {{ 'qip.add-new-issue'| translate:{'issue_label':issue_label | async} }}</button>
    <button mat-raised-button color="primary" (click)="cancelNewIssue()" *ngIf="showNewIssue"><mat-icon>clear</mat-icon> {{ 'qip.cancel-new-issue'| translate:{'issue_label':issue_label | async} }}</button>
  </div>
  <mat-dialog-content class="common-issues-content">
    <mat-accordion multi="true"
                   megTooltip="{{'qip.common-issue-list-hint' | translate:{'issue_label':issue_label | async} }}"
                   [megTooltipShowHint]="true"
                   [megTooltipShow]="false"
                   [megTooltipPosition]="tooltipPosition.TOP"
                   [megTooltipKey]="hints.CommonIssues"
    >
      <!-- Add custom issue -->
      <mat-expansion-panel class="qip-issues-list-group" *ngIf="showNewIssue" [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'60px'" class="issue-expansion">
          <mat-panel-title>
            <mat-checkbox class="issue-checkbox" [checked]="false" (change)="toggleNewIssue(newIssue, $event)" (click)="$event.stopPropagation()" [disabled]="!newIssue.comment"></mat-checkbox>
            <p class="issue-name ellipsis-3-lines">{{ newIssue.comment ? newIssue.comment : 'qip.new-issue'|translate:{'issue_label':issue_label | async} }}</p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <label>{{'qip.describe-issue' | translate:{'issue_label':issue_label | async} }}:</label>
          <mat-form-field class="no-underline">
            <textarea matInput
                      [(ngModel)]="newIssue.comment"
                      spellcheck="true"
                      autocomplete="true"
                      (ngModelChange)="onNewIssueCommentChange($event)"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="4"></textarea>
          </mat-form-field>
        </div>
        <mat-error *ngIf="commentsErrors['comment_new_issue']">{{ commentsErrors['comment_new_issue'] }}</mat-error>
        <mat-error *ngIf="errorsNewIssue && errorsNewIssue.comment">{{ errorsNewIssue.comment }}</mat-error>

        <ng-template matExpansionPanelContent>
          <meg-issue #newIssueComponent [issue]="newIssue" [audit]="audit" (edit)="onEdited($event)" [errors]="(errorsNewIssue ? errorsNewIssue : null) || null"></meg-issue>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="qip-issues-list-group" *ngFor="let issue of items; let n = index">
        <mat-expansion-panel-header [@.disabled]="isDisabled" [collapsedHeight]="'60px'" class="issue-expansion">
          <mat-panel-title>
            <mat-checkbox class="issue-checkbox" [checked]="isAdded(issue)" (change)="toggleIssue(issue, $event)" (click)="$event.stopPropagation()"></mat-checkbox>
            <p class="issue-name ellipsis-3-lines">
              {{ issue.comment }}
              <span *ngIf="issue.field_name && isAdded(issue)">({{getIssueQuestion(issue) | async}})</span>
              <mat-chip-list *ngIf="isNewIssue(issue)" class="inline-chip"><mat-chip color="warn">New</mat-chip></mat-chip-list>
            </p>
          </mat-panel-title>
          <mat-icon class="error_icon" color="error" *ngIf="hasError(n)">error_outline</mat-icon>
        </mat-expansion-panel-header>
        <!--Issue comment section-->
        <div>
          <label>{{'qip.describe-issue' | translate:{'issue_label':issue_label | async} }}:</label>
          <mat-form-field class="no-underline">
            <textarea matInput
                      [(ngModel)]="issue.comment"
                      spellcheck="true"
                      autocomplete="true"
                      (ngModelChange)="onCommentChange($event, n)"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="2"
                      cdkAutosizeMaxRows="4"></textarea>
          </mat-form-field>
        </div>
        <mat-error *ngIf="commentsErrors['comment_'+n]">{{ commentsErrors['comment_'+n] }}</mat-error>
        <mat-error *ngIf="errors && errors[n] && errors[n].comment">{{ errors[n].comment }}</mat-error>

        <ng-template matExpansionPanelContent>
          <meg-issue #issueComponents [issue]="issue" [audit]="audit" (edit)="onEdited($event)" [errors]="(errors ? errors[n] : null) || null"></meg-issue>
        </ng-template>

    </mat-expansion-panel>
    </mat-accordion>
  </mat-dialog-content>
  <mat-dialog-actions class="qip-issues-list-footer">
    <button mat-raised-button color="warn" class="issue-btn-left dialog-btn-left" [mat-dialog-close]="true"><mat-icon>clear</mat-icon> {{'qip.close-issues' | translate:{'issue_label':issue_label_plural | async} }}</button>
    <button mat-raised-button color="accent" class="issue-btn-right dialog-btn-right" (click)="onConfirm()"><mat-icon>check</mat-icon> {{'confirm' | translate}}</button>
  </mat-dialog-actions>
</section>
