import { MatDialog } from '@angular/material';
import { AlertComponent, AlertType } from '../alert.component';
import { ModalButton, ModalAlertData } from '../modal-alert-data';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngx-translate/core";
var ModalService = /** @class */ (function () {
    function ModalService(dialog, translateService) {
        this.dialog = dialog;
        this.translateService = translateService;
    }
    /**
     * Opens custom modal with callback function.
     */
    ModalService.prototype.openCustomModal = function (data) {
        return this.dialog.open(AlertComponent, { data: data }).afterClosed();
    };
    ModalService.prototype.openWarningModal = function (message, btnPositive, btnNegative, btnNeutral) {
        if (!btnPositive)
            btnPositive = this.btnOK();
        return this.openCustomModal(new ModalAlertData(this.translateService.get('warning'), message, AlertType.WARNING, btnPositive, btnNegative, btnNeutral));
    };
    ModalService.prototype.openErrorModal = function (message, btnPositive, btnNegative) {
        if (!btnPositive)
            btnPositive = this.btnOK();
        return this.openCustomModal(new ModalAlertData(this.translateService.get('error'), message, AlertType.ERROR, btnPositive, btnNegative));
    };
    ModalService.prototype.openInfoModal = function (message, btnPositive, btnNegative, btnNeutral) {
        if (!btnPositive)
            btnPositive = this.btnOK();
        return this.openCustomModal(new ModalAlertData(this.translateService.get('info'), message, AlertType.INFO, btnPositive, btnNegative, btnNeutral));
    };
    ModalService.prototype.btnOK = function (onclick) {
        return new ModalButton(this.translateService.get('ok'), onclick, 'check');
    };
    ModalService.prototype.btnCancel = function (onclick) {
        return new ModalButton(this.translateService.get('cancel'), onclick, 'close');
    };
    ModalService.ngInjectableDef = i0.defineInjectable({ factory: function ModalService_Factory() { return new ModalService(i0.inject(i1.MatDialog), i0.inject(i2.TranslateService)); }, token: ModalService, providedIn: "root" });
    return ModalService;
}());
export { ModalService };
