import { DownloadAuditDialogComponent } from './download-audit-dialog/download-audit-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from './loading/download.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ModalService } from './alert/service/modal.service';
import { ModalButton } from './alert/modal-alert-data';
import { tap } from 'rxjs/internal/operators/tap';
import { AnalyticsService, CATEGORY_UI } from './analytics.service';
import { AuditFormService } from './audit-form.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./loading/download.service";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "@angular/material/dialog";
import * as i5 from "./alert/service/modal.service";
import * as i6 from "./analytics.service";
import * as i7 from "./audit-form.service";
/**
 * AuditCheckStatus enum for the result of the audit form check
 * NO_UPDATE - there is no update available for the audit form passed in
 * UPDATE_NOT_DONE - there is an update but the user has declined to update now
 * UPDATE_COMPLETE - there is an update available and the user has updated that audit form
 */
export var AuditCheckStatus;
(function (AuditCheckStatus) {
    AuditCheckStatus[AuditCheckStatus["NO_UPDATE"] = 0] = "NO_UPDATE";
    AuditCheckStatus[AuditCheckStatus["UPDATE_NOT_DONE"] = 1] = "UPDATE_NOT_DONE";
    AuditCheckStatus[AuditCheckStatus["UPDATE_COMPLETE"] = 2] = "UPDATE_COMPLETE";
})(AuditCheckStatus || (AuditCheckStatus = {}));
var CheckAuditFormService = /** @class */ (function () {
    function CheckAuditFormService(translateService, downloadService, snackbar, dialog, modal, analytics, auditFormService) {
        this.translateService = translateService;
        this.downloadService = downloadService;
        this.snackbar = snackbar;
        this.dialog = dialog;
        this.modal = modal;
        this.analytics = analytics;
        this.auditFormService = auditFormService;
    }
    /**
     * Check audit for if update is available, if there is an update it will show a SnackBar dialog with option to update
     * Shows the Download Audit Dialog if the update button is pressed. The method also takes an optional callback method that
     * returns AuditCheckStatus
     * @param auditForm: the form which to check
     * @param callBack: optional call back once download is complete
     */
    CheckAuditFormService.prototype.checkAuditFormUpdate = function (auditForm, callBack) {
        var _this = this;
        this.auditFormService.isSchemaSaved(auditForm.id).pipe(tap(function (isSaved) {
            if (isSaved)
                _this.showUpdateDialog(auditForm, callBack);
            else
                _this.showDownloadDialog(auditForm, callBack);
        })).subscribe(undefined, function () {
            if (callBack)
                callBack(AuditCheckStatus.NO_UPDATE);
        }, undefined);
    };
    /**
     * Shows dialog asking user to download the form if it's not downloaded
     */
    CheckAuditFormService.prototype.showDownloadDialog = function (auditForm, callBack) {
        var _this = this;
        var formName = auditForm.name;
        var formId = auditForm.id;
        var updateBtn = new ModalButton(this.translateService.get('form-update.download'), function () {
            _this.dialog.open(DownloadAuditDialogComponent, { data: { id: formId, name: formName } }).afterClosed()
                .pipe(tap(function (dialogResult) {
                if (callBack)
                    callBack(dialogResult ? AuditCheckStatus.UPDATE_COMPLETE : AuditCheckStatus.UPDATE_NOT_DONE);
            })).subscribe();
        }, 'cloud_download');
        this.modal.openWarningModal(this.translateService.get('form-update.form_needs_download'), updateBtn, this.modal.btnCancel()).subscribe(function (button) {
            if (button instanceof ModalButton)
                button.label.subscribe(function (label) { return _this.analytics.trackUserAction('Form update', label); });
            else
                _this.analytics.trackUserAction('Form update', 'clicked outside of update dialog');
        });
    };
    /**
     * Checks if current form is up to date and displays update dialog if its not
     */
    CheckAuditFormService.prototype.showUpdateDialog = function (auditForm, callBack) {
        var _this = this;
        var formName = auditForm.name;
        var formId = auditForm.id;
        this.downloadService.checkAuditFormUpdate(formId).subscribe(function (updateAvailable) {
            if (!updateAvailable && callBack)
                callBack(AuditCheckStatus.NO_UPDATE);
            if (updateAvailable) {
                _this.dialog.open(DownloadAuditDialogComponent, { data: { id: formId, name: formName } }).afterClosed()
                    .pipe(tap(function (dialogResult) {
                    if (callBack)
                        callBack(dialogResult ? AuditCheckStatus.UPDATE_COMPLETE : AuditCheckStatus.UPDATE_NOT_DONE);
                })).subscribe();
                _this.analytics.trackEvent(CATEGORY_UI, 'Auto updating form');
            }
        });
    };
    CheckAuditFormService.ngInjectableDef = i0.defineInjectable({ factory: function CheckAuditFormService_Factory() { return new CheckAuditFormService(i0.inject(i1.TranslateService), i0.inject(i2.DownloadService), i0.inject(i3.MatSnackBar), i0.inject(i4.MatDialog), i0.inject(i5.ModalService), i0.inject(i6.AnalyticsService), i0.inject(i7.AuditFormService)); }, token: CheckAuditFormService, providedIn: "root" });
    return CheckAuditFormService;
}());
export { CheckAuditFormService };
