import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {MatDatepicker} from '@angular/material/datepicker';

import {Moment} from 'moment';
import {CustomDatePickerComponent} from '../custom-date-picker/custom-date-picker.component';

export const SHORT_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY MMMM',
  },
  display: {
    dateInput: 'YYYY MMMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'meg-date-picker',
  templateUrl: './short-date-picker.component.html',
  styleUrls: ['./short-date-picker.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ShortDatePickerComponent), multi: true },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: SHORT_DATE_FORMATS},
  ],
})

export class ShortDatePickerComponent extends CustomDatePickerComponent {

  public format = SHORT_DATE_FORMATS.parse.dateInput;

  chosenMonthHandler(dateMoment: Moment, datepicker: MatDatepicker<Moment>) {
    this.dateChanged(dateMoment.toDate());
    datepicker.close();
  }

}
