var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuditService } from '../audit-form/audit.service';
import { AccountService } from '../accounts.service';
import { AnalyticsService } from '../analytics.service';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { BaseComponent } from '../base.component';
var ToolbarComponent = /** @class */ (function (_super) {
    __extends(ToolbarComponent, _super);
    function ToolbarComponent(router, auditService, accountService, analytics, location, injector) {
        var _this = _super.call(this, injector) || this;
        _this.router = router;
        _this.auditService = auditService;
        _this.accountService = accountService;
        _this.analytics = analytics;
        _this.location = location;
        _this.injector = injector;
        _this.toggleMenuEvent = new EventEmitter();
        _this.sideMenuChange = false;
        _this.showMenu = true;
        _this.showInformation = false;
        _this.showBackButton = false;
        _this.auditIdSubscription = null;
        _this.auditId = null;
        _this.numObservations = 0;
        _this.showNextButton = false;
        _this.fullMatLogoPath = 'assets/images/logos/MEG-Logo-White.svg';
        return _this;
    }
    ToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        /**
         * using router.url when navigating from walkThrough
         */
        this.showMenu = this.accountService.isAuthenticated() && this.router.url !== '/loading';
        this.showInformation = this.router.url === '/login';
        this.showBackButton = !this.accountService.isAuthenticated() && this.router.url !== '/login';
        /**
         * subscribing to router.events when reloading page
         */
        this.router.events.subscribe(function (url) {
            _this.logger.trace('events url', url);
            if ((url['url']) !== undefined) {
                _this.showMenu = _this.accountService.isAuthenticated() && url['url'] !== '/loading';
                _this.showInformation = url['url'] === '/login';
                _this.showBackButton = !_this.accountService.isAuthenticated() && url['url'] !== '/login';
            }
        });
        this.auditIdSubscription = this.auditService.auditId$.pipe(tap(function (auditId) { return _this.auditId = auditId; }), tap(function (auditId) {
            if (auditId === null)
                _this.numObservations = 0;
            else {
                _this.auditService.getAuditWithFormSchema(auditId).subscribe(function (_a) {
                    var audit = _a[0], auditForm = _a[1], auditFormSchema = _a[2];
                    _this.numObservations = audit.auditSession.observations.length;
                    _this.showNextButton = auditForm.config.save_next_observation && _this.numObservations > 0;
                });
            }
        })).subscribe();
    };
    ToolbarComponent.prototype.ngOnDestroy = function () {
        if (this.auditIdSubscription !== null) {
            this.auditIdSubscription.unsubscribe();
            this.auditIdSubscription = null;
        }
    };
    ToolbarComponent.prototype.onToggleMenu = function () {
        this.sideMenuChange = !this.sideMenuChange;
        this.toggleMenuEvent.emit(this.sideMenuChange);
        this.analytics.trackButtonClick('Menu');
    };
    Object.defineProperty(ToolbarComponent.prototype, "showAuditReviewButton", {
        get: function () {
            if (!this.showNextButton)
                return false;
            var url = this.router.url;
            return this.auditId !== null && (!url.startsWith('/audit-preview') && !url.startsWith('/submit-preview'));
        },
        enumerable: true,
        configurable: true
    });
    ToolbarComponent.prototype.onAuditReviewPressed = function () {
        this.hintService.markShown(this.hints.SubmitAudit);
        this.router.navigate(['/audit-preview', this.auditId]);
    };
    ToolbarComponent.prototype.onBackClicked = function () {
        this.location.back();
    };
    return ToolbarComponent;
}(BaseComponent));
export { ToolbarComponent };
