import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {AccountService} from '../accounts.service';
import {forkJoin, Observable, of} from 'rxjs';
import {Avatar} from '../api/models/avatar';
import {User} from '../api/models/user';
import {map, mergeMap, tap} from 'rxjs/operators';
import {InstitutionService} from '../institution.service';
import {Institution} from '../api/models/institution';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class ProfileService {

  constructor(private apiService: ApiService, private accountService: AccountService, private institutionService: InstitutionService,
              private logger: NGXLogger) {
  }

  /**
   * Gets current user's avatar or null if user has no avatar or avatar cannot be found
   */
  public getUserAvatar(): Observable<Avatar | null> {
    return this.accountService.getUser().pipe(
      map((user: User) => user.auditor.avatar),
      mergeMap((avatarId: number | null) => {
        if (avatarId === null) return of(null);
        else return this.apiService.getAvatars().pipe(
          map((response) => response.results.find((avatar) => avatar.id === avatarId) || null),
        );
      }),
    );
  }

  public getUserAndInstitution(): Observable<[User, Institution]> {
    return this.accountService.getUser().pipe(
      mergeMap((user: User) => this.institutionService.getInstitutionOrNull(user.auditor.institution).pipe(
        map((institution: Institution | null) => <[User, Institution]>[user, institution]),
      )),
    );
  }

  /**
   * Saves profile changes locally and in the back-end
   * @param {User} user
   * @return {Observable<boolean>}
   */
  public saveProfile(user: User): Observable<boolean> {
      return this.apiService.updateAuditor(user.auditor).pipe(
        mergeMap(() => this.accountService.saveUserData(user)),
        map(() => true),
      );
  }

  /**
   * increments number of audits submitted by user
   * @param {number} increment number to increase by, default is 1
   * @return {Observable<number>} new number of submitted audits
   */
  public incrementNumAudits(increment: number = 1): Observable<number> {
    return this.accountService.getUser().pipe(
      tap((user: User) => user.auditor.num_audits += increment),
      mergeMap((user: User) => this.accountService.saveUserData(user).pipe(
        map(() => user.auditor.num_audits),
        tap((numAudits: number) => this.logger.debug('Incremented number of submitted audits to', numAudits)),
      )),
    );
  }

}
