<div class="preview-container">
  <h1 class="title">
    {{ auditForm?.name }}
  </h1>

  <h2 class="subtitle">
    {{ ward?.name }}
  </h2>
  <meg-charts *ngIf="audit && auditForm && auditFormSchema && auditForm.config.merge_submit_preview" [audit]="audit" [auditForm]="auditForm" [auditFormSchema]="auditFormSchema"></meg-charts>
  <br>
  <meg-table
    megTooltip="{{'audit-preview.hint-edit' | translate}}"
    [megTooltipKey]="hints.ObservationEdit"
    [megTooltipShow]="false"
    [megTooltipShowHint]="true"
    [megTooltipPosition]="tooltipPosition.TOP"
    *ngIf="audit && auditFormSchema && auditForm" [audit]="audit"
             [auditFormSchema]="auditFormSchema" [auditForm]="auditForm">
  </meg-table>
  <br>
  <meg-submit-preview-form *ngIf="auditForm.config.merge_submit_preview" [audit]="audit" [auditForm]="auditForm" [auditFormSchema]="auditFormSchema"></meg-submit-preview-form>
  <div class="preview-btn-container">
      <button *ngIf="!isAutoCycleComplete && auditForm.config.save_next_observation" mat-raised-button
              megTooltip="{{'audit-preview.hint-add-form' | translate}}"
              [megTooltipPosition]="tooltipPosition.LEFT"
              [megTooltipShow]="true"
              [megTooltipShowHint]="true"
              [megTooltipKey]="hints.AddForm"
              class="meg-preview-button-center preview-btn" color="primary" (click)="addForm()">
        <mat-icon>add</mat-icon>
        {{ 'audit-preview.add-form' | translate }}
      </button>
      <div class="spacer"></div>
      <button mat-raised-button class="meg-preview-button-left preview-btn" color="warn" (click)="deleteAudit()">
        <mat-icon>delete</mat-icon>
        {{ 'delete' | translate }}
      </button>
      <button *ngIf="auditForm.config.merge_submit_preview" mat-raised-button class="meg-preview-button-right preview-btn" color="accent" (click)="onSubmitClicked()"><mat-icon>send</mat-icon> {{ 'submit' | translate }}</button>
      <button *ngIf="!auditForm.config.merge_submit_preview" mat-raised-button class="meg-preview-button-right preview-btn" color="accent" (click)="confirmAudit()"
              [disabled]="audit && (audit.auditSession.observations.length === 0 || audit.submitted)">
        {{ 'next' | translate }}
        <mat-icon>arrow_forward</mat-icon>
      </button>
  </div>
</div>
