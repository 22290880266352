var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BaseLoginRequiredComponent } from '../base';
import { AccountService } from '../accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DownloadService } from './download.service';
import { NGXLogger } from 'ngx-logger';
/**
 * Downloads user data such as list of institutions, available audit forms and their schema to the app for offline use.
 */
var LoadingComponent = /** @class */ (function (_super) {
    __extends(LoadingComponent, _super);
    function LoadingComponent(router, route, accountService, downloadService, logger) {
        var _this = _super.call(this, router, route, accountService, logger) || this;
        _this.route = route;
        _this.downloadService = downloadService;
        _this.subscription = null;
        _this.error = null;
        _this.showErrorDetails = false;
        return _this;
    }
    LoadingComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.accountService.isAuthenticated()) {
            this.loadData();
        }
    };
    LoadingComponent.prototype.ngOnDestroy = function () {
        if (this.subscription !== null) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    };
    LoadingComponent.prototype.loadData = function () {
        var _this = this;
        this.error = null;
        this.subscription = this.downloadService.loadData().subscribe(function () { }, function (error) {
            _this.error = error;
        }, function () {
            _this.route.queryParamMap.subscribe(function (params) {
                _this.subscription = null;
                var next = params.get('next');
                if (next) {
                    _this.router.navigateByUrl(next);
                }
                else {
                    _this.router.navigateByUrl('/');
                }
            });
        });
    };
    LoadingComponent.prototype.logOut = function () {
        this.accountService.clearLogin();
        this.router.navigate(['login']);
    };
    return LoadingComponent;
}(BaseLoginRequiredComponent));
export { LoadingComponent };
