var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { Audit } from '../../audit-form/audit';
import { AuditFormSchema } from '../../api/models/audit-form-schema';
import { AuditForm, LayoutType } from '../../api/models/audit-form';
import { Router } from '@angular/router';
import { isObjectEmpty } from '../../utils/misc';
import { BaseComponent } from '../../base.component';
import { Hints } from '../../hint.service';
var TableComponent = /** @class */ (function (_super) {
    __extends(TableComponent, _super);
    function TableComponent(router, injector) {
        var _this = _super.call(this, injector) || this;
        _this.router = router;
        _this.injector = injector;
        _this.columns = null;
        _this.displayedColumns = null;
        return _this;
    }
    TableComponent.prototype.ngOnInit = function () {
        this.buildTableColumns();
    };
    TableComponent.prototype.buildTableColumns = function () {
        var _this = this;
        this.translateService.get(['form', 'subform']).subscribe(function (translations) {
            _this.columns = [];
            if (_this.auditForm.config.app_review_fields.length > 0) {
                var _loop_1 = function (appReviewField) {
                    var fieldName = appReviewField.split('.').pop();
                    var field = _this.auditFormSchema.fields.find(function (f) { return f.field_name === fieldName; });
                    if (field !== undefined) {
                        _this.columns.push({ columnDef: field.field_name, header: field.label });
                    }
                    else {
                        for (var _i = 0, _a = _this.auditFormSchema.sub_forms; _i < _a.length; _i++) {
                            var subForm = _a[_i];
                            field = subForm.fields.find(function (f) { return f.field_name === fieldName; });
                            if (field !== undefined) {
                                _this.columns.push({ columnDef: field.field_name, header: field.label });
                            }
                        }
                    }
                };
                for (var _i = 0, _a = _this.auditForm.config.app_review_fields; _i < _a.length; _i++) {
                    var appReviewField = _a[_i];
                    _loop_1(appReviewField);
                }
            }
            else if (_this.auditFormSchema.sub_forms.length > 0 && _this.auditForm.config.form_layout === LayoutType.Default) {
                _this.columns.push({ columnDef: 'sub_form', header: translations['subform'] });
            }
            else {
                _this.columns.push({ columnDef: 'form', header: translations['form'] });
            }
            /**
             * list of values for columns in the table, position is hardcoded to display the number and cell_error if that observation has errors
             * @type {(string | any)[]}
             */
            _this.displayedColumns = ['position'].concat(_this.columns.map(function (c) { return c.columnDef; }), ['cell_error']);
        });
    };
    TableComponent.prototype.isAccordionLayout = function () {
        return this.auditForm.config.form_layout === LayoutType.Accordion;
    };
    TableComponent.prototype.getSubFormName = function (observation) {
        return this.auditFormSchema.sub_forms
            .filter(function (subForm) { return (observation[subForm.name] !== undefined); })
            .map(function (subForm) { return subForm.display_name; })
            .join(',');
    };
    /**
     * Returns a Answer or null for the cell from current observation row and field name
     * @param observation: Observation, current observation row
     * @param fieldName: String for field name
     */
    TableComponent.prototype.getCellValue = function (observation, fieldName) {
        if (this.auditForm.config.app_review_fields.length > 0) {
            for (var _i = 0, _a = this.auditFormSchema.sub_forms; _i < _a.length; _i++) {
                var subForm = _a[_i];
                var subObservation = observation[subForm.name];
                if (subObservation !== undefined) {
                    if (subObservation[fieldName]) {
                        return subObservation[fieldName];
                    }
                }
            }
            if (observation[fieldName]) {
                return observation[fieldName];
            }
        }
        else if (this.auditFormSchema.sub_forms.length > 0 && this.auditForm.config.form_layout === LayoutType.Default) {
            return this.getSubFormName(observation);
        }
        else {
            return this.auditForm.name;
        }
        return null;
    };
    TableComponent.prototype.onRowClicked = function (observation, rowIndex) {
        this.hintService.markShown(Hints.ObservationEdit);
        if (this.auditFormSchema === undefined || this.audit === undefined) {
            throw Error('error loading audit form');
        }
        else if (!this.audit.submitted) {
            var subForm = this.auditFormSchema.sub_forms.find(function (subform) { return observation[subform.name] !== undefined; });
            if (subForm !== undefined && !this.isAccordionLayout()) {
                this.router.navigate(["audit-form", this.audit.id, "subform", subForm.name, 'edit', rowIndex]);
            }
            else {
                this.router.navigate(["audit-form", this.audit.id, 'edit', rowIndex]);
            }
        }
    };
    /**
     * non-field errors in the observation
     * @param index index of the observation in session
     */
    TableComponent.prototype.getObservationErrors = function (index) {
        var errors = this.audit.errors;
        if (!errors)
            return [];
        var observationErrors = errors.observations[index];
        if (!observationErrors)
            return [];
        var result = [];
        if (observationErrors.non_field_errors)
            result.push.apply(result, observationErrors.non_field_errors);
        this.auditFormSchema.sub_forms.forEach(function (subform) {
            var subformErrors = observationErrors[subform.name];
            if (subformErrors && subformErrors.non_field_errors) {
                result.push.apply(result, subformErrors.non_field_errors);
            }
        });
        result.forEach(this.logger.log);
        return result;
    };
    TableComponent.prototype.hasObservationErrors = function (index) {
        if (this.audit.errors === null) {
            return false;
        }
        else if (this.audit.errors.observations !== undefined) {
            var observationErrors = this.audit.errors.observations[index];
            return !isObjectEmpty(observationErrors);
        }
        else {
            return false;
        }
    };
    return TableComponent;
}(BaseComponent));
export { TableComponent };
