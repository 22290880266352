<div class="mat-form-wrapper" *ngIf="schema">
  <section class="mat-form-header">
    <h2 class="subtitle v2">
      {{ subform?.display_name || auditForm?.name }}, <span>{{ ward?.name }}</span>
    </h2>
    <div class="field-info required">{{'required' | translate }}</div>
  </section>
  <mat-accordion *ngIf="infoItems.length !== 0 || documentItems.length !== 0" class="mat-form-information">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>{{'audit-form.documents.title' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list>
        <mat-list-item (click)="item.action()" *ngFor="let item of infoItems" class="info-item">
          <mat-icon svgIcon="info"></mat-icon>
          {{ item.label }}
        </mat-list-item>
        <mat-list-item (click)="item.action()" *ngFor="let item of documentItems" class="document-item">
          <div class="document-item-wrapper">
            <div class="document-item-img">
              <img [src]="item.icon" alt=""/>
            </div>
            <div class="document-item-content">
              <h2 class="document-item-title">{{item.label}}</h2>
              <p class="document-item-description">{{item.document.description}}</p>
              <div class="document-item-footer">
                <span>{{'audit-form.documents.version' | translate: {'version': item.document.revision} }}</span>
                <span><mat-icon>person</mat-icon>
                  {{item.document.owner}}</span>
              </div>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
  <meg-observation
    (clearClicked)="onClearClicked()"
    (deleteClicked)="onDeleteClicked()"
    (nextClicked)="onSave()"
    (backClicked)="onBackClicked()"
    (progressSaved)="onProgressSave()"
    [observation]="getSubObservation()"
    [observationId]="observationId"
    [schema]="subform || schema"
    [auditFormSchema]="schema"
    [audit]="audit"
    [auditForm]="auditForm"
    [fieldErrors]="getErrors()"
    [isAccordionSubObservation]="false"
    [isNextLoading]="isNextLoading"
  ></meg-observation>
</div>

<mat-progress-spinner mode="indeterminate" *ngIf="!schema"></mat-progress-spinner>
