import {Component, EventEmitter, Injector, Input, NgZone, OnInit, Output} from '@angular/core';
import {Issue, IssueHandler, IssuePhoto, Room} from '../../../api/models/issue';
import {MAX_IMAGE_SIZE, QipService} from '../../qip.service';
import {Audit} from '../../../audit-form/audit';
import {InstitutionService} from '../../../institution.service';
import {map, mergeMap, tap} from 'rxjs/operators';
import {Institution} from '../../../api/models/institution';
import {Department} from '../../../api/models/department';
import {Ward} from '../../../api/models/ward';
import {MatDialog} from '@angular/material';
import {AuditFormService} from '../../../audit-form.service';
import {Field} from '../../../api/models/audit-form-schema';
import {AudioDialogComponent} from '../../audio-dialog/audio-dialog.component';
import {FormControl} from '@angular/forms';
import {AuditForm, AuditFormConfig} from '../../../api/models/audit-form';
import {TranslateService} from '@ngx-translate/core';
import {IssueErrors} from '../../../api/models/errors';
import {getFileExtension} from '../../../utils/misc';
import {NGXLogger} from 'ngx-logger';
import {CameraService} from '../../../camera.service';
import {DomSanitizer} from '@angular/platform-browser';
import {BaseComponent} from '../../../base.component';

@Component({
  selector: 'meg-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.css']
})
export class IssueComponent extends BaseComponent implements OnInit {
  @Input() issue: Issue;
  @Input() audit: Audit;
  @Input() public errors: IssueErrors | null = null;
  @Output() edit = new EventEmitter<Issue>(false);
  public issueHandlers: IssueHandler[];
  public photos: IssuePhoto[] = [];
  public audio: string | null = null;
  public field: Field | null = null;
  private rooms: Room[] = [];
  public filteredRooms: Room[];
  public filteredHandlers: IssueHandler[];
  public auditFormConfig: AuditFormConfig | null = null;

  roomsCtrl = new FormControl();
  handlersCtrl = new FormControl();
  dueDateCtrl = new FormControl();

  constructor(private qipService: QipService, private institutionService: InstitutionService, private dialog: MatDialog,
              private ngZone: NgZone, private auditFormService: AuditFormService,
              private cameraService: CameraService, private sanitizer: DomSanitizer, private injector: Injector) {
    super(injector);

    this.roomsCtrl.valueChanges
      .pipe(
        map(searchString => searchString ? this.filterRooms(searchString) : this.rooms)
      ).subscribe((rooms: Room[]) => this.filteredRooms = rooms);

    this.handlersCtrl.valueChanges
      .pipe(
        map(searchString => searchString ? this.filterHandlers(searchString) : this.issueHandlers)
      ).subscribe((handlers: IssueHandler[]) => this.filteredHandlers = handlers);

    this.dueDateCtrl.valueChanges.subscribe(
      (date: string) => this.issue.duedate = date
    );
  }

  private filterRooms(value: string): Room[] {
    const filterValue = value.toLowerCase();
    return this.rooms.filter(room => room.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private filterHandlers(value: string): IssueHandler[] {
    const filterValue = value.toLowerCase();
    return this.issueHandlers.filter(handler => handler.name.toLowerCase().indexOf(filterValue) === 0);
  }

  public getPhotoBase64(photo: IssuePhoto) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(photo.photo);
  }

  ngOnInit() {
    if (this.issue) {
      this.dueDateCtrl.setValue(this.issue.duedate);
    }
    this.institutionService.getWardData(this.audit.wardId).pipe(
      tap((result: [Institution, Department, Ward]) => {
        if (result[2].rooms) {
          this.rooms = result[2].rooms;
          this.filteredRooms = result[2].rooms;
        }
      } ),
      mergeMap((result: [Institution, Department, Ward]) => this.qipService.getIssueHandlers(result[0].id, this.audit.auditFormId)),
      mergeMap((issueHandlers: IssueHandler[]) => this.auditFormService.getAuditForm(this.audit.auditFormId).pipe(
        tap((auditForm: AuditForm) => this.auditFormConfig = auditForm.config),
        map((auditForm: AuditForm) => {
          if (auditForm.config.issue_handlers && auditForm.config.issue_handlers.length > 0) {
           return issueHandlers.filter((issueHandler: IssueHandler) => auditForm.config.issue_handlers.indexOf(issueHandler.id) > -1);
          }
          return issueHandlers;
        })
      )),
    ).subscribe((issueHandlers: IssueHandler[]) => {
      this.issueHandlers = issueHandlers;
      this.filteredHandlers = issueHandlers;
      this.logger.debug(this.issueHandlers);
      if (this.issue) {
        issueHandlers.forEach(handler => {
          if (handler.id === this.issue.handler) {
            this.handlersCtrl.setValue(handler.name);
          }
        });
      }
    });

    if (this.issue.field_name) {
      this.auditFormService.getFieldByName(this.audit.auditFormId, this.issue.field_name).subscribe((field) => this.field = field);
    }
    this.updatePhotos();
  }

  public updatePhotos() {
    this.logger.debug('Issue photos will be updated...');
    this.qipService.getIssuePhotos(this.audit, this.issue).pipe(
      tap((value: IssuePhoto[]) => this.logger.debug('Updated photos display', value)),
    ).subscribe((photos: IssuePhoto[]) => this.photos = photos);
  }

  /**
   * Updates / adds room object in issue
   * Sets room to null if room number is empty
   * Creates new room object and adds it to issue otherwise.
   * @param {string} roomName room name entered by user
   */
  public updateRoom(roomName: string) {
    const room: Room | null = roomName === '' ? null : new Room(roomName);
    this.issue.room = room;
    this.logger.debug(`Updated issue "${this.issue}" room to ${roomName}`);
    this.onEdit();
  }

  public updateHandler(id: number) {
    this.issue.handler = id;
    this.logger.debug(`Updated issue "${this.issue}" handler to ${id}`);
    this.onEdit();
  }

  /**
   * Called when user clicks photo capture button
   */
  public capturePhotoClicked() {
    this.openCamera();
  }

  /**
   * Opens relevant camera interface:
   * - for web app, open html view with web camera
   * - for native apps, opens cordova plugin camera
   */
  public openCamera() {
    const subscription = this.cameraService.takePhoto().pipe(
      tap((photo) => this.logger.debug('Received photo from camera service', photo)),
      tap((photo: string) => {
        const issuePhoto = new IssuePhoto(photo, null);
        this.photos.push(issuePhoto);
      }),
    ).subscribe();
    this.subscriptions.push(subscription);
  }

  /**
   * Called when user selects a file to upload using file dialog
   */
  public onPhotoPicked(files: FileList) {
    const supportedFileTypes = ['jpeg', 'jpg', 'png'];
    const file: File | null = files.item(0);
    if (file !== null) {
      this.logger.debug('file selected', file);

      if (file.size > MAX_IMAGE_SIZE) {
        const errorMessage: string = this.translateService.instant('qip.incorrect-file-size', {
          max_size: MAX_IMAGE_SIZE / 1024 / 1024,
          file_size: Math.round(file.size / 1024 / 1024),
        });
        alert(errorMessage);
        return;
      }

      const fileExtension = getFileExtension(file);
      this.logger.debug(`File extension: ${fileExtension}`);
      if (fileExtension === undefined || supportedFileTypes.indexOf(fileExtension.toLowerCase()) === -1) {
        const supportedFileTypeString = supportedFileTypes.join(', ');
        alert(`${this.translateService.instant('qip.incorrect-audio-file-type')} (${supportedFileTypeString})`);
      } else {
        const fileReader = new FileReader();
        fileReader.onloadend = (event) => {
          const photo = new IssuePhoto(fileReader.result);
          this.photos.push(photo);
        };
        fileReader.readAsDataURL(file);
      }
    } else {
      this.logger.error('File is null');
    }
  }

  /**
   * Removes issue photo from database
   * If there are other photos, photo is removed from photo array and Issue photos updated.
   * Otherwise the whole issue photos array is removed
   * @param {IssuePhoto} photo photo to be removed
   */
  public removePhoto(photo: IssuePhoto) {
    this.photos = this.photos.filter((value: IssuePhoto) => value.photo !== photo.photo);
  }

  public openAudioDialog() {
    if ( this.issue.audio === null || (confirm(this.translateService.instant('qip.audio-override-prompt'))) ) {
      this.dialog.open(AudioDialogComponent, {
        width: '100%',
      }).afterClosed().subscribe((result: string | boolean) => {
        if (typeof result === 'string') {
          this.audio = result as string;
          this.onEdit();
        }
      });
    }
  }

  public removeAudio() {
    this.audio = null;
  }

  public onEdit() {
    this.edit.emit(this.issue);
  }

  get isAudioRecordingEnabled(): boolean {
    const ableToRecord = ((window as any).cordova !== undefined || (window as any).MediaRecorder !== undefined);
    if (this.auditFormConfig && this.auditFormConfig.enable_audio_recording !== undefined) {
      return (ableToRecord && this.auditFormConfig.enable_audio_recording);
    }
    return ableToRecord;
  }

  public updateMedia() {
    this.qipService.setIssuePhotos(this.audit, this.issue, this.photos).subscribe();
    this.issue.audio = this.audio;
    this.onEdit();
  }

  public blurRoomInput() {
    const node = document.querySelector('.room-input') as HTMLElement;
    node.blur();
  }

  public blurHandlerInput() {
    const node = document.querySelector('.handler-input') as HTMLElement;
    node.blur();
  }
}
