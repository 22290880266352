import { OnInit } from '@angular/core';
import { ApiService } from '../api/api.service';
import { Feedback } from '../api/models/feedback';
import { AccountService } from '../accounts.service';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
var FeedbackDialogComponent = /** @class */ (function () {
    function FeedbackDialogComponent(apiService, dialogRef, accountService, translateService) {
        var _this = this;
        this.apiService = apiService;
        this.dialogRef = dialogRef;
        this.accountService = accountService;
        this.translateService = translateService;
        this.sendingRequest = false;
        this.feedback = new Feedback(null, null, null);
        this.user = null;
        this.accountService.getUser().subscribe(function (user) {
            _this.user = user;
            if (_this.user) {
                _this.feedback.email = _this.user.email;
                _this.feedback.name = _this.user.username;
            }
        });
    }
    Object.defineProperty(FeedbackDialogComponent.prototype, "namePlaceholder", {
        get: function () {
            if (this.user)
                return this.translateService.instant('login.username');
            else
                return this.translateService.instant('contact.full-name');
        },
        enumerable: true,
        configurable: true
    });
    FeedbackDialogComponent.prototype.ngOnInit = function () {
        this.form = new FormGroup({
            username: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            message: new FormControl('', [Validators.required]),
        });
    };
    FeedbackDialogComponent.prototype.sendFeedback = function () {
        var _this = this;
        if (this.form.valid) {
            this.sendingRequest = true;
            this.apiService.sendFeedback(this.feedback).subscribe(function (feedback) { return _this.dialogRef.close(true); }, function (error) {
                _this.sendingRequest = false;
                alert(_this.translateService.instant('contact.error-message'));
            });
        }
        else {
            Object.keys(this.form.controls)
                .map(function (key) { return _this.form.controls[key]; })
                .forEach(function (field) { return field.markAsTouched({ onlySelf: true }); });
        }
    };
    FeedbackDialogComponent.prototype.errorMessage = function (controlName) {
        var formControl = this.form.controls[controlName];
        if (formControl.invalid && formControl.touched && formControl.errors) {
            switch (controlName) {
                case 'username':
                    return this.translateService.instant('audit-form.field.field-required');
                case 'message':
                    return this.translateService.instant('audit-form.field.field-required');
                case 'email':
                    return this.translateService.instant('contact.email-incorrect');
            }
        }
        return null;
    };
    return FeedbackDialogComponent;
}());
export { FeedbackDialogComponent };
