import {Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {addNameToBase64, getBase64HeaderName, isNullOrUndefined} from '../../../utils/misc';
import {TranslateService} from '@ngx-translate/core';
import {CameraService} from '../../../camera.service';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'meg-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileFieldComponent), multi: true},
  ]
})
export class FileFieldComponent implements ControlValueAccessor {

  fileFormControl = new FormControl();
  @ViewChild('inputFile') nativeInputFile: ElementRef;
  @Input()
  multiple = false;
  value: string[] = [];

  public fileNames: string[] = [];

  constructor(private translateService: TranslateService, private cameraService: CameraService) {}

  public get valueLabel(): string {
    return this.fileNames.join(', ');
  }

  private setFormControl(value: string[] | string) {
    if (typeof value === 'string') value = [value];
    this.value = value;
    // @ts-ignore
    this.fileNames = value.map(getBase64HeaderName);
    if (this.multiple) this.onValueChange(value);
    else this.onValueChange(value.length === 0 ? '' : value[0]);
  }

  private addFile(fileBase64: string) {
    // if only one file is supported, replace file
    if (!this.multiple) {
      this.fileNames = [];
      this.value = [];
    }
    const name: string | null = getBase64HeaderName(fileBase64);
    if (name !== null) this.fileNames.push(name);
    const values = [...this.value, fileBase64];
    this.setFormControl(values);
  }

  onNativeInputFileSelect(event: any) {
    const files = event.srcElement.files;
    if (files.length === 0) return;
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const base64Str: string = addNameToBase64(reader.result, file.name);
          this.addFile(base64Str);
        } catch {
          alert(this.translateService.instant('audit-form.field.adding-file-error'));
        }
      };
      reader.readAsDataURL(file);
      // Don't read any more files if this widget accepts only one file
      if (!this.multiple) return;
    }
  }

  selectFile() {
    this.nativeInputFile.nativeElement.click();
  }

  public takePhoto() {
    this.cameraService.takePhoto().pipe(
      tap((photo: string) => {
        this.addFile(photo);
      }),
    ).subscribe();
  }

  // ---------- ControlValueAccessor methods start ------------- //

  /**
   * Writes a new value to the element.
   * @param value: any
   */
  writeValue(value: any) {
    if (isNullOrUndefined(value)) {
      this.setFormControl([]);
    } else {
      this.setFormControl(value);
    }
  }

  /**
   *  Callback function to call when the file field has changed
   * @param _ string the value to update the form control
   */
  onValueChange = (_: string[] | string) => {};

  /**
   * Registers a callback function that is called when the control's value changes in the UI.
   * @param fn: any The callback function to register
   */
  registerOnChange(fn: any) {
    this.onValueChange = fn;
  }

  /**
   * Registers a callback function is called by the forms API on initialization to update the form model on blur.
   * @param fn: any The callback function to register
   */
  registerOnTouched(fn: any) {}

  // ---------- ControlValueAccessor methods end ------------ //

}
