import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';

import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';

export const ISO_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY-MM-DD',
  },
};

@Component({
  selector: 'meg-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CustomDatePickerComponent), multi: true },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: ISO_DATE_FORMATS},
  ]
})
export class CustomDatePickerComponent implements ControlValueAccessor, OnInit {
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
  @Input() maxDate: Date | null = null;
  @Input() useDOB = false;
  @Input() startDate: Date | null = null;

  public dateFormControl = new FormControl(moment());
  public format = ISO_DATE_FORMATS.parse.dateInput;

  constructor(private adapter: DateAdapter<any>, private translateService: TranslateService) {
    this.adapter.setLocale(this.translateService.getBrowserCultureLang());
  }

  ngOnInit(): void {
    if (this.useDOB) {
      this.startDate = new Date(1970, 0, 0);
    }
  }

  dateChanged(date: Date) {
    this.dateFormControl.setValue(date);
    const dateValue: moment.Moment = moment(date);
    this.dateFormControl.setValue(dateValue);
    this.onDateChange(dateValue.format(this.format));
  }

  onDateInput(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      this.dateChanged(event.value);
    }
  }

  onWriteValueChange(date: string) {
    const dateValue: moment.Moment = moment(date);
    this.startDate = dateValue.toDate();
    this.dateFormControl.setValue(dateValue);
  }

  // Write a value to the input
  writeValue(value: any) {
    if (value !== undefined && value !== null) {
      this.onWriteValueChange(value);
    } else {
      this.dateFormControl.setValue(null);
    }
  }

  // Function to call when the date change.
  onDateChange = (_: string) => {};

  // Allows Angular to register a function to call when the model (date) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: any) {
    this.onDateChange = fn;
  }

  // Register a function to tell Angular when the input has been touched
  registerOnTouched(fn: any) {
  }

}
