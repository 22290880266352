import {Observable, of} from 'rxjs';

export class MenuItem {
  label: string;
  action: () => void;
  visible: boolean;
  icon: string | null;

  constructor(label: string, action: () => void, visible = true, icon: string | null = null,
  public badge: Observable<string|null> = of(null)) {
    this.icon = icon;
    this.label = label;
    this.action = action;
    this.visible = visible;
  }
}
