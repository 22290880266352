<mat-accordion class="preview-accordion" *ngIf="allIssues.length > 0">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{issue_label_plural|async|titlecase}}:
      </mat-panel-title>
      <mat-panel-description>
        {{allIssues.length}}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div *ngFor="let item of allIssues">
      <meg-issue-preview [issue]="item" [audit]="audit" [auditFormSchema]="auditFormSchema" [auditForm]="auditForm"></meg-issue-preview>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="submit-card-container">
  <h3 translate>audit-submission.time-label</h3>
  <label translate>audit-submission.start-time</label>
  <meg-date-time-field [formControl]="startDateFormControl" [formEnabled]="allowSubmissionDateChange$">
  </meg-date-time-field>
  <mat-error *ngFor="let error of errors['start_time']">
    {{error}}
  </mat-error>
  <br>
  <label translate>audit-submission.end-time</label>
  <meg-date-time-field [formControl]="endDateFormControl"
                       [startDate]="audit.auditSession.start_time"
                       [formEnabled]="allowSubmissionDateChange$"
  >
  </meg-date-time-field>
  <mat-error *ngFor="let error of errors['end_time']">
    {{error}}
  </mat-error>
  <br>
  <div *ngIf="auditForm?.config.show_send_email_option !== false">
  <mat-slide-toggle [(ngModel)]="audit?.auditSession.send_email">{{'audit-submission.send-email' | translate}}</mat-slide-toggle>
  </div>
  <br>
  <p *ngIf="auditSize > 100 * 1024" class="audit-size">
    {{'audit-submission.audit-size'|translate:{audit_size: auditSize / 1024 / 1024| number:'1.0-2'} }}<br/>
    <span *ngIf="auditSize > 10 * 1024 * 1024" class="audit-size-warning"><strong>{{'warning'|translate}}</strong> {{'audit-submission.audit-size-warning'|translate}}</span>
  </p>
  <br>
  <div *ngIf="auditForm && auditForm?.config.enable_app_cc_reports && auditForm?.auditors && auditForm?.auditors.length">
    <label translate>audit-submission.cc-reporting-label</label>
    <mat-select disableOptionCentering panelClass="form-dropdown-3-lines" class="audit-form-dropdown issue-dropdown" [multiple]="true"  placeholder="{{'audit-submission.add-auditor' | translate}}" [(value)]="audit.auditSession.report_auditors">
      <mat-option *ngFor="let auditor of auditForm?.auditors" [value]="auditor.id" [disabled]="!auditor.user.has_email">{{auditor.user.username}}</mat-option>
    </mat-select>
  </div>
  <br>
  <label *ngIf="auditForm?.config?.enable_session_comment" translate>audit-submission.comments</label>
  <mat-form-field class="no-underline" *ngIf="auditForm?.config?.enable_session_comment">
    <textarea matInput [(ngModel)]="audit?.auditSession.comment"></textarea>
  </mat-form-field>
  <div *ngFor="let signature of audit.auditSession.signatures">
    <img [src]="signature.image" class="img-signature">
  </div>
  <br>
  <button mat-raised-button class="add-signature-btn" color="primary" *ngIf="auditForm?.config?.require_signature" (click)="addSignature()">{{ 'audit-submission.add-signature' | translate}}</button>
</div>
