import {Injectable} from '@angular/core';
import {Location} from '@angular/common';

@Injectable()
export class BackbuttonService {

  constructor(private locationService: Location) { }

  public installBackListener() {
    document.addEventListener('backbutton', () => this.onBackPressed(), false);
  }

  private onBackPressed() {
    this.locationService.back();
  }
}
