var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MatDatepickerInputEvent, MatDialog } from '@angular/material';
import { TimePickerDialogComponent } from '../time-field/time-picker-dialog/time-picker-dialog.component';
import * as moment from 'moment';
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';
import { TranslateService } from '@ngx-translate/core';
export var ISO_DATE_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY-MM-DD',
    },
};
var DateTimeFieldComponent = /** @class */ (function (_super) {
    __extends(DateTimeFieldComponent, _super);
    function DateTimeFieldComponent(dialog, adapter, translateService) {
        var _this = _super.call(this, adapter, translateService) || this;
        _this.dialog = dialog;
        _this.formEnabled = true;
        _this.format = ISO_DATE_FORMATS.parse.dateInput;
        _this.dateTimeFormControl = new FormControl();
        _this.datePart = '';
        _this.timePart = '';
        _this.cssClass = '';
        _this.dateTimeValue = '';
        return _this;
    }
    DateTimeFieldComponent.prototype.ngOnChanges = function () {
        this.cssClass = this.formEnabled ? '' : 'disabled-view';
    };
    DateTimeFieldComponent.prototype.splitDateTime = function (dateTime) {
        var dateTimeParts = dateTime.split(' ');
        this.datePart = dateTimeParts[0];
        this.timePart = dateTimeParts[1];
    };
    DateTimeFieldComponent.prototype.updateFormControl = function () {
        // only update the form control if both values are entered.
        if (!this.datePart.isEmpty() && !this.timePart.isEmpty()) {
            this.dateTimeValue = this.datePart + " " + this.timePart;
            this.onDateChange(this.dateTimeValue);
        }
    };
    DateTimeFieldComponent.prototype.showPicker = function () {
        var _this = this;
        var dialogRef = this.dialog.open(TimePickerDialogComponent, {
            data: this.timePart
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === false || result === undefined) {
                return;
            }
            else {
                _this.timePart = result;
                _this.updateFormControl();
            }
        });
    };
    DateTimeFieldComponent.prototype.onWriteValueChange = function (date) {
        if (date) {
            this.dateTimeFormControl.setValue(date);
            this.splitDateTime(date);
        }
        else {
            this.timePart = '';
            this.datePart = '';
        }
    };
    DateTimeFieldComponent.prototype.onDateInput = function (type, event) {
        if (event.value) {
            var dateValue = moment(event.value);
            this.datePart = dateValue.format(this.format);
            this.updateFormControl();
        }
    };
    return DateTimeFieldComponent;
}(CustomDatePickerComponent));
export { DateTimeFieldComponent };
