import {MEGChartData} from '../charts_calculator';
import {Widget} from '../../api/models/dashboard';
import {BaseChart} from './base_chart';
import {ChartConfiguration, ChartData, ChartDataSets} from 'chart.js';

export class PieChart extends BaseChart {

  public buildChart(widget: Widget): ChartConfiguration {

    let chartDataValues: MEGChartData[];
    if (widget.config.count_entries) {
      chartDataValues = this.chartCalculator.calculateNoOfEntries(widget);
    } else {
      chartDataValues = this.chartCalculator.getPieChartData(widget);
    }

    const chartValues = this.buildChartData(chartDataValues);
    const dataSets: ChartDataSets[] = [{data: chartValues.data, backgroundColor: chartValues.colors}];
    const chartData: ChartData = {labels: chartValues.labels, datasets: dataSets};
    const options = {
      title: {
        display: true,
        text: this.getChartTitles(widget),
      },
      legend: {
        display: true
      },
    };
    return {type: 'pie', data: chartData, options: options};
  }
}
