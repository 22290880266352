import {Injectable} from '@angular/core';
import {Audit} from '../audit-form/audit';
import {ComplianceCalculator} from './calculator';
import {AuditFormSchema} from '../api/models/audit-form-schema';
import {AuditForm} from '../api/models/audit-form';
import {AccountService} from '../accounts.service';
import {User} from '../api/models/user';
import {ProfileService} from '../profile/profile.service';
import {Avatar} from '../api/models/avatar';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export const HIGH_LEVEL = 2;
export const MEDIUM_LEVEL = 1;
export const LOW_LEVEL = 0;

@Injectable()
export class ComplianceService {

  constructor(private profileService: ProfileService) { }

  public calculateAuditCompliance(schema: AuditFormSchema, audit: Audit): number | null {
    const calculator = new ComplianceCalculator(schema);
    return calculator.calculateSessionCompliance(audit.auditSession);
  }

  public calculateObservationCompliance(schema: AuditFormSchema, observation: { [fieldName: string]: any }): number | null {
    const calculator = new ComplianceCalculator(schema);
    return calculator.calculateObservationCompliance(observation);
  }

  public getComplianceImageUrl(auditForm: AuditForm, compliance: number | null): Observable<string> {
    return this.profileService.getUserAvatar().pipe(
      map((avatar: Avatar | null): string => {
        if (avatar === null) {
          let complianceString = 'high';
          if (compliance !== null) {
            let passFirst = 0.9;
            let passSecond = 0.8;
            if (auditForm.config !== null) {
              passFirst = auditForm.config.pass_first;
              passSecond = auditForm.config.pass_second;
            }
            if (compliance >= passFirst) complianceString = 'high';
            else if (compliance >= passSecond) complianceString = 'medium';
            else complianceString = 'low';
          }
          return `assets/images/compliance-${complianceString}.svg`;
        } else {
          return avatar.image;
        }
      }),
    );
  }

  /**
   * Calculates compliance level where 2 = high, 1 = medium and 0 is low
   * @param auditForm
   * @param compliance
   * @returns {number | null} compliance value where 2 = high, 1 = medium and 0 is low
   */
  public getComplianceLevel(auditForm: AuditForm, compliance: number | null): number {
    let complianceLevel = HIGH_LEVEL;
    if (compliance !== null) {
      let passFirst = 0.9;
      let passSecond = 0.8;
      if (auditForm.config !== null) {
        passFirst = auditForm.config.pass_first;
        passSecond = auditForm.config.pass_second;
      }
      if (compliance >= passFirst) complianceLevel = HIGH_LEVEL;
      else if (compliance >= passSecond) complianceLevel = MEDIUM_LEVEL;
      else complianceLevel = LOW_LEVEL;
    }
    return complianceLevel;
  }
}
