import {Injectable} from '@angular/core';
import {WalkthroughPage} from './models';
import {StorageService} from '../storage.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export const DB_KEY_WALKTHROUGH_SHOWN = 'walkthrough-shown';
export const DB_VALUE_WALKTHROUGH_SHOWN = 'true';

@Injectable()
export class WalkthroughService {
  private pages$: Observable<WalkthroughPage[]>;
  public showing = false;

  constructor(private storageService: StorageService, private translateService: TranslateService) {
    this.pages$ = this.translateService.get([
      'tutorial.pages.welcome-title',
      'tutorial.pages.welcome-text',
      'tutorial.pages.select-audit-title',
      'tutorial.pages.select-audit-text',
      'tutorial.pages.add-issues-title',
      'tutorial.pages.add-issues-text',
      'tutorial.pages.review-and-submit-title',
      'tutorial.pages.review-and-submit-text',
      'tutorial.pages.saved-audits-title',
      'tutorial.pages.saved-audits-text',
    ]).pipe(
      map((translations: { [id: string]: string }) => [
        {
          title: translations['tutorial.pages.welcome-title'],
          text: translations['tutorial.pages.welcome-text'],
          imageSrc: 'assets/images/walkthrough/meg-intro.png',
        },
        {
          title: translations['tutorial.pages.select-audit-title'],
          text: translations['tutorial.pages.select-audit-text'],
          imageSrc: 'assets/images/walkthrough/introGif1.gif',
        },
        {
          title: translations['tutorial.pages.add-issues-title'],
          text: translations['tutorial.pages.add-issues-text'],
          imageSrc: 'assets/images/walkthrough/introGif2.gif',
        },
        {
          title: translations['tutorial.pages.review-and-submit-title'],
          text: translations['tutorial.pages.review-and-submit-text'],
          imageSrc: 'assets/images/walkthrough/introGif3.gif',
        },
        {
          title: translations['tutorial.pages.saved-audits-title'],
          text: translations['tutorial.pages.saved-audits-text'],
          imageSrc: 'assets/images/walkthrough/introGif4.gif',
        },
      ]),
    );
  }

  public markShown() {
    this.storageService.setString(DB_KEY_WALKTHROUGH_SHOWN, DB_VALUE_WALKTHROUGH_SHOWN);
  }

  public wasShown(): boolean {
    return this.storageService.getString(DB_KEY_WALKTHROUGH_SHOWN) === DB_VALUE_WALKTHROUGH_SHOWN;
  }

  public getAllPages(): Observable<WalkthroughPage[]> {
    return this.pages$;
  }

}
