<mat-card *ngIf="institutions != null" class="limit-width center" id="ward-card">

  <img [src]="institutionLogo || 'assets/images/logos/MEG-Logo-Full.svg'" class="institute-logo">
  <div class="user-info">
    <h1 class="title">{{'audit-select.welcome' | translate:{'username':username} }}</h1>
    <p *ngIf="institutions?.length === 1" class="institution-name">{{ institution?.name}}</p>
  </div>

  <div *ngFor="let audit of upcomingAudits$|async" class="audit-schedule" (click)="selectAudit(audit)">
    <div>{{'audit-select.scheduled-audit'|translate}}</div>
    <div class="audit-name">{{audit.auditForm.name}}</div>
    <div class="ward-name" *ngIf="audit.wardData">{{audit.wardData[2].name}}</div>
    <mat-icon>chevron_right</mat-icon>
  </div>

  <mat-form-field *ngIf="institutions?.length > 1">
    <mat-select class="audit-select" placeholder="{{institutionLabel | async}}" (selectionChange)="onInstitutionSelected()" [(value)]="institution">
      <mat-option *ngFor="let institution of institutions" [value]="institution">
        {{institution.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>



  <meg-searchable-dropdown *ngIf="auditForms?.length !== 1 || !auditForm"
    selectionClass="audit-select"
    searchbarClass="audit-select"
    [selectionPlaceholderText]="'audit-select.select-audit'|translate"
    [searchbarPlaceholderText]="'audit-form.field.searchable-select'|translate"
    [disabled]="institution == null"
    [itemToString]="itemToString"
    [allValues]="auditForms"
    [(selectedItem)]="auditForm"
    (selectionChange)="onAuditFormSelected()"
  ></meg-searchable-dropdown>

  <div *ngIf="auditForms?.length === 1 && auditForm" class="lone-audit-container">
    <p class="lone-help-text">{{'audit' | translate}}</p>
    <p class="lone-audit-name">{{ auditForm?.name}}</p>
  </div>
  <div *ngIf="auditForms?.length === 1 && auditForm" class="mat-input-underline mat-form-field-underline fake-underline"></div>

  <div class="audit-select-help-text" *ngIf="auditForm?.config.help_text && !auditForm.config.help_text.isEmpty()"><span class="material-icons">help</span><span class="help-text-content">{{ auditForm?.config.help_text}}</span></div>

  <meg-searchable-dropdown
    selectionClass="audit-select"
    searchbarClass="audit-select"
    [selectionPlaceholderText]="departmentLabel|async"
    [searchbarPlaceholderText]="'audit-form.field.searchable-select'|translate"
    [disabled]="auditForm == null"
    [disabled]="institution == null"
    [itemToString]="itemToString"
    [allValues]="departments"
    [(selectedItem)]="department"
    (selectionChange)="onDepartmentSelected()"
  ></meg-searchable-dropdown>

  <meg-searchable-dropdown
    selectionClass="audit-select"
    searchbarClass="audit-select"
    [selectionPlaceholderText]="wardLabel|async"
    [searchbarPlaceholderText]="'audit-form.field.searchable-select'|translate"
    [disabled]="department == null"
    [itemToString]="itemToString"
    [allValues]="wards"
    [(selectedItem)]="ward"
    (selectionChange)="valid = isSelectionValid()"
  ></meg-searchable-dropdown>


  <button mat-raised-button color="primary" [disabled]="!valid" (click)="onStartClicked()"><mat-icon>check</mat-icon> {{ 'audit-select.start' | translate}}</button>

</mat-card>

<mat-progress-spinner mode="indeterminate" *ngIf="institutions == null"></mat-progress-spinner>
