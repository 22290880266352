import {Injectable} from '@angular/core';
import {ANDROID, ApiService, IOS, WINDOWS} from './api/api.service';
import {Observable} from 'rxjs';
import {AppVersion} from './api/models/app-version';
import {throwError} from 'rxjs/internal/observable/throwError';
import {openUrl} from './utils/misc';

@Injectable()
export class AppUpdateService {

  public isCordovaBuild: boolean = ((window as any).cordova !== undefined);

  private androidURL = 'https://play.google.com/store/apps/details?id=';
  private iosURL = 'https://itunes.apple.com/ie/app/meg-audit-tool/id';
  private windowsURL = 'https://www.microsoft.com/en-us/p/meg-audit-tool-v3/9n8wd2hwbc90?activetab=pivot:overviewtab';

  private appIDs: {[key: string]: string} = {
    'ios': '1412682613',
    'android': 'com.meg.mat.client',
    'windows': 'MEGSupportTools.MEGAuditToolv3',
  };

  constructor(private apiService: ApiService) { }

  public checkAppVersion(): Observable<AppVersion> {
    if (this.isCordovaBuild ) {
      const deviceType = this.apiService.getDeviceType();
      const appId = this.appIDs[deviceType];
      if (appId !== undefined) {
        return this.apiService.checkAppVersion(appId);
      } else {
        return throwError(new Error('Application id is undefined'));
      }
    } else {
      return throwError(new Error('Not a cordova build'));
    }
  }

  public openAppStore() {
    const deviceType = this.apiService.getDeviceType();
    const apiId = this.appIDs[deviceType];
    let url = null;
    if (deviceType === IOS) {
      url = this.iosURL + apiId;
    } else if (deviceType === ANDROID) {
      url = this.androidURL + apiId;
    } else if (deviceType === WINDOWS) {
      url = this.windowsURL;
    }
    if (url !== null) {
      openUrl(url);
    }
  }
}
