<div class="section-download" *ngIf="!hideDownloadButton">
  <button mat-raised-button color="primary" [disabled]="dialogOpen" (click)="onDownloadClick()">
    <mat-icon>file_download</mat-icon> {{'audit-list.download-submission' | translate}}
  </button>
</div>

<div *ngIf="items !== undefined">
  <div class="meg-list">
    <mat-card class="limit-width center meg-saved-card" *ngFor="let item of items; let i = index" [ngStyle]="{'animation-delay': (0.1 * i) + 's'}">
      <mat-card-header class="list-card-header">
        <p class="list-card-audit">{{ item.auditForm.name }}<mat-icon class="error_icon" color="error" *ngIf="item.audit.errors">error_outline</mat-icon></p>
        <p class="list-card-datetime">{{ item.audit.auditSession.start_time | localizedDate }}</p>
      </mat-card-header>
      <div class="tagged download" *ngIf="!item.audit.submitted">{{ 'audit-list.audit-downloaded' | translate}}</div>
      <h2 class="list-card-subheader" *ngIf="item.ward">{{ item.ward?.name }}</h2>
      <h2 class="list-card-object-label" *ngIf="item.objectLabel">{{ item.objectLabel }}</h2>
      <h2 class="list-card-subheader" *ngIf="!item.ward" style="color: #ea3d2b;">{{ 'audit-list.ward-not-exists' | translate}}</h2>
      <mat-card-actions>
        <button mat-raised-button class="meg-list-button" (click)="deleteAudit(item.audit)" color="warn"><mat-icon>delete</mat-icon><p> {{ 'delete' | translate }}</p></button>
        <button mat-raised-button class="meg-list-button meg-list-button-center" (click)="onEditAudit(item.audit)" color="primary" [disabled]="item.audit.submitted || !item.ward"><mat-icon>edit</mat-icon><p> {{ 'edit' | translate }}</p></button>
        <button mat-raised-button class="meg-list-button meg-list-button-right" routerLink="/audit-submit/{{ item.audit.id }}" color="accent" [disabled]="item.audit.auditSession.observations.length === 0 || !item.ward"><mat-icon>send</mat-icon><p> {{ 'submit' | translate }}</p></button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div *ngIf="items && items.length == 0" class="center">
    <h1 class="title" translate>audit-list.unsubmitted-audits</h1>
    <h2 class="subtitle" translate>audit-list.nothing-here</h2>
    <div class="img-container">
      <div class="speech-bubble">
        <p class="larger-text">{{'audit-list.no-audits-message' | translate}}</p>
        <p class="smaller-text">{{'audit-list.no-audits-subtext' | translate}}</p>
      </div>
      <img class="message-image" src="assets/images/audits-empty.png" alt="" />
      <div class="lone-btn-container">
        <button mat-raised-button class="lone-audit-button new-audit-btn" routerLink="/" color="primary"><mat-icon>add</mat-icon> {{ 'audit-list.new-audit' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<mat-progress-spinner *ngIf="items == undefined" mode="indeterminate"></mat-progress-spinner>
