import {Component, forwardRef, NgZone} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {getPlatform, isNullOrUndefined, Platform} from '../../../utils/misc';
import {NGXLogger} from 'ngx-logger';

declare var MediaRecorder: any;
declare var Media: any;

type ErrorCallBack = (error: any) => any;
type SuccessCallBack = (value: any) => any;

const AUDIO_RECORDING_LIMIT = 2 * 60;

export enum MediaStatus {
  READY = 0,
  RECORDING = 1,
  RECORDED = 2,
  PLAYING = 3,
  COMPLETED = 4,
}

export enum CordovaMediaStatus {
  MEDIA_NONE = 0,
  MEDIA_STARTING = 1,
  MEDIA_RUNNING = 2,
  MEDIA_PAUSED = 3,
  MEDIA_STOPPED = 4
}

@Component({
  selector: 'meg-audio-field',
  templateUrl: './audio-field.component.html',
  styleUrls: ['./audio-field.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AudioFieldComponent), multi: true},
  ],
})
export class AudioFieldComponent implements ControlValueAccessor {
  public progressValue = 0;
  public audioLength = 0;
  public audioCurrentSecs = AUDIO_RECORDING_LIMIT;
  public MediaStatus = MediaStatus;
  public mediaStatus: MediaStatus = MediaStatus.READY;
  public audioBase64: string | null = null;

  private timeouts: any[] = [];

  // Cordova variables
  private cordovaMediaRecord: any = null;
  private audioFileEntry: any;

  // Native
  private audioBlob: Blob | null = null;
  private mediaRecorder: any = null;
  private audio: any = null;

  private onValueChange = (_: string) => {};

  private static get audioFilePath(): string {
    const platform = getPlatform();
    switch (platform) {
      case Platform.iOS:
        return 'cdvfile://localhost/temporary/recording.wav';
      case Platform.Android:
        return `${(window as any).cordova.file.externalCacheDirectory}recording.mp3`;
      case Platform.Windows:
        return `${(window as any).cordova.file.tempDirectory}recording.wma`;
      default:
        return `${(window as any).cordova.file.cacheDirectory}recording.mp3`;
    }
  }


  constructor(private ngZone: NgZone, private logger: NGXLogger) {
  }

  /**
   * Updates the UI values using ngZone
   * @param progressValue: Progress bar value
   * @param audioCurrentSecs: Current seconds for the audio
   * @param mediaStatus: MediaStatus i.e PLAYING
   */
  private updateUITimer(progressValue: number, audioCurrentSecs: number, mediaStatus: MediaStatus) {
    this.ngZone.run(() => {
      this.progressValue = progressValue;
      this.audioCurrentSecs = audioCurrentSecs;
      this.mediaStatus = mediaStatus;
    });
  }

  private cancelTimeouts() {
    this.timeouts.forEach((index: number, timeout: any) => {
      window.clearTimeout(timeout);
    });
  }

  private deleteAudioFile() {
    const self = this;
    if (this.audioFileEntry) {
      this.audioFileEntry.remove(function () {
        self.logger.debug('Successfully removed ' + self.audioFileEntry);
      });
    }
  }

  private getNativeFilePath(filePath: string, successCallback: SuccessCallBack, errorCallback: ErrorCallBack) {
    const self = this;
    (window as any).resolveLocalFileSystemURL(filePath, function (entry: any) {
      successCallback(entry);
    }, function (error: any) {
      self.logger.error('Error: ', error);
      errorCallback('Error retrieving file at path: ' + filePath);
    });
  }

  private convertFileToBase64(fileEntry: any, successCallback: SuccessCallBack, errorCallback: ErrorCallBack) {
    this.logger.debug(fileEntry);
    fileEntry.file(function (file: any) {
      const reader = new FileReader();
      reader.onloadend = function () {
        if (reader.result === undefined || reader.result === null) errorCallback('Error converting to base64');
        else successCallback(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }

  private startCordovaRecording() {
    const self = this;
    const startTime = new Date();

    function onSuccess() {
      self.logger.debug(`Audio recorded successfully`);
      self.mediaStatus = MediaStatus.RECORDED;
      const currentTime = ((new Date()).getTime() - startTime.getTime());
      self.audioLength = Math.floor(currentTime / 1000);
      self.getNativeFilePath(AudioFieldComponent.audioFilePath, function (fileEntry: any) {
        self.audioFileEntry = fileEntry;
        self.convertFileToBase64(fileEntry, function (base64: string) {
          self.ngZone.run(() => {
            self.audioBase64 = base64;
            self.mediaStatus = MediaStatus.COMPLETED;
            self.onValueChange(self.audioBase64);
          });
        }, function (error: any) {
          self.logger.debug(error);
        });
      }, function (error: any) {
        self.logger.debug(error);
      });
    }

    function onError(error: any) {
      self.logger.error(`Failed to record audio: ${error.code}`);
    }

    function onMediaStatus(status: number) {
      self.logger.debug(`Media status callback: ${CordovaMediaStatus[status]}`);
      if (status === CordovaMediaStatus.MEDIA_RUNNING) {
        self.mediaStatus = MediaStatus.RECORDING;
        self.startRecordingProgressTimer();
      }
    }

    self.cordovaMediaRecord = new Media(AudioFieldComponent.audioFilePath, onSuccess, onError, onMediaStatus);
    self.cordovaMediaRecord.startRecord();

    const timeout = setTimeout(() => {
      this.stopRecording();
      this.progressValue = 100;
    }, (AUDIO_RECORDING_LIMIT * 1000));
    this.timeouts.push(timeout);
  }

  public startRecording() {
    this.cancelTimeouts();
    if ((window as any).cordova !== undefined) this.startCordovaRecording();
    else if (navigator.mediaDevices !== undefined) this.startNativeRecording();
    else alert('Your browser does not appear to support HTML5 audio recording.');
  }

  private startNativeRecording() {
    navigator.mediaDevices.getUserMedia({audio: {
      }}).then(stream => {
      const startTime = new Date();
      this.mediaRecorder = new MediaRecorder(stream);
      const chunks: any[] = [];

      this.mediaRecorder.ondataavailable = (event: any) => {
        const currentTime = ((new Date()).getTime() - startTime.getTime());
        this.audioLength = Math.floor(currentTime / 1000);

        const audioCurrentSecs = AUDIO_RECORDING_LIMIT - this.audioLength;
        const progressValue = (100 / AUDIO_RECORDING_LIMIT) * this.audioLength;
        const mediaStatus = (this.mediaRecorder.state === 'recording') ? MediaStatus.RECORDING : MediaStatus.RECORDED;
        this.updateUITimer(progressValue, audioCurrentSecs, mediaStatus);

        chunks.push(event.data);

        if (this.mediaRecorder.state === 'inactive') {
          this.mediaStatus = MediaStatus.RECORDED;
          let mimeType = this.mediaRecorder.mimeType;
          if (isNullOrUndefined(mimeType)) {
            mimeType = 'audio/mp3';
          }
          this.audioBlob = new Blob(chunks, {type: mimeType});

          const reader = new FileReader();
          reader.readAsDataURL(this.audioBlob);
          reader.onloadend = () => {
            this.ngZone.run(() => {
              this.audioBase64 = reader.result;
              this.mediaStatus = MediaStatus.COMPLETED;
              this.onValueChange(reader.result);
            });
          };

          // stop all the tracks in the stream to remove the red recording icon
          stream.getTracks().forEach(track => track.stop());
          this.mediaRecorder = null;
        }
      };
      this.mediaRecorder.start(1000);
      // setTimeout to stop recording after 30 seconds
      const timeout = setTimeout(() => {
        this.stopRecording();
        this.progressValue = 100;
      }, AUDIO_RECORDING_LIMIT * 1000);
      this.timeouts.push(timeout);
    }).catch((error) => {
      this.logger.error(error);
      alert(`Cannot start recording: ${error}`);
    });
  }

  public stopRecording() {
    if (this.cordovaMediaRecord) this.cordovaMediaRecord.stopRecord();
    if (this.mediaRecorder !== null) this.mediaRecorder.stop();
  }

  public playRecording() {
    this.mediaStatus = MediaStatus.PLAYING;
    if (this.audioBase64) {
      this.mediaStatus = MediaStatus.PLAYING;
      this.audio = new Audio(this.audioBase64);
      this.logger.debug(`Audio length: ${this.audioLength}`);
      const self = this;
      this.audio.onloadedmetadata = function () {
        self.audio.play();
        self.startPlaybackProgressTimer();
      };
    }
  }

  private pausePlayback() {
    if (this.audio !== null) {
      this.audio.pause();
    }
    this.mediaStatus = MediaStatus.COMPLETED;
  }

  private startPlaybackProgressTimer() {
    this.logger.debug(`startProgressTimer: current time: ${this.audio.currentTime}`);
    this.logger.debug(`startProgressTimer: duration: ${this.audio.duration}`);
    const updateProgressBar = () => {
      if (this.audio.currentTime < this.audio.duration && this.mediaStatus === MediaStatus.PLAYING) {
        const progressValue = (100 / this.audio.duration) * this.audio.currentTime;
        const audioCurrentSecs = (this.audio.duration - this.audio.currentTime);
        this.updateUITimer(progressValue, audioCurrentSecs, MediaStatus.PLAYING);
        this.logger.debug(
          `updateProgressBar - (duration: ${this.audio.duration}) (currentTime: ${this.audio.currentTime})
       (progress: ${this.progressValue}) (audioCurrentSecs: ${this.audioCurrentSecs})`
        );
        const timerId = window.setTimeout(updateProgressBar, 500);
        this.timeouts.push(timerId);
      } else {
        this.updateUITimer(100, this.audio.duration, MediaStatus.COMPLETED);
      }
    };
    updateProgressBar();
  }

  private startRecordingProgressTimer() {
    let seconds = 0;
    const updateProgressBar = () => {
      if (this.mediaStatus === MediaStatus.RECORDING) {
        this.logger.debug(seconds, this.progressValue);
        const progressValue = (100 / AUDIO_RECORDING_LIMIT) * seconds;
        seconds += 1;
        this.updateUITimer(progressValue, AUDIO_RECORDING_LIMIT - seconds, MediaStatus.RECORDING);
        setTimeout(updateProgressBar, 1000);
      }
    };
    updateProgressBar();
  }

  public closeAudioCapture() {
    this.pausePlayback();
    this.cancelTimeouts();
    this.deleteAudioFile();
  }

  public saveAndCloseAudioCapture() {
    this.pausePlayback();
    this.cancelTimeouts();
    if (this.cordovaMediaRecord) {
      // Releases the underlying operating system's audio resources. This is particularly important for Android, since there are a finite
      // amount of OpenCore instances for media playback. Applications should call the release function for any Media
      // resource that is no longer needed
      this.cordovaMediaRecord.release();
    }
    this.deleteAudioFile();
  }

  registerOnChange(fn: any): void {
    this.onValueChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(obj: any): void {
    this.logger.debug('writeValue', obj);
    if (isNullOrUndefined(obj)) {
      this.audioBase64 = null;
      this.mediaStatus = MediaStatus.READY;
      this.audio = null;
    } else {
      this.audioBase64 = obj;
      this.audio = new Audio(this.audioBase64 || undefined);
      this.mediaStatus = MediaStatus.COMPLETED;
    }
  }
}
