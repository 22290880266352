/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./menu/menu.component.ngfactory";
import * as i3 from "./menu/menu.component";
import * as i4 from "./accounts.service";
import * as i5 from "@angular/router";
import * as i6 from "./information.service";
import * as i7 from "./analytics.service";
import * as i8 from "./audit-form/audit.service";
import * as i9 from "./walkthrough/walkthrough.service";
import * as i10 from "@ngx-translate/core";
import * as i11 from "@angular/material/dialog";
import * as i12 from "ngx-logger";
import * as i13 from "./alert/service/modal.service";
import * as i14 from "./api/api.service";
import * as i15 from "./institution.service";
import * as i16 from "./app.component";
import * as i17 from "./idle.service";
import * as i18 from "./backbutton.service";
import * as i19 from "./settings/settings.service";
import * as i20 from "@angular/common";
import * as i21 from "./push-messages/push.service";
import * as i22 from "./insights/insights.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-menu", [], null, null, null, i2.View_MenuComponent_0, i2.RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i3.MenuComponent, [i4.AccountService, i5.Router, i6.InformationService, i7.AnalyticsService, i8.AuditService, i9.WalkthroughService, i10.TranslateService, i11.MatDialog, i1.ElementRef, i12.NGXLogger, i13.ModalService, i14.ApiService, i15.InstitutionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 4308992, null, 0, i16.AppComponent, [i17.IdleService, i7.AnalyticsService, i18.BackbuttonService, i19.SettingsService, i10.TranslateService, i4.AccountService, i20.DOCUMENT, i12.NGXLogger, i10.TranslateService, i13.ModalService, i21.PushService, i22.InsightsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("meg-root", i16.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
