import {Injectable} from '@angular/core';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable, of, forkJoin} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {NGXLogger} from 'ngx-logger';

/** Keeps track of keys inserted to database */
const DB_KEY_KEYS = 'keys';

export const DB_KEY_QIP_FILTER_STATE = 'filter-state';

@Injectable()
export class StorageService {

  constructor(protected localStorage: LocalStorage, private logger: NGXLogger) {
  }

  /** Adds key to index of keys */
  private addKey(key: string): Observable<boolean> {
    return this.getRawKeys().pipe(
      mergeMap((keyset: string[]) => {
        if (keyset.find((k: string) => k === key) === undefined) {
          keyset.push(key);
          return this.localStorage.setItem(DB_KEY_KEYS, keyset);
        } else {
          return of(true);
        }
      }),
    );
  }

  /** Removes key from index of keys */
  private removeKey(key: string): Observable<boolean> {
    return this.getRawKeys().pipe(
      mergeMap((keyset: string[]) => {
        keyset = keyset.filter((k: string) => k !== key);
        return this.localStorage.setItem(DB_KEY_KEYS, keyset);
      }),
    );
  }

  /** Returns set of keys for current environment recorded in indexed database using this storage manager */
  public getKeys(): Observable<string[]> {
    return this.getRawKeys().pipe(
      map((keyset: string[]) => {
        const prefix = environment.storagePrefix;
        if (prefix) {
          // filter out keys from other builds (staging, local) and remove prefix
          return keyset
            .filter((key: string) => key.startsWith(prefix))
            .map((key: string) => key.slice(prefix.length));
        } else {
          return keyset;
        }
      }),
    );
  }

  /** Returns set of keys recorded in indexed database using this storage manager */
  private getRawKeys(): Observable<string[]> {
    return this.localStorage.getItem(DB_KEY_KEYS).pipe(
      map((keyset) => {
        // keyset could still be a Set instance, in which case convert it to array
        if (keyset instanceof Array) return keyset;
        else if (keyset === null) return null;
        else return Array.from(keyset);
      }),
      map((keyset: string[] | null) => keyset === null ? <string[]>[] : keyset),
    );
  }

  public setItem<T>(key: string, value: T): Observable<boolean> {
    key = this.prefixKey(key);
    return forkJoin(
        this.localStorage.setItem(key, value).pipe(
          tap((result: boolean) => this.logger.debug(`Written "${key}":`, value, result)),
        ),
        this.addKey(key),
    ).pipe(
      map((result: boolean[]) => result[0]),
    );
  }

  public removeItem(key: string): Observable<boolean> {
    key = this.prefixKey(key);
    return forkJoin(
      this.localStorage.removeItem(key),
      this.removeKey(key),
    ).pipe(
      map((result: boolean[]) => result[0]),
      tap(() => this.logger.debug(`Removed item "${key}"`)),
    );
  }

  /**
   * Gets item from database.
   * Raises an error if item does not exist or is null.
   */
  public getItem<T>(key: string): Observable<T> {
    key = this.prefixKey(key);
    return this.localStorage.getItem(key).pipe(
      tap((value: T) => {
        if (value === null) { throw new Error(`Empty value received for ${key}`); }
      }),
    );
  }

  /**
   * Returns number representing number of characters stored in given key(s).
   * Defaults to 0 for non-existing keys
   * @param keys
   */
  public getItemSizes(...keys: string[]): Observable<number> {
    const observables: Observable<number>[] = keys.map((key: string): Observable<number> =>
      this.getItemOrNull(key).pipe(
        map((item: any): number => item === null ? 0 : JSON.stringify(item).length),
      ));

    if (observables.length === 0) return of(0);
    else return forkJoin(observables).pipe(
      map((sizes: number[]): number => sizes.reduce((a, b) => a + b)),
    );
  }

  /**
   * Checks whether key exists in Indexed DB.
   * This implementation tries to retrieve item and returns false if any error occurs during retrieval.
   * @param {string} key
   * @returns {Observable<boolean>} true if item exists, false if it doesn't
   */
  public hasItem(key: string): Observable<boolean> {
    key = this.prefixKey(key);
    return this.localStorage.getItem(key).pipe(
      map((value: any) => value !== null),
    );
  }

  /** Gets item from database or default value if item does not exist or is null */
  public getItemOrDefault<T>(key: string, defaultValue: T): Observable<T> {
    key = this.prefixKey(key);
    return this.localStorage.getItem(key).pipe(
      map((value: T | null) => value === null ? defaultValue : value),
    );
  }

  /**
   * Gets item from database.
   * Returns null if item does not exist
   */
  public getItemOrNull<T>(key: string): Observable<T | null> {
    key = this.prefixKey(key);
    return this.localStorage.getItem(key).pipe();
  }

  /**
   * Clears all data from database and cookies
   */
  public clearAllItems(): Observable<boolean> {
    window.localStorage.clear();
    return this.localStorage.clear();
  }

  public setString(key: string, value: string) {
    key = this.prefixKey(key);
    window.localStorage.setItem(key, value);
  }

  public getString(key: string): string | null {
    key = this.prefixKey(key);
    return window.localStorage.getItem(key) || null;
  }

  public removeString(key: string) {
    key = this.prefixKey(key);
    window.localStorage.removeItem(key);
  }

  /** Prepends prefix to given key */
  private prefixKey(key: string): string {
    return `${environment.storagePrefix}${key}`;
  }
}
