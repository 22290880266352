<div [ngClass]="['form-field', fieldClass, form.controls[field.field_name].errors ? 'has-errors' : 'no-errors']" *ngIf="form && auditForm && isVisible" [formGroup]="form" [id]="'question-' + field.field_name">
  <button *ngIf="showClearButton" mat-button class="clear-button" (click)="resetField(); false;"><mat-icon>clear</mat-icon> {{ 'clear' | translate }}</button>
  <h2>
    <mat-icon style="vertical-align: middle" *ngIf="canAutoCycle" matTooltipPosition="below" matTooltip="{{'audit-form.field.auto-cycle-tooltip' | translate}}">cached</mat-icon>
    {{field.label}}
  </h2>
  <div *ngIf="fieldImage" class="field-image" (click)="toggleQuestionImage()" [ngClass]="questionImageSize ? 'large' : 'small'">
    <i class="question-image-status"></i>
    <img class="question-image" [src]="fieldImage">
  </div>
  <meg-timer *ngIf="showTimer"></meg-timer>
  <div [ngSwitch]="field.widget" [ngClass]="[auditForm?.config?.enable_answer_comments? 'answer-comments-enabled': '']">
    <div *ngSwitchCase="widgetTypes.SELECT_MULTIPLE">
      <ng-container *ngTemplateOutlet="multiselect_widget"></ng-container>
    </div>
    <div *ngSwitchCase="widgetTypes.MODEL_SELECT_MULTIPLE">
      <ng-container *ngTemplateOutlet="multiselect_widget"></ng-container>
    </div>

    <div *ngSwitchCase="widgetTypes.SELECT_RADIO">
      <mat-radio-group [id]="field.field_name" [formControlName]="field.field_name" (change)="radioChangeHandler($event)">
        <mat-radio-button color="primary" *ngFor="let choice of choices" [value]="choice.value">
          {{ choice.label }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <ng-template #select_widget>
      <mat-select *ngIf="!useAutocompleteWidget" placeholder="{{'audit-form.field.select' | translate}}" [id]="field.field_name" [formControlName]="field.field_name">
        <mat-option *ngFor="let choice of choices" [value]="choice.value">{{choice.label}}</mat-option>
      </mat-select>

      <mat-form-field class="no-underline" *ngIf="useAutocompleteWidget">
        <input matInput type="text" placeholder="{{'audit-form.field.select' | translate}}" [formControlName]="field.field_name" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayChoiceGetter()">
          <mat-option *ngFor="let choice of filteredChoices|async" [value]="choice.value">{{choice.label}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-template>

    <ng-template #remote_select_widget>
      <mat-form-field class="no-underline">
        <input matInput type="text" placeholder="{{'audit-form.field.searchable-select' | translate}}" [formControlName]="field.field_name" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="displayChoiceGetter()">
          <mat-option *ngFor="let choice of filteredRemoteFormChoices|async" [value]="choice.value">{{choice.label}}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-template>
    <ng-template #multiselect_widget>
      <mat-select *ngIf="!useAutocompleteMultiSelectWidget" placeholder="{{'audit-form.field.select' | translate}}" [multiple]="true" [id]="field.field_name" [formControlName]="field.field_name">
        <mat-option *ngFor="let choice of choices" [value]="choice.value">{{choice.label}}</mat-option>
      </mat-select>

      <mat-form-field class="no-underline mat-chip-container" *ngIf="useAutocompleteMultiSelectWidget">
        <input
            placeholder="{{'audit-form.field.select' | translate}}"
            #choiceInput
            [formControl]="multiChoiceFormControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputAddOnBlur]="true">
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let choice of multiChoicesSelected()"
            [selectable]="true"
            [removable]="true"
            (removed)="removeMultiChoice(choice)">
            {{labelFromValue(choice)}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="multiChoiceSelected($event)">
          <mat-option *ngFor="let choice of filteredMultiSelectChoices | async" [value]="choice">
            <mat-pseudo-checkbox [state]="multiChoiceIsSelected(choice.value) ? 'checked' : 'unchecked'"></mat-pseudo-checkbox><span style="margin-left: 10px">{{choice.label}}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-template>

    <div *ngSwitchCase="widgetTypes.SELECT">
      <ng-container *ngTemplateOutlet="select_widget"></ng-container>
    </div>

    <div *ngSwitchCase="widgetTypes.MODEL_SELECT">
      <ng-container *ngTemplateOutlet="select_widget"></ng-container>
    </div>

    <div *ngSwitchCase="widgetTypes.REMOTE_FORM_SELECT">
      <ng-container *ngTemplateOutlet="remote_select_widget"></ng-container>
    </div>

    <div *ngSwitchCase="widgetTypes.TEXT_INPUT">
      <mat-form-field class="no-underline">
        <input matInput [id]="field.field_name" [formControlName]="field.field_name" [maxlength]="field.max_length">
      </mat-form-field>
    </div>

    <div *ngSwitchCase="widgetTypes.TEXT_AREA">
      <mat-form-field class="no-underline">
        <textarea matInput
                  [id]="field.field_name"
                  [formControlName]="field.field_name"
                  [maxlength]="field.max_length"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="3"
                  cdkAutosizeMaxRows="8"></textarea>
      </mat-form-field>
    </div>

    <div *ngSwitchCase="widgetTypes.NUMBER_INPUT">
      <mat-form-field class="no-underline">
        <input matInput type="number" pattern="[0-9-]+" [id]="field.field_name" [formControlName]="field.field_name">
      </mat-form-field>
    </div>

    <div *ngSwitchCase="widgetTypes.STOPWATCH_INPUT" style="padding-bottom: 2px">
      <meg-timer [id]="field.field_name" [formControlName]="field.field_name"></meg-timer>
    </div>

    <div *ngSwitchCase="widgetTypes.CHECKBOX_INPUT">
      <mat-checkbox [id]="field.field_name" [formControlName]="field.field_name"></mat-checkbox>
    </div>

    <div class="date-input-short" *ngSwitchCase="widgetTypes.DATE_INPUT">
      <p class="outside-help-text">{{'audit-form.field.enter-date' | translate}}</p>
      <meg-custom-date-picker [id]="field.field_name" [formControlName]="field.field_name"></meg-custom-date-picker>
    </div>

    <div class="time-input" *ngSwitchCase="widgetTypes.TIME_INPUT">
      <p class="outside-help-text">{{'audit-form.field.select-time' | translate}}</p>
      <meg-time-field [id]="field.field_name" [formControlName]="field.field_name"></meg-time-field>
    </div>

    <div *ngSwitchCase="widgetTypes.DATE_TIMEINPUT">
      <meg-date-time-field [id]="field.field_name" [formControlName]="field.field_name"></meg-date-time-field>
    </div>

    <div *ngSwitchCase="widgetTypes.FLOAT_INPUT">
      <mat-form-field class="no-underline">
        <input matInput type="number" step="0.01" [id]="field.field_name" [formControlName]="field.field_name">
      </mat-form-field>
    </div>

    <div class="date-input-short no-underline" *ngSwitchCase="widgetTypes.SHORT_DATE_INPUT">
      <p class="outside-help-text">{{'audit-form.field.enter-date' | translate}}</p>
      <meg-date-picker [formControlName]="field.field_name" ></meg-date-picker>
    </div>

    <div class="date-input-short no-underline" *ngSwitchCase="widgetTypes.DOB_INPUT">
      <p class="outside-help-text">{{'audit-form.field.enter-date' | translate}}</p>
      <meg-custom-date-picker [id]="field.field_name" [formControlName]="field.field_name" startView="multi-year" [useDOB]="true"></meg-custom-date-picker>
    </div>

    <div *ngSwitchCase="widgetTypes.FILE_INPUT">
      <p class="outside-help-text">{{'audit-form.field.add-file-multi' | translate}}</p>
      <meg-file-field [id]="field.field_name" [formControlName]="field.field_name" ></meg-file-field>
    </div>

    <div *ngSwitchCase="widgetTypes.MULTIFILE_INPUT">
      <p class="outside-help-text">{{'audit-form.field.add-file' | translate}}</p>
      <meg-file-field [id]="field.field_name" [formControlName]="field.field_name" [multiple]="true" ></meg-file-field>
    </div>

    <div *ngSwitchCase="widgetTypes.AUDIO_INPUT">
      <meg-audio-field [id]="field.field_name" [formControlName]="field.field_name" ></meg-audio-field>
    </div>

    <div *ngSwitchCase="widgetTypes.HEADING"></div>

    <div *ngSwitchDefault>
      {{'audit-form.field.unsupported-widget' | translate}}: {{ field.widget}}.
    </div>
    <button *ngIf="auditForm?.config?.enable_answer_comments && !showCommmentBox;" (click)="toggleCommentBox();" mat-raised-button color="primary" class="answer-comment-btn"><mat-icon>comment</mat-icon></button>
    <button *ngIf="showCommmentBox" (click)="toggleCommentBox();" mat-raised-button color="warn" class="answer-comment-btn"><mat-icon>delete</mat-icon></button>
  </div>
  <!-- Errors from server-->
  <mat-error *ngFor="let error of errors">{{error}}</mat-error>
  <!--Local form errors-->
  <div *ngIf="form.get(field.field_name)?.touched">
    <mat-error *ngFor="let msg of errorMessages">
      {{msg}}
    </mat-error>
  </div>
  <p class="help-text" [innerHTML]="field.help_text | safe : 'html'"></p>

  <button *ngIf="auditForm?.config?.enable_qip && (compliance !== null && compliance < 1)  && auditForm?.config?.backend_only_qip !== true" (click)="addIssue(); true;" mat-raised-button color="primary" class="add-field-issue"><mat-icon>add</mat-icon> {{ 'qip.add-issue' | translate:{'issue_label':issue_label | async} }}</button>
  <div *ngIf="showCommmentBox" class="comments-box">
      <mat-form-field class="no-underline">
        <textarea matInput
                  [id]="'comment-' + field.field_name"
                  [formControlName]="'comment-' + field.field_name"
                  [maxlength]="field.max_length"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="3"
                  cdkAutosizeMaxRows="8"></textarea>
      </mat-form-field>
  </div>
</div>
