<div class="issue-details" *ngIf="issue">
  <h1>{{issue.comment}}</h1>
  <mat-chip-list class="status-container" *ngIf="!editable">
    <mat-chip *ngIf="status" color="primary" class="issue-status" [ngStyle]="{backgroundColor: status?.color}">{{status?.label|translate}}</mat-chip>
  </mat-chip-list>

  <mat-select class="status-label status" *ngIf="editable" [compareWith]="compareStatuses" [(ngModel)]="issue.status">
    <mat-select-trigger class="status_select" [ngStyle]="{backgroundColor: issue.status.color}">{{ issue.status.label|translate }} <mat-icon class="mat-select-arrow"></mat-icon></mat-select-trigger>
    <mat-option class="status-label" [ngStyle]="{backgroundColor: status?.color}" *ngFor="let status of statusChoices" [value]="status" >{{status.label|translate }}</mat-option>
  </mat-select>
  <p class="issue-info">
    <span class="heading">{{'qip.due-date'|translate}}: </span>
    {{issue.duedate|date}}
  </p>
  <p class="issue-info">
    <span class="heading">{{'qip.location'|translate}} </span>
    {{location$|async}}
  </p>
  <p class="issue-info">
    <span class="heading">{{'qip.assigned-to'|translate}} </span>
    <span *ngIf="!editable">{{issueHandlerName$|async}}</span>
    <mat-select *ngIf="editable" [(value)]="issue.handler" placeholder="{{'qip.issue-handler-assign'|translate}}">
      <mat-option [value]="null">{{'qip.handler-none'|translate}}</mat-option>
      <mat-option *ngFor="let handler of issueHandlers" [value]="handler.id">{{handler.name}}</mat-option>
    </mat-select>
  </p>
  <p class="issue-info" *ngIf="editable">
    <span class="heading">{{'qip.action-taken'|translate}}</span>
    <mat-form-field class="no-underline">
      <textarea matInput [(ngModel)]="issue.actiontaken" spellcheck="true" autocomplete="true"></textarea>
    </mat-form-field>
  </p>
  <img class="issue-photo" *ngFor="let photo of issue.photos" [src]="photo.photo" alt="Issue photo"/>

  <button mat-raised-button color="primary" (click)="goBack()"><mat-icon>arrow_back</mat-icon> {{'back'|translate}}</button>
  <button mat-raised-button class="float-right" color="accent" (click)="saveClicked()" *ngIf="editable"><mat-icon>check</mat-icon> {{'save'|translate}}</button>
</div>

<mat-progress-spinner mode="indeterminate" *ngIf="issue === undefined"></mat-progress-spinner>
