import {Component, Injector, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {SelectAvatarDialogComponent} from './select-avatar-dialog/select-avatar-dialog.component';
import {User} from '../api/models/user';
import {Institution} from '../api/models/institution';
import {Avatar, DEFAULT_AVATAR_PATH} from '../api/models/avatar';
import {ProfileService} from './profile.service';
import {BaseComponent} from '../base.component';
import {catchError, map, take, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Region} from '../api/models/region';
import {AccountService} from '../accounts.service';
import {of} from 'rxjs/internal/observable/of';
import {isNullOrUndefined} from '../utils/misc';
import {AppConfigService} from '../app-config.service';

@Component({
  selector: 'meg-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent extends BaseComponent implements OnInit {
  public user: User;
  public institution: Institution | null = null;
  public userAvatar: Avatar | null;
  public updatingAvatar = false;
  public defaultAvatarPath = DEFAULT_AVATAR_PATH;
  public region: Region | null = null;

  constructor(private dialog: MatDialog, private profileService: ProfileService, private accountService: AccountService,
              private injector: Injector, private appConfig: AppConfigService) {
    super(injector);
  }

  public get region$(): Observable<Region> {
    return this.accountService.getRegion().pipe(
      map(region => {
        if (isNullOrUndefined(region)) return this.appConfig.getDefaultRegion();
        else return region;
      }),
      catchError(() => of(this.appConfig.getDefaultRegion())),
    );
  }

  ngOnInit() {
    this.profileService.getUserAndInstitution().subscribe(
      (result: [User, Institution]) => [this.user, this.institution] = result,
      (error) => this.logger.error('Error loading user and institution data', error),
    );

    this.profileService.getUserAvatar().subscribe(
      (avatar: Avatar | null) => this.userAvatar = avatar,
      (error) => this.logger.error('Error loading user avatar', error),
    );

    this.region$.subscribe((region: Region) => this.region = region);
  }

  public editAvatar() {
    const dialog = this.dialog.open(SelectAvatarDialogComponent, {
      data: this.userAvatar,
    });
    dialog.afterClosed().subscribe((result: Avatar | false | null) => {
      if (result !== false) this.changeAvatar(result);
    });
  }

  /**
   * Updates UI with the new avatar and saves changes
   * @param {Avatar | null} avatar
   */
  public changeAvatar(avatar: Avatar | null) {
    this.updatingAvatar = true;
    this.user.auditor.avatar = avatar !== null ? avatar.id : null;
    const profileSubscription = this.profileService.saveProfile(this.user).pipe(
      tap( () => {
        this.updatingAvatar = false;
        this.userAvatar = avatar;
      }),
      catchError((error: HttpErrorResponse, caught: Observable<boolean>) => {
        this.updatingAvatar = false;
        this.logger.error(error);
        const message = this.translateService.instant('profile.update-error');
        const action = this.translateService.instant('retry');
        return this.snackbar.open(message, action, {}).onAction().pipe(
          tap(() => this.changeAvatar(avatar))
        );
      })
    ).subscribe();
    this.subscriptions.push(profileSubscription);
  }

}
