import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var LastModifiedService = /** @class */ (function () {
    function LastModifiedService(translateService) {
        this.translateService = translateService;
    }
    /**
     * Get last modified date with language code appended and Deletes old storage key.
     * @param keyName
     */
    LastModifiedService.prototype.getLastModifiedKey = function (keyName) {
        var language = this.translateService.currentLang;
        return "last-modified-" + keyName + "-" + language;
    };
    LastModifiedService.ngInjectableDef = i0.defineInjectable({ factory: function LastModifiedService_Factory() { return new LastModifiedService(i0.inject(i1.TranslateService)); }, token: LastModifiedService, providedIn: "root" });
    return LastModifiedService;
}());
export { LastModifiedService };
