<mat-table #table [dataSource]="audit.auditSession.observations" *ngIf="columns && displayedColumns">

  <ng-container matColumnDef="position">
    <mat-header-cell *cdkHeaderCellDef>{{'audit-preview.form-number' | translate}}</mat-header-cell>
    <mat-cell *matCellDef="let rowIndex = index">{{rowIndex + 1}}</mat-cell>
  </ng-container>

  <ng-container *ngFor="let column of columns" [cdkColumnDef]="column.columnDef">
    <mat-header-cell *cdkHeaderCellDef>{{ column.header }}</mat-header-cell>
    <mat-cell *cdkCellDef="let row; let observation;">
      <p>
        {{ getCellValue(observation, column.columnDef) }}
      </p>
    </mat-cell>
  </ng-container>


  <ng-container matColumnDef="cell_error">
    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
    <mat-cell class="action-cell" *matCellDef="let rowIndex = index">
      <mat-icon class="error_icon" *ngIf="hasObservationErrors(rowIndex)">error_outline</mat-icon>
      <mat-icon>edit</mat-icon>
    </mat-cell>
  </ng-container>


  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row class="observation-row audit-preview" (click)="onRowClicked(observation, rowIndex)" *matRowDef="let observation; let rowIndex = index; columns: displayedColumns;"></mat-row>
</mat-table>
