import { OnInit } from '@angular/core';
import { QipService } from '../qip.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { IssuesDialogComponent } from './issues-dialog/issues-dialog.component';
import { IssueDialogArguments } from './issue-dialog-arguments';
import { Audit } from '../../audit-form/audit';
import { AuditFormService } from '../../audit-form.service';
import { mergeMap } from 'rxjs/operators';
import { AuditService } from '../../audit-form/audit.service';
var IssuesWidgetComponent = /** @class */ (function () {
    function IssuesWidgetComponent(qipService, dialog, auditFormService, snackbar, auditService) {
        this.qipService = qipService;
        this.dialog = dialog;
        this.auditFormService = auditFormService;
        this.snackbar = snackbar;
        this.auditService = auditService;
        /** Current observation or sub-observation */
        this.auditForm = null;
        this.commonIssues = [];
    }
    IssuesWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.auditFormService.getAuditForm(this.audit.auditFormId).pipe(mergeMap(function (auditForm) { return _this.qipService.getCommonIssueComments(auditForm, _this.subForm); })).subscribe(function (issue) { return _this.commonIssues = issue; });
        this.auditFormService.getAuditForm(this.audit.auditFormId).subscribe(function (auditForm) {
            _this.auditForm = auditForm;
            _this.issue_label = _this.auditService.getIssueLabel(_this.auditForm);
            _this.issue_label_plural = _this.auditService.getIssueLabel(_this.auditForm, true);
        });
    };
    IssuesWidgetComponent.prototype.isCommonIssue = function (issue) {
        return this.commonIssues.indexOf(issue.comment) > -1;
    };
    Object.defineProperty(IssuesWidgetComponent.prototype, "numCommonIssues", {
        get: function () {
            var _this = this;
            return this.observation.issues.filter(function (issue) { return _this.isCommonIssue(issue); }).length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IssuesWidgetComponent.prototype, "numCustomIssues", {
        get: function () {
            var _this = this;
            return this.observation.issues.filter(function (issue) { return !_this.isCommonIssue(issue); }).length;
        },
        enumerable: true,
        configurable: true
    });
    IssuesWidgetComponent.prototype.getIssues = function (showNewIssueButton, title, hideUnselectedCommonIssues) {
        var _this = this;
        if (showNewIssueButton === void 0) { showNewIssueButton = true; }
        if (title === void 0) { title = 'qip.issues'; }
        if (hideUnselectedCommonIssues === void 0) { hideUnselectedCommonIssues = false; }
        this.auditFormService.getAuditForm(this.audit.auditFormId).pipe(mergeMap(function (auditForm) { return _this.qipService.getCommonIssues(auditForm, _this.subForm); })).subscribe(function (commonIssues) {
            var dialogRef = _this.dialog.open(IssuesDialogComponent, {
                height: '90%',
                data: new IssueDialogArguments(_this.observation.issues, _this.audit, commonIssues, null, _this.errors, showNewIssueButton, title, hideUnselectedCommonIssues, _this.issue_label, _this.issue_label_plural),
            });
        });
    };
    IssuesWidgetComponent.prototype.showIssuesDialog = function () {
        this.getIssues();
    };
    IssuesWidgetComponent.prototype.reviewIssuesDialog = function () {
        this.getIssues(false, 'qip.action-plan', true);
    };
    return IssuesWidgetComponent;
}());
export { IssuesWidgetComponent };
