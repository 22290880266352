import {AuditForm} from '../api/models/audit-form';
import {AuditSession} from '../api/models/audit-session';
import {AuditFormSchema} from '../api/models/audit-form-schema';
import {Errors} from '../api/models/errors';

/**
 * Represents an audit in progress. Holds audit data and references audit form and ward.
 */
export class Audit {
  constructor(
    public id: number,
    public auditFormId: number,
    public wardId: number,
    public userId: number,
    public auditSession: AuditSession,
    public errors: Errors | null = null,
    /** whether this audit has been submitted successfully (without photos) */
    public submitted: boolean = false,
    // whether this audit was already submitted and is downloaded onto device for editing
    public downloaded: boolean = false,
  ) {}
}

/**
 * Contains a pair of audit form and its schema
 */
export class AuditFormSchemaWrapper {
  constructor(public auditForm: AuditForm, public auditFormSchema: AuditFormSchema) {
    if (auditForm == null) { throw Error('Audit Form must not be null'); }
    if (auditFormSchema == null) { throw Error('Audit Form schema must not be null'); }
  }
}

/**
 * Contains audit with relevant audit form object and its schema
 */
export class AuditWithFormSchema {
  constructor(public audit: Audit, public auditForm: AuditForm, public auditFormSchema: AuditFormSchema) {
  }
}
