import {Injectable} from '@angular/core';
import {StorageService} from '../storage.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
  Dashboard,
  DASHBOARD_WIDGET_BAR,
  DASHBOARD_WIDGET_FIELD_TABLE,
  DASHBOARD_WIDGET_SIMPLE_COMPLIANCE,
  DASHBOARD_WIDGET_SUB_FORM_BAR
} from '../api/models/dashboard';
import {
  OBSERVATION_MODEL_CVC,
  OBSERVATION_MODEL_HAND_HYGIENE,
  OBSERVATION_MODEL_HAND_HYGIENE_UK,
  OBSERVATION_MODEL_HAND_HYGIENE_US,
  OBSERVATION_MODEL_HIQA,
  OBSERVATION_MODEL_IPS, OBSERVATION_MODEL_PVC,
  OBSERVATION_MODEL_PVC_CVC, OBSERVATION_MODEL_UCC
} from '../api/models/audit-form';
import {of} from 'rxjs/internal/observable/of';
import {TranslateService} from '@ngx-translate/core';

export const DB_KEY_DASHBOARDS = 'dashboards';

@Injectable()
export class DashboardService {

  constructor(private storageService: StorageService, private translateService: TranslateService) {
  }

  public saveDashboards(dashboards: Dashboard[]): Observable<boolean> {
    return this.storageService.setItem(DB_KEY_DASHBOARDS, dashboards);
  }

  public getAllDashboards(): Observable<Dashboard[]> {
    return this.storageService.getItem<Dashboard[]>(DB_KEY_DASHBOARDS).pipe(map(value => value == null ? [] : value));
  }

  public getDashboard(auditFormId: number): Observable<Dashboard | null> {
    const dashboardFilter = (dashboard: Dashboard) => dashboard.audit_form === auditFormId;
    return this.getAllDashboards().pipe(
      map((dashboards: Dashboard[]) => dashboards.find(dashboardFilter) || null),
    );
  }

  public getHardcodedDashboard(modelPath: string): Observable<Dashboard | null> {
    switch (modelPath) {
      case OBSERVATION_MODEL_HAND_HYGIENE:
      case OBSERVATION_MODEL_HAND_HYGIENE_UK:
      case OBSERVATION_MODEL_HAND_HYGIENE_US:
        return this.translateService.get([
          'charts.summary',
          'charts.overview',
          'charts.hand-hygiene.compliance-moment',
          'charts.hand-hygiene.moment',
          'charts.hand-hygiene.compliance-profession',
        ]).pipe(
          map((translations: { [id: string]: string }) => {
            return {
              'id': 1,
              'widgets': [{
                'id': 1,
                'title': translations['charts.summary'],
                'subtitle': null,
                'widget': DASHBOARD_WIDGET_SIMPLE_COMPLIANCE,
                'size': 6,
                'config': {
                  'id': 1,
                  'compliance': null,
                  'field_name': 'profession',
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }, {
                'id': 2,
                'title': translations['charts.hand-hygiene.compliance-moment'],
                'subtitle': null,
                'widget': DASHBOARD_WIDGET_FIELD_TABLE,
                'size': 6,
                'config': {
                  'id': 2,
                  'compliance': null,
                  'field_name': 'moment',
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }, {
                'id': 3,
                'title': translations['charts.overview'],
                'subtitle': translations['charts.hand-hygiene.compliance-profession'],
                'widget': DASHBOARD_WIDGET_BAR,
                'size': 6,
                'config': {
                  'id': 3,
                  'compliance': true,
                  'field_name': 'profession',
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }],
              'type': 'instant-report',
              'audit_form': 1,
            };
          })
        );
      case OBSERVATION_MODEL_HIQA:
        return this.translateService.get(['charts.summary', 'charts.overview', 'charts.compliance-by-form']).pipe(
          map((translations: { [id: string]: string }) => {
            return {
              'id': 1,
              'widgets': [{
                'id': 1,
                'title': translations['charts.summary'],
                'subtitle': '',
                'widget': DASHBOARD_WIDGET_SIMPLE_COMPLIANCE,
                'size': 6,
                'config': {
                  'id': 1,
                  'compliance': null,
                  'field_name': null,
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }, {
                'id': 3,
                'title': translations['charts.overview'],
                'subtitle': translations['charts.compliance-by-form'],
                'widget': DASHBOARD_WIDGET_SUB_FORM_BAR,
                'size': 6,
                'config': {
                  'id': 3,
                  'compliance': null,
                  'field_name': null,
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }],
              'type': 'instant-report',
              'audit_form': 1,
            };
          })
        );
      case OBSERVATION_MODEL_IPS:
        return this.translateService.get(['charts.summary', 'charts.overview']).pipe(
          map((translations: { [id: string]: string }) => {
            return {
              'id': 1,
              'widgets': [{
                'id': 1,
                'title': translations['charts.summary'],
                'subtitle': '',
                'widget': DASHBOARD_WIDGET_SIMPLE_COMPLIANCE,
                'size': 6,
                'config': {
                  'id': 1,
                  'compliance': null,
                  'field_name': null,
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }, {
                'id': 3,
                'title': translations['charts.overview'],
                'subtitle': '',
                'widget': DASHBOARD_WIDGET_SUB_FORM_BAR,
                'size': 6,
                'config': {
                  'id': 3,
                  'compliance': null,
                  'field_name': null,
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }],
              'type': 'instant-report',
              'audit_form': 1,
            };
          })
        );
      case OBSERVATION_MODEL_PVC_CVC:
        return this.translateService.get(['charts.summary', 'charts.overview', 'charts.compliance-by-question']).pipe(
          map((translations: { [id: string]: string }) => {
            return {
              'id': 1,
              'widgets': [{
                'id': 1,
                'title': 'charts.summary',
                'subtitle': '',
                'widget': DASHBOARD_WIDGET_SIMPLE_COMPLIANCE,
                'size': 6,
                'config': {
                  'id': 1,
                  'compliance': null,
                  'field_name': null,
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }, {
                'id': 3,
                'title': translations['charts.overview'],
                'subtitle': translations['charts.compliance-by-question'],
                'widget': DASHBOARD_WIDGET_BAR,
                'size': 6,
                'config': {
                  'id': 3,
                  'compliance': true,
                  'field_name': null,
                  'field_names': [
                    'date_on_dressing', 'dressing_intact', 'signs_of_infection', 'insertion_form_complete', 'continuing_care_form'
                  ],
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }],
              'type': 'instant-report',
              'audit_form': 1,
            };
          })
        );
      case OBSERVATION_MODEL_PVC:
      case OBSERVATION_MODEL_UCC:
      case OBSERVATION_MODEL_CVC:
        return this.translateService.get(['charts.summary', 'charts.overview', 'charts.compliance-by-question']).pipe(
          map((translations: { [id: string]: string }) => {
            return {
              'id': 1,
              'widgets': [{
                'id': 1,
                'title': translations['charts.summary'],
                'subtitle': '',
                'widget': DASHBOARD_WIDGET_SIMPLE_COMPLIANCE,
                'size': 6,
                'config': {
                  'id': 1,
                  'compliance': null,
                  'field_name': null,
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }, {
                'id': 3,
                'title': translations['charts.overview'],
                'subtitle': translations['charts.compliance-by-question'],
                'widget': DASHBOARD_WIDGET_SUB_FORM_BAR,
                'size': 6,
                'config': {
                  'id': 3,
                  'compliance': null,
                  'field_name': null,
                  'field_names': null,
                  'count_entries': false,
                  'show_meg': undefined,
                },
                'dashboard': 1,
              }],
              'type': 'instant-report',
              'audit_form': 1,
            };
          })
        );
    }
    return of(null);
  }
}
