import { ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSidenav } from '@angular/material';
import { MenuItem } from './menu-item';
import { AccountService, PERMISSION_DOCS_MANAGE, PERMISSION_DOCS_VIEW } from '../accounts.service';
import { Router } from '@angular/router';
import { InformationService } from '../information.service';
import { AuditService } from '../audit-form/audit.service';
import { concat, of } from 'rxjs';
import { AnalyticsService } from '../analytics.service';
import { map, mergeMap } from 'rxjs/operators';
import { WalkthroughService } from '../walkthrough/walkthrough.service';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { DASHBOARD_TOKEN_LOGIN, DOCUMENTS_MANAGE, DOCUMENTS_VIEW } from '../api/constants';
import { ACCOUNT_TYPE_PUBLIC } from '../api/models/auditor';
import { TranslateService } from '@ngx-translate/core';
import { openUrl } from '../utils/misc';
import { NGXLogger } from 'ngx-logger';
import { ModalService } from '../alert/service/modal.service';
import { ModalButton } from '../alert/modal-alert-data';
import { ApiService } from '../api/api.service';
import { tap } from 'rxjs/internal/operators/tap';
import { InstitutionService } from '../institution.service';
var MenuComponent = /** @class */ (function () {
    function MenuComponent(accountService, router, informationService, analytics, auditService, walkthroughService, translateService, dialog, elementRef, logger, modalService, apiService, institutionService) {
        this.accountService = accountService;
        this.router = router;
        this.informationService = informationService;
        this.analytics = analytics;
        this.auditService = auditService;
        this.walkthroughService = walkthroughService;
        this.translateService = translateService;
        this.dialog = dialog;
        this.elementRef = elementRef;
        this.logger = logger;
        this.modalService = modalService;
        this.apiService = apiService;
        this.institutionService = institutionService;
        this.items = [];
        this.mode = new FormControl('over');
        this.menuOpenChanged = false;
        this.allowMenuSwipe = false;
        this.unavailableMenuPages = ['/loading', '/login'];
        this.user$ = of(null);
    }
    MenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allowMenuSwipe = this.accountService.isAuthenticated() && this.unavailableMenuPages.indexOf(this.router.url) === -1;
        /**
         * subscribing to router.events when reloading page
         */
        this.router.events.subscribe(function (url) {
            _this.logger.trace('events url', url);
            if ((url['url']) !== undefined) {
                _this.allowMenuSwipe = _this.accountService.isAuthenticated() && _this.unavailableMenuPages.indexOf(url['url']) === -1;
            }
        });
        var hammertime = new Hammer(this.elementRef.nativeElement, {});
        hammertime.on('panright', function (event) {
            if (_this.allowMenuSwipe && event.center.x < 30) {
                _this.sideNav.open();
            }
        });
        hammertime.get('pan').set({ threshold: 200 });
        hammertime.on('panleft', function () {
            _this.sideNav.close();
        });
        this.sideNav.openedChange.subscribe(function (next) { return _this.menuOpenChanged = next; });
        this.user$ = this.accountService.currentUser$;
        this.initMenuItems();
    };
    Object.defineProperty(MenuComponent.prototype, "auditCount$", {
        /**
         * Observes number of audits in SavedAudits section.
         * Initially emits current audit count and emits a new value whenever an audit is saved/deleted.
         * Note: Number of audits are not unique - saving audit will also trigger emit.
         */
        get: function () {
            var _this = this;
            return concat(
            // emit empty event before listening to changes to trigger fetching number of audits
            of(null), this.auditService.auditChanges$).pipe(mergeMap(function (audit) { return _this.accountService.getUser(); }), mergeMap(function (user) { return _this.auditService.getAuditsByUser(user.id, true); }), map(function (audits) { return audits.length; }));
        },
        enumerable: true,
        configurable: true
    });
    MenuComponent.prototype.initMenuItems = function () {
        var _this = this;
        this.translateService.stream([
            'menu.dashboard',
            'menu.documents',
            'menu.messages',
            'menu.qip',
            'menu.saved-audits',
            'menu.restart-audit',
            'menu.profile',
            'menu.settings',
            'menu.calendar',
            'menu.about',
            'menu.contact-us',
            'menu.logout',
        ]).subscribe(function (translations) {
            var dashboard = new MenuItem(translations['menu.dashboard'], function () {
                _this.analytics.trackButtonClick('Dashboard');
                _this.sideNav.toggle();
                _this.openDashboard();
            }, false, 'launch');
            var qip = new MenuItem(translations['menu.qip'], function () {
                _this.analytics.trackButtonClick('QIP');
                _this.sideNav.toggle();
                _this.router.navigate(['qip']);
            }, false, 'done');
            var messages = new MenuItem(translations['menu.messages'], function () {
                _this.analytics.trackButtonClick('messages');
                _this.sideNav.toggle();
                _this.router.navigate(['channels']);
            }, false, 'message');
            var menuItemProfile = new MenuItem(translations['menu.profile'], function () {
                _this.sideNav.toggle();
                _this.router.navigateByUrl('/profile');
                _this.analytics.trackButtonClick('Profile');
            }, true, 'person');
            var calendarMenuItem = new MenuItem(translations['menu.calendar'], function () {
                _this.sideNav.toggle();
                _this.router.navigateByUrl('/calendar');
                _this.analytics.trackButtonClick('calendar');
            }, true, 'event');
            var documents = new MenuItem(translations['menu.documents'], function () { }, false, 'launch');
            _this.accountService.currentUser$.subscribe(function (user) {
                var userPermissions = user ? user.permissions || [] : [];
                dashboard.visible = userPermissions.indexOf('dashboard_widgets.view_dashboard') > -1;
                qip.visible = userPermissions.indexOf('qip.change_issue') > -1;
                if (user) {
                    _this.institutionService.getInstitutionOrNull(user.auditor.institution).subscribe(function (institution) {
                        if (institution && institution.megdocs_enabled) {
                            _this.accountService.hasPermissions(PERMISSION_DOCS_MANAGE).subscribe(function (hasChangePermission) {
                                if (hasChangePermission) {
                                    documents.visible = hasChangePermission;
                                    documents.action = function () {
                                        _this.analytics.trackButtonClick('MegDocs');
                                        _this.sideNav.toggle();
                                        _this.openDashboard("docs/" + institution.slug + DOCUMENTS_MANAGE);
                                    };
                                }
                                else {
                                    _this.accountService.hasPermissions(PERMISSION_DOCS_VIEW).subscribe(function (hasViewPermission) {
                                        documents.visible = hasViewPermission;
                                        documents.action = function () {
                                            _this.analytics.trackButtonClick('MegDocs');
                                            _this.sideNav.toggle();
                                            _this.openDashboard("docs/" + institution.slug + DOCUMENTS_VIEW);
                                        };
                                    });
                                }
                            });
                        }
                    });
                }
                messages.visible = user !== null && user.messaging_enabled === true;
                var isPublicAccount = user === null || user.auditor.account_type === ACCOUNT_TYPE_PUBLIC;
                menuItemProfile.visible = !isPublicAccount;
                calendarMenuItem.visible = !isPublicAccount;
            });
            _this.items = [
                new MenuItem(translations['menu.saved-audits'], function () {
                    _this.analytics.trackButtonClick('Saved Audits');
                    _this.sideNav.toggle();
                    _this.router.navigateByUrl('/draft-audits');
                }, true, 'list', _this.auditCount$.pipe(map(function (numAudits) { return numAudits === 0 ? null : numAudits.toString(); }))),
                messages,
                qip,
                new MenuItem(translations['menu.restart-audit'], function () {
                    _this.analytics.trackButtonClick('New Audit');
                    _this.auditService.clearAuditFormId();
                    _this.sideNav.toggle();
                    _this.router.navigateByUrl('/');
                }, true, 'add'),
                calendarMenuItem,
                menuItemProfile,
                new MenuItem(translations['menu.settings'], function () {
                    _this.sideNav.toggle();
                    _this.router.navigate(['settings']);
                    _this.analytics.trackButtonClick('Settings');
                }, true, 'settings'),
                new MenuItem(translations['menu.about'], function () {
                    _this.analytics.trackButtonClick('About');
                    _this.sideNav.toggle();
                    _this.router.navigate(['about-app']);
                }, true, 'info'),
                new MenuItem(translations['menu.contact-us'], function () {
                    _this.analytics.trackButtonClick('Contact Us');
                    _this.sideNav.toggle();
                    _this.dialog.open(FeedbackDialogComponent, {
                        width: '100%',
                    }).afterClosed().subscribe(function (result) {
                        if (result) {
                            alert(_this.translateService.instant('contact.success-message'));
                        }
                    });
                }, true, 'message'),
                dashboard,
                documents,
                new MenuItem(translations['menu.logout'], function () {
                    _this.analytics.trackButtonClick('Log Out');
                    _this.sideNav.toggle();
                    _this.logOutConfirm();
                }, true, 'exit_to_app'),
            ];
        });
    };
    /**
     * Opens back-end dashboard
     */
    MenuComponent.prototype.openDashboard = function (next) {
        var _this = this;
        if (next === void 0) { next = ''; }
        var token = this.accountService.token;
        this.accountService.getUser().pipe(map(function (user) { return user.username; }), mergeMap(function (username) { return _this.apiService.buildUrl(DASHBOARD_TOKEN_LOGIN + "?username=" + username + "&token=" + token + (next ? '&next=' + next : '')); }), tap(function (url) { return openUrl(url); })).subscribe();
    };
    MenuComponent.prototype.logOut = function () {
        this.accountService.logOut(this.auditService, this.router);
    };
    MenuComponent.prototype.logOutConfirm = function () {
        var _this = this;
        this.modalService.openInfoModal(this.translateService.get('menu.logout-prompt'), new ModalButton(this.translateService.get('menu.logout'), function () { return _this.logOut(); }, 'exit_to_app'), this.modalService.btnCancel());
    };
    MenuComponent.prototype.toggleMenu = function (state) {
        this.sideNav.toggle();
    };
    MenuComponent.prototype.walkthroughShowing = function () {
        return this.walkthroughService.showing;
    };
    return MenuComponent;
}());
export { MenuComponent };
