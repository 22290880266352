

export const NORMAL_SCALE = 1.0;
export const MEDIUM_SCALE = 1.2;
export const HIGH_SCALE = 1.3;
export const EXTRA_HIGH_SCALE = 1.5;

export class ScaleLevel {
  constructor(public name: string, public value: number = NORMAL_SCALE) {
  }
}
