<mat-card id="login-card" class="limit-width center mat-elevation-z" *ngIf="sendingRequest == false">

  <mat-card-header translate>login.please-login</mat-card-header>

  <form class="form-height" #UserLogin="ngForm" (ngSubmit)="onLoginClicked()" (keydown.enter)="onLoginClicked()">
    <mat-error *ngIf="errors?.non_field_errors">{{errors?.non_field_errors}}</mat-error>
    <mat-form-field class="form-inputs">
      <input matInput placeholder="{{'login.username' | translate}}" required [(ngModel)]="credentials.username" name="username" autocapitalize="none">
      <mat-error *ngIf="errors?.username">{{errors?.username}}</mat-error>
    </mat-form-field>
    <mat-form-field class="form-inputs">
      <input type="password" matInput placeholder="{{ 'login.password' | translate}}" required [(ngModel)]="credentials.password" name="password">
      <mat-error *ngIf="errors?.password">{{errors?.password}}</mat-error>
    </mat-form-field>
    <div megRecaptcha class="form__captcha" *ngIf="errors?.captcha"></div>

    <button mat-button class="centered-buttons" (click)="onForgotPasswordClicked(); false;">{{ 'login.forgot-password' | translate }}</button>
    <button mat-button class="centered-buttons" (click)="onRequestFreeTrialClicked(); false;">{{requestTrialText | translate}}</button>

    <button mat-raised-button color="primary" class="centered-buttons" [disabled]="!UserLogin.form.valid" ><mat-icon>check</mat-icon> {{ 'login.login' | translate}}</button>
    <a mat-button class="centered-buttons centered-buttons-a" [href]="ssoLoginUrl" ><mat-icon>lock</mat-icon> {{ 'login.sso' | translate}}</a>
  </form>

  <meg-app-version class="login-version"></meg-app-version>
</mat-card>

<mat-progress-spinner mode="indeterminate" *ngIf="sendingRequest"></mat-progress-spinner>
