var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorHandler } from '@angular/core';
import { InsightsService } from './insights.service';
var ErrorHandlerService = /** @class */ (function (_super) {
    __extends(ErrorHandlerService, _super);
    function ErrorHandlerService(insights) {
        var _this = _super.call(this) || this;
        _this.insights = insights;
        return _this;
    }
    ErrorHandlerService.prototype.handleError = function (error) {
        this.insights.logException(error);
    };
    return ErrorHandlerService;
}(ErrorHandler));
export { ErrorHandlerService };
