/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tooltip.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tooltip.component";
var styles_MEGTooltipComponent = [i0.styles];
var RenderType_MEGTooltipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MEGTooltipComponent, data: { "animation": [{ type: 7, name: "tooltip", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: 300 }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: 300 }], options: null }], options: {} }] } });
export { RenderType_MEGTooltipComponent as RenderType_MEGTooltipComponent };
export function View_MEGTooltipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[24, "@tooltip", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.toolTipClass; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.text; _ck(_v, 2, 0, currVal_2); }); }
export function View_MEGTooltipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-tooltip", [], null, null, null, View_MEGTooltipComponent_0, RenderType_MEGTooltipComponent)), i1.ɵdid(1, 49152, null, 0, i3.MEGTooltipComponent, [], null, null)], null, null); }
var MEGTooltipComponentNgFactory = i1.ɵccf("meg-tooltip", i3.MEGTooltipComponent, View_MEGTooltipComponent_Host_0, { text: "text", toolTipClass: "toolTipClass" }, {}, []);
export { MEGTooltipComponentNgFactory as MEGTooltipComponentNgFactory };
