<div class="limit-width center" id="loading" *ngIf="!error">
  <h1>{{'loading.loading' | translate}}</h1>
  <mat-progress-bar [mode]="downloadService.progress === 0 ? 'indeterminate' : 'determinate'" [value]="downloadService.progress"></mat-progress-bar>
</div>

<div *ngIf="error" class="login-error-message limit-width center">
  <h1>{{'error' | translate}}</h1>
  <p>{{'loading.error-message' | translate}}</p>

  <button mat-raised-button color="primary" (click)="logOut()"><mat-icon>arrow_back</mat-icon>{{'menu.logout' | translate}}</button>
  <button mat-raised-button color="primary" (click)="loadData()"><mat-icon>replay</mat-icon>{{'retry' | translate}}</button>

  <div *ngIf="error?.message" class="technical-details">
    <button mat-button (click)="showErrorDetails = !showErrorDetails">{{'loading.technical-details-btn' | translate}}</button>
  </div>
  <div id="error-message-container">
    <p class="error-msg" *ngIf="showErrorDetails">{{ error?.message }}</p>
  </div>
</div>
