<mat-dialog-content>

  <div>
    <h1>{{'login.password-reset.password-reset' | translate}}</h1>
  </div>
  <form #ForgotPassword="ngForm" (keydown.enter)="onSubmitClicked()">
    <mat-form-field class="form-inputs">
      <input matInput placeholder="username" required [(ngModel)]="username" name="{{'login.username' | translate}}" autocapitalize="none">
    </mat-form-field>
  </form>

  <mat-progress-spinner mode="indeterminate" *ngIf="sendingRequest"></mat-progress-spinner>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" class="dialog-btn-left" [mat-dialog-close]="false"><mat-icon>clear</mat-icon> {{'cancel' | translate}}</button>
  <button mat-raised-button color="accent" class="dialog-btn-right" [disabled]="!ForgotPassword.form.valid" (click)="onSubmitClicked()"><mat-icon>send</mat-icon> {{'send' | translate}}</button>
</mat-dialog-actions>
