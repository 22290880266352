var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditFormService } from '../audit-form.service';
import { LayoutType } from '../api/models/audit-form';
import { AuditService } from '../audit-form/audit.service';
import { BaseComponent } from '../base.component';
import { MatSnackBar } from '@angular/material';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { validateSessionToken } from '../api/utils';
import { ApiService } from '../api/api.service';
var SubformSelectComponent = /** @class */ (function (_super) {
    __extends(SubformSelectComponent, _super);
    function SubformSelectComponent(activatedRoute, auditFormService, injector, auditService, router, snackBar, api) {
        var _this = _super.call(this, injector) || this;
        _this.activatedRoute = activatedRoute;
        _this.auditFormService = auditFormService;
        _this.injector = injector;
        _this.auditService = auditService;
        _this.router = router;
        _this.snackBar = snackBar;
        _this.api = api;
        return _this;
    }
    SubformSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        validateSessionToken(this.api, this.translateService);
        this.activatedRoute.params.subscribe(function (params) {
            var auditId = +params['audit_id'];
            if (!isNaN(auditId)) {
                _this.auditService.setAuditId(auditId);
                _this.auditService.getAuditWithFormSchema(auditId).pipe(catchError(function (e) {
                    return _this.translateService.get('audit-form.form-load-error').pipe(tap(function (message) { return _this.snackBar.open(message, undefined, { duration: 3000 }); }), tap(function () { return _this.router.navigate(['']); }), mergeMap(function () { return throwError(e); }));
                })).subscribe(function (result) {
                    _this.audit = result[0], _this.auditForm = result[1], _this.auditFormSchema = result[2];
                    if (!_this.auditFormService.hasSubforms(_this.auditFormSchema) ||
                        _this.auditFormService.getFormLayoutType(_this.auditForm) === LayoutType.Accordion) {
                        // if this audit has no subforms, skip directly to audit form
                        _this.router.navigate(['audit-form', auditId]);
                    }
                }, function (error) {
                    _this.logger.error(error);
                });
            }
        });
    };
    /**
     * Returns observations which has an answer for given subform.
     * If no observation have a given subobservation, empty list is returned.
     */
    SubformSelectComponent.prototype.getObservationsForSubform = function (form) {
        var subformName = form.name;
        return this.audit.auditSession.observations.filter(function (observation) { return observation[subformName] !== undefined; });
    };
    SubformSelectComponent.prototype.onFormSelect = function (form) {
        var _this = this;
        this.logger.debug('Form selected', form);
        var existingObservations = this.getObservationsForSubform(form);
        if (form.many || existingObservations.length === 0) {
            this.router.navigate(['audit-form', this.audit.id, 'subform', form.name]);
        }
        else {
            this.modalService.openWarningModal(this.translateService.get('audit-list.form-already-filled', {
                form: form.display_name,
            }), this.modalService.btnOK(function () {
                var observationId = _this.audit.auditSession.observations.indexOf(existingObservations[0]);
                if (observationId != null)
                    _this.router.navigate(['audit-form', _this.audit.id, 'subform', form.name, 'edit', observationId]);
            }), this.modalService.btnCancel()).subscribe();
        }
    };
    return SubformSelectComponent;
}(BaseComponent));
export { SubformSelectComponent };
