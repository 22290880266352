var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { AuditService } from '../audit-form/audit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionService } from '../institution.service';
import { AuditForm, LayoutType } from '../api/models/audit-form';
import { AuditFormService } from '../audit-form.service';
import { AnalyticsService } from '../analytics.service';
import { BaseComponent } from '../base.component';
import { Observation } from '../api/models/observation';
import { isNullOrUndefined } from '../utils/misc';
import { Hints } from '../hint.service';
import { SubmitPreviewFormComponent } from '../submit-preview/submit-preview-form/submit-preview-form.component';
import { StorageService } from '../storage.service';
var DB_VALUE_TWO_STAGE_REDIRECTION_DONE = 'true';
var AuditPreviewComponent = /** @class */ (function (_super) {
    __extends(AuditPreviewComponent, _super);
    function AuditPreviewComponent(storageService, auditService, route, institutionService, auditFormService, analytics, router, injector) {
        var _this = _super.call(this, injector) || this;
        _this.storageService = storageService;
        _this.auditService = auditService;
        _this.route = route;
        _this.institutionService = institutionService;
        _this.auditFormService = auditFormService;
        _this.analytics = analytics;
        _this.router = router;
        _this.injector = injector;
        _this.ward = null;
        return _this;
    }
    AuditPreviewComponent.prototype.getTwoStageFormRedirectionKey = function (auditId) {
        return "audit-" + auditId + "-two-stage-has-redirected";
    };
    AuditPreviewComponent.prototype.getTwoStageRedirection = function (auditId) {
        return this.storageService.getString(this.getTwoStageFormRedirectionKey(auditId)) === DB_VALUE_TWO_STAGE_REDIRECTION_DONE;
    };
    AuditPreviewComponent.prototype.setTwoStageRedirection = function (auditId) {
        this.storageService.setString(this.getTwoStageFormRedirectionKey(auditId), DB_VALUE_TWO_STAGE_REDIRECTION_DONE);
    };
    AuditPreviewComponent.prototype.removeTwoStageRedirection = function (auditId) {
        this.storageService.removeString(this.getTwoStageFormRedirectionKey(auditId));
    };
    AuditPreviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            var auditId = +params['audit_id'];
            _this.auditService.setAuditId(auditId);
            _this.auditService.getAuditWithFormSchema(auditId).subscribe(function (result) {
                _this.audit = result[0], _this.auditForm = result[1], _this.auditFormSchema = result[2];
                _this.institutionService.getWard(_this.audit.wardId).subscribe(function (ward) { return _this.ward = ward; });
                var isTwoStageAuditForm = _this.auditForm.config.stages !== undefined && _this.auditForm.config.stages >= 2;
                if (_this.audit.errors !== null && _this.audit.errors.observations !== undefined) {
                    var observationErrors_1 = _this.audit.errors.observations;
                    var missingSubForm = _this.auditFormSchema.sub_forms.find(function (subform) {
                        return observationErrors_1.find(function (errors) { return errors[subform.name] !== undefined; }) !== undefined &&
                            _this.audit.auditSession.observations.find(function (observation) { return observation[subform.name] !== undefined; }) === undefined;
                    });
                    if (missingSubForm) {
                        alert(missingSubForm.display_name + " " + _this.translateService.instant('audit-form.form-missing'));
                    }
                }
                else if (isTwoStageAuditForm && _this.audit.auditSession.observations.length === 1 && !_this.getTwoStageRedirection(auditId)) {
                    var firstObservation_1 = _this.audit.auditSession.observations[0];
                    var subForm = _this.auditFormSchema.sub_forms.find(function (subform) { return firstObservation_1[subform.name] !== undefined; });
                    _this.setTwoStageRedirection(auditId);
                    if (subForm !== undefined && _this.auditForm.config.form_layout !== LayoutType.Accordion) {
                        _this.router.navigate(['audit-form', _this.audit.id, 'subform', subForm.name, 'edit', 0]);
                    }
                    else {
                        _this.router.navigate(['audit-form', _this.audit.id, 'edit', 0]);
                    }
                }
            });
        });
    };
    Object.defineProperty(AuditPreviewComponent.prototype, "hasObservations", {
        get: function () {
            if (isNullOrUndefined(this.audit))
                return false;
            else {
                return this.audit.auditSession.observations.length > 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    AuditPreviewComponent.prototype.deleteAudit = function () {
        var _this = this;
        this.analytics.trackButtonClick('Delete');
        this.analytics.trackButtonClick('Deleting form from audit');
        this.modalService.openWarningModal(this.translateService.get('audit-preview.delete-audit-prompt'), this.modalService.btnOK(function () {
            _this.auditService.deleteAudit(_this.audit).subscribe(function () {
                _this.removeTwoStageRedirection(_this.audit.id);
                _this.auditService.setAuditId(null);
                _this.router.navigate(['/']);
            });
        }), this.modalService.btnCancel()).subscribe();
    };
    Object.defineProperty(AuditPreviewComponent.prototype, "isAutoCycleComplete", {
        get: function () {
            return this.auditService.getAutoCycleComplete(this.audit, this.auditForm, this.auditFormSchema);
        },
        enumerable: true,
        configurable: true
    });
    AuditPreviewComponent.prototype.addForm = function () {
        this.analytics.trackButtonClick('Add Form');
        this.analytics.trackAction('Adding form to audit');
        this.hintService.markShown(Hints.AddForm);
        this.router.navigate(["select-form", this.audit.id]);
    };
    AuditPreviewComponent.prototype.confirmAudit = function () {
        var _this = this;
        var missingSubForm = this.auditFormSchema.sub_forms.find(function (subform) {
            return subform.required && _this.audit.auditSession.observations.find(function (observation) {
                return observation[subform.name] !== undefined;
            }) === undefined;
        });
        if (missingSubForm) {
            alert(missingSubForm.display_name + " " + this.translateService.instant('audit-form.form-missing'));
        }
        else {
            this.router.navigate(["submit-preview", this.audit.id]);
        }
    };
    AuditPreviewComponent.prototype.onSubmitClicked = function () {
        var _this = this;
        var config = this.auditForm.config;
        if (config && config.require_signature && this.audit.auditSession.signatures.length === 0) {
            alert(this.translateService.instant('audit-submission.signature-required-error'));
            return;
        }
        this.analytics.trackButtonClick('Submit');
        this.analytics.trackAction('Audit preview submit');
        if (this.previewForm.submit()) {
            this.save().subscribe(function () { return _this.router.navigate(['audit-submit', _this.audit.id]); });
        }
    };
    AuditPreviewComponent.prototype.save = function () {
        return this.auditService.saveAudit(this.audit);
    };
    return AuditPreviewComponent;
}(BaseComponent));
export { AuditPreviewComponent };
