import {Component, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {QipService} from '../../qip.service';
import {IssueDialogArguments} from '../issue-dialog-arguments';
import {Issue} from '../../../api/models/issue';
import {Audit} from '../../../audit-form/audit';
import {IssueComponent} from '../issue/issue.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'meg-create-issue-dialog',
  templateUrl: './create-issue-dialog.component.html',
  styleUrls: ['./create-issue-dialog.component.css']
})
export class CreateIssueDialogComponent implements OnInit {
  public audit: Audit;
  public issue: Issue;
  public issue_label: Observable<string> | null;

  @ViewChild('issueComponent') private issueComponent: IssueComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IssueDialogArguments, private qipService: QipService,
              private dialogRef: MatDialogRef<CreateIssueDialogComponent>) {
    this.audit = data.audit;
    this.issue_label = data.issue_label;
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.issue = new Issue('');
  }

  public create() {
    if (this.issueComponent) this.issueComponent.updateMedia();
    this.dialogRef.close(this.issue);
  }

}
