// Values for `Field.compliance_calculation` field
import {Model} from './model';
import {FormRelatedData} from './form-relation';
import {Answer} from './observation';
import {Document} from './docs';

/** Field not used in compliance calculation @type {number} */
export const FIELD_COMPLIANCE_IGNORE = 0;
/** Field used to calculate average compliance @type {number} */
export const FIELD_COMPLIANCE_USE = 1;
/** Field essential in compliance calculation:
 * if field value is incompliant, the observation is considered incompliant regardless of answers to other questions @type {number} */
export const FIELD_COMPLIANCE_REQUIRE = 2;

// values for `Field.widget` field
export const FIELD_WIDGET_SELECT = 'django.forms.widgets.Select';
export const FIELD_WIDGET_RADIOSELECT = 'django.forms.widgets.RadioSelect';
export const FIELD_WIDGET_SELECTMULTIPLE = 'django.forms.widgets.SelectMultiple';
export const FIELD_WIDGET_TEXTINPUT = 'django.forms.widgets.TextInput';
export const FIELD_WIDGET_TEXTAREA = 'django.forms.widgets.Textarea';
export const FIELD_WIDGET_NUMBERINPUT = 'django.forms.widgets.NumberInput';
export const FIELD_WIDGET_STOPWATCH = 'meg_forms.audit_builder.form_widget.StopwatchInput';
export const FIELD_WIDGET_CHECKBOXINPUT = 'django.forms.widgets.CheckboxInput';
export const FIELD_WIDGET_DATEINPUT = 'django.forms.widgets.DateInput';
export const FIELD_WIDGET_TIMEINPUT = 'django.forms.widgets.TimeInput';
export const FIELD_WIDGET_DATETIMEINPUT = 'django.forms.widgets.DateTimeInput';
export const FIELD_WIDGET_FLOATINPUT = 'meg_forms.audit_builder.form_widget.FloatInput';
export const FIELD_WIDGET_SHORTDATEINPUT = 'audit_builder.widgets.ShortDateWidget';
export const FIELD_WIDGET_DOBINPUT = 'audit_builder.widgets.DOBDateWidget';
export const FIELD_WIDGET_FILEINPUT = 'django.forms.widgets.ClearableFileInput';
export const FIELD_WIDGET_MULTIFILEINPUT = 'audit_builder.widgets.MultiFileClearableInput';
export const FIELD_WIDGET_AUDIO = 'audit_builder.widgets.AudioWidget';
export const FIELD_WIDGET_MODEL_SELECT = 'audit_builder.widgets.ModelSelect';
export const FIELD_WIDGET_REMOTE_FORM_SELECT = 'audit_builder.widgets.RemoteFormSelect';
export const FIELD_WIDGET_MODEL_SELECT_MULTIPLE = 'audit_builder.widgets.ModelSelectMultiple';
export const FIELD_WIDGET_MODEL_HEADING = 'audit_builder.widgets.HeadingWidget';
export const FIELD_WIDGET_ACCORDION = 'audit_builder.widgets.AccordionHeadingWidget';

// values for `Field.model.model`
export const INSTITUTION = 'institution';
export const ROOM = 'room';

export class Choice {
  /** Name displayed to the user */
  label: string;
  /** Name used programmatically and in API */
  value: string;
}

/** Field/Answer combination used to evaluate complex conditional logic */
export class ConditionFieldAnswer {
  // source field name
  field_name: string;
  // answer field must have for condition to evaluate to true
  answer: Answer;
}

/** Allows multiple fields to be compared to evaluate complex conditional logic */
export class NestedCondition {
  // AND/OR operator to compare field answer combinations
  operator: string;
  // Fields to be compared.
  fields: NestedCondition[] | ConditionFieldAnswer[];
}

/** Defines condition on a field to trigger action in another field */
export class Condition {
  // source field name
  field_name: string | NestedCondition;
  // value in the source field to trigger action
  has_value: string;
  // whether the field should become required
  make_required: boolean;
  // whether the field should be made visible
  show: boolean;
  limit_choices: string[] | null;
  // value the field should display
  set_value: string | null;
  // field name in model field containing image to set
  set_image: string | null;
}

export class FieldValidator {
  id: number | null;
  validator_class: string;
  error_message?: string;
  typed_value?: string | number;
}

export class Field {
  /** Database id of the field */
  id: number;
  /** Name displayed to the user */
  label: string;
  /** Name used programmatically and in API */
  field_name: string;
  help_text: string;
  /** Python pathc to the widget class; One of the `FIELD_WIDGET_*` values */
  widget: string;
  max_length: number | null;
  required: boolean;
  choices: Choice[];
  compliance_calculation: number;
  /** value for this field answer to be considered compliance */
  compliant_value: string | null;
  /** Another value to be treated the same as no answer (null) */
  ignored_value: string | null;
  compliance_weight: number;
  /** Dictionary mapping value (as string) to its compliance value */
  answer_values: {[value: string]: number | null};
  conditions: Condition[];
  image: string | null;
  image_base64: string | null;
  validators?: FieldValidator[];
  model?: ModelFieldModelSchema;
  field_group: number | null;
  autoselect: boolean;
  show_in_app?: boolean;

  public toString() {
    return this.label;
  }
}


/** A group of fields to be nested
 * within a question group, and displayed inline in UI. */
export class InlineFieldGroup {
  fields: Field[] = [];
  layout_columns: number;

  /**
   * Returns true if group contains any required fields
   */
  public get required(): boolean {
    return this.fields.find(field => field.required) !== undefined;
  }
}

export class SubFormSchema {
  /** Name displayed to the user */
  display_name: string;
  /** Name used programmatically and in API */
  name: string;
  fields: Field[];
  many: boolean;
  required: boolean;

  public toString() {
    return this.display_name;
  }
}

export class FieldGroup {
  id: number;
  layout_columns: number;
  form: number | null;

  public toString() {
    return this.layout_columns;
  }
}
export class AuditFormSchema extends Model {
  /** Fields in this form; If form has subforms, this list will be empty and all fields will be found in their respective subforms */
  fields: Field[];
  /** Sub-forms to this form (if any) */
  sub_forms: SubFormSchema[];
  /** Custom field groups (if any). Used to group custom fields into columns */
  field_groups: FieldGroup[];
  related_data_sources?: FormRelatedData[];
  documents?: Document[];
}

export class ModelFieldModelSchema {
  id: number;
  app_label: string;
  model: string;
}

/** Used to record answers given to accordion subform
 * For use in conditional logic. */
export class AccordionFieldAnswer extends ConditionFieldAnswer {
  accordion_index: number;
}
