import { Injectable } from '@angular/core';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {environment} from '../../environments/environment';
import {AccountService} from '../accounts.service';

@Injectable({
  providedIn: 'root'
})
export class InsightsService {
  appInsights: ApplicationInsights;

  constructor(private account: AccountService) {
    const key = environment.appInsights.instrumentationKey;
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        loggingLevelConsole: 2,
        loggingLevelTelemetry: 2,
        enableDebugExceptions: true,
      }
    });
    if (key) {
      this.appInsights.loadAppInsights();
      account.user$.subscribe(user => {
        if (user === null) this.appInsights.clearAuthenticatedUserContext();
        else {
          this.appInsights.setAuthenticatedUserContext(user.id.toString(), user.username, false);
        }
      });
    }
  }

  public logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  public logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }

  public trackPage(url: string, name?: string) {
    this.appInsights.trackPageView({
      uri: url,
      name: name,
    });
  }

  public trackLongEvent(name: string) {
    this.appInsights.startTrackEvent(name);
  }

  public trackLongEventStop(name: string, properties?: {}) {
    this.appInsights.stopTrackEvent(name, properties);
  }
}
