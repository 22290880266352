/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-dialog.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/ngx-webcam/ngx-webcam.ngfactory";
import * as i4 from "ngx-webcam";
import * as i5 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "@ngx-translate/core";
import * as i13 from "./photo-dialog.component";
import * as i14 from "ngx-logger";
var styles_PhotoDialogComponent = [i0.styles];
var RenderType_PhotoDialogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PhotoDialogComponent, data: {} });
export { RenderType_PhotoDialogComponent as RenderType_PhotoDialogComponent };
export function View_PhotoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "webcam", [["class", "webcam-display"]], null, [[null, "imageCapture"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageCapture" === en)) {
        var pd_0 = (_co.onImageCapture($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_WebcamComponent_0, i3.RenderType_WebcamComponent)), i1.ɵdid(3, 4374528, null, 0, i4.WebcamComponent, [], { trigger: [0, "trigger"] }, { imageCapture: "imageCapture" }), (_l()(), i1.ɵeld(4, 0, null, null, 16, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "button", [["class", "issue-btn-left dialog-btn-left"], ["color", "primary"], ["mat-dialog-close", ""], ["mat-raised-button", ""], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dialogRef.close(i1.ɵnov(_v, 7).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(7, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i1.ɵdid(8, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(10, 638976, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵted(12, 0, [" ", ""])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 6, "button", [["class", "dialog-btn-right"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.trigger.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(17, 638976, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["check"])), (_l()(), i1.ɵted(19, 0, [" ", ""])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trigger.asObservable(); _ck(_v, 3, 0, currVal_0); var currVal_4 = ""; _ck(_v, 7, 0, currVal_4); var currVal_5 = "primary"; _ck(_v, 8, 0, currVal_5); _ck(_v, 10, 0); var currVal_10 = "primary"; _ck(_v, 15, 0, currVal_10); _ck(_v, 17, 0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 7).ariaLabel; var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_6 = i1.ɵnov(_v, 10).inline; _ck(_v, 9, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("cancel")); _ck(_v, 12, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 15).disabled || null); var currVal_9 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_8, currVal_9); var currVal_11 = i1.ɵnov(_v, 17).inline; _ck(_v, 16, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("save")); _ck(_v, 19, 0, currVal_12); }); }
export function View_PhotoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-photo-dialog", [], null, null, null, View_PhotoDialogComponent_0, RenderType_PhotoDialogComponent)), i1.ɵdid(1, 114688, null, 0, i13.PhotoDialogComponent, [i2.MatDialogRef, i14.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoDialogComponentNgFactory = i1.ɵccf("meg-photo-dialog", i13.PhotoDialogComponent, View_PhotoDialogComponent_Host_0, {}, {}, []);
export { PhotoDialogComponentNgFactory as PhotoDialogComponentNgFactory };
