import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {AuditSelectComponent} from './audit-select/audit-select.component';
import {LoadingComponent} from './loading/loading.component';
import {InformationComponent} from './information/information.component';
import {AuditFormComponent} from './audit-form/audit-form.component';
import {SubformSelectComponent} from './subform-select/subform-select.component';
import {AuditPreviewComponent} from './audit-preview/audit-preview.component';
import {AuditListComponent} from './audit-list/audit-list.component';
import {SubmissionComponent} from './submission/submission.component';
import {AboutAppComponent} from './about-app/about-app.component';
import {SubmitPreviewComponent} from './submit-preview/submit-preview.component';
import {ProfileComponent} from './profile/profile.component';
import {SettingsComponent} from './settings/settings.component';
import {WalkthroughComponent} from './walkthrough/walkthrough.component';
import {AuditFormDeactivateGuard} from './audit-form/audit-form-deactivate-guard.service';
import {SubmissionGuard} from './submission/submission-guard';
import {CalendarComponent} from './calendar/calendar.component';
import {IssueListComponent} from './qip/issue-list/issue-list.component';
import {IssueDetailComponent} from './qip/issue-list/issue-detail/issue-detail.component';
import {MessageListComponent} from './messaging/message-list/message-list.component';
import {ChannelListComponent} from './messaging/channel-list/channel-list.component';

const routes: Routes = [
  {path: '', component: AuditSelectComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'channels', component: ChannelListComponent},
  {path: 'channels/:channel_id/messages', component: MessageListComponent},
  {path: 'qip', component: IssueListComponent},
  {path: 'qip/:issue_id', component: IssueDetailComponent},
  {path: 'start/:institution_id/:audit_form_id', component: AuditSelectComponent},
  {path: 'login', component: LoginComponent},
  {path: 'loading', component: LoadingComponent},
  {path: 'information/:information_id', component: InformationComponent},
  {path: 'draft-audits', component: AuditListComponent},
  {path: 'select-form/:audit_id', component: SubformSelectComponent},
  {
    path: 'audit-form/:audit_id', component: AuditFormComponent, canDeactivate: [AuditFormDeactivateGuard], runGuardsAndResolvers: 'always',
  },
  {
    path: 'audit-form/:audit_id/subform/:subform_name', component: AuditFormComponent, canDeactivate: [AuditFormDeactivateGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'audit-form/:audit_id/edit/:observation_id', component: AuditFormComponent, canDeactivate: [AuditFormDeactivateGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'audit-form/:audit_id/subform/:subform_name/edit/:observation_id', component: AuditFormComponent,
    canDeactivate: [AuditFormDeactivateGuard],
    runGuardsAndResolvers: 'always',
  },
  {path: 'audit-preview/:audit_id', component: AuditPreviewComponent},
  {path: 'audit-submit/:audit_id', component: SubmissionComponent, canDeactivate: [SubmissionGuard]},
  {path: 'about-app', component: AboutAppComponent},
  {path: 'submit-preview/:audit_id', component: SubmitPreviewComponent},
  {path: 'calendar', component: CalendarComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'intro', component: WalkthroughComponent},
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
