import {AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MessagingService} from '../messaging.service';
import {Observable} from 'rxjs/internal/Observable';
import {Channel, CHANNEL_TYPE_PRIVATE, Message, MESSAGE_FORMAT_HTML} from '../../api/models/messaging';
import {ActivatedRoute} from '@angular/router';
import {tap} from 'rxjs/internal/operators/tap';
import {catchError, map, mergeMap, retry} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {interval, Subscription} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'meg-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit, AfterViewChecked, OnDestroy {
  public channel$: Observable<Channel>;
  public scrollingToBottom = true;
  public messages: Message[];
  private checkInterval = 5000;
  private channelId: number;
  private timer: Subscription | undefined;
  public sending = false;
  public allowSendMessage = false;

  @ViewChild('scrollContainer') private scrollContainer: ElementRef;
  @ViewChild('message') private messageInput: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private messaging: MessagingService,
              private logger: NGXLogger, private translation: TranslateService) {
  }

  get channelId$(): Observable<number> {
    return this.activatedRoute.params.pipe(
      map(params => +params['channel_id']),
    );
  }

  ngOnInit() {
    this.channelId$.pipe(
      tap((channelId: number) => {
        this.channelId = channelId;
        this.channel$ = this.messaging.getChannel(channelId).pipe(
          tap(channel => this.allowSendMessage = (channel.channel_type !== CHANNEL_TYPE_PRIVATE)),
        );
      }),
      mergeMap(() => this.fetchMessages()),
    ).subscribe();
  }

  private addMessages(messages: Message[]) {
    if (this.messages === undefined) this.messages = messages;
    else if (messages.length === 0) return;
    else this.messages.push(...messages);
    this.scrollingToBottom = true;
  }

  private fetchMessages() {
    return this.messaging.getMessages(this.channelId).pipe(
      tap((messages) => this.addMessages(messages)),
      tap(() => this.timer = interval(this.checkInterval).pipe(
        mergeMap(() => this.checkNewMessages()),
      ).subscribe()),
    );
  }

  private checkNewMessages(): Observable<Message[]> {
    return this.messaging.getMessages(this.channelId, true).pipe(
      tap(messages => this.addMessages(messages)),
    );
  }

  public scrollToBottom() {
    try {
      const scrollElement = this.scrollContainer.nativeElement;
      scrollElement.scrollTop = scrollElement.scrollHeight;
    } catch (err) {
    }
  }

  public isSafeHtml(message: Message): boolean {
    return message.format === MESSAGE_FORMAT_HTML;
  }

  ngAfterViewChecked(): void {
    if (this.scrollingToBottom) this.scrollToBottom();
  }

  @HostListener('window:scroll', ['$event'])
  public onScroll(event: Event) {
    this.scrollingToBottom = false;
  }

  ngOnDestroy(): void {
    if (this.timer) {
      this.timer.unsubscribe();
    }
  }

  public sendMessage() {
    this.sending = true;
    const messageBox = this.messageInput.nativeElement;
    const message: string = messageBox.value;

    this.channelId$.pipe(
      mergeMap((id: number) => this.messaging.sendMessage(id, message)),
      retry(3),
      catchError((e) => {
        this.sending = false;
        this.logger.error(e);
        return this.translation.get('messaging.send-error').pipe(
          map((msg: string) => alert(msg)),
        );
      }),
      mergeMap(() => this.checkNewMessages().pipe(
        retry(3),
        catchError(() => of(true)),
      )),
      tap(() => this.sending = false),
      tap(() => messageBox.value = ''),
    ).subscribe();
  }
}
