<div class="issue-form-container">
  <mat-error *ngFor="let error of errors?.non_field_errors">{{error}}</mat-error>
  <p *ngIf="field" class="issue-question">{{'qip.question' | translate}}: {{ field?.label }}</p>
  <div class="issue-form-grouped flexed-row">
    <!-- Room -->
    <div class="flexed-column">
      <label>{{'qip.room-number' | translate}}</label>
      <mat-form-field class="issue-form-field no-underline">
        <input class="room-input" matInput maxlength="100" ngModel="{{issue.room?.name}}" [matAutocomplete]="auto" [formControl]="roomsCtrl" (input)="updateRoom($event.target.value.trim());" autocomplete="off">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let room of filteredRooms" [value]="room.name" (onSelectionChange)="updateRoom(room.name)" (click)="blurRoomInput()">
            {{room.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-error *ngFor="let error of errors?.room?.name">{{ error }}</mat-error>
    </div>

    <!-- Issue handlers -->
    <div class="flexed-column wide col-2" *ngIf="issueHandlers && issueHandlers.length > 0">
      <label class="column-label">{{'qip.issue-handler-assign' | translate}}</label>
      <mat-form-field class="issue-form-field no-underline">
          <input class="handler-input" matInput type="text" [formControl]="handlersCtrl" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let handler of filteredHandlers" [value]="handler.name" (onSelectionChange)="updateHandler(handler.id)" (click)="blurHandlerInput()">
            {{handler.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-error *ngFor="let error of errors?.handler">{{ error }}</mat-error>
      <mat-slide-toggle class="issue-email" [disabled]="!issue.handler" [checked]="issue.send_email && issue.handler" (change)="issue.send_email = $event.source.checked">{{'qip.send-email' | translate}}</mat-slide-toggle>
    </div>

    <!-- Risk levels -->
    <div class="flexed-column wide" *ngIf="auditFormConfig?.risk_levels && auditFormConfig?.risk_levels.length > 0">
      <label>{{'qip.risk-level' | translate}}</label>
      <mat-select placeholder="{{'qip.risk-level-select' | translate}}" [(value)]="issue.risk_level">
        <mat-option class="capitalise" [value]="null">{{'qip.risk-level-none' | translate}}</mat-option>
        <mat-option class="capitalise" *ngFor="let risk_level_choice of auditFormConfig?.risk_levels" [value]="risk_level_choice.value">{{risk_level_choice.label}}</mat-option>
      </mat-select>
    </div>
    <mat-error *ngFor="let error of errors?.risk_level">{{ error }}</mat-error>

    <!-- Recommendation -->
    <div class="flexed-row wide"  *ngIf="auditFormConfig && auditFormConfig?.show_recommendation">
      <label>{{'qip.recommendation' | translate}}:</label>
      <mat-form-field class="no-underline">
        <textarea matInput
                  [(ngModel)]="issue.recommendation"
                  (input)="onEdit();"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="4"></textarea>
      </mat-form-field>
      <mat-error *ngFor="let error of errors?.recommendation">{{ error }}</mat-error>
    </div>

    <!-- Action taken -->
    <div class="flexed-row wide"  *ngIf="auditFormConfig && auditFormConfig?.show_action_taken">
      <label>{{'qip.action-taken' | translate}}:</label>
      <mat-form-field class="no-underline">
        <textarea matInput
                  [(ngModel)]="issue.actiontaken"
                  (input)="onEdit();"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="4"></textarea>
      </mat-form-field>
      <mat-error *ngFor="let error of errors?.actiontaken">{{ error }}</mat-error>
    </div>
  </div>

  <!-- Due date -->
  <label>{{'qip.due-date' | translate}}</label>
  <meg-custom-date-picker [formControl]="dueDateCtrl" ></meg-custom-date-picker>
  <mat-error *ngFor="let error of errors?.duedate">{{ error }}</mat-error>

  <!-- Media -->
  <div *ngIf="photos || issue.audio"><p class="media-count">{{'qip.media-count' | translate}}: {{ photos.length + (issue.audio ? 1 : 0) }}</p></div>
  <div *ngIf="photos || audio" class="issue-photos">
    <div class="issue-photo" *ngFor="let photo of photos">
      <img [src]="getPhotoBase64(photo)">
      <button class="btn-remove-photo" mat-icon-button color="warn" title="{{'qip.remove-photo' | translate}}" (click)="removePhoto(photo)"><mat-icon>delete_forever</mat-icon></button>
    </div>
    <img *ngIf="audio" id="empty-image" src="assets/images/audio-icon.png" alt="" />
    <button *ngIf="audio" class="btn-remove-audio" mat-icon-button color="warn" title="{{'qip.remove-audio' | translate}}" (click)="removeAudio()"><mat-icon>delete_forever</mat-icon></button>
  </div>
</div>

<mat-action-row class="issue-row">
  <input type="file" #photoFile name="file" hidden accept="image/*" (change)="onPhotoPicked($event.target.files)"/>
  <button mat-mini-fab color="primary" (click)="photoFile.click()"><mat-icon>photo</mat-icon></button>
  <button mat-mini-fab color="warn" [disabled]="!isAudioRecordingEnabled" (click)="openAudioDialog()"><mat-icon>mic</mat-icon></button>
  <button mat-mini-fab color="accent" (click)="capturePhotoClicked()"><mat-icon>camera_alt</mat-icon></button>
</mat-action-row>
