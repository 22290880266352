import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Audit} from '../audit-form/audit';
import {forkJoin, merge, Observable, of, pipe, throwError} from 'rxjs';
import {AuditSession} from '../api/models/audit-session';
import {catchError, last, map, mergeMap, tap, finalize} from 'rxjs/operators';
import {AuditService} from '../audit-form/audit.service';
import {QipService} from '../qip/qip.service';
import {Issue, IssuePhoto} from '../api/models/issue';
import {AuditFormService} from '../audit-form.service';
import {AuditFormSchema} from '../api/models/audit-form-schema';
import {SubObservation} from '../api/models/observation';
import {StorageService} from '../storage.service';
import {ProfileService} from '../profile/profile.service';
import {NGXLogger} from 'ngx-logger';
import {HttpErrorResponse} from '@angular/common/http';
import {DownloadService} from '../loading/download.service';
import {InsightsService} from '../insights/insights.service';

/**
 * Service responsible for submitting audits
 */
@Injectable()
export class SubmissionService {
  // total number of issues containing photos
  public numPhotos: number | null = null;
  // number of issues whose photos were uploaded
  public photosUploaded = 0;
  // number of issues whose photos failed to upload
  public photosFailed = 0;

  constructor(private apiService: ApiService, private auditService: AuditService, private auditFormService: AuditFormService,
              private qipService: QipService, private storageService: StorageService, private profileService: ProfileService,
              private logger: NGXLogger, private downloadService: DownloadService, private insights: InsightsService) {
    this.resetProgress();
  }

  private resetProgress() {
    this.numPhotos = null;
    this.photosUploaded = 0;
    this.photosFailed = 0;
  }

  /**
   * Links photos stored in the indexed db to their issue after issues have been submitted to the back-end and their ids are known.
   * @param audit related Audit object
   * @param {AuditSession} session session object from submission response
   */
  private associatePhotosToIssues(audit: Audit, session: AuditSession): Observable<Issue[]> {
    this.logger.debug('Associating photos to issues in audit');
    return this.auditFormService.getAuditFormSchema(audit.auditFormId).pipe(
      // Extract issues from each observation and its subobservations into a list
      map((schema: AuditFormSchema) => {
        // all issues that have a client_key and will be referenced in photos
        let keyedIssues: Issue[] = [];

        /** Adds issues to a local flat list */
        const processIssues = (issues: Issue[]) => {
          keyedIssues = keyedIssues.concat(issues.filter(issue => issue.client_key));
        };

        session.observations.forEach((observation) => {
          // add issues from current observation
          processIssues(observation.issues);
          schema.sub_forms.forEach((subForm) => {
            if (!observation[subForm.name]) return;
            // add issues from each subobservation
            const subObservations: SubObservation[] = [];
            if (Array.isArray(observation[subForm.name])) subObservations.push(...<SubObservation[]>observation[subForm.name]);
            else subObservations.push(<SubObservation>observation[subForm.name]);

            subObservations
              .filter((subObservation) => subObservation.issues)
              .forEach(
                (subObservation: SubObservation) => processIssues(subObservation.issues),
              );
          });
        });

        return keyedIssues;
      }),
      // Link photos to their respective issues
      // This is done by fetching photos for each issue using the temporary uid
      // then each photo is assigned its issue id from the back-end
      mergeMap((issues: Issue[]) => {
        // for each issue, find its Photos and update `issue` property
        const issueObservables: Observable<Issue>[] = issues.map(issue => this.qipService.getIssuePhotos(audit, issue).pipe(
          tap((photos: IssuePhoto[]) => this.logger.debug('Updating photos', photos, 'for issue', issue)),
          mergeMap((photos: IssuePhoto[]) => {
            // update issue in each of the photos and save
            photos.forEach(photo => photo.issue = issue.id);
            return this.qipService.setIssuePhotos(audit, issue, photos);
          }),
          map((result: boolean) => issue),
        ));

        if (issueObservables.length === 0) return of([]);
        return forkJoin(issueObservables);
      }),
    );
  }

  private submitPhoto(photo: IssuePhoto): Observable<boolean> {
    if (!photo.issue) {
      this.logger.error('Photo has no issue. It is possible that issue was deleted or never created', photo);
      return of(true);
    }
    return this.apiService.uploadPhoto(photo).pipe(
      tap(() => {
        this.logger.debug('Photo uploaded', photo);
      }),
      map((response: IssuePhoto) => true),
      catchError((error, caught) => {
        // ignore error if photo failed to upload to continue uploading further photos
        this.logger.error('Error while submitting photo', photo, error);
        this.photosFailed += 1;
        return of(false);
      }),
    );
  }

  private submitAuditPhotos(audit: Audit): Observable<boolean> {
    this.logger.debug('Submitting photos for audit', audit);
    return this.qipService.getAuditPhotoKeys(audit).pipe(
      tap((keys: string[]) => this.numPhotos = keys.length),
      mergeMap((keys: string[]): Observable<boolean> => {
        this.logger.debug('Found photo keys', keys);
        if (keys.length === 0) return of(true);
        // create submission observable for each key (may contain multiple photos)
        const keySubmissionObservables: Observable<boolean>[] = keys.map(
          (key: string): Observable<boolean> => this.storageService.getItemOrDefault(key, []).pipe(
          mergeMap((photos: IssuePhoto[]): Observable<boolean> => {
            // submission observable for each individual photo
            const photoSubmissionObservables: Observable<boolean>[] = photos.map((photo: IssuePhoto) => this.submitPhoto(photo));
            if (photoSubmissionObservables.length === 0) return of(true);
            else return merge(...photoSubmissionObservables).pipe(
              last(),
            );
          }),
          // TODO: handle edge case where only some of the photos is submitted
          // Remove photos after submission
          tap(() => this.photosUploaded += 1),
          mergeMap((result: boolean): Observable<boolean> => {
            if (result) return this.storageService.removeItem(key);
            return of(false);
          }),
        ));
        if (keySubmissionObservables.length === 0) return of(true);
        return forkJoin(keySubmissionObservables).pipe(
          map((results: boolean[]) => results.find(result => !result) === undefined ),
        );
      }),
    );
  }

  /**
   * Checks and downloads form update
   */
  private updateForm(formId: number): Observable<boolean> {
    return this.downloadService.checkAuditFormUpdate(formId).pipe(
      mergeMap((updateAvailable: boolean): Observable<boolean> => {
        if (updateAvailable) return this.downloadService.downloadAuditForm(formId, null);
        else return of(false);
      }),
      map(() => true),
    );
  }
  /**
   * Submits audit and its photos
   * Skips audit submission if already submitted and just submits photos
   * @param {Audit} audit
   * @returns {Observable<boolean>}
   */
  public submitAudit(audit: Audit): Observable<boolean> {
    this.resetProgress();
    if (!audit.submitted) {
      this.logger.debug('Submitting audit', audit);
      this.insights.trackLongEvent('submit audit');
      return this.apiService.submitAudit(audit.auditFormId, audit.auditSession).pipe(
        tap(() => {
          this.logger.debug('Audit submitted. Response:', audit);
        }),
        catchError((err: HttpErrorResponse) => {
          // If submission was not accepted, download updated form to show correct error messages to the user
          if (err.status === 400) return this.updateForm(audit.auditFormId).pipe(mergeMap(() => throwError(err)));
          else return throwError(err);
        }),
        mergeMap((session: AuditSession) => this.associatePhotosToIssues(audit, session)),
        mergeMap(() => {
          // mark audit as submitted to avoid sending duplicate when re-trying photos
          this.logger.debug('Marking audit as submitted');
          audit.submitted = true;
          return this.auditService.saveAudit(audit).pipe(
            tap(() => this.logger.debug('Audit marked as submitted')),
          );
        }),
        mergeMap(() => this.profileService.incrementNumAudits()),
        mergeMap(() => this.submitAuditPhotos(audit)),
        mergeMap((result: boolean) => {
          if (!result) return of(false);
          return this.auditService.deleteAudit(audit);
        }),
        finalize(() => this.insights.trackLongEventStop('submit audit', {auditFormId: audit.auditFormId})),
      );
    } else {
      this.logger.debug('Audit already submitted. Sending photos only.');
      return this.submitAuditPhotos(audit).pipe(
        mergeMap((result: boolean) => {
          if (!result) return of(result);
          return this.auditService.deleteAudit(audit);
        })
      );
    }
  }

}
