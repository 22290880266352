import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';


import {AppRoutingModule} from './app-routing.module';
import {LoginComponent} from './login/login.component';
import {AppComponent} from './app.component';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AccountService} from './accounts.service';
import {ApiService} from './api/api.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AuditSelectComponent} from './audit-select/audit-select.component';
import {InstitutionService} from './institution.service';
import {AuditFormService} from './audit-form.service';
import {StorageService} from './storage.service';
import {LoadingComponent} from './loading/loading.component';
import {AppVersionComponent} from './app-version/app-version.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {InformationService} from './information.service';
import {MenuComponent} from './menu/menu.component';
import {InformationComponent} from './information/information.component';
import {AuditFormComponent} from './audit-form/audit-form.component';
import {FormFieldComponent} from './audit-form/field/field.component';
import {AuditService} from './audit-form/audit.service';
import {SubformSelectComponent} from './subform-select/subform-select.component';
import {AuditPreviewComponent} from './audit-preview/audit-preview.component';
import {AuditListComponent} from './audit-list/audit-list.component';
import {SubmissionComponent} from './submission/submission.component';
import {SubmissionService} from './submission/submission.service';
import {AboutAppComponent} from './about-app/about-app.component';
import {SubmitPreviewComponent} from './submit-preview/submit-preview.component';
import {ComplianceService} from './compliance/compliance.service';
import {PercentagePipe} from './percentage.pipe';
import {DownloadService} from './loading/download.service';
import {QipService} from './qip/qip.service';
import {ChartComponent} from './charts/chart/chart.component';
import {ChartsComponent} from './charts/charts.component';
import {DashboardService} from './charts/dashboard.service';
import {IssuesWidgetComponent} from './qip/issues-widget/issues-widget.component';
import {IssuesDialogComponent} from './qip/issues-widget/issues-dialog/issues-dialog.component';
import {MaterialDesignModule} from './material-design.module';
import {CreateIssueDialogComponent} from './qip/issues-widget/create-issue-dialog/create-issue-dialog.component';
import {IssueComponent} from './qip/issues-widget/issue/issue.component';
import {WebcamModule} from 'ngx-webcam';
import {PhotoDialogComponent} from './qip/photo-dialog/photo-dialog.component';
import {ProfileComponent} from './profile/profile.component';
import {SelectAvatarDialogComponent} from './profile/select-avatar-dialog/select-avatar-dialog.component';
import {ObservationComponent} from './audit-form/observation/observation.component';
import {AppUpdateService} from './app-update.service';
import {TimerComponent} from './audit-form/timer/timer.component';
import {AudioDialogComponent} from './qip/audio-dialog/audio-dialog.component';
import {AnalyticsService} from './analytics.service';
import {SettingsComponent} from './settings/settings.component';
import {SettingsService} from './settings/settings.service';
import {ProfileService} from './profile/profile.service';
import {SignatureDialogComponent} from './signature-dialog/signature-dialog.component';
import {WalkthroughComponent} from './walkthrough/walkthrough.component';
import {WalkthroughService} from './walkthrough/walkthrough.service';
import {BackbuttonService} from './backbutton.service';
import {IssuePreviewComponent} from './submit-preview/issue-preview/issue-preview.component';
import {AuditFormDeactivateGuard} from './audit-form/audit-form-deactivate-guard.service';
import {CustomSnackbarComponent} from './custom-snackbar/custom-snackbar.component';
import 'hammerjs';
import {TableComponent} from './audit-preview/table/table.component';
import {CalendarComponent} from './calendar/calendar.component';
import {CalendarService} from './calendar/calendar.service';
import {FeedbackDialogComponent} from './feedback-dialog/feedback-dialog.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ForgotPasswordDialogComponent} from './forgot-password-dialog/forgot-password-dialog.component';
import {ShortDatePickerComponent} from './audit-form/field/short-date-picker/short-date-picker.component';
import {SubmissionGuard} from './submission/submission-guard';
import {DayDialogComponent} from './calendar/day-dialog/day-dialog.component';
import {LastModifiedService} from './last-modified.service';
import {TimeFieldComponent} from './audit-form/field/time-field/time-field.component';
import {TimePickerDialogComponent} from './audit-form/field/time-field/time-picker-dialog/time-picker-dialog.component';
import {CustomDatePickerComponent} from './audit-form/field/custom-date-picker/custom-date-picker.component';
import {LanguageService} from './language.service';
import {HintService} from './hint.service';
import {SafePipe} from './safe.pipe';
import {BaseComponent} from './base.component';
import {FileFieldComponent} from './audit-form/field/file-field/file-field.component';
import {DateTimeFieldComponent} from './audit-form/field/date-time-field/date-time-field.component';
import {DownloadAuditDialogComponent} from './download-audit-dialog/download-audit-dialog.component';
import {CheckAuditFormService} from './check-audit-form.service';
import {MEGTooltipComponent} from './tooltip/tooltip.component';
import {MEGTooltipDirective} from './tooltip/tooltip.directive';
import {OverlayModule} from '@angular/cdk/overlay';
import {AlertComponent} from './alert/alert.component';
import {ModalService} from './alert/service/modal.service';
import {LocalizedDatePipe} from './localized-date.pipe';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../environments/environment';
import {IssueListComponent} from './qip/issue-list/issue-list.component';
import {IssueFilterComponent} from './qip/issue-list/issue-filter/issue-filter.component';
import {IssueDetailComponent} from './qip/issue-list/issue-detail/issue-detail.component';
import {AudioFieldComponent} from './audit-form/field/audio-field/audio-field.component';
import {CameraService} from './camera.service';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {RecaptchaDirective} from './login/recaptcha.directive';
import {AppConfigService} from './app-config.service';
import {MessageListComponent} from './messaging/message-list/message-list.component';
import {ChannelListComponent} from './messaging/channel-list/channel-list.component';
import {NewMessageDialogComponent} from './messaging/new-message-dialog/new-message-dialog.component';
import {WrappedFieldComponent} from './audit-form/wrapped-field/wrapped-field.component';
import {SubmitPreviewFormComponent} from './submit-preview/submit-preview-form/submit-preview-form.component';
import {SubmissionPickerComponent} from './submission-picker/submission-picker.component';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {IdleService} from './idle.service';
import {ErrorHandlerService} from './insights/error-handler';
import {MegSearchableDropdownComponent} from './utils/meg-searchable-dropdown/meg-searchable-dropdown.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AuditSelectComponent,
    ToolbarComponent,
    LoadingComponent,
    AuditFormComponent,
    FormFieldComponent,
    MenuComponent,
    AppVersionComponent,
    InformationComponent,
    SubformSelectComponent,
    AuditPreviewComponent,
    AuditListComponent,
    SubmissionComponent,
    AboutAppComponent,
    SubmitPreviewComponent,
    PercentagePipe,
    ChartComponent,
    ChartsComponent,
    IssuesWidgetComponent,
    IssuesDialogComponent,
    CreateIssueDialogComponent,
    IssueComponent,
    PhotoDialogComponent,
    ProfileComponent,
    SelectAvatarDialogComponent,
    ObservationComponent,
    TimerComponent,
    AudioDialogComponent,
    SignatureDialogComponent,
    MegSearchableDropdownComponent,
    SettingsComponent,
    WalkthroughComponent,
    CustomSnackbarComponent,
    TableComponent,
    IssuePreviewComponent,
    FeedbackDialogComponent,
    ForgotPasswordDialogComponent,
    ShortDatePickerComponent,
    CalendarComponent,
    DayDialogComponent,
    CustomDatePickerComponent,
    TimeFieldComponent,
    TimePickerDialogComponent,
    SafePipe,
    FileFieldComponent,
    DateTimeFieldComponent,
    AlertComponent,
    LocalizedDatePipe,
    BaseComponent,
    MEGTooltipComponent,
    MEGTooltipDirective,
    DownloadAuditDialogComponent,
    IssueListComponent,
    IssueFilterComponent,
    IssueDetailComponent,
    AudioFieldComponent,
    RecaptchaDirective,
    MessageListComponent,
    ChannelListComponent,
    NewMessageDialogComponent,
    WrappedFieldComponent,
    SubmitPreviewFormComponent,
    SubmissionPickerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialDesignModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    WebcamModule,
    OverlayModule,
    MatIconModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true} },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfig: AppConfigService) => () => appConfig.loadAppConfig(),
      multi: true,
      deps: [AppConfigService],
    },
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    AccountService,
    ApiService,
    InstitutionService,
    StorageService,
    AuditFormService,
    InformationService,
    AuditService,
    SubmissionService,
    ComplianceService,
    DownloadService,
    QipService,
    AppUpdateService,
    DashboardService,
    AnalyticsService,
    SettingsService,
    ProfileService,
    WalkthroughService,
    BackbuttonService,
    AuditFormDeactivateGuard,
    SubmissionGuard,
    CalendarService,
    HintService,
    LastModifiedService,
    LanguageService,
    ModalService,
    CheckAuditFormService,
    CameraService,
    MatIconRegistry,
    IdleService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    IssuesDialogComponent,
    CreateIssueDialogComponent,
    PhotoDialogComponent,
    SignatureDialogComponent,
    SelectAvatarDialogComponent,
    AudioDialogComponent,
    CustomSnackbarComponent,
    FeedbackDialogComponent,
    DayDialogComponent,
    ForgotPasswordDialogComponent,
    TimePickerDialogComponent,
    AlertComponent,
    DownloadAuditDialogComponent,
    MEGTooltipComponent,
    NewMessageDialogComponent,
    SubmissionPickerComponent
  ],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
