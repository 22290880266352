import {ChartsCalculator, MEGChartData} from '../charts_calculator';
import {ComplianceService} from '../../compliance/compliance.service';
import {AuditFormSchema} from '../../api/models/audit-form-schema';
import {AuditForm} from '../../api/models/audit-form';
import {Observation} from '../../api/models/observation';
import {Widget} from '../../api/models/dashboard';


/**
 * Base chart
 * base class for creating JS charts
 */
export class BaseChart {
  /**
   * Three compliance color levels, index 0 = red, 1 = orange, 2 = green
   * @type {string[]}
   */
  chartColors: string[] = ['#ed3724', '#fbb03b', '#8bc541'];
  chartCalculator: ChartsCalculator;

  constructor(private observations: Observation[],
              private auditFormSchema: AuditFormSchema,
              private auditForm: AuditForm,
              private complianceService: ComplianceService) {
    this.chartCalculator = new ChartsCalculator(this.observations, this.auditFormSchema, this.auditForm, this.complianceService);
  }

  /**
   * Building chart data by separating the data, labels and colors into arrays
   * @param {MEGChartData[]} chartData
   * @return {{data: number[]; labels: string[]; colors: string[]}}
   */
  public buildChartData(chartData: MEGChartData[]): {data: number[], labels: string[], colors: string[]} {
    const labels: string[] = [];
    const data: number[] = [];
    const colors: string[] = [];
    for (const value of chartData) {
        labels.push(value[0]);
        data.push(value[1]);
        colors.push(this.chartColors[value[2]]);
    }
    return {
      data: data,
      labels: labels,
      colors: colors,
    };
  }

  /**
  * Retrieves the bar char title, if the widget sub-title exists then adds to the text list
  * @param widget chart
  * @return {string[]} title list
  * */
  public getChartTitles(widget: Widget): string[] {
    const chartTitles: string[] = [];
    if (widget.title) {
      chartTitles.push(widget.title);
    }
    if (widget.subtitle) {
      chartTitles.push(widget.subtitle);
    }
    return chartTitles;
  }
}
