/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audio-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../audit-form/field/audio-field/audio-field.component.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "../../audit-form/field/audio-field/audio-field.component";
import * as i6 from "ngx-logger";
import * as i7 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i8 from "@angular/material/button";
import * as i9 from "@angular/cdk/platform";
import * as i10 from "@angular/cdk/a11y";
import * as i11 from "@angular/platform-browser/animations";
import * as i12 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i13 from "@angular/material/icon";
import * as i14 from "@ngx-translate/core";
import * as i15 from "./audio-dialog.component";
var styles_AudioDialogComponent = [i0.styles];
var RenderType_AudioDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AudioDialogComponent, data: {} });
export { RenderType_AudioDialogComponent as RenderType_AudioDialogComponent };
export function View_AudioDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { audioInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "meg-audio-field", [], null, null, null, i3.View_AudioFieldComponent_0, i3.RenderType_AudioFieldComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.AudioFieldComponent]), i1.ɵdid(5, 49152, [[1, 4], ["audioInput", 4]], 0, i5.AudioFieldComponent, [i1.NgZone, i6.NGXLogger], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 15, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 6, "button", [["class", "dialog-btn-left"], ["color", "primary"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeAudioCapture() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(11, 638976, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["clear"])), (_l()(), i1.ɵted(13, 0, [" ", ""])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 6, "button", [["class", "dialog-btn-right"], ["color", "accent"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveAndCloseAudioCapture() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatButton_0, i7.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i8.MatButton, [i1.ElementRef, i9.Platform, i10.FocusMonitor, [2, i11.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i12.View_MatIcon_0, i12.RenderType_MatIcon)), i1.ɵdid(18, 638976, null, 0, i13.MatIcon, [i1.ElementRef, i13.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["save"])), (_l()(), i1.ɵted(20, 0, [" ", ""])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 9, 0, currVal_2); _ck(_v, 11, 0); var currVal_7 = !i1.ɵnov(_v, 5).audioBase64; var currVal_8 = "accent"; _ck(_v, 16, 0, currVal_7, currVal_8); _ck(_v, 18, 0); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 9).disabled || null); var currVal_1 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 11).inline; _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("cancel")); _ck(_v, 13, 0, currVal_4); var currVal_5 = (i1.ɵnov(_v, 16).disabled || null); var currVal_6 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 18).inline; _ck(_v, 17, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("save")); _ck(_v, 20, 0, currVal_10); }); }
export function View_AudioDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-audio-dialog", [], null, null, null, View_AudioDialogComponent_0, RenderType_AudioDialogComponent)), i1.ɵdid(1, 114688, null, 0, i15.AudioDialogComponent, [i2.MatDialogRef, i6.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AudioDialogComponentNgFactory = i1.ɵccf("meg-audio-dialog", i15.AudioDialogComponent, View_AudioDialogComponent_Host_0, {}, {}, []);
export { AudioDialogComponentNgFactory as AudioDialogComponentNgFactory };
