import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material';
import {NGXLogger} from 'ngx-logger';
import {AudioFieldComponent} from '../../audit-form/field/audio-field/audio-field.component';


@Component({
  selector: 'meg-audio-dialog',
  templateUrl: './audio-dialog.component.html',
  styleUrls: ['./audio-dialog.component.css']
})
export class AudioDialogComponent implements OnInit {
  @ViewChild('audioInput')
  private audioInput: AudioFieldComponent;
  constructor(private dialogRef: MatDialogRef<AudioDialogComponent>, private logger: NGXLogger) { }

  ngOnInit() {}

  public closeAudioCapture() {
    this.audioInput.closeAudioCapture();
    this.dialogRef.close(false);
  }

  public saveAndCloseAudioCapture() {
    this.audioInput.saveAndCloseAudioCapture();
    this.dialogRef.close(this.audioInput.audioBase64);
  }

}
