import {Ward} from '../../../api/models/ward';
import {AuditForm} from '../../../api/models/audit-form';

/**
 * Holds current filter selections in Issue list view
 */
export class IssueFilter {
  ward: Ward | null = null;
  audit_form: AuditForm | null = null;
  assigned_to_me = true;
}
