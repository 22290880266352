import { Component, OnInit } from '@angular/core';
import {appData} from '../../../appData';

@Component({
  selector: 'meg-app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.css']
})
export class AppVersionComponent implements OnInit {

  appVersion = appData.app_version;

  constructor() {}

  ngOnInit() {
  }

}
