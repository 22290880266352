import {Component, OnInit} from '@angular/core';
import {Institution, InstitutionGroup} from '../api/models/institution';
import {Department} from '../api/models/department';
import {Ward} from '../api/models/ward';
import {BaseLoginRequiredComponent} from '../base';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AccountService} from '../accounts.service';
import {InstitutionService} from '../institution.service';
import {AuditFormService} from '../audit-form.service';
import {AuditForm} from '../api/models/audit-form';
import {AuditService} from '../audit-form/audit.service';
import {User} from '../api/models/user';
import {Audit} from '../audit-form/audit';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {forkJoin, Observable, of} from 'rxjs';
import {AnalyticsService} from '../analytics.service';
import {AuditCheckStatus, CheckAuditFormService} from '../check-audit-form.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ModalService} from '../alert/service/modal.service';
import {NGXLogger} from 'ngx-logger';
import {throwError} from 'rxjs/internal/observable/throwError';
import {CalendarService, UpcomingAudit} from '../calendar/calendar.service';
import {ApiService} from '../api/api.service';
import {validateSessionToken} from '../api/utils';
import {UserAuth} from '../api/responses/user-auth';
import {parseBase64Object} from '../utils/misc';
import {AppVersion} from '../api/models/app-version';
import {AppUpdateService} from '../app-update.service';


@Component({
  selector: 'meg-audit-select',
  templateUrl: './audit-select.component.html',
  styleUrls: ['./audit-select.component.css']
})
export class AuditSelectComponent extends BaseLoginRequiredComponent implements OnInit {
  public institutions: Institution[] | null = null;
  public departments: Department[] | null = null;
  public wards: Ward[] | null = null;
  public auditForms: AuditForm[] | null = null;
  public valid = false;
  public institution: Institution | null = null;
  public department: Department | null = null;
  public ward: Ward | null = null;
  public auditForm: AuditForm | null = null;
  public institutionLogo: string | null = null;
  public institutionLabel: Observable<string>;
  public departmentLabel: Observable<string>;
  public wardLabel: Observable<string>;
  public user: User | null = null;
  public upcomingAudits$: Observable<UpcomingAudit[]> = of([]);

  constructor(public institutionService: InstitutionService,
              public auditFormService: AuditFormService,
              private auditService: AuditService,
              private route: ActivatedRoute,
              private analytics: AnalyticsService,
              private checkAuditService: CheckAuditFormService,
              router: Router, accountService: AccountService,
              private translateService: TranslateService,
              private modalService: ModalService,
              private snackBar: MatSnackBar,
              private accountsService: AccountService,
              private calendarService: CalendarService,
              private activatedRoute: ActivatedRoute,
              private appUpdateService: AppUpdateService,
              private api: ApiService,
              logger: NGXLogger) {
    super(router, route, accountService, logger);
  }

  protected goToLogin(nextUrl: string | null = null) {
    this.newAuth.subscribe((auth: string | null) => {
      if (auth === null) super.goToLogin();
      else {
        this.logger.warn('token auth');
        this.router.navigate(['login'], {
          queryParams: {
            auth: auth,
            next: nextUrl,
          }
        });
      }
    });
  }

  /**
   * emits new auth token if one was passed in query param, or null if none was passed
   */
  private get newAuth(): Observable<string | null> {
    return this.activatedRoute.queryParams.pipe(
      map((params: Params): string | null => params.auth || null),
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.auditService.setAuditId(null);
    this.departmentLabel = this.institutionService.getDepartmentLabel(null, false, true);
    this.wardLabel = this.institutionService.getWardLabel(null, false, true);

    this.newAuth.pipe(
      tap((authBase64: string | null) => {
        if (authBase64 !== null) {
          const auth: UserAuth = parseBase64Object(authBase64);
          this.logger.debug('new token, redirecting to login', auth);
          this.goToLogin(this.router.url);
        }
      }),
    ).subscribe();

    if (this.isLoggedIn()) {
      this.accountsService.getUser().pipe(
        catchError((e) => {
          this.goToLogin();
          return throwError(e);
        }),
        tap((user: User) => this.user = user || null),
        mergeMap(() => this.loadDeepLinkPresets()),
      ).subscribe((deepLinked: boolean) => {
        if (!deepLinked) this.loadInstitutions();
      });

      validateSessionToken(this.api, this.translateService);
      this.accountsService.getUser().subscribe((user: User) => {
        this.institutionService.getInstitutionOrNull(user.auditor.institution).subscribe((institution: Institution) => {
          this.institutionLabel = this.institutionService.getInstitutionLabel(institution, false, true);
          if (institution.group) {
            this.institutionLogo = institution.group.logo;
        } else {
            this.institutionLogo = institution.logo;
          }
        });
      });
    }
    this.upcomingAudits$ = this.calendarService.getUpcomingAudits();

    this.appUpdateService.checkAppVersion().subscribe( (version: AppVersion) => {
        if (!version) return;
        this.translateService.get(['login.update.update-available', 'login.update.update'],
          {'version': version.app_version}).subscribe(
          (translations: {[id: string]: string}) => {
            this.snackBar.open(translations['login.update.update-available'], translations['login.update.update'])
              .onAction().subscribe(() => this.appUpdateService.openAppStore());
          }
        );
      },
      (error => this.logger.error('Cannot check app update', error, 'Ignore this error if this is not a cordova build.')),
    );
  }

  public get username(): string {
    if (this.user === null) return '';
    else if (!this.user.first_name) return this.user.username;
    else return this.user.first_name;
  }

  public itemToString(item: AuditForm | Department | Ward): string {
    return item.name;
  }

  private getDeepLink(): Observable<[Institution, AuditForm] | null> {
    return this.route.params.pipe(
      mergeMap((params: Params) => {
        const institutionId: number | undefined = +params['institution_id'];
        const auditFormId: number | undefined = +params['audit_form_id'];
        if (institutionId && auditFormId) {
          return forkJoin(
            this.institutionService.getInstitutionOrNull(institutionId),
            this.auditFormService.getAuditForm(auditFormId),
          ).pipe(
            map((result) => {
              const [institution, auditForm] = result;
              if (institution && auditForm) return <[Institution, AuditForm]>[institution, auditForm];
              else return null;
            }),
            catchError((error) => {
              this.logger.warn('Could not pre-select institution or audit form', error);
              return of(null);
            }),
          );
        } else return of(null);
      }),
    );
  }

  private loadDeepLinkPresets(): Observable<boolean> {
    return this.getDeepLink().pipe(
      tap(result => this.logger.info('Deep link', result)),
      tap((result: [Institution, AuditForm] | null) => {
        if (result !== null) {
          [this.institution, this.auditForm] = result;
          this.institutions = [this.institution];
          this.departments = this.institution.departments;
          this.onInstitutionSelected();
          this.onAuditFormSelected();
        }
      }),
      map((result) => result !== null),
    );
  }

  private loadInstitutions() {
    this.institutionService.getInstitutions().subscribe((institutions: Institution[]) => {
      this.institutions = institutions;
      if (institutions.length === 1) this.institution = institutions[0];
      if (this.institution !== null) this.onInstitutionSelected();
    });
  }

  /**
   * Filters the departments by:
   * 1. Wards set in the audit form config
   * 2. Departments that don't have any wards
   * Lastly calls `selectDepartmentIfOne` function.
   */
  public updateDepartments() {
    if (this.auditForm && this.institution) {

      this.departments = this.institution.departments.filter((department: Department) => this.getAvailableWards(department).length > 0);
      this.selectDepartmentIfOne();
    }
  }

  /**
   * Select the first department if there is only one in the list
   */
  private selectDepartmentIfOne() {
    if (this.departments && this.departments.length === 1) {
      this.department = this.departments[0];
      this.onDepartmentSelected();
    }
  }

  public onInstitutionSelected() {
    this.wards = [];
    const selectedDepartment: Department | null = this.department;
    const selectedWard: Ward | null = this.ward;
    this.department = null;
    this.ward = null;
    this.institutionLabel = this.institutionService.getInstitutionLabel(this.institution, false, true);
    if (this.institution && this.institution.logo == null && this.institution.group) {
      this.institutionLogo = this.institution.group.logo;
    } else {
      this.institutionLogo = this.institution!.logo;
    }
    this.departmentLabel = this.institutionService.getDepartmentLabel(this.institution, false, true);
    this.wardLabel = this.institutionService.getWardLabel(this.institution, false, true);
    if (this.institution === null) {
      this.departments = [];
      this.auditForms = [];
    } else {
      this.logger.debug('Selected institution', this.institution);
      this.departments = this.institution.departments;
      if (selectedDepartment) {
        this.department = this.departments.find((d) => d.id === selectedDepartment.id) || null;
        this.ward = selectedWard;
        this.onDepartmentSelected();
      }
      if (!this.department) {
        this.selectDepartmentIfOne();
        this.ward = null;
      }
      this.auditFormService.getAuditForms(this.institution).pipe(
        tap((auditForms: AuditForm[]) => this.auditForms = auditForms),
      ).subscribe((auditForms: AuditForm[]) => {
          if (auditForms.length === 1) {
            this.auditForm = auditForms[0];
            this.onAuditFormSelected();
          } else if (this.auditForm) {
            // Persist audit form selection by updating selected audit form with instance from the list
            const currentForm = this.auditForm;
            this.auditForm = auditForms.find((form: AuditForm) => form.id === currentForm.id) || null;
            if (this.auditForm) this.onAuditFormSelected();
          }
        },
        (e) => this.logger.error(e),
      );
    }

    this.valid = this.isSelectionValid();
  }

  public onAuditFormSelected() {
    this.updateDepartments();
    this.updateWards();

    if (this.ward != null && this.auditForm != null && this.auditForm.config != null
      && this.auditForm.config.wards.length && !this.auditForm.config.wards.includes(this.ward.id)) {
      this.ward = null;
    }

    this.valid = this.isSelectionValid();
  }

  /**
   * Updates wards list based off selected audit form and department
   */
  private updateWards() {
    if (this.department != null) {
      this.wards = this.getAvailableWards(this.department);
      if (this.ward) {
        const selectedWard = this.ward;
        this.ward = this.wards.find((w: Ward) => w.id === selectedWard.id) || null;
      }
      if (!this.ward && this.wards.length === 1) {
        this.ward = this.wards[0];
      }
    }
  }

  private getAvailableWards(department: Department): Ward[] {
    let result = department.wards;
    if (this.auditForm && this.auditForm.config.wards.length > 0) {
      const wardIds = this.auditForm.config.wards;
      result = result.filter((ward: Ward) => wardIds.indexOf(ward.id) !== -1);
    }
    const enableSubmitAllWards = this.auditForm && this.auditForm.config ? this.auditForm.config.enable_submission_for_all_wards : false;
    if (this.user && this.user.auditor.wards.length > 0 && !enableSubmitAllWards) {
      const wardIds = this.user.auditor.wards;
      result = result.filter((ward: Ward) => wardIds.indexOf(ward.id) !== -1);
    }
    return result;
  }

  public onDepartmentSelected() {
    this.updateWards();

    this.valid = this.isSelectionValid();
  }

  private proceedToNextScreen() {
    const auditForm = this.auditForm;
    const ward = this.ward;
    if (auditForm != null && ward != null) {
      this.auditService.setAuditFormId(auditForm.id);
      this.accountService.getUser().subscribe((user: User) => {
        this.auditService.createAudit(auditForm, ward, user).subscribe(
          (audit: Audit) => this.router.navigate(['select-form', audit.id]),
          (e) => this.logger.error(e));
      });
    }
  }

  /**
   * Checks that all items have been selected and selection is consistent
   * @returns {boolean}
   */
  private isSelectionValid(): boolean {
    if (this.institution && this.department && this.ward) {
      return (this.department.institution === this.institution.id && this.ward.department === this.department.id);
    }
    return false;
  }

  public onStartClicked() {
    this.analytics.trackButtonClick('Start');
    this.analytics.trackButtonClick('Audit start');
    if (this.isSelectionValid()) {
      const name = this.auditForm!.name;
      this.checkAuditService.checkAuditFormUpdate(this.auditForm!, (result: AuditCheckStatus) => {
        if (result === AuditCheckStatus.UPDATE_COMPLETE) {
          this.snackBar.open(this.translateService.instant('audit-select.audit-updated', {audit_name: name}), 'OK', {duration: 3000});
        }
        if (this.auditForm && this.auditForm.config.intro_note && !this.auditForm.config.intro_note.isEmpty()) {
          this.modalService.openInfoModal(of(this.auditForm.config.intro_note)).subscribe();
        }
        this.proceedToNextScreen();
      });

    }
  }

  selectAudit(audit: UpcomingAudit) {
    this.logger.debug('Audit selected', audit);
    this.auditForm = audit.auditForm;

    if (this.institutions) {
      if (audit.wardData) {
        this.logger.debug('Selected ward', audit.wardData);
        const [institution, department, ward] = audit.wardData;
        this.institution = this.institutions.find((i: Institution) => i.id === institution.id) || institution;
        this.department = this.institution.departments.find((d: Department) => d.id === department.id) || department;
        this.ward = this.department.wards.find((w: Ward) => w.id === ward.id) || ward;
        this.onInstitutionSelected();
        this.onDepartmentSelected();
      } else {
        // pick institution based on audit form if currently selected institution does not have the selected audit
        const institutionIds = audit.auditForm.institution_ids;
        if (!this.institution || institutionIds.indexOf(this.institution.id) === -1) {
          this.institution = this.institutions.find((institution: Institution) => institutionIds.indexOf(institution.id) > -1) || null;
        }
        this.onInstitutionSelected();
      }
    }
  }
}
