import {DASHBOARD_WIDGET_SUB_FORM_BAR, Widget} from '../../api/models/dashboard';
import {MEGChartData} from '../charts_calculator';
import {BaseChart} from './base_chart';
import {ChartConfiguration, ChartData, ChartDataSets} from 'chart.js';


export class BarChart extends  BaseChart {

  public getChartData(widget: Widget): [string, string][] {
    let chartDataValues: MEGChartData[] = [];
    if (widget.widget === DASHBOARD_WIDGET_SUB_FORM_BAR) {
      chartDataValues = this.chartCalculator.getSubFormChartData();
    } else {
      chartDataValues = this.chartCalculator.getFieldChartData(widget);
    }

    const chartValues = this.buildChartData(chartDataValues);

    return chartValues.labels.map(function (value: string, index: number) {
      return <[string, string]> [`(${index + 1}): ${chartValues.labels[index]}`, chartValues.colors[index] ];
    });
  }

  public buildChart(widget: Widget): ChartConfiguration | null {

    let chartDataValues: MEGChartData[];
    if (widget.widget === DASHBOARD_WIDGET_SUB_FORM_BAR) {
      chartDataValues = this.chartCalculator.getSubFormChartData();
    } else {
      chartDataValues = this.chartCalculator.getFieldChartData(widget);
    }

    const chartValues = this.buildChartData(chartDataValues);
    const labels = Array.apply(null, {length: chartValues.labels.length}).map(function(value: number, index: number) {
      return `${index + 1}`;
    });

    const dataSets: ChartDataSets[] = [{data: chartValues.data, backgroundColor: chartValues.colors}];
    const chartData: ChartData = {labels: labels, datasets: dataSets};
    let scales = {};
    if ( widget.config.compliance) {
      scales = {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: false,
          }
        }],
        yAxes: [{
          display: true,
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 100,
            callback: function (value: number) {
              return`${value}%`;
            },
          },
          scaleLabel: {
            display: false,
          }
        }]
      };
    } else {
      scales = {
        xAxes: [{
          display: true,
          scaleLabel: {
            display: false,
          }
        }],
        yAxes: [{
          display: true,
          ticks: {
            beginAtZero: true,
            min: 0,
            callback: function (value: number) {
              return value;
            },
          },
          scaleLabel: {
            display: false,
          }
        }]
      };
    }

    const options = {
      legend: {
        display: false,
      },
      responsive: true,
      title: {
        display: true,
        text: this.getChartTitles(widget),
      },
      scales: scales,
    };
    return {type: 'bar', data: chartData, options: options};
  }
}
