import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {SubmissionComponent} from './submission.component';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/internal/Observable';
import {Injectable} from '@angular/core';

@Injectable()
export class SubmissionGuard implements CanDeactivate<SubmissionComponent> {
  constructor(private translateService: TranslateService) {
  }

  canDeactivate(component: SubmissionComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return component.complete || confirm(this.translateService.instant('audit-submission.navigate-away-prompt'));
  }
}
