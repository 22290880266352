export enum ModelNameType {
  Section = 'section',
  EGuide = 'eguide',
  Document = 'document',
  Information = 'information',
  Dashboard = 'dashboard',
  PublicDashboard = 'publicdashboardviewconfig',
  AuditSession = 'auditsession',
  CustomObservation = 'customobservation',
  CustomSubObservation = 'customsubobservationinstance',
  CustomWebForm = 'formviewurl',
  Issue = 'issue'
}
