import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {AuditService} from '../audit-form/audit.service';
import {Audit} from '../audit-form/audit';
import {ActivatedRoute, Router} from '@angular/router';
import {InstitutionService} from '../institution.service';
import {Ward} from '../api/models/ward';
import {AuditForm, CUSTOM_OBSERVATION_NAME, LayoutType} from '../api/models/audit-form';
import {AuditFormService} from '../audit-form.service';
import {AuditFormSchema, SubFormSchema} from '../api/models/audit-form-schema';
import {AnalyticsService} from '../analytics.service';
import {BaseComponent} from '../base.component';
import {Errors} from '../api/models/errors';
import {Answer, Observation} from '../api/models/observation';
import {isNullOrUndefined} from '../utils/misc';
import {Hints} from '../hint.service';
import {Observable} from 'rxjs';
import {SubmitPreviewFormComponent} from '../submit-preview/submit-preview-form/submit-preview-form.component';
import {StorageService} from '../storage.service';
import {getFieldChoices} from '../utils/conditionals';

const DB_VALUE_TWO_STAGE_REDIRECTION_DONE = 'true';

@Component({
  selector: 'meg-audit-preview',
  templateUrl: './audit-preview.component.html',
  styleUrls: ['./audit-preview.component.css']
})

export class AuditPreviewComponent extends BaseComponent implements OnInit {
  audit: Audit;
  auditForm: AuditForm;
  auditFormSchema: AuditFormSchema;
  ward: Ward | null = null;
  @ViewChild(SubmitPreviewFormComponent) previewForm: SubmitPreviewFormComponent;

  constructor(
              private storageService: StorageService,
              private auditService: AuditService,
              private route: ActivatedRoute,
              private institutionService: InstitutionService,
              private auditFormService: AuditFormService,
              private analytics: AnalyticsService,
              private router: Router,
              private injector: Injector) {
    super(injector);
  }

  private getTwoStageFormRedirectionKey(auditId: number): string {
    return `audit-${auditId}-two-stage-has-redirected`;
  }

  private getTwoStageRedirection(auditId: number): boolean {
    return this.storageService.getString(this.getTwoStageFormRedirectionKey(auditId)) === DB_VALUE_TWO_STAGE_REDIRECTION_DONE;
  }

  private setTwoStageRedirection(auditId: number): void {
    this.storageService.setString(this.getTwoStageFormRedirectionKey(auditId), DB_VALUE_TWO_STAGE_REDIRECTION_DONE);
  }

  private removeTwoStageRedirection(auditId: number): void {
    this.storageService.removeString(this.getTwoStageFormRedirectionKey(auditId));
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const auditId = +params['audit_id'];
      this.auditService.setAuditId(auditId);
      this.auditService.getAuditWithFormSchema(auditId).subscribe((result: [Audit, AuditForm, AuditFormSchema]) => {
        [this.audit, this.auditForm, this.auditFormSchema] = result;

        this.institutionService.getWard(this.audit.wardId).subscribe((ward: Ward) => this.ward = ward);
        const isTwoStageAuditForm = this.auditForm.config.stages !== undefined && this.auditForm.config.stages >= 2;

        if (this.audit.errors !== null && this.audit.errors.observations !== undefined) {
          const observationErrors: Errors[] = (this.audit.errors.observations as Errors[]);
          const missingSubForm: SubFormSchema | undefined = this.auditFormSchema.sub_forms.find((subform: SubFormSchema) =>
            observationErrors.find((errors: Errors) => errors[subform.name] !== undefined) !== undefined &&
            this.audit.auditSession.observations.find((observation: Observation) => observation[subform.name] !== undefined) === undefined
          );
          if (missingSubForm) {
            alert(`${missingSubForm.display_name} ${this.translateService.instant('audit-form.form-missing')}`);
          }
        } else if (isTwoStageAuditForm && this.audit.auditSession.observations.length === 1 && !this.getTwoStageRedirection(auditId)) {
          const firstObservation = this.audit.auditSession.observations[0];
          const subForm = this.auditFormSchema.sub_forms.find((subform: SubFormSchema) => firstObservation[subform.name] !== undefined);
          this.setTwoStageRedirection(auditId);
          if (subForm !== undefined && this.auditForm.config.form_layout !== LayoutType.Accordion) {
            this.router.navigate(['audit-form', this.audit.id, 'subform', subForm.name, 'edit', 0]);
          } else {
            this.router.navigate(['audit-form', this.audit.id, 'edit', 0]);
          }
        }
      });
    });
  }

  public get hasObservations(): boolean {
    if (isNullOrUndefined(this.audit)) return false;
    else {
      return this.audit.auditSession.observations.length > 0;
    }
  }

  deleteAudit() {
    this.analytics.trackButtonClick('Delete');
    this.analytics.trackButtonClick('Deleting form from audit');
    this.modalService.openWarningModal(this.translateService.get('audit-preview.delete-audit-prompt'),
    this.modalService.btnOK(() => {
      this.auditService.deleteAudit(this.audit).subscribe(() => {
        this.removeTwoStageRedirection(this.audit.id);
        this.auditService.setAuditId(null);
        this.router.navigate(['/']);
      });

    }), this.modalService.btnCancel()).subscribe();
  }

  public get isAutoCycleComplete(): boolean {
    return this.auditService.getAutoCycleComplete(this.audit, this.auditForm, this.auditFormSchema);
  }

  addForm() {
    this.analytics.trackButtonClick('Add Form');
    this.analytics.trackAction('Adding form to audit');
    this.hintService.markShown(Hints.AddForm);
    this.router.navigate([`select-form`, this.audit.id]);
  }

  confirmAudit() {
    const missingSubForm: SubFormSchema | undefined = this.auditFormSchema.sub_forms.find((subform: SubFormSchema) =>
      subform.required && this.audit.auditSession.observations.find((observation: Observation) =>
      observation[subform.name] !== undefined) === undefined
    );
    if (missingSubForm) {
      alert(`${missingSubForm.display_name} ${this.translateService.instant('audit-form.form-missing')}`);
    } else {
      this.router.navigate([`submit-preview`, this.audit.id]);
    }
  }

  public onSubmitClicked() {
    const config = (this.auditForm as AuditForm).config;
    if (config && config.require_signature && this.audit.auditSession.signatures.length === 0) {
      alert(this.translateService.instant('audit-submission.signature-required-error'));
      return;
    }
    this.analytics.trackButtonClick('Submit');
    this.analytics.trackAction('Audit preview submit');
    if (this.previewForm.submit()) {
      this.save().subscribe(
        () => this.router.navigate(['audit-submit', this.audit.id])
      );
    }
  }

  private save(): Observable<boolean> {
    return this.auditService.saveAudit(this.audit);
  }

}
