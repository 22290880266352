/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./information.component";
import * as i3 from "@angular/router";
import * as i4 from "../information.service";
import * as i5 from "../analytics.service";
var styles_InformationComponent = [i0.styles];
var RenderType_InformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationComponent, data: {} });
export { RenderType_InformationComponent as RenderType_InformationComponent };
export function View_InformationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dataContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["dataContainer", 1]], null, 0, "div", [["class", "info-container"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_InformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-information", [], null, null, null, View_InformationComponent_0, RenderType_InformationComponent)), i1.ɵdid(1, 245760, null, 0, i2.InformationComponent, [i3.ActivatedRoute, i4.InformationService, i5.AnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationComponentNgFactory = i1.ɵccf("meg-information", i2.InformationComponent, View_InformationComponent_Host_0, {}, {}, []);
export { InformationComponentNgFactory as InformationComponentNgFactory };
