import {
  AuditForm,
  OBSERVATION_MODEL_HAND_HYGIENE,
  OBSERVATION_MODEL_HAND_HYGIENE_UK,
  OBSERVATION_MODEL_HAND_HYGIENE_US
} from '../api/models/audit-form';

import * as moment from 'moment';

/**
 * Checks whether given audit form is a hand hygiene audit form (IE, US or UK)
 */
export function isHandHygieneAudit(auditForm: AuditForm): boolean {
  return [
    OBSERVATION_MODEL_HAND_HYGIENE,
    OBSERVATION_MODEL_HAND_HYGIENE_UK,
    OBSERVATION_MODEL_HAND_HYGIENE_US,
  ].find((model: string) => model === auditForm.observation_model) !== undefined;
}

export function capitalizeFirstLetter(s: string): string {
  if (s.length > 0) return s.charAt(0).toLocaleUpperCase() + s.slice(1);
  else return s;
}


export function isCordovaProject(): boolean {
  return ((window as any).cordova !== undefined);
}

export enum Platform {
  iOS = 'ios',
  Android = 'android',
  Web = 'web',
  Windows = 'windows',
}


export function getPlatform(): Platform | undefined {
  if (isCordovaProject()) {
    const platform: Platform | undefined = (window as any).cordova.platformId.toLowerCase() as Platform;
    if (platform) return platform;
  }
  return Platform.Web;
}


export function openUrl(url: string) {
  const platform = getPlatform();
  switch (platform) {
    case Platform.iOS:
      window.location.assign(url);
      break;
    default:
      window.open(url, '_blank');
      break;
  }
}

/**
 * Adds the name passed in to the Base64 header if the header exists else throws an error
 * @param base64Str Base64 string to which include the name
 * @param name string
 * @return completed Base64 string
 */
export function addNameToBase64(base64Str: string, name: string):  string {
  const base64Groups: RegExpMatchArray | null = base64Str.match(/^data:(.*;base64,)?/);
  if (base64Groups === null) throw new Error('Base64 string does not contain a header');
  const base64header = base64Groups[1];
  const base64HeaderName = base64header.replace(';', `;name=${name};`);
  return base64Str.replace(base64header, base64HeaderName);
}

/**
 * Returns the name inside the header else returns null.
 * @param base64Str string the complete base64 string.
 * @return string | null if the name was found.
 */
export function getBase64HeaderName(base64Str: string): string | null {
  const base64Groups: RegExpMatchArray | null = base64Str.match(/name=(.*);/);
  if (!base64Groups) return null;
  return base64Groups[1];
}

/**
 * Checks if value is either null or undefined
 * @param value: returns boolean if null or undefined
 */
export function isNullOrUndefined(value: any): boolean {
  return (value === null || value === undefined);
}

/**
 * Checks to see if an object is empty
 * @param object: Object
 */
export function isObjectEmpty(object: {}) {
  if (isNullOrUndefined(object)) {
    return true;
  }
  return Object.keys(object).length === 0;
}

/**
 * declaring available functions for String, Number, Array, Date
 */
declare global {
  interface String {
    isEmpty(): boolean;
    toDate(format?: string): Date | null;
  }

  interface Number {
    addPaddedZero(size: number): string;
  }

  interface Array<T> {
    isArrayOfType(type: string): boolean;
  }

  interface Date {
    toString(format?: string): string;
  }
}

/**
 * It trims the string first before checking the length
 * and returns false if the string is empty.
 */
String.prototype.isEmpty = function () {
  const s = this.trim();
  return s.length === 0;
};

/**
 * Converts string to Date object
 * @param format Optional, default format 'yyyy-MM-dd H:mm'
 * @return null or Date
 */
String.prototype.toDate = function(format?: string): Date | null {
  if (!format) format = 'YYYY-MM-DD H:mm';
  const result = moment(this, format, true);
  if (!result.isValid()) return null;
  else return result.toDate();
};


/**
 * Adding padding of zeros to number
 * @param size length of total string
 */
Number.prototype.addPaddedZero = function (size: number) {
  const noString = String(this);
  const noPadding = (size - noString.length);
  return noString.length > 1 ? noString : `${String('0').repeat(noPadding)}${noString}`;
};

/**
 * Checks if given array's first item is of given type
 * @param array array instance
 * @param type expected type of the first item
 */
export function isArrayOfType<T>(array: Array<T>, type: string): boolean {
  return Array.isArray(array) && !!array.length && (typeof array[0] === type);
}


/**
 * Checking if the array elements are of type the passed in value
 * This only checks the first element for performance
 * @param type
 */
Array.prototype.isArrayOfType = function (type: string): boolean {
  return isArrayOfType(this, type);
};

/**
 * Retrieves the file extension from a file.
 * https://stackoverflow.com/a/1203361/23746
 * @param file: File
 * @return string | undefined
 */
export function getFileExtension(file: File): string | undefined {
  return file.name.split('.').pop();
}


/**
 * Converts date into a string
 * @param format defaults to 'YYYY-MM-DD'
 * @return string
 */
Date.prototype.toString = function (format?: string): string {
  if (!format) format = 'YYYY-MM-DD';
  return moment(this).format(format);
};

/**
 * Turns array of arrays into a single array combining items from all nested arrays.
 * Use this function multiple times to flatten arrays of deeper hierarchy
 * @param items a multi dimensional array.
 */
export function flattenArrays<T>(items: T[][]): T[] {
  const result: T[] = [];
  items.forEach((subItems: T[]) => result.push(...subItems));
  return result;
}

/**
 * Parses base64 into a json string and then into a js object
 */
export function parseBase64Object<T>(base64: string): T {
  return JSON.parse(atob(base64));
}

export function fileTypeImage(fileType: string): string {
  let image = 'text.svg';
  if (['pdf'].includes(fileType)) {
    image = 'pdf.svg';
  } else if (['jpg', 'jpeg', 'png'].includes(fileType)) {
    image = 'image.svg';
  } else if (['xls', 'xlsm', 'xlsx', 'xlt', 'xltm'].includes(fileType)) {
    image = 'excel.svg';
  } else if (['docx', 'dot', 'dotx'].includes(fileType)) {
    image = 'word.svg';
  }
  return 'assets/images/document_icons/' + image;
}
