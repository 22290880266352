<mat-form-field>
  <mat-select #searchableDropdownMatSelectObject disableOptionCentering class="{{ selectionClass }} mat-select" placeholder="{{ selectionPlaceholderText }}" (selectionChange)="onSelectionChange($event)" (openedChange)="searchbarPanelToggled($event)" [(value)]="selectedItem" (keydown)="matSelectKeyboardEvents($event)" [disabled]="disabled">
    <div class="flex-container" *ngIf="allValues?.length>30">
      <mat-option>
        <input matInput #searchableDropdownSearchbar id="searchableDropdownSearchbar" type="text" class="{{ searchbarClass }} mat-input-element mat-form-field" autocomplete="off" placeholder="{{ searchbarPlaceholderText }}" (click)="searchBarClicked($event)" (focusin)="searchBarFocused()" (keydown)="searchbarKeyboardEvents($event)" [formControl]="itemSearchBarControl">
      </mat-option>
    </div>
    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
      <span [innerHTML]="searchbarInnerHTML(item)"></span>
    </mat-option>
  </mat-select>
</mat-form-field>
