import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {ModalAlertData, ModalButton} from './modal-alert-data';
import {NGXLogger} from 'ngx-logger';

export enum AlertType { INFO, WARNING, ERROR, CONFIRM }

@Component({
  selector: 'meg-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ModalAlertData,
    private logger: NGXLogger,
  ) { }

  ngOnInit() {}

  public get getAlertIcon() {
    switch (this.data.alertType) {
      case AlertType.INFO: return 'info';
      case AlertType.WARNING: return 'warning';
      case AlertType.ERROR: return 'error';
      case AlertType.CONFIRM: return 'check_circle';
    }
  }

  public get alertClass() {
    switch (this.data.alertType) {
      case AlertType.INFO: return 'blue';
      case AlertType.WARNING: return 'orange';
      case AlertType.ERROR: return 'red';
      case AlertType.CONFIRM: return 'green';
    }
  }

  public onClicked(button: ModalButton) {
    if (button.onclick) button.onclick();
    button.label.subscribe((label) => this.logger.debug('Clicked button:', label));
  }
}
