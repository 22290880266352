import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import {
  DASHBOARD_WIDGET_BAR,
  DASHBOARD_WIDGET_FIELD_TABLE,
  DASHBOARD_WIDGET_LINE,
  DASHBOARD_WIDGET_PIE,
  DASHBOARD_WIDGET_SIMPLE_COMPLIANCE,
  DASHBOARD_WIDGET_SUB_FORM_BAR,
  Widget
} from '../../api/models/dashboard';
import {AuditFormSchema} from '../../api/models/audit-form-schema';
import {Ward} from '../../api/models/ward';
import {Audit} from '../../audit-form/audit';
import {AuditForm} from '../../api/models/audit-form';
import {AuditService} from '../../audit-form/audit.service';
import {InstitutionService} from '../../institution.service';
import {ComplianceService} from '../../compliance/compliance.service';
import {BarChart} from '../chart_config/bar_chart';
import {PieChart} from '../chart_config/pie_chart';
import {ChartsCalculator} from '../charts_calculator';
import {LineChart} from '../chart_config/line_chart';
import {Observable} from 'rxjs';
import {AuditFormService} from '../../audit-form.service';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'meg-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit {

  public chart: Chart | null = null;
  public compliance: number | null;
  public complianceLevel: number;
  public complianceImage$: Observable<string>;
  public ward: Ward | null = null;

  @Input() audit: Audit;
  @Input() widget: Widget;
  @Input() auditForm: AuditForm;
  @Input() auditFormSchema: AuditFormSchema;

  @ViewChild('megCanvas') canvas: ElementRef;

  public tableData: { [fieldName: string]: any }[] = [];
  public displayedColumns = ['name', 'no_observations', 'compliance'];
  public megChartLabels:  [string, string][] = [];

  public widgetTypes = {
    PIE: DASHBOARD_WIDGET_PIE,
    BAR: DASHBOARD_WIDGET_BAR,
    LINE: DASHBOARD_WIDGET_LINE,
    SIMPLE_COMPLIANCE: DASHBOARD_WIDGET_SIMPLE_COMPLIANCE,
    SUB_FORM_BAR: DASHBOARD_WIDGET_SUB_FORM_BAR,
    ANSWER_TABLE: DASHBOARD_WIDGET_FIELD_TABLE,
  };

  constructor(private auditService: AuditService,
              private institutionService: InstitutionService,
              private complianceService: ComplianceService,
              private auditFormService: AuditFormService,
              private logger: NGXLogger) {
  }

  ngOnInit() {
    this.compliance = this.complianceService.calculateAuditCompliance(this.auditFormSchema, this.audit);
    this.complianceLevel = this.complianceService.getComplianceLevel(this.auditForm, this.compliance);
    this.complianceImage$ = this.complianceService.getComplianceImageUrl(this.auditForm, this.compliance);
    this.institutionService.getWard(this.audit.wardId).subscribe((ward: Ward) => {
      this.ward = ward;
      this.buildChart();
    });
  }

  private buildChart() {
    let chartData: {} | null = null;
    if (this.widget !== null) {
      switch (this.widget.widget) {
        case this.widgetTypes.BAR: {
          const barChart = new BarChart(this.audit.auditSession.observations, this.auditFormSchema,
            this.auditForm, this.complianceService);
          this.megChartLabels = barChart.getChartData(this.widget);
          chartData = barChart.buildChart(this.widget);
          break;
        }
        case this.widgetTypes.SUB_FORM_BAR: {
          const barChart = new BarChart(this.audit.auditSession.observations, this.auditFormSchema,
            this.auditForm, this.complianceService);
          this.megChartLabels = barChart.getChartData(this.widget);
          chartData = barChart.buildChart(this.widget);
          break;
        }
        case this.widgetTypes.PIE: {
          const pieChart = new PieChart(this.audit.auditSession.observations, this.auditFormSchema,
            this.auditForm, this.complianceService);
          chartData = pieChart.buildChart(this.widget);
          break;
        }
        case this.widgetTypes.LINE: {
          const barChart = new LineChart(this.audit.auditSession.observations, this.auditFormSchema,
            this.auditForm, this.complianceService);
          chartData = barChart.buildChart(this.widget);
          break;
        }
        case this.widgetTypes.ANSWER_TABLE: {
          const answerTableChart = new ChartsCalculator(this.audit.auditSession.observations, this.auditFormSchema,
            this.auditForm, this.complianceService);
          this.tableData =  answerTableChart.getTableData(this.widget);
          break;
        }
        default: {
          this.logger.error('Unsupported widget', this.widget);
          break;
        }
      }
      if (this.canvas !== undefined && chartData !== null) {
        this.chart = new Chart(this.canvas.nativeElement, chartData);
      }
    }
  }

  public getFieldName(): string {
    if (this.widget.config.field_name) {
      const fieldSubForm = this.auditFormService.getFieldSubformFromSchema(this.widget.config.field_name, this.auditFormSchema);
      if (fieldSubForm[0]) {
        return fieldSubForm[0].label;
      }
    }
    return '';
  }

}
