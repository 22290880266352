import {AfterViewInit, ComponentRef, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';

import {MEGTooltipComponent} from './tooltip.component';
import {Hints, HintService} from '../hint.service';
import {Router} from '@angular/router';
import {isNullOrUndefined} from '../utils/misc';
import {Subscription} from 'rxjs';

/**
 * Enum holding all the possible positions for the tooltip/hint.
 */
export enum TooltipPosition {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

export namespace TooltipPosition {
  /**
   * Returns the position of the overlay
   * @param position position on which to set the overlay
   * @return ConnectedPosition
   */
  export function getPosition(position: TooltipPosition): ConnectedPosition {
    switch (position) {
      case TooltipPosition.LEFT: return {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
        offsetX: -5,
      };
      case TooltipPosition.RIGHT: return {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
        offsetX: 5,
      };
      case TooltipPosition.TOP: return {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
      };
      case TooltipPosition.TOP_RIGHT: return {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      };
      case TooltipPosition.TOP_LEFT: return {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      };
      case TooltipPosition.BOTTOM: return {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      };
      case TooltipPosition.BOTTOM_LEFT: return {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      };
      case TooltipPosition.BOTTOM_RIGHT: return {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
      };
    }
    return {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -5,
    };
  }

  /**
   * Returns the css class for thee arrow location.
   * @param position the position of the overlay
   * @return returns the css class, defaults to 'tooltip tooltip-top'
   */
  export function getArrowLocationCSSClass(position: TooltipPosition): string {
    switch (position) {
      case TooltipPosition.LEFT: return 'tooltip tooltip-right';
      case TooltipPosition.RIGHT: return 'tooltip tooltip-left';
      case TooltipPosition.TOP: return 'tooltip tooltip-bottom';
      case TooltipPosition.TOP_RIGHT: return 'tooltip tooltip-bottom-right';
      case TooltipPosition.TOP_LEFT: return 'tooltip tooltip-bottom-left';
      case TooltipPosition.BOTTOM: return 'tooltip tooltip-bottom';
      case TooltipPosition.BOTTOM_RIGHT: return 'tooltip tooltip-top-right';
      case TooltipPosition.BOTTOM_LEFT: return 'tooltip tooltip-top-left';
    }
    return 'tooltip tooltip-top';
  }
}

@Directive({ selector: '[megTooltip]' })
export class MEGTooltipDirective implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() megTooltip = ''; // The text within the overlay
  @Input() megTooltipPosition: TooltipPosition; // The position of the overlay compared to element
  @Input() megTooltipKey: Hints; // The hints enum, so we can set seen of that hint
  @Input() megTooltipShow = false; // To show the tool tip when hoovering over the element
  @Input() megTooltipShowHint = false; // To show a hint when the page loads

  private overlayRef: OverlayRef;
  private alreadySeen: Boolean = true;
  private routerSubscription: Subscription;

  constructor(private overlay: Overlay,
              private overlayPositionBuilder: OverlayPositionBuilder,
              private elementRef: ElementRef,
              private hintService: HintService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe(() => this.hideOverlay(false));
    this.alreadySeen = this.hintService.wasShown(this.megTooltipKey);
    this.setupView();
  }

  /**
   * Wait for the view to be loaded before showing the overlay
   * Reason is that the element may be moved during loading
   */
  ngAfterViewInit(): void {
    this.showDelayOverlayHint();
  }

  /**
   * If any of the INPUT values change, we can listen for them and try
   * setup the view again and show the overlay if not done already.
   */
  ngOnChanges(): void {
    this.setupView();
    this.showDelayOverlayHint();
  }

  ngOnDestroy(): void {
    this.hideOverlay(false);
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  /**
   * Setups the overlay view, if the overlay has not been set yet
   * or either showToolTip or showHint values are true
   * Also sets a listener on the overlay, so we can close on click.
   */
  private setupView() {
    // only create if either showToolTip or showHint is true
    if (isNullOrUndefined(this.megTooltipPosition) || isNullOrUndefined(this.megTooltipKey)) return;
    if (this.overlayRef === undefined && (this.megTooltipShow || this.megTooltipShowHint)) {
      const position = TooltipPosition.getPosition(this.megTooltipPosition);
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([position]);
      this.overlayRef = this.overlay.create({positionStrategy});
      const self = this;
      this.overlayRef.hostElement.addEventListener('click', function () {
        self.hideOverlay();
      }, false);
    }
  }

  /**
   * Show overlay after waiting a second for the view to be finished laid out.
   * There is some time difference when ngAfterViewInit is called and the elements are set
   * Sets alreadySeen to be true, so if ngOnChanges is called the it wont show again.
   */
  private showDelayOverlayHint() {
    if (this.overlayRef !== undefined && !this.overlayRef.hasAttached() && !this.alreadySeen) {
      this.alreadySeen = true;
      setTimeout(() => {
        this.showOverlay();
      }, 1000);
    }
  }

  /**
   * Hides the overlay if the overlay has been attached
   */
  private hideOverlay(markSeen: boolean = true) {
    if (this.overlayRef) {
      this.overlayRef.detach();
      if (markSeen) this.hintService.markShown(this.megTooltipKey);
    }
  }

  /**
   * Shows the overlay with the text and toolTipClass being set
   */
  private showOverlay() {
    const tooltipRef: ComponentRef<MEGTooltipComponent>
      = this.overlayRef.attach(new ComponentPortal(MEGTooltipComponent));
    tooltipRef.instance.text = this.megTooltip;
    tooltipRef.instance.toolTipClass = TooltipPosition.getArrowLocationCSSClass(this.megTooltipPosition);
  }

  /**
   * Click listener on the element, so we can dismiss the hint
   */
  @HostListener('click')
  click() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.hintService.markShown(this.megTooltipKey);
    }
  }

  /**
   * mouseenter listener on the element, so if showToolTip has been set to true, we can
   * show a tool tip.
   */
  @HostListener('mouseenter')
  show() {
    // the overlay may have been attached already by timeout above
    if (this.overlayRef && !this.overlayRef.hasAttached() && this.megTooltipShow) {
      this.showOverlay();
    }
  }

  /**
   * mouseleave listener on the element to hide the overlay if showToolTip is set to true.
   */
  @HostListener('mouseleave')
  hide() {
    if (this.megTooltipShow) this.hideOverlay();
  }
}
