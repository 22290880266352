/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./download-audit-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i6 from "@angular/material/progress-bar";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./download-audit-dialog.component";
import * as i9 from "../loading/download.service";
var styles_DownloadAuditDialogComponent = [i0.styles];
var RenderType_DownloadAuditDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DownloadAuditDialogComponent, data: {} });
export { RenderType_DownloadAuditDialogComponent as RenderType_DownloadAuditDialogComponent };
function View_DownloadAuditDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("settings.refresh-audits-label")); _ck(_v, 2, 0, currVal_1); }); }
function View_DownloadAuditDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.name; _ck(_v, 2, 0, currVal_1); }); }
export function View_DownloadAuditDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadAuditDialogComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DownloadAuditDialogComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 5, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i5.View_MatProgressBar_0, i5.RenderType_MatProgressBar)), i1.ɵdid(7, 49152, null, 0, i6.MatProgressBar, [i1.ElementRef, [2, i7.ANIMATION_MODULE_TYPE], [2, i6.MAT_PROGRESS_BAR_LOCATION]], { value: [0, "value"], mode: [1, "mode"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "help-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Updating, thank you for your patience. "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.data.name; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.name; _ck(_v, 3, 0, currVal_1); var currVal_5 = _co.downloadService.progress; var currVal_6 = ((_co.downloadService.progress === 0) ? "indeterminate" : "determinate"); _ck(_v, 7, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7).value; var currVal_3 = i1.ɵnov(_v, 7).mode; var currVal_4 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_DownloadAuditDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-download-audit-dialog", [], null, null, null, View_DownloadAuditDialogComponent_0, RenderType_DownloadAuditDialogComponent)), i1.ɵdid(1, 245760, null, 0, i8.DownloadAuditDialogComponent, [i2.MAT_DIALOG_DATA, i9.DownloadService, i1.Injector, i2.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DownloadAuditDialogComponentNgFactory = i1.ɵccf("meg-download-audit-dialog", i8.DownloadAuditDialogComponent, View_DownloadAuditDialogComponent_Host_0, {}, {}, []);
export { DownloadAuditDialogComponentNgFactory as DownloadAuditDialogComponentNgFactory };
