<div class="walkthrough-content swipe-box" [ngClass]="pageNumber === 0 ? 'first-page' : ''">

  <div *ngFor="let currentPage of walkthroughPages; let idx=index" (swipeleft)="swipe(idx, $event.type)"
       (swiperight)="swipe(idx, $event.type)"
       [class.hidden]="!isCurrentPage(currentPage)"
       [class.visible-from-right]="isCurrentPage(currentPage) && action===SWIPE_ACTION.LEFT"
       [class.hidden-to-left]="!isCurrentPage(currentPage) && action===SWIPE_ACTION.LEFT"
       [class.visible-from-left]="isCurrentPage(currentPage) && action===SWIPE_ACTION.RIGHT"
       [class.hidden-to-right]="!isCurrentPage(currentPage) && action===SWIPE_ACTION.RIGHT"
       >
    <img [src]="currentPage.imageSrc" class="walkthrough-image">

    <div class="intro-txt">
      <h1 class="title">{{currentPage.title}}</h1>

      <p class="subtitle">{{currentPage.text}}</p>
    </div>
  </div>

  <div *ngIf="pageNumber === 0" class="swipe-container">
    <img src="assets/images/swipe-indicator.png" width="100" class="swipe-image">
  </div>

</div>
<div class="walkthrough-status">
  <button mat-button (click)="finish()" id="btn-skip">{{'skip' | translate}}</button>
  <div class="walkthrough-progress">
    <div *ngFor="let page of walkthroughPages" class="page-progress" [ngClass]="page === currentPage ? 'current' : ''"></div>
  </div>
  <button mat-button (click)="nextPage()" id="btn-next">
    <mat-icon *ngIf="!isLastPage">keyboard_arrow_right</mat-icon>
    <mat-icon *ngIf="isLastPage">check</mat-icon>
  </button>
</div>
