import { StorageService } from './storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./storage.service";
export var DB_KEY_HINTS = 'in_app_hints';
export var DB_VALUE_HINTS_SHOWN = 'true';
export var Hints;
(function (Hints) {
    Hints["ObservationEdit"] = "observation-edit";
    Hints["SubmitAudit"] = "submit-audit";
    Hints["AddForm"] = "add-form";
    Hints["CommonIssues"] = "common-issues";
})(Hints || (Hints = {}));
var HintService = /** @class */ (function () {
    function HintService(storageService) {
        this.storageService = storageService;
        this.hints = Hints;
    }
    HintService.prototype.createKey = function (hint) {
        return DB_KEY_HINTS + "-" + hint;
    };
    HintService.prototype.markShown = function (hint) {
        this.storageService.setString(this.createKey(hint), DB_VALUE_HINTS_SHOWN);
    };
    HintService.prototype.wasShown = function (hint) {
        return this.storageService.getString(this.createKey(hint)) === DB_VALUE_HINTS_SHOWN;
    };
    HintService.ngInjectableDef = i0.defineInjectable({ factory: function HintService_Factory() { return new HintService(i0.inject(i1.StorageService)); }, token: HintService, providedIn: "root" });
    return HintService;
}());
export { HintService };
