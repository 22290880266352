import {Model} from './model';
import {UserName} from './basic-auditor';

export const CHANNEL_TYPE_PRIVATE = 'channel_private';
export const CHANNEL_TYPE_P2P = 'channel_p2p';
export const CHANNEL_TYPE_GROUP = 'channel_group';

export const MESSAGE_FORMAT_PLAIN = 'plaintext';
export const MESSAGE_FORMAT_HTML = 'html';

export class Channel extends Model {
  name: string;
  channel_type: string;
}

export class Message extends Model {
  sender: UserName;
  format: string;
  content: string;
  created: string;
  is_sender: boolean;
}
