<div class="container-body">

  <div class="calendar-wrapper" *ngIf="weeks">
    <mat-select class="calendar-dropdown" placeholder="Month" [(value)]="month" (selectionChange)="loadData()">
      <mat-option *ngFor="let monthChoice of monthChoices" [value]="monthChoice[0]">{{monthChoice[1]}}</mat-option>
    </mat-select>
    <mat-select class="calendar-dropdown" placeholder="Year" [(value)]="year" (selectionChange)="loadData()">
      <mat-option *ngFor="let yearChoice of yearChoices" [value]="yearChoice">{{yearChoice}}</mat-option>
    </mat-select>
    
    <div id="legend">
      <mat-chip color="primary" [selected]="true">{{ 'calendar.scheduled-audit' | translate }}</mat-chip>
      <mat-chip color="accent" [selected]="true">{{ 'calendar.completed-audit' | translate }}</mat-chip>
    </div>

    <table class="calendar-table">
      <thead>
      <tr>
        <th class="day-heading" *ngFor="let day of weekDays$ | async">{{day}}</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let days of weeks">
          <td *ngFor="let day of days" class="day-cell" [ngClass]="{'empty': !day, 'today': day?.is_today}" (click)="onDayClicked(day || null)">
            <div class="day-number-container" *ngIf="day">
              <p class="day-number">{{day.day_of_month}}</p>
              <mat-chip-list class="day-audits">
                <mat-chip class="calendar-chip" *ngFor="let schedule of day.schedules.slice(0, 2)" color="primary" [selected]="true">{{getAuditForm(schedule.audit_form)?.name}}</mat-chip>
                <mat-chip class="calendar-chip" *ngFor="let audit of day.audit_sessions.slice(0, 2)" color="accent" [selected]="true">{{getAuditForm(audit.audit_form)?.name}}</mat-chip>
              </mat-chip-list>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <mat-progress-spinner *ngIf="!weeks" mode="indeterminate"></mat-progress-spinner>
</div>
