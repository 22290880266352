import {Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Audit} from '../audit-form/audit';
import {AuditFormService} from '../audit-form.service';
import {AuditFormSchema, SubFormSchema} from '../api/models/audit-form-schema';
import {AuditForm, LayoutType} from '../api/models/audit-form';
import {AuditService} from '../audit-form/audit.service';
import {Observation} from '../api/models/observation';
import {BaseComponent} from '../base.component';
import {MatSnackBar} from '@angular/material';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {validateSessionToken} from '../api/utils';
import {ApiService} from '../api/api.service';

@Component({
  selector: 'meg-subform-select',
  templateUrl: './subform-select.component.html',
  styleUrls: ['./subform-select.component.css']
})
export class SubformSelectComponent extends BaseComponent implements OnInit {
  public audit: Audit;
  public auditForm: AuditForm;
  public auditFormSchema: AuditFormSchema;

  constructor(private activatedRoute: ActivatedRoute, private auditFormService: AuditFormService,
              protected injector: Injector, private auditService: AuditService, private router: Router,
              private snackBar: MatSnackBar, private api: ApiService) {
    super(injector);
  }

  ngOnInit() {
    validateSessionToken(this.api, this.translateService);
    this.activatedRoute.params.subscribe((params: Params) => {
      const auditId: number = +params['audit_id'];

      if (!isNaN(auditId)) {
        this.auditService.setAuditId(auditId);

        this.auditService.getAuditWithFormSchema(auditId).pipe(
          catchError((e) => {
            return this.translateService.get('audit-form.form-load-error').pipe(
              tap((message: string) => this.snackBar.open(message, undefined, {duration: 3000})),
              tap(() => this.router.navigate([''])),
              mergeMap(() => throwError(e)),
            );
          }),
        ).subscribe(
          (result: [Audit, AuditForm, AuditFormSchema]) => {
            [this.audit, this.auditForm, this.auditFormSchema] = result;

            if (!this.auditFormService.hasSubforms(this.auditFormSchema) ||
              this.auditFormService.getFormLayoutType(this.auditForm) === LayoutType.Accordion) {
              // if this audit has no subforms, skip directly to audit form
              this.router.navigate(['audit-form', auditId]);
            }
          },
          (error) => {
            this.logger.error(error);
          });
      }
    });
  }

  /**
   * Returns observations which has an answer for given subform.
   * If no observation have a given subobservation, empty list is returned.
   */
  private getObservationsForSubform(form: SubFormSchema): Observation[] {
    const subformName = form.name;
    return this.audit.auditSession.observations.filter((observation: Observation) => observation[subformName] !== undefined);
  }

  public onFormSelect(form: SubFormSchema) {
    this.logger.debug('Form selected', form);
    const existingObservations = this.getObservationsForSubform(form);
    if (form.many || existingObservations.length === 0) {
      this.router.navigate(['audit-form', this.audit.id, 'subform', form.name]);
    } else {
      this.modalService.openWarningModal(this.translateService.get('audit-list.form-already-filled', {
        form: form.display_name,
        }),
        this.modalService.btnOK(() => {
          const observationId = this.audit.auditSession.observations.indexOf(existingObservations[0]);
          if (observationId != null) this.router.navigate(
            ['audit-form', this.audit.id, 'subform', form.name, 'edit', observationId]);
        }), this.modalService.btnCancel()).subscribe();
    }
  }
}
