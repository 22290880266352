<meg-toolbar *ngIf="!walkthroughShowing()" (toggleMenuEvent)="toggleMenu($event)" [sideMenuChange]="menuOpenChanged"></meg-toolbar>
<mat-sidenav-container class="menu-container ios-container">
  <mat-sidenav #sideNav [mode]="mode.value" class="meg-side-nav">
    <mat-nav-list>
      <div *ngFor="let item of items">
        <a mat-list-item *ngIf="item.visible" (click)="item.action()">
          <mat-icon *ngIf="item.icon" class="menu-icons">{{ item.icon }}</mat-icon>
            {{ item.label }}
          <span class="badge badge-large">{{item.badge | async}}</span>
          <mat-divider></mat-divider>
        </a>
      </div>
    </mat-nav-list>

    <p>{{ 'menu.logged-in-as' | translate }}<br><strong>{{(user$|async)?.username}}</strong></p>

    <div class="app-version-footer">
      <meg-app-version></meg-app-version>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="restricted-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
