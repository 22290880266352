import { LocalStorage } from '@ngx-pwa/local-storage';
import { of, forkJoin } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NGXLogger } from 'ngx-logger';
/** Keeps track of keys inserted to database */
var DB_KEY_KEYS = 'keys';
export var DB_KEY_QIP_FILTER_STATE = 'filter-state';
var StorageService = /** @class */ (function () {
    function StorageService(localStorage, logger) {
        this.localStorage = localStorage;
        this.logger = logger;
    }
    /** Adds key to index of keys */
    StorageService.prototype.addKey = function (key) {
        var _this = this;
        return this.getRawKeys().pipe(mergeMap(function (keyset) {
            if (keyset.find(function (k) { return k === key; }) === undefined) {
                keyset.push(key);
                return _this.localStorage.setItem(DB_KEY_KEYS, keyset);
            }
            else {
                return of(true);
            }
        }));
    };
    /** Removes key from index of keys */
    StorageService.prototype.removeKey = function (key) {
        var _this = this;
        return this.getRawKeys().pipe(mergeMap(function (keyset) {
            keyset = keyset.filter(function (k) { return k !== key; });
            return _this.localStorage.setItem(DB_KEY_KEYS, keyset);
        }));
    };
    /** Returns set of keys for current environment recorded in indexed database using this storage manager */
    StorageService.prototype.getKeys = function () {
        return this.getRawKeys().pipe(map(function (keyset) {
            var prefix = environment.storagePrefix;
            if (prefix) {
                // filter out keys from other builds (staging, local) and remove prefix
                return keyset
                    .filter(function (key) { return key.startsWith(prefix); })
                    .map(function (key) { return key.slice(prefix.length); });
            }
            else {
                return keyset;
            }
        }));
    };
    /** Returns set of keys recorded in indexed database using this storage manager */
    StorageService.prototype.getRawKeys = function () {
        return this.localStorage.getItem(DB_KEY_KEYS).pipe(map(function (keyset) {
            // keyset could still be a Set instance, in which case convert it to array
            if (keyset instanceof Array)
                return keyset;
            else if (keyset === null)
                return null;
            else
                return Array.from(keyset);
        }), map(function (keyset) { return keyset === null ? [] : keyset; }));
    };
    StorageService.prototype.setItem = function (key, value) {
        var _this = this;
        key = this.prefixKey(key);
        return forkJoin(this.localStorage.setItem(key, value).pipe(tap(function (result) { return _this.logger.debug("Written \"" + key + "\":", value, result); })), this.addKey(key)).pipe(map(function (result) { return result[0]; }));
    };
    StorageService.prototype.removeItem = function (key) {
        var _this = this;
        key = this.prefixKey(key);
        return forkJoin(this.localStorage.removeItem(key), this.removeKey(key)).pipe(map(function (result) { return result[0]; }), tap(function () { return _this.logger.debug("Removed item \"" + key + "\""); }));
    };
    /**
     * Gets item from database.
     * Raises an error if item does not exist or is null.
     */
    StorageService.prototype.getItem = function (key) {
        key = this.prefixKey(key);
        return this.localStorage.getItem(key).pipe(tap(function (value) {
            if (value === null) {
                throw new Error("Empty value received for " + key);
            }
        }));
    };
    /**
     * Returns number representing number of characters stored in given key(s).
     * Defaults to 0 for non-existing keys
     * @param keys
     */
    StorageService.prototype.getItemSizes = function () {
        var _this = this;
        var keys = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            keys[_i] = arguments[_i];
        }
        var observables = keys.map(function (key) {
            return _this.getItemOrNull(key).pipe(map(function (item) { return item === null ? 0 : JSON.stringify(item).length; }));
        });
        if (observables.length === 0)
            return of(0);
        else
            return forkJoin(observables).pipe(map(function (sizes) { return sizes.reduce(function (a, b) { return a + b; }); }));
    };
    /**
     * Checks whether key exists in Indexed DB.
     * This implementation tries to retrieve item and returns false if any error occurs during retrieval.
     * @param {string} key
     * @returns {Observable<boolean>} true if item exists, false if it doesn't
     */
    StorageService.prototype.hasItem = function (key) {
        key = this.prefixKey(key);
        return this.localStorage.getItem(key).pipe(map(function (value) { return value !== null; }));
    };
    /** Gets item from database or default value if item does not exist or is null */
    StorageService.prototype.getItemOrDefault = function (key, defaultValue) {
        key = this.prefixKey(key);
        return this.localStorage.getItem(key).pipe(map(function (value) { return value === null ? defaultValue : value; }));
    };
    /**
     * Gets item from database.
     * Returns null if item does not exist
     */
    StorageService.prototype.getItemOrNull = function (key) {
        key = this.prefixKey(key);
        return this.localStorage.getItem(key).pipe();
    };
    /**
     * Clears all data from database and cookies
     */
    StorageService.prototype.clearAllItems = function () {
        window.localStorage.clear();
        return this.localStorage.clear();
    };
    StorageService.prototype.setString = function (key, value) {
        key = this.prefixKey(key);
        window.localStorage.setItem(key, value);
    };
    StorageService.prototype.getString = function (key) {
        key = this.prefixKey(key);
        return window.localStorage.getItem(key) || null;
    };
    StorageService.prototype.removeString = function (key) {
        key = this.prefixKey(key);
        window.localStorage.removeItem(key);
    };
    /** Prepends prefix to given key */
    StorageService.prototype.prefixKey = function (key) {
        return "" + environment.storagePrefix + key;
    };
    return StorageService;
}());
export { StorageService };
