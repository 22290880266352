import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseLoginRequiredComponent} from '../base';
import {AccountService} from '../accounts.service';
import {Router, ParamMap, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {DownloadService} from './download.service';
import {NGXLogger} from 'ngx-logger';

/**
 * Downloads user data such as list of institutions, available audit forms and their schema to the app for offline use.
 */
@Component({
  selector: 'meg-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent extends BaseLoginRequiredComponent implements OnInit, OnDestroy {
  private subscription: Subscription | null = null;
  public error: HttpErrorResponse | null = null;
  public showErrorDetails = false;

  constructor(router: Router, private route: ActivatedRoute, accountService: AccountService,
              public downloadService: DownloadService, logger: NGXLogger) {
    super(router, route, accountService, logger);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.accountService.isAuthenticated()) {
      this.loadData();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription !== null) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  public loadData() {
    this.error = null;
    this.subscription = this.downloadService.loadData().subscribe(
      () => {},
      (error: HttpErrorResponse) => {
        this.error = error;
      },
      () => {
        this.route.queryParamMap.subscribe((params: ParamMap) => {
            this.subscription = null;
            const next: string | null = params.get('next');
            if (next) {
              this.router.navigateByUrl(next);
            } else {
              this.router.navigateByUrl('/');
            }
          }
        );
      },
    );
  }

  public logOut() {
    this.accountService.clearLogin();
    this.router.navigate(['login']);
  }

}
