import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {StorageService} from './storage.service';
import {Information} from './api/models/information';
import {map, mergeMap, tap} from 'rxjs/operators';
import {ContentType} from './api/models/content-type';
import {AuditFormService} from './audit-form.service';
import {AuditForm, CUSTOM_OBSERVATION_MODEL_NAME} from './api/models/audit-form';
import {NGXLogger} from 'ngx-logger';
import {ApiService} from './api/api.service';
import {DB_KEY_INFORMATIONS} from './api/constants';

/**
 * Stores information from audits
 */
@Injectable()
export class InformationService {

  constructor(private storageService: StorageService, private auditFormService: AuditFormService, private api: ApiService,
              private logger: NGXLogger) {
  }

  public saveInformations(informations: Information[]) {
    return this.storageService.setItem(DB_KEY_INFORMATIONS, informations);
  }

  private updateInformationMediaUrls(informations: Information[]): Observable<Information[]> {
    return this.api.buildUrl('/media/uploads/').pipe(
      tap(uploads_url => this.logger.debug('Updating media urls', uploads_url)),
      tap((uploads_url: string) => {
        informations.forEach((information) => information.content = information.content.replace(/\/media\/uploads\//g, uploads_url));
      }),
      map(() => informations),
    );
  }

  public getAllInformations(): Observable<Information[]> {
    return this.storageService.getItem<Information[]>(DB_KEY_INFORMATIONS).pipe(map(value => value == null ? [] : value)).pipe(
      mergeMap((informations) => this.updateInformationMediaUrls(informations)),
    );
  }

  public getInformations(auditFormId: number): Observable<Information[]> {
    return this.auditFormService.getAuditForm(auditFormId).pipe(
      mergeMap((auditForm: AuditForm) => {
        const contentTypeId = auditForm.observation_content_type.model;
        const informationFilter = (information: Information) => information.audit_forms.includes(auditFormId) ||
          information.audits.filter((contentType: ContentType) => contentType.model !== CUSTOM_OBSERVATION_MODEL_NAME
            && contentType.model === contentTypeId).length > 0;
        return this.getAllInformations().pipe(
          map((informations: Information[]) => informations.filter(informationFilter)));
      }));
  }

  public getInformationById(id: number): Observable<Information> {
    const observable = this.getAllInformations();
    const informationFilter = (information: Information) => (information.id === id);
    return observable.pipe(
      map((value: Information[]) => value.filter(informationFilter)[0]),
    );
  }

}
