import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {AuditFormSchema, Field, InlineFieldGroup, SubFormSchema} from '../../api/models/audit-form-schema';
import {FormGroup} from '@angular/forms';
import {Observation, SubObservation} from '../../api/models/observation';
import {Audit} from '../audit';
import {AuditForm} from '../../api/models/audit-form';
import {ObservationErrors} from '../../api/models/errors';
import {FormFieldComponent} from '../field/field.component';

@Component({
  selector: 'meg-wrapped-field',
  templateUrl: './wrapped-field.component.html',
  styleUrls: ['./wrapped-field.component.css']
})
export class WrappedFieldComponent implements OnInit {
  @Input() field: Field | InlineFieldGroup;
  @Input() form: FormGroup;
  @Input() errors: string[];
  @Input() observation: Observation | SubObservation;
  @Input() audit: Audit;
  @Input() public auditForm: AuditForm;
  @Input() public auditFormSchema: AuditFormSchema;
  @Input() public subForm: SubFormSchema | null = null;
  @Input() fieldErrors: ObservationErrors | null = null;
  @Input() accordionIndex: number;
  @Input() editing: boolean;
  @Input() public setValuePlaceholders: {[key: string]: string} = {};
  @ViewChildren(FormFieldComponent) public formFieldComponents: QueryList<FormFieldComponent>;

  public isField: boolean;
  constructor() { }

  ngOnInit() {
    this.isField = !this.field.hasOwnProperty('layout_columns');
  }

}
