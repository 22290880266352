import { OnInit } from '@angular/core';
import { ModalAlertData } from './modal-alert-data';
import { NGXLogger } from 'ngx-logger';
export var AlertType;
(function (AlertType) {
    AlertType[AlertType["INFO"] = 0] = "INFO";
    AlertType[AlertType["WARNING"] = 1] = "WARNING";
    AlertType[AlertType["ERROR"] = 2] = "ERROR";
    AlertType[AlertType["CONFIRM"] = 3] = "CONFIRM";
})(AlertType || (AlertType = {}));
var AlertComponent = /** @class */ (function () {
    function AlertComponent(data, logger) {
        this.data = data;
        this.logger = logger;
    }
    AlertComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(AlertComponent.prototype, "getAlertIcon", {
        get: function () {
            switch (this.data.alertType) {
                case AlertType.INFO: return 'info';
                case AlertType.WARNING: return 'warning';
                case AlertType.ERROR: return 'error';
                case AlertType.CONFIRM: return 'check_circle';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertComponent.prototype, "alertClass", {
        get: function () {
            switch (this.data.alertType) {
                case AlertType.INFO: return 'blue';
                case AlertType.WARNING: return 'orange';
                case AlertType.ERROR: return 'red';
                case AlertType.CONFIRM: return 'green';
            }
        },
        enumerable: true,
        configurable: true
    });
    AlertComponent.prototype.onClicked = function (button) {
        var _this = this;
        if (button.onclick)
            button.onclick();
        button.label.subscribe(function (label) { return _this.logger.debug('Clicked button:', label); });
    };
    return AlertComponent;
}());
export { AlertComponent };
