<mat-dialog-content>
  <section class="mat-dialog-content-header">
    <h1>{{'audit-form.field.select-time' | translate }}</h1>
  </section>
  <section class="mat-dialog-content-body">
    <div class="picker-container">
      <p class="outside-help-text">{{'audit-form.field.select-time-hours' | translate }}</p>
      <mat-select class="time-select" placeholder="Select hour" (selectionChange)="onHourChange($event)" [value]="hourValue">
        <mat-option *ngFor="let hour of hourArray" [value]="hour">{{hour.addPaddedZero(2)}}</mat-option>
      </mat-select>
    </div>
    <div class="picker-container">
      <p class="outside-help-text">{{'audit-form.field.select-time-minutes' | translate }}</p>
      <mat-select class="time-select" placeholder="Select min" (selectionChange)="onMinChange($event)" [value]="minValue">
        <mat-option *ngFor="let min of minArray" [value]="min">{{min.addPaddedZero(2)}}</mat-option>
      </mat-select>
    </div>
  </section>
</mat-dialog-content>

<mat-dialog-actions class="mat-dialog-content-actions">
  <button mat-raised-button color="primary" class="dialog-btn-left" [mat-dialog-close]="false"><mat-icon>clear</mat-icon> {{'cancel' | translate}}</button>
  <button mat-raised-button color="accent" class="dialog-btn-right" (click)="closeDialog()" ><mat-icon>done</mat-icon> {{'save' | translate}}</button>
</mat-dialog-actions>
