import {OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {AccountService} from './accounts.service';
import {NGXLogger} from 'ngx-logger';

/**
 * Base class for components that require that user is logged in.
 * Navigates to login screen is user is not authenticated.
 */
export class BaseLoginRequiredComponent implements OnInit {
  constructor(protected router: Router, route: ActivatedRoute, protected accountService: AccountService, protected logger: NGXLogger) {
  }

  public isLoggedIn(): boolean {
    return this.accountService.isAuthenticated();
  }

  /**
   * Checks if user is authenticated, if not redirects to login page
   */
  protected checkLogin(): void {
    if (!this.isLoggedIn()) {
      this.logger.debug('User not logged in. redirecting to login');
      this.goToLogin();
    }
  }

  ngOnInit(): void {
    this.checkLogin();
  }

  protected goToLogin() {
    this.router.navigateByUrl('/login');
  }
}
