var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { DownloadService } from '../loading/download.service';
import { SettingsService } from './settings.service';
import { EXTRA_HIGH_SCALE, HIGH_SCALE, MEDIUM_SCALE, NORMAL_SCALE, ScaleLevel } from './scale_level';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AccountService } from '../accounts.service';
import { LanguageService } from '../language.service';
import { ProfileService } from '../profile/profile.service';
import { AuditService } from '../audit-form/audit.service';
import { BackupService } from '../backup.service';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { of } from 'rxjs/internal/observable/of';
import { AppUpdateService } from '../app-update.service';
import { BaseComponent } from '../base.component';
import { appData } from '../../../appData';
import { isNullOrUndefined } from '../utils/misc';
var SettingsComponent = /** @class */ (function (_super) {
    __extends(SettingsComponent, _super);
    function SettingsComponent(downloadService, settingsService, accountService, languageService, profileService, auditService, backupService, appUpdateService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.downloadService = downloadService;
        _this.settingsService = settingsService;
        _this.accountService = accountService;
        _this.languageService = languageService;
        _this.profileService = profileService;
        _this.auditService = auditService;
        _this.backupService = backupService;
        _this.appUpdateService = appUpdateService;
        _this.injector = injector;
        _this.downloadingForms = false;
        _this.availableLanguages = [];
        _this.prefLanguageUpdating = false;
        _this.backupNumForms = 0;
        _this.backupProgress = 0;
        _this.appData = appData;
        _this.appVersionChecking = false;
        return _this;
    }
    SettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user$ = this.accountService.getUser();
        this.settingsService.readSettings().subscribe(function (settings) { return _this.settings = settings; });
        this.subscriptions.push(this.languageService.getAllLanguages()
            .subscribe(function (languages) { return _this.availableLanguages = languages; }));
        this.scaleLevels$ = this.translateService.stream([
            'settings.text-sizes.normal',
            'settings.text-sizes.medium',
            'settings.text-sizes.large',
            'settings.text-sizes.extra-large',
        ]).pipe(map(function (translations) { return [
            new ScaleLevel(translations['settings.text-sizes.normal'], NORMAL_SCALE),
            new ScaleLevel(translations['settings.text-sizes.medium'], MEDIUM_SCALE),
            new ScaleLevel(translations['settings.text-sizes.large'], HIGH_SCALE),
            new ScaleLevel(translations['settings.text-sizes.extra-large'], EXTRA_HIGH_SCALE),
        ]; }));
    };
    SettingsComponent.prototype.downloadForms = function () {
        var _this = this;
        this.downloadFormsObservable().subscribe(function () { }, function (e) {
            _this.logger.debug(e);
            alert(_this.translateService.instant('settings.refresh-audits-error'));
        });
    };
    SettingsComponent.prototype.downloadFormsObservable = function () {
        var _this = this;
        this.downloadingForms = true;
        return this.downloadService.loadData().pipe(tap(function () { return _this.downloadingForms = false; }), catchError(function (e) {
            _this.downloadingForms = false;
            return throwError(e);
        }));
    };
    SettingsComponent.prototype.changeShowOptionalFields = function (changeEvent) {
        this.settings.showOptionalFields = changeEvent.checked;
        this.saveSettings();
    };
    SettingsComponent.prototype.changeZoom = function (scaleLevel) {
        this.settings.scaleValue = scaleLevel.value;
        this.saveSettings();
    };
    SettingsComponent.prototype.changeLanguageSelection = function (changeEvent) {
        this.updateUserLanguage(changeEvent.source.value);
    };
    SettingsComponent.prototype.updateUserLanguage = function (language) {
        var _this = this;
        var currentLanguage = this.translateService.currentLang;
        if (language === currentLanguage)
            return;
        this.logger.debug('Language change', language);
        this.prefLanguageUpdating = true;
        this.user$.pipe(tap(function (user) { return user.auditor.language = language; }), mergeMap(function (user) { return _this.profileService.saveProfile(user).pipe(catchError(function (e) {
            // Reset the selection
            user.auditor.language = currentLanguage;
            return throwError(e);
        }), tap(function () {
            _this.logger.debug('Language changed', language);
            _this.accountService.setLanguage(language);
            _this.translateService.use(language);
        }), mergeMap(function () { return _this.settingsService.saveSettings(_this.settings); }), mergeMap(function () { return _this.downloadFormsObservable(); }), tap(function () { return _this.prefLanguageUpdating = false; }), catchError(function (e) {
            _this.prefLanguageUpdating = false;
            _this.logger.error(e);
            return _this.translateService.get(['settings.update-language-error', 'retry']).pipe(mergeMap(function (translations) { return _this.snackbar.open(translations['settings.update-language-error'], translations['retry'], { duration: 2000 }).onAction(); }), tap(function () { return _this.updateUserLanguage(language); }));
        })); })).subscribe();
    };
    SettingsComponent.prototype.backupAudits = function () {
        var _this = this;
        // Set num forms to 1 immediately to prevent double-clicking before actual number of forms is known
        this.backupNumForms = 1;
        this.backupProgress = 0;
        this.accountService.getUser().pipe(map(function (user) { return user.id; }), mergeMap(function (userId) { return _this.auditService.getAuditsByUser(userId); }), tap(function (audits) { return _this.backupNumForms = audits.length; }), tap(function () {
            if (_this.backupNumForms === 0)
                throw new Error(_this.translateService.instant('settings.backup-audits-none'));
        }), map(function (audits) { return audits.map(function (audit) { return _this.backupService.backup(audit).pipe(
        // Capture errors and flag as FALSE
        catchError(function (error) {
            return of(false);
        }), tap(function () { return _this.backupProgress++; }), map(function () { return true; })); }); }), mergeMap(function (observables) {
            return observables.length === 0 ? of([]) : forkJoin(observables);
        }), 
        // Count number of successful backups and create message
        map(function (results) { return results.filter(function (result) { return result; }).length; }), mergeMap(function (numSuccessful) {
            return _this.translateService.get('settings.backup-audits-complete', {
                num: numSuccessful,
            });
        })).subscribe(function (message) { return _this.snackbar.open(message, _this.translateService.instant('ok'), {
            'duration': 5000,
        }); }, function (error) {
            _this.logger.error('Backup error', error);
            _this.snackbar.open(error, _this.translateService.instant('ok'), {
                'duration': 5000,
            });
        });
    };
    SettingsComponent.prototype.checkAppVersion = function () {
        var _this = this;
        this.appVersionChecking = true;
        var subscription = this.appUpdateService.checkAppVersion().pipe(mergeMap(function (version) {
            var newVersionAvailable = !isNullOrUndefined(version);
            var interpolateParams = newVersionAvailable ? { 'version': version.app_version } : undefined;
            var buttonKey = newVersionAvailable ? 'login.update.update' : 'ok';
            var messageKey = newVersionAvailable ? 'login.update.update-available' : 'settings.check-app-version.no-update';
            return _this.translateService.get([messageKey, buttonKey], interpolateParams).pipe(map(function (result) { return [result[messageKey], result[buttonKey]]; })).pipe(tap(function () { return _this.appVersionChecking = false; }), mergeMap(function (result) {
                var message = result[0], button = result[1];
                return _this.snackbar.open(message, button).onAction().pipe(tap(function () { if (newVersionAvailable)
                    _this.appUpdateService.openAppStore(); }));
            }));
        }), catchError(function (error, responseObservable) {
            _this.logger.error(error);
            _this.appVersionChecking = false;
            return _this.snackbar.open(error.error.detail, _this.translateService.instant('ok'), { 'duration': 5000 }).onAction();
        })).subscribe();
        this.subscriptions.push(subscription);
    };
    SettingsComponent.prototype.saveSettings = function () {
        if (this.settings === undefined)
            this.logger.error('Cannot save settings: settings object is undefined');
        else
            this.settingsService.saveSettings(this.settings).subscribe();
    };
    return SettingsComponent;
}(BaseComponent));
export { SettingsComponent };
