var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { IssueFilter } from './filter';
import { of } from 'rxjs';
import { InstitutionService } from '../../../institution.service';
import { AuditFormService } from '../../../audit-form.service';
import { AccountService } from '../../../accounts.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base.component';
import { IssueService } from '../issue.service';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
var IssueFilterComponent = /** @class */ (function (_super) {
    __extends(IssueFilterComponent, _super);
    function IssueFilterComponent(injector, accountService, institutionService, auditFormService, issueService) {
        var _this = _super.call(this, injector) || this;
        _this.accountService = accountService;
        _this.institutionService = institutionService;
        _this.auditFormService = auditFormService;
        _this.issueService = issueService;
        _this.filter = new IssueFilter();
        _this.description$ = of('');
        _this.expanded = false;
        _this.filterChanges$ = new ReplaySubject();
        return _this;
    }
    IssueFilterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.filterChanges$.pipe(tap(function (filter) { return _this.filter = filter; }), tap(function () { return _this.updateDescription(); })).subscribe());
        this.subscriptions.push(this.issueService.loadFilters().pipe(tap(function (filter) { return _this.filter = filter; }), tap(function () { return _this.filterChanges$.next(_this.filter); })).subscribe());
        this.issueHandler$ = this.accountService.getUser().pipe(map(function (user) { return user.auditor || null; }), tap(function (handler) {
            if (handler === null) {
                _this.filter.assigned_to_me = false;
            }
        }));
        this.wardChoices$ = this.accountService.getUser().pipe(map(function (user) { return user.auditor; }), map(function (auditor) { return auditor === null ? [] : auditor.wards; }), mergeMap(function (wardIds) { return _this.institutionService.getWards(wardIds); }), tap(function (wards) {
            // Update selected ward to match option from the dropdown
            // fixes issue where selected item isnt matched beacuse it's not the same instance
            // and updates filter option in case it changed on the back-end in the meantime
            var selectedWard = _this.filter.ward;
            if (selectedWard !== null)
                _this.filter.ward = wards.find(function (w) { return selectedWard.id === w.id; }) || null;
        }));
        this.auditFormChoices$ = this.auditFormService.getAuditForms().pipe(map(function (forms) { return forms.filter(function (form) { return form.config.enable_qip; }); }), tap(function (forms) {
            // Update selected audit form to match option from the dropdown
            // fixes issue where selected item isnt matched beacuse it's not the same instance
            // and updates filter option in case it changed on the back-end in the meantime
            var auditForm = _this.filter.audit_form;
            if (auditForm !== null)
                _this.filter.audit_form = forms.find(function (form) { return form.id === auditForm.id; }) || null;
        }));
    };
    IssueFilterComponent.prototype.updateDescription = function () {
        var _this = this;
        this.description$ = this.translateService.get(['qip.filtering-options.assigned-to-me', 'qip.filtering-options.all-issues']).pipe(map(function (translations) {
            var filters = [];
            if (_this.filter.assigned_to_me)
                filters.push(translations['qip.filtering-options.assigned-to-me']);
            if (_this.filter.ward !== null)
                filters.push(_this.filter.ward.name);
            if (_this.filter.audit_form !== null)
                filters.push(_this.filter.audit_form.name);
            if (filters.length === 0)
                filters.push(translations['qip.filtering-options.all-issues']);
            return filters.join(', ');
        }));
    };
    IssueFilterComponent.prototype.onApplyClicked = function () {
        var _this = this;
        this.subscriptions.push(this.issueService.saveFilters(this.filter).pipe(
        // Ignore error when filter doesnt save
        catchError(function () { return of(); }), tap(function () {
            _this.filterChanges$.next(_this.filter);
            _this.expanded = false;
        })).subscribe());
    };
    return IssueFilterComponent;
}(BaseComponent));
export { IssueFilterComponent };
