/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./charts.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./chart/chart.component.ngfactory";
import * as i3 from "./chart/chart.component";
import * as i4 from "../audit-form/audit.service";
import * as i5 from "../institution.service";
import * as i6 from "../compliance/compliance.service";
import * as i7 from "../audit-form.service";
import * as i8 from "ngx-logger";
import * as i9 from "@angular/common";
import * as i10 from "./charts.component";
import * as i11 from "./dashboard.service";
var styles_ChartsComponent = [i0.styles];
var RenderType_ChartsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartsComponent, data: {} });
export { RenderType_ChartsComponent as RenderType_ChartsComponent };
function View_ChartsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-chart", [], null, null, null, i2.View_ChartComponent_0, i2.RenderType_ChartComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChartComponent, [i4.AuditService, i5.InstitutionService, i6.ComplianceService, i7.AuditFormService, i8.NGXLogger], { audit: [0, "audit"], widget: [1, "widget"], auditForm: [2, "auditForm"], auditFormSchema: [3, "auditFormSchema"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.audit; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.auditForm; var currVal_3 = _co.auditFormSchema; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ChartsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartsComponent_1)), i1.ɵdid(2, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.widgets; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChartsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-charts", [], null, null, null, View_ChartsComponent_0, RenderType_ChartsComponent)), i1.ɵdid(1, 114688, null, 0, i10.ChartsComponent, [i4.AuditService, i11.DashboardService, i8.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartsComponentNgFactory = i1.ɵccf("meg-charts", i10.ChartsComponent, View_ChartsComponent_Host_0, { audit: "audit", auditForm: "auditForm", auditFormSchema: "auditFormSchema" }, {}, []);
export { ChartsComponentNgFactory as ChartsComponentNgFactory };
