<div *ngIf="widget !== undefined" style="width:100%;">

  <div  *ngIf="widgetTypes.SIMPLE_COMPLIANCE === widget?.widget">
    <div class="compliance_container">
      <div class="compliance-background compliance-level-{{complianceLevel}}" *ngIf="widget?.config.show_meg === undefined || widget?.config.show_meg">
        <img [src]="complianceImage$|async" class="compliance-avatar">
      </div>
      <br>
      <span class="compliance_forms_ward">{{ 'charts.total-forms' | translate }} {{audit.auditSession.observations.length}}</span>
      <br>
      <span class="compliance_forms_ward">{{ward?.name}}</span>
      <h3>{{'charts.average-compliance' | translate}}</h3>
      <span class="compliance_score score-level-{{complianceLevel}}">{{ compliance | percentage }}</span>
    </div>
  </div>

  <div *ngIf="[widgetTypes.BAR, widgetTypes.PIE, widgetTypes.LINE, widgetTypes.SUB_FORM_BAR].indexOf(widget.widget) >= 0">
    <canvas #megCanvas>{{ chart }}</canvas>
    <div *ngFor="let label of megChartLabels, let i = index">
      <span [ngStyle]="{ 'color': label[1] }">{{label[0]}} </span>
    </div>
  </div>

  <div class="details-table"  *ngIf="widgetTypes.ANSWER_TABLE === widget?.widget">
    <h3>{{ widget?.title }}</h3>
    <i>{{ widget?.subtitle }}</i>
    <mat-table *ngIf="tableData.length > 0" #table [dataSource]="tableData">
      <!-- Title -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> {{getFieldName()}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.answer}} </mat-cell>
      </ng-container>
      <!-- No. Observations Column -->
      <ng-container matColumnDef="no_observations">
        <mat-header-cell *matHeaderCellDef> {{'charts.num-observations' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.no_observation}} </mat-cell>
      </ng-container>
      <!-- Compliance Column -->
      <ng-container matColumnDef="compliance">
        <mat-header-cell *matHeaderCellDef> {{'compliance' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.compliance | percentage}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let observation; let rowIndex = index; columns: displayedColumns;"></mat-row>

    </mat-table>
  </div>
</div>
