<mat-expansion-panel [expanded]="expanded" (opened)="expanded = true">
  <mat-expansion-panel-header>
    <mat-panel-title>{{'qip.filtering-options.title' | translate}}</mat-panel-title>
    <p>{{description$|async}}</p>
  </mat-expansion-panel-header>
  <form>
    <label>{{'qip.filtering-options.select-ward' | translate}}</label>
    <mat-select name="ward" placeholder="{{'qip.filtering-options.any-ward' | translate}}" [(ngModel)]="filter.ward">
      <mat-option [value]="null">{{'qip.filtering-options.any-ward'|translate}}</mat-option>
      <mat-option *ngFor="let ward of wardChoices$|async" [value]="ward">{{ ward.name }}</mat-option>
    </mat-select>
    <label>{{'qip.filtering-options.select-audit' | translate}}</label>
    <mat-select name="audit-form" placeholder="{{'qip.filtering-options.any-audit' | translate}}" [(ngModel)]="filter.audit_form">
      <mat-option [value]="null">{{'qip.filtering-options.any-audit'|translate}}</mat-option>
      <mat-option *ngFor="let auditForm of auditFormChoices$|async" [value]="auditForm">{{ auditForm.name }}</mat-option>
    </mat-select>
    <mat-checkbox *ngIf="issueHandler$|async" name="assigned_to_me" [(ngModel)]="filter.assigned_to_me">{{'qip.filtering-options.assigned-to-me' | translate}}</mat-checkbox>
    <mat-action-row>
      <button mat-raised-button color="primary" (click)="onApplyClicked()"><mat-icon>check</mat-icon> {{'qip.filtering-options.apply' | translate}}</button>
    </mat-action-row>
  </form>
</mat-expansion-panel>
