import { ApiService } from './api/api.service';
import { defaultIfEmpty, map, mergeMap, tap } from 'rxjs/operators';
import { AuditService } from './audit-form/audit.service';
import { QipService } from './qip/qip.service';
import { StorageService } from './storage.service';
import { forkJoin, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import * as i0 from "@angular/core";
import * as i1 from "./api/api.service";
import * as i2 from "./audit-form/audit.service";
import * as i3 from "./qip/qip.service";
import * as i4 from "./storage.service";
import * as i5 from "ngx-logger";
var BackupService = /** @class */ (function () {
    function BackupService(apiService, audits, qip, storage, logger) {
        this.apiService = apiService;
        this.audits = audits;
        this.qip = qip;
        this.storage = storage;
        this.logger = logger;
    }
    BackupService.prototype.uploadBackup = function (audit) {
        var _this = this;
        var backupId = audit.auditSession.backupId;
        if (backupId)
            return this.apiService.updateBackup(audit, backupId);
        else
            return this.apiService.createBackup(audit).pipe(mergeMap(function (response) {
                // Copy backup id for future updates
                audit.auditSession.backupId = response.id;
                return _this.audits.saveAudit(audit).pipe(map(function () { return response; }));
            }));
    };
    /**
     * Strips prefix generated by `getAuditPhotoKeyPrefix`
     *
     * @param key String starting with "audit-${audit.id}-issue-photos-", followed by a key
     * @return portion of the key string after the prefix
     */
    BackupService.prototype.stripAuditIdPrefixFromKey = function (key) {
        var token = '-issue-photos-';
        var pos = key.indexOf(token);
        if (pos > 0) {
            return key.substr(pos + token.length);
        }
        return key;
    };
    /**
     * Gets issue photos and their corresponding storage keys
     */
    BackupService.prototype.uploadIssuePhotos = function (audit, backupId) {
        var _this = this;
        return this.qip.getAuditPhotoKeys(audit).pipe(mergeMap(function (keys) {
            // Create observables that take photo for each key and upload it to the API
            var photos = keys.map(function (key) { return _this.storage.getItemOrDefault(key, []).pipe(mergeMap(function (value) { return _this.apiService.uploadBackupMedia(backupId, {
                key: _this.stripAuditIdPrefixFromKey(key),
                data: value,
            }); })); });
            // short-circuit of there are no photos to avoid forkJoin crash
            if (photos.length === 0)
                return of(0);
            return forkJoin.apply(void 0, photos).pipe(map(function (value) { return photos.length; }));
        }));
    };
    BackupService.prototype.backup = function (audit) {
        var _this = this;
        if (audit.auditSession.backupId)
            this.apiService.updateBackup(audit, audit.auditSession.backupId);
        return this.uploadBackup(audit).pipe(mergeMap(function (backup) { return _this.uploadIssuePhotos(backup.data, backup.id).pipe(tap(function (result) { return _this.logger.debug('Uploaded photo entries:', result); }), map(function (result) { return backup; })); }), map(function (backup) { return backup.data; }));
    };
    BackupService.prototype.restoreAuditMedia = function (backup) {
        var _this = this;
        return this.apiService.getBackupMedia(backup.id).pipe(
        // update storage keys in case audit id has changed (likely if audit was created on another device)
        map(function (media) { return media.map(function (m) {
            m.key = _this.qip.getAuditPhotoKeyPrefix(backup.data) + m.key;
            _this.logger.debug('Downloaded photos for key', m.key);
            return m;
        }); }), 
        // Save photos to storage
        mergeMap(function (media) {
            var observables = media.map(function (m) { return _this.storage.setItem(m.key, m.data); });
            return forkJoin(observables).pipe(defaultIfEmpty([]));
        }), map(function () { return true; }));
    };
    BackupService.ngInjectableDef = i0.defineInjectable({ factory: function BackupService_Factory() { return new BackupService(i0.inject(i1.ApiService), i0.inject(i2.AuditService), i0.inject(i3.QipService), i0.inject(i4.StorageService), i0.inject(i5.NGXLogger)); }, token: BackupService, providedIn: "root" });
    return BackupService;
}());
export { BackupService };
