import {Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {IssueFilter} from './filter';
import {Observable, of} from 'rxjs';
import {Ward} from '../../../api/models/ward';
import {AuditForm} from '../../../api/models/audit-form';
import {InstitutionService} from '../../../institution.service';
import {AuditFormService} from '../../../audit-form.service';
import {AccountService} from '../../../accounts.service';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {User} from '../../../api/models/user';
import {Auditor} from '../../../api/models/auditor';
import {BaseComponent} from '../../../base.component';
import {IssueHandler} from '../../../api/models/issue';
import {IssueService} from '../issue.service';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'meg-issue-filter',
  templateUrl: './issue-filter.component.html',
  styleUrls: ['./issue-filter.component.css']
})
export class IssueFilterComponent extends BaseComponent implements OnInit {
  public filter: IssueFilter = new IssueFilter();
  public wardChoices$: Observable<Ward[]>;
  public auditFormChoices$: Observable<AuditForm[]>;
  public description$ = of('');
  public expanded = false;
  public filterChanges$: Subject<IssueFilter> = new ReplaySubject();
  /* Current user's issue handler (if any) */
  public issueHandler$: Observable<Auditor | null>;

  constructor(
    injector: Injector,
    private accountService: AccountService,
    private institutionService: InstitutionService,
    private auditFormService: AuditFormService,
    private issueService: IssueService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.subscriptions.push(this.filterChanges$.pipe(
      tap((filter: IssueFilter) => this.filter = filter),
      tap(() => this.updateDescription()),
    ).subscribe());

    this.subscriptions.push(this.issueService.loadFilters().pipe(
      tap((filter: IssueFilter) => this.filter = filter),
      tap(() => this.filterChanges$.next(this.filter)),
    ).subscribe());


    this.issueHandler$ = this.accountService.getUser().pipe(
      map((user: User) => user.auditor || null),
      tap((handler: Auditor | null) => {
        if (handler === null) {
          this.filter.assigned_to_me = false;
        }
      }),
    );

    this.wardChoices$ = this.accountService.getUser().pipe(
      map((user: User): Auditor | null => user.auditor),
      map((auditor: Auditor): number[] => auditor === null ? [] : auditor.wards),
      mergeMap((wardIds: number[]): Observable<Ward[]> => this.institutionService.getWards(wardIds)),
      tap((wards: Ward[]) => {
        // Update selected ward to match option from the dropdown
        // fixes issue where selected item isnt matched beacuse it's not the same instance
        // and updates filter option in case it changed on the back-end in the meantime
        const selectedWard: Ward | null = this.filter.ward;
        if (selectedWard !== null) this.filter.ward = wards.find((w) => selectedWard.id === w.id) || null;
      }),
    );

    this.auditFormChoices$ = this.auditFormService.getAuditForms().pipe(
      map((forms: AuditForm[]): AuditForm[] => forms.filter((form: AuditForm): boolean => form.config.enable_qip)),
      tap((forms: AuditForm[]) => {
        // Update selected audit form to match option from the dropdown
        // fixes issue where selected item isnt matched beacuse it's not the same instance
        // and updates filter option in case it changed on the back-end in the meantime
        const auditForm: AuditForm | null = this.filter.audit_form;
        if (auditForm !== null) this.filter.audit_form = forms.find((form) => form.id === auditForm.id) || null;
      }),
    );
  }

  public updateDescription() {
    this.description$ = this.translateService.get(['qip.filtering-options.assigned-to-me', 'qip.filtering-options.all-issues']).pipe(
      map((translations): string => {
        const filters: string[] = [];
        if (this.filter.assigned_to_me) filters.push(translations['qip.filtering-options.assigned-to-me']);
        if (this.filter.ward !== null) filters.push(this.filter.ward.name);
        if (this.filter.audit_form !== null) filters.push(this.filter.audit_form.name);
        if (filters.length === 0) filters.push(translations['qip.filtering-options.all-issues']);
        return filters.join(', ');
      })
    );
  }

  public onApplyClicked() {
    this.subscriptions.push(this.issueService.saveFilters(this.filter).pipe(
      // Ignore error when filter doesnt save
      catchError(() => of()),
      tap(() => {
        this.filterChanges$.next(this.filter);
        this.expanded = false;
      }),
    ).subscribe());
  }

}
