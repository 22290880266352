import {Choice, FieldValidator} from '../../api/models/audit-form-schema';
import {AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {timer, Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {ApiService} from '../../api/api.service';
import {isNullOrUndefined} from '../../utils/misc';
import {switchMap} from 'rxjs/operators';


function pastDateValidator(): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    if (!c.value) return null;
    if (Date.parse(c.value) > Date.now()) {
      return {'pastdate': {'today': new Date().toDateString(), 'actual': c.value}};
    }
    return null;
  };
}

/**
 * A Generic validator that makes a request to backend to valiate value.
 * Used to validate uniqueness of a value, or fall back for unimplemented validators.
 */
function backendValidator(apiService: ApiService, validator: FieldValidator): AsyncValidatorFn {
  return (c: AbstractControl): Observable<ValidationErrors | null> => {
    if (isNullOrUndefined(c.value)) return of(null);

    return timer(500).pipe(
      switchMap(() =>  apiService.validateFormField(validator, c.value))
    );
  };
}

export function choiceValidator(choices: Choice[]): ValidatorFn {
  const allowedValues = choices.map((choice: Choice) => choice.value);
  return (c: AbstractControl): ValidationErrors | null => {
    if (!c.value || allowedValues.length === 0) return null;
    else if (allowedValues.indexOf(c.value) === -1) return {
      'invalid-choice': {
        'actual': c.value,
        'allowed': choices.map((choice: Choice) => choice.label).join(', '),
      },
    };
    else return null;
  };
}

export function integerValidator(): ValidatorFn {
  return (c: AbstractControl): ValidationErrors | null => {
    const validator = Validators.pattern('[0-9]+');
    if (!c.value) return null;
    else if (validator(c) !== null) return {
      'invalid-integer': {
        'actual': c.value,
      },
    };
    else return null;
  };
}

export const VALIDATOR_METHODS: {[name: string]: (...value: any[]) => ValidatorFn} = {
  'django.core.validators.MaxValueValidator': Validators.max,
  'django.core.validators.MinValueValidator': Validators.min,
  'django.core.validators.MaxLengthValidator': Validators.maxLength,
  'django.core.validators.MinLengthValidator': Validators.minLength,
  'django.core.validators.RegexValidator': Validators.pattern,
  'audit_builder.validators.PastDateValidator': pastDateValidator,
};

// Maps validators class to key in ValidationErrors object
export const VALIDATOR_KEYS: {[name: string]: string} = {
  'django.core.validators.MaxValueValidator': 'max',
  'django.core.validators.MinValueValidator': 'min',
  'django.core.validators.MaxLengthValidator': 'maxlength',
  'django.core.validators.MinLengthValidator': 'minlength',
  'django.core.validators.RegexValidator': 'pattern',
  'audit_builder.validators.PastDateValidator': 'pastdate',
  'choiceValidator': 'invalid-choice',
  'integerValidator': 'invalid-integer',
};

export function createErrorMessages(validators: FieldValidator[], errors: ValidationErrors, translateService: TranslateService): string[] {
  const result: { [key: string]: string } = {};

  // Create default error messages initially
  Object.keys(errors).forEach((key) => {
    const translationKey = `audit-form.validation.${key}`;
    let message = translateService.instant(translationKey, errors[key]);
    if (translationKey === message) {
      // Show generic error message if no message is specified for this validator in translations
      message = translateService.instant('audit-form.validation.unrecognized_error', {key: key});
    }
    result[key] = message;
  });

  // Override customized messages
  validators.filter((validator: FieldValidator) => validator.error_message && VALIDATOR_KEYS[validator.validator_class])
    .forEach((validator: FieldValidator) => {
      const key: string = VALIDATOR_KEYS[validator.validator_class];
      if (errors[key]) result[key] = <string>validator.error_message;
    });

  // Return result as a list
  return Object.keys(result).map((key) => result[key]);
}

/**
 * Takes validator spec from the feed and returns angular form validator function
 */
export function createFormFieldValidator(apiService: ApiService, fieldValidator: FieldValidator): ValidatorFn | AsyncValidatorFn | null {
  const validator: (...value: any[]) => ValidatorFn = VALIDATOR_METHODS[fieldValidator.validator_class];
  if (validator) return validator(fieldValidator.typed_value);
  else return backendValidator(apiService, fieldValidator);
}

function isAsyncValidator(validator: FieldValidator) {
  return VALIDATOR_METHODS[validator.validator_class] === undefined;
}

/**
 * Converts multiple validators to angular form validators array
 */
export function createFormFieldValidators(fieldValidators: FieldValidator[], async: boolean,
                                          apiService: ApiService): (ValidatorFn[]) | (AsyncValidatorFn[]) {
  const map: (ValidatorFn | AsyncValidatorFn | null)[] = fieldValidators
    .filter(((v: FieldValidator): boolean => isAsyncValidator(v) === async))
    .map((v: FieldValidator) => createFormFieldValidator(apiService, v), false);
  return <(ValidatorFn[]) | (AsyncValidatorFn[])>map.filter((v): boolean => v !== null);
}

/**
 * Validates that value is a model instance (contains id property)
 */
export function isModelValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (!value || value.id === undefined) return {model: {value: value}};
  return null;
}
