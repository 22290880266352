/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-version.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./app-version.component";
var styles_AppVersionComponent = [i0.styles];
var RenderType_AppVersionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppVersionComponent, data: {} });
export { RenderType_AppVersionComponent as RenderType_AppVersionComponent };
export function View_AppVersionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("app-version")); var currVal_1 = _co.appVersion; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_AppVersionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-app-version", [], null, null, null, View_AppVersionComponent_0, RenderType_AppVersionComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppVersionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppVersionComponentNgFactory = i1.ɵccf("meg-app-version", i3.AppVersionComponent, View_AppVersionComponent_Host_0, {}, {}, []);
export { AppVersionComponentNgFactory as AppVersionComponentNgFactory };
