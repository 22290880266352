import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectChange} from '@angular/material';

@Component({
  selector: 'meg-time-picker-dialog',
  templateUrl: './time-picker-dialog.component.html',
  styleUrls: ['./time-picker-dialog.component.css']
})
export class TimePickerDialogComponent implements OnInit {

  hourArray: number[] = Array.from(new Array(24), ( val: number, index: number) => index);
  minArray: number[] = Array.from(new Array(60), ( val: number, index: number) => index);

  hourValue = 0;
  minValue = 0;

  constructor(private dialogRef: MatDialogRef<TimePickerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string | null) {
    this.dialogRef.disableClose = true;
    this.parseTimeString(data);
  }

  ngOnInit() {}

  private parseTimeString(time: string | null) {
    if (time !== null) {
      const splitTime = time.split(':');
      if (splitTime.length === 2) {
        this.hourValue = Number(splitTime[0]);
        this.minValue = Number(splitTime[1]);
        return;
      }
    }
    const nowDate = new Date();
    this.hourValue = nowDate.getHours();
    this.minValue = nowDate.getMinutes();
  }

  onHourChange(event: MatSelectChange) {
    this.hourValue = event.source.value as number;
  }

  onMinChange(event: MatSelectChange) {
    this.minValue = event.source.value as number;
  }

  public closeDialog() {
    this.dialogRef.close(`${this.hourValue.addPaddedZero(2)}:${this.minValue.addPaddedZero(2)}`);
  }
}
