import { EventEmitter, ElementRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
var MegSearchableDropdownComponent = /** @class */ (function () {
    function MegSearchableDropdownComponent() {
        this.itemSearchBarControl = new FormControl('');
        this.selectedItemChange = new EventEmitter();
        this.selectionChange = new EventEmitter();
    }
    Object.defineProperty(MegSearchableDropdownComponent.prototype, "selectedItem", {
        get: function () {
            return this._selectedItem;
        },
        set: function (newValue) {
            this._selectedItem = newValue;
            this.selectedItemChange.emit(newValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MegSearchableDropdownComponent.prototype, "allValues", {
        get: function () {
            return this._allItems;
        },
        set: function (newValues) {
            this._allItems = newValues;
            this.itemSearchBarControl.setValue('');
        },
        enumerable: true,
        configurable: true
    });
    MegSearchableDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        /** Connect the MatSelect panel's options to the values of the searchbar and _allItems. */
        this.filteredItems = this.itemSearchBarControl.valueChanges.pipe(startWith(''), map(function (value) { return _this.searchbarValueChange(value); }));
    };
    MegSearchableDropdownComponent.prototype.searchbarPanelToggled = function (opened) {
        /**
         * Focus the searchbar when the MatSelect panel is opened so that the user may begin typing.
         * Reset the searchbar when the MatSelect panel is closed so that the user can use the Arrow keys over the full array of _allItems.
         */
        if (opened) {
            this.searchableDropdownSearchbar.nativeElement.focus();
        }
        else {
            this.itemSearchBarControl.setValue('');
        }
    };
    MegSearchableDropdownComponent.prototype.searchBarClicked = function (event) {
        /**
         * Handle the clicking of the searchbar so that it is not treated as an Option selection.
         * This appears to only work on the <input> HTML.
         */
        this.searchableDropdownSearchbar.nativeElement.focus();
        event.preventDefault();
        event.stopPropagation();
    };
    MegSearchableDropdownComponent.prototype.searchBarFocused = function () {
        /**
         * Set the searchbar as the MatSelect panel's focused item when the searchbar is focused.
         * This is necessary for ensuring Arrow key behaviours start from the searchbar, and for keeping the correct items visually highlighted.
         */
        this.searchableDropdownMatSelectObject._keyManager.setFirstItemActive();
    };
    MegSearchableDropdownComponent.prototype.searchbarValueChange = function (value) {
        var _this = this;
        /**
         * For the given value of the searchbar, return a subArray of _allItems whose string representation includes the searchbar value.
         * All of _allItems matches an empty string. An empty Array should be returned if _allItems is null.
         */
        var filterValue = value.toLowerCase();
        if (this._allItems) {
            if (filterValue) {
                if (this.selectedItem && this.itemToString(this.selectedItem) !== filterValue) {
                    this.selectedItem = null;
                }
                return this._allItems.filter(function (item) { return _this.itemToString(item).toLowerCase().includes(filterValue); });
            }
            else {
                return this._allItems.slice();
            }
        }
        else {
            return [];
        }
    };
    MegSearchableDropdownComponent.prototype.onSelectionChange = function (selectionEvent) {
        /** Propagate the MatSelect panel's change event to this component's subscriber(s) */
        this.selectionChange.emit(selectionEvent);
    };
    MegSearchableDropdownComponent.prototype.matSelectKeyboardEvents = function (event) {
        /**
         * If the user is navigating the open MatSelect panel with the arrow keys and moves back up to the searchbar,
         * refocus the searchbar so that they may resume typing in it.
         */
        var key = event.key;
        if (key === 'ArrowUp') {
            var activeItem = this.searchableDropdownMatSelectObject._keyManager.activeItem;
            if (activeItem && activeItem.viewValue === '' && this.searchableDropdownMatSelectObject.panelOpen) {
                this.searchableDropdownSearchbar.nativeElement.focus();
            }
        }
    };
    MegSearchableDropdownComponent.prototype.searchbarKeyboardEvents = function (event) {
        /**
         * Prevent certain KeyboardEvents from propagating.
         * Shift focus from searchbar to the MatSelect panel. This allows the user to navigate and select from the panel
         * with the keyboard when they use Arrow keys.
         */
        var key = event.key;
        var isTextControlKey = key === ' ' || key === 'Home' || key === 'End' || key === 'Enter' || (key >= 'a' && key <= 'z');
        if (key === 'ArrowDown' || key === 'ArrowUp') {
            this.searchableDropdownSearchbar.nativeElement.blur();
            this.searchableDropdownMatSelectObject.focus();
        }
        if (isTextControlKey) {
            event.stopPropagation();
        }
    };
    MegSearchableDropdownComponent.prototype.searchbarInnerHTML = function (item) {
        /**
         * Put bold tags around the matched substring of the dropdown item.
         * Has to use the innerHTML property to include html tags.
         * Uses substrings of the string representation of the dropdown item to preserve capitalisation.
         */
        var searchbarValue = this.itemSearchBarControl.value.toLowerCase();
        var itemString = this.itemToString(item);
        if (searchbarValue) {
            var searchableString = itemString.toLowerCase();
            var matchStartIndex = searchableString.indexOf(searchbarValue);
            var matchEndIndex = matchStartIndex + searchbarValue.length;
            var matchString = itemString.substring(matchStartIndex, matchEndIndex);
            return itemString.replace(matchString, '<b>$&</b>');
        }
        return itemString;
    };
    return MegSearchableDropdownComponent;
}());
export { MegSearchableDropdownComponent };
