<div class="widget-audio">
  <div *ngIf="mediaStatus !== MediaStatus.RECORDED" class="widget-audio-controller play">
    <button mat-mini-fab *ngIf="mediaStatus === MediaStatus.COMPLETED" color="primary" (click)="playRecording()"><mat-icon>play_arrow</mat-icon></button>
    <button class="pause" mat-mini-fab *ngIf="mediaStatus === MediaStatus.PLAYING" color="warn" (click)="pausePlayback()"><mat-icon>stop</mat-icon></button>
  </div>
  <div class="widget-audio-timer">
    <div class="audio-timer-status">
      <div *ngIf="mediaStatus == MediaStatus.RECORDING">
        <mat-icon color="warn" class="mic_position blink_text">mic</mat-icon>
      </div>
      <div *ngIf="mediaStatus !== MediaStatus.RECORDING">
        <mat-icon color="accent" class="mic_position">mic</mat-icon>
      </div>
    </div>
    <mat-progress-bar class="recording-status" mode="determinate" [value]="progressValue"></mat-progress-bar>
    <div class="audio-timer-start">
      0 <BR> {{'sec' | translate}}
    </div>
    <div class="audio-timer-remaining">
      <div *ngIf="mediaStatus === MediaStatus.PLAYING || mediaStatus === MediaStatus.READY || mediaStatus === MediaStatus.RECORDING">
        {{audioCurrentSecs | number : '1.1-1'}}<BR>{{'qip.audio-seconds-remaining' | translate}}
      </div>
      <div *ngIf="mediaStatus == MediaStatus.RECORDED || mediaStatus == MediaStatus.COMPLETED">
        {{audioLength | number : '1.1-1'}}<BR>{{'seconds' | translate}}
      </div>
    </div>
  </div>
  <div *ngIf="mediaStatus !== MediaStatus.RECORDED" class="widget-audio-controller record">
    <button class="rec" mat-mini-fab *ngIf="mediaStatus === MediaStatus.RECORDING" color="warn" (click)="stopRecording(); false;"><mat-icon>mic</mat-icon></button>
    <button class="mic" mat-mini-fab *ngIf="mediaStatus === MediaStatus.READY || mediaStatus === MediaStatus.COMPLETED" color="accent" (click)="startRecording(); false;"><mat-icon>mic</mat-icon></button>
  </div>
</div>
<div *ngIf="mediaStatus === MediaStatus.RECORDED">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
