import {AlertType} from './alert.component';
import {Observable} from 'rxjs/internal/Observable';

export class ModalButton {
  constructor(
    public label: Observable<string>,
    public onclick?: () => void,
    public icon?: string,
  ) {
  }

}

export class ModalAlertData {
  constructor(
    public title: Observable<string>,
    public content: Observable<any>,
    public alertType: AlertType,
    public positiveButton: ModalButton,
    public negativeButton?: ModalButton,
    public neutralButton?: ModalButton,
  ) {
  }
}
