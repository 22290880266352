import {Component, Input, OnInit} from '@angular/core';
import {Audit} from '../audit-form/audit';
import {AuditService} from '../audit-form/audit.service';
import {DashboardService} from './dashboard.service';
import {Dashboard, Widget} from '../api/models/dashboard';
import {AuditForm} from '../api/models/audit-form';
import {AuditFormSchema} from '../api/models/audit-form-schema';
import {mergeMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'meg-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css']
})
export class ChartsComponent implements OnInit {

  @Input() audit: Audit;
  @Input() auditForm: AuditForm;
  @Input() auditFormSchema: AuditFormSchema;

  public widgets: Widget[] = [];

  constructor(private auditService: AuditService,
              private dashboardService: DashboardService, private logger: NGXLogger) {
  }

  ngOnInit() {
    this.setupDashboard(this.audit.auditFormId);
  }

  private setupDashboard(auditFormId: number) {
    this.dashboardService.getDashboard(auditFormId).pipe(
      tap((dashboard) => this.logger.debug('Dashboard', dashboard)),
      mergeMap((dashboard) => {
        if (dashboard === null) return this.dashboardService.getHardcodedDashboard(this.auditForm.observation_model);
        else return of(dashboard);
      }),
      tap((dashboard) => this.logger.debug('Dashboard', dashboard)),
    ).subscribe(
      (dashboard: Dashboard | null) => {
        if (dashboard !== null) this.widgets = dashboard.widgets;
      }
    );
  }
}
