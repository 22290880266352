import {map, mergeMap, tap} from 'rxjs/operators';
import {ApiService} from './api.service';
import {TranslateService} from '@ngx-translate/core';

/**
 * Utility function to check if session token is still valid. if not valid, logs user out, redirects to login and displays an alert.
 */
export function validateSessionToken(api: ApiService, translateService: TranslateService) {
  api.checkAuthentication().pipe(
    mergeMap((authenticated: boolean) => translateService.get('session-expired').pipe(
      map((message: string) => [authenticated, message]),
    )),
    tap((result: [boolean, string]) => {
      const [authenticated, message] = result;
      if (!authenticated) alert(message);
    }),
  ).subscribe();
}
