<div class="messages-container">
  <h1>
    <button mat-button routerLink="/channels"><mat-icon>chevron_left</mat-icon></button>
    {{ (channel$|async)?.name }}
  </h1>
  <div class="message-list" #scrollContainer (scroll)="onScroll($event)">
    <mat-spinner *ngIf="messages === undefined"></mat-spinner>
    <mat-card *ngFor="let message of messages" class="mat-card {{message.is_sender ? 'message-sent' : 'message-received'}}">
      <mat-card-header>
        <mat-card-title> {{message.sender?.username || 'MEG'}} </mat-card-title>
        <mat-card-subtitle> {{message.created|date:'shortTime'}} </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p *ngIf="isSafeHtml(message)" [innerHTML]="message.content"></p>
        <p *ngIf="!isSafeHtml(message)" [innerText]="message.content"></p>
      </mat-card-content>
    </mat-card>
    <button class="buttonScrollToBottom" mat-fab color="accent" *ngIf="!scrollingToBottom" (click)="scrollToBottom()"><mat-icon>arrow_downward</mat-icon></button>
  </div>
  <div class="fixed-bottom" *ngIf="allowSendMessage">
    <textarea matInput matTextareaAutosize #message [disabled]="sending"></textarea>
    <button mat-mini-fab color="primary" [disabled]="sending || message.value === ''" (click)="sendMessage()"><mat-icon>send</mat-icon></button>
  </div>
</div>
