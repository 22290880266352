var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { DownloadService } from '../loading/download.service';
import { BaseComponent } from '../base.component';
import { MatDialogRef } from '@angular/material';
var DownloadAuditDialogComponent = /** @class */ (function (_super) {
    __extends(DownloadAuditDialogComponent, _super);
    function DownloadAuditDialogComponent(data, downloadService, injector, dialogRef) {
        var _this = _super.call(this, injector) || this;
        _this.data = data;
        _this.downloadService = downloadService;
        _this.injector = injector;
        _this.dialogRef = dialogRef;
        return _this;
    }
    DownloadAuditDialogComponent.prototype.ngOnInit = function () {
        this.logger.debug('Download audit dialog open', this.data);
        this.downloadForms();
    };
    DownloadAuditDialogComponent.prototype.downloadForms = function () {
        if (this.data.id)
            this.downloadForm(this.data.id);
        else
            this.downloadAllForms();
    };
    DownloadAuditDialogComponent.prototype.downloadAllForms = function () {
        var _this = this;
        this.downloadService.loadData().subscribe(function () { }, function (e) {
            _this.logger.error(e);
            alert(_this.translateService.instant('settings.refresh-audits-error'));
        }, function () { return _this.dialogRef.close(true); });
    };
    DownloadAuditDialogComponent.prototype.downloadForm = function (id) {
        var _this = this;
        this.logger.debug("Downloading form " + id);
        this.downloadService.downloadAuditForm(id, null).subscribe(function () { }, function (e) { return _this.logger.error(e); }, function () { return _this.dialogRef.close(true); });
    };
    return DownloadAuditDialogComponent;
}(BaseComponent));
export { DownloadAuditDialogComponent };
