import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {InformationService} from '../information.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';
import {AnalyticsService} from '../analytics.service';
import {ModelNameType} from '../api/models/analytics';

@Component({
  selector: 'meg-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription | null = null;
  title: string;
  @ViewChild('dataContainer') dataContainer: ElementRef;

  constructor(private route: ActivatedRoute, private informationService: InformationService, private analytics: AnalyticsService) { }

  ngOnInit() {
    this.routeSubscription = this.route.params.pipe(
      map((params): number => +params['information_id']),
      mergeMap(information_id => this.informationService.getInformationById(information_id)),
      tap(information => {
        this.title = information.title;
        this.analytics.trackButtonClick('Information Item');
        this.analytics.trackAction('View Information Item');
        this.analytics.trackModelAction(ModelNameType.Information, information.id);
      }),
    ).subscribe();
  }

  ngOnDestroy() {
    if (this.routeSubscription !== null) {
      this.routeSubscription.unsubscribe();
    }
    this.routeSubscription = null;
  }

}
