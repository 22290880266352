import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {AuditSchedule, CalendarDay, CalendarMonth} from '../api/models/calendar';
import {TranslateService} from '@ngx-translate/core';
import {map, mergeMap} from 'rxjs/operators';
import {AuditForm} from '../api/models/audit-form';
import {AuditFormService} from '../audit-form.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {of} from 'rxjs/internal/observable/of';
import {Ward} from '../api/models/ward';
import {InstitutionService} from '../institution.service';
import {Institution} from '../api/models/institution';
import {Department} from '../api/models/department';
import {tap} from 'rxjs/internal/operators/tap';

export class UpcomingAudit {
  schedule: AuditSchedule;
  auditForm: AuditForm;
  wardData?: [Institution, Department, Ward];
}

@Injectable()
export class CalendarService {
  // day names must be all lowercase as they're used as key for getting translations
  private weekDays: string[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  // months names must be all lowercase as they're used as key for getting translations
  private months: string[] = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  constructor(private apiService: ApiService, private translationService: TranslateService, private auditFormService: AuditFormService,
              private institutionService: InstitutionService) {}

  /**
   * Gets list of days for month view
   * @param year
   * @param month
   */
  public getMonthView(year: number, month: number): Observable<CalendarMonth> {
    return this.apiService.getCalendar(year, month).pipe(
      tap((result: CalendarMonth) => {
        // mark current day as today. Initially set to undefined.
        const today = new Date();
        let day_of_month: number;
        if (result.month - 1 === today.getMonth() && result.year === today.getFullYear()) day_of_month = today.getDate();
        result.days.forEach((day: CalendarDay) => day.is_today = (day.day_of_month === day_of_month));
      }),
    );
  }

  /**
   * Breaks list of days into list of weeks each containing a lit of days
   */
  public groupByWeek(month: CalendarMonth): (CalendarDay | null)[][] {
    const weeks: (CalendarDay | null)[][] = [];
    let week: (CalendarDay | null)[] = this.getMonthPadding(month);
    month.days.forEach((day) => {
      week.push(day);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
    });
    if (week.length) weeks.push(week);
    return weeks;
  }

  /**
   * Creates padding of empty days to ensure that month view starts on Monday
   * @param month
   * @return array of typed nulls to be prepended to days array
   */
  private getMonthPadding(month: CalendarMonth): (CalendarDay | null)[] {
    const date = this.getDate(month, month.days[0]);
    const firstDayOfWeek = date.getDay();
    const paddingSize = (firstDayOfWeek + 6) % 7;
    return new Array<CalendarDay>(paddingSize).map(() => null);
  }

  public getDate(month: CalendarMonth, day: CalendarDay) {
    return new Date(month.year, month.month - 1, day.day_of_month);
  }

  public createYearChoices(): number[] {
    const result: number[] = [];
    const lastYear = new Date().getFullYear() + 1;
    for (let i = 2015; i <= lastYear; i++) result.push(i);
    return result;
  }

  public getWeekDaysShort(): Observable<string[]> {
    const codes = this.weekDays.map((name: string) => `calendar.day-names-short.${name}`);
    return this.translationService.get(codes).pipe(
      map((translations: { [key: string]: string }) => codes.map((name: string) => translations[name])),
    );
  }

  public createMonthChoices(): Observable<[number, string][]> {
    const codes = this.months.map((name: string) => `calendar.month-names.${name}`);
    return this.translationService.get(codes).pipe(
      map((translations: {[key: string]: string}) => codes.map((code, i) => <[number, string]>[i + 1, translations[code]])),
    );
  }

  /*
  * Gets a list of audits scheduled for today and maps them to a container having the audit form */
  public getUpcomingAudits(): Observable<UpcomingAudit[]> {
    return this.apiService.fetchScheduledAudits().pipe(
      mergeMap((schedules: AuditSchedule[]): Observable<UpcomingAudit[]> => {
        const observables = schedules.map((schedule: AuditSchedule): Observable<UpcomingAudit> => {
          return this.auditFormService.getAuditForm(schedule.audit_form).pipe(
            map((auditForm: AuditForm): UpcomingAudit => {
              return {schedule: schedule, auditForm: auditForm};
            }),
            mergeMap((upcomingAudit: UpcomingAudit): Observable<UpcomingAudit> => {
              if (upcomingAudit.schedule.ward) {
                return this.institutionService.getWardData(upcomingAudit.schedule.ward).pipe(
                  map((wardData) => {
                    upcomingAudit.wardData = wardData;
                    return upcomingAudit;
                  }),
                );
              } else return of(upcomingAudit);
            }),
          );
        });
        if (observables.length === 0) return of([]);
        return forkJoin(observables);
      }),
    );
  }

}
