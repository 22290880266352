import {PhotoDialogComponent} from './qip/photo-dialog/photo-dialog.component';
import {filter} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material';

@Injectable()
export class CameraService {
  constructor(private logger: NGXLogger, private ngZone: NgZone, private dialog: MatDialog) {
  }

  /**
   * Opens relevant camera interface:
   * - for web app, open html view with web camera
   * - for native apps, opens cordova plugin camera
   */
  public takePhoto(): Observable<string> {
    const result = new Subject();
    const camera: any = (window.navigator as any).camera;
    if (camera === undefined) return this.openWebCameraDialog();
    else return this.openNativeCameraDialog();
  }

  /**
   * Opens cordova camera plugin.
   *
   * Use this method to capture photo in web app wrapped in apk (Cordova)
   * as web camera permission is not allowed for locally-served pages.
   */
  private openNativeCameraDialog(): Observable<string> {
    const observable: Subject<string> = new Subject<string>();
    const camera: any = (window.navigator as any).camera;
    this.logger.debug('Native camera plugin', camera);
    camera.getPicture((data: string) => {
      this.logger.debug('Image captured', data);
      const filename = `capture_${new Date()}.jpeg`;
      const uri = `data:image/jpeg;name=${filename};base64,${data}`;
      this.ngZone.run(() => {
        observable.next(uri);
        observable.complete();
      });
    }, (errorMessage: string) => {
      this.logger.error('Camera error', errorMessage);
    }, {
      quality: 80,
      destinationType: camera.DestinationType.DATA_URL,
      encodingType: camera.EncodingType.JPEG,
      mediaType: camera.MediaType.PICTURE,
      correctOrientation: true,
      targetWidth: 1080,
      targetHeight: 1080,
      allowEdit: false,
    });

    return observable;
  }

  /**
   * Opens web camera dialog.
   * This dialog will only work for web app server over https
   */
  private openWebCameraDialog(): Observable<string> {
    return this.dialog.open(PhotoDialogComponent).afterClosed().pipe(
      filter((result) => result !== false && result !== ''),
    );
  }
}
