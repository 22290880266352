<mat-form-field class="no-underline {{cssClass}}">
  <div class="container">
    <div class="container-child"  (focus)="picker.open(); false;" (click)="picker.open(); false;">
      <input matInput readonly
             [matDatepicker]="picker"
             [value]="datePart"
             (dateInput)="onDateInput('input', $event)">
      <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
    <div class="container-child" (focus)="showPicker(); false;" (click)="showPicker(); false;">
      <button mat-icon-button class="button-icon">
        <mat-icon matPrefix>access_time</mat-icon>
      </button>
      <input class="datetime-time-input" readonly matInput [value]="timePart">
    </div>
  </div>
</mat-form-field>
