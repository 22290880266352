import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { AccountService } from '../accounts.service';
import * as i0 from "@angular/core";
import * as i1 from "../accounts.service";
var InsightsService = /** @class */ (function () {
    function InsightsService(account) {
        var _this = this;
        this.account = account;
        var key = environment.appInsights.instrumentationKey;
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: key,
                enableAutoRouteTracking: true,
                autoTrackPageVisitTime: true,
                loggingLevelConsole: 2,
                loggingLevelTelemetry: 2,
                enableDebugExceptions: true,
            }
        });
        if (key) {
            this.appInsights.loadAppInsights();
            account.user$.subscribe(function (user) {
                if (user === null)
                    _this.appInsights.clearAuthenticatedUserContext();
                else {
                    _this.appInsights.setAuthenticatedUserContext(user.id.toString(), user.username, false);
                }
            });
        }
    }
    InsightsService.prototype.logException = function (exception, severityLevel) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    };
    InsightsService.prototype.logTrace = function (message, properties) {
        this.appInsights.trackTrace({ message: message }, properties);
    };
    InsightsService.prototype.trackPage = function (url, name) {
        this.appInsights.trackPageView({
            uri: url,
            name: name,
        });
    };
    InsightsService.prototype.trackLongEvent = function (name) {
        this.appInsights.startTrackEvent(name);
    };
    InsightsService.prototype.trackLongEventStop = function (name, properties) {
        this.appInsights.stopTrackEvent(name, properties);
    };
    InsightsService.ngInjectableDef = i0.defineInjectable({ factory: function InsightsService_Factory() { return new InsightsService(i0.inject(i1.AccountService)); }, token: InsightsService, providedIn: "root" });
    return InsightsService;
}());
export { InsightsService };
