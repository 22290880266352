import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LastModifiedService {

  constructor(private translateService: TranslateService) { }

  /**
   * Get last modified date with language code appended and Deletes old storage key.
   * @param keyName
   */
  public getLastModifiedKey(keyName: string): string {
    const language = this.translateService.currentLang;
    return `last-modified-${keyName}-${language}`;
  }
}
