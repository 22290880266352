import {Component, OnInit} from '@angular/core';
import {MessagingService} from '../messaging.service';
import {tap} from 'rxjs/internal/operators/tap';
import {Router} from '@angular/router';
import {UserIdName} from '../../api/models/basic-auditor';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs/internal/observable/of';
import {FormControl, Validators} from '@angular/forms';
import {catchError, map, switchMap} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {MatBottomSheetRef} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {isModelValidator} from '../../audit-form/observation/validators';

@Component({
  selector: 'meg-new-message-dialog',
  templateUrl: './new-message-dialog.component.html',
  styleUrls: ['./new-message-dialog.component.css']
})
export class NewMessageDialogComponent implements OnInit {
  public recipientControl = new FormControl('', [
    Validators.required,
    isModelValidator,
  ]);
  public content = '';
  public users$: Observable<UserIdName[]> = of([]);
  public sending = false;

  constructor(private messaging: MessagingService, private router: Router, private logger: NGXLogger,
              private bottomSheetRef: MatBottomSheetRef<NewMessageDialogComponent>, private translate: TranslateService) {
  }

  ngOnInit() {
    this.users$ = this.recipientControl.valueChanges.pipe(
      map((value: UserIdName | string) => typeof value === 'string' ? value : value.username),
      switchMap((value: string): Observable<UserIdName[]> => this.messaging.getContacts(value).pipe(
        tap((users: UserIdName[]) => {
          const user = users.find((u) => u.username === value);
          if (user) {
            this.logger.debug(`found user matching ${value}`, user);
            this.recipientControl.setValue(users[0], {emitEvent: false});
          }
        }),
      )),
    );
  }

  public renderUser(user?: UserIdName): string | undefined {
    const label = user ? user.username : undefined;
    return label || undefined;
  }

  public sendMessage() {
    const recipient = this.recipientControl.value;
    this.sending = true;
    this.messaging.sendP2pMessage(recipient.id, this.content).pipe(
      tap(channel => this.router.navigate(['channels', channel.id, 'messages'])),
      tap(() => this.bottomSheetRef.dismiss()),
      catchError((e) => {
        this.sending = false;
        this.logger.error(e);
        return this.translate.get('messaging.send-error').pipe(
          tap(message => alert(message))
        );
      }),
    ).subscribe();
  }
}
