import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Observable, of, Subscription, throwError} from 'rxjs';
import {AuditService} from '../audit-form/audit.service';
import {Audit} from '../audit-form/audit';
import {SubmissionService} from './submission.service';
import {BaseLoginRequiredComponent} from '../base';
import {AccountService} from '../accounts.service';
import {HttpErrorResponse} from '@angular/common/http';
import {STATUS_REQUEST_ERROR, STATUS_NOT_FOUND, STATUS_FORBIDDEN} from '../api/api.service';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {DownloadService} from '../loading/download.service';
import {AuditFormService} from '../audit-form.service';
import {NGXLogger} from 'ngx-logger';
import {AnalyticsService} from '../analytics.service';
import {DetailedError} from '../api/models/errors';

@Component({
  selector: 'meg-submission',
  templateUrl: './submission.component.html',
  styleUrls: ['./submission.component.css']
})
export class SubmissionComponent extends BaseLoginRequiredComponent implements OnInit, OnDestroy {
  public complete = false;
  public retryEnabled = true;
  private routeSubscription: Subscription | null = null;
  public errorMessage: string | null = null;
  public error: any = null;
  public audit: Audit;

  constructor(router: Router, route: ActivatedRoute, accountService: AccountService,
              private activatedRoute: ActivatedRoute,
              private auditService: AuditService,
              public submissionService: SubmissionService,
              private translationService: TranslateService,
              private analytics: AnalyticsService,
              private downloadService: DownloadService,
              private auditFormService: AuditFormService,
              logger: NGXLogger) {
    super(router, route, accountService, logger);
  }

  ngOnInit() {
    if (this.isLoggedIn()) {
      this.routeSubscription = this.activatedRoute.params.pipe(
        switchMap((params: Params) => {
          const auditId: number = +params['audit_id'];
          return this.auditService.getAudit(auditId);
        }),
        tap((audit: Audit) => this.audit = audit),
      ).subscribe(
        (audit: Audit) => this.submit(audit),
        (error: Error) => {
          this.translationService.get('audit-submission.submission.already-submitted').subscribe((message: string) => {
            this.errorMessage = message;
          });
          this.complete = true;
        }
      );
    }
  }

  public submit(audit: Audit) {
    this.complete = false;
    this.errorMessage = null;
    this.error = null;
    this.retryEnabled = true;
    // Reset current audit to hide audit counter from Toolbar
    this.auditService.setAuditId(null);

    this.submissionService.submitAudit(audit).pipe(
      mergeMap((result: boolean) => {
        if (!result) {
          return this.translationService.get(['error', 'audit-submission.submission.media-error' ]).pipe(
            tap((translations: {[id: string]: string}) => {
              this.error = translations['error'];
              this.errorMessage = translations['audit-submission.submission.media-error'];
            }),
            map(() => result),
          );
        } else {
          return of(result);
        }
      }),
      catchError((error: HttpErrorResponse, responseObservable: Observable<boolean>) => {
        if (error.status === STATUS_REQUEST_ERROR) {
          return this.auditFormService.getAuditFormSchemaLastModified(audit.auditFormId).pipe(
            mergeMap((lastModifiedDate: string | null) => this.downloadService.downloadAuditForm(audit.auditFormId, lastModifiedDate)),
          ).pipe(mergeMap(() => throwError(error)));
        } else {
          return throwError(error);
        }
      }),
    ).subscribe(
      () => {
        this.analytics.trackAction('Audit submitted');
      },
      (error: HttpErrorResponse) => {
        this.analytics.trackAction('Audit submission error');
        this.error = error;
        this.errorMessage = error.statusText;
        if (error.status === STATUS_REQUEST_ERROR) {
          this.logger.error('Submission request error', error);
          audit.errors = error.error;
          let translationKey = 'audit-submission.submission.request-error';

          const detailedErrors: DetailedError[] = error.error.non_field_detailed_errors;
          const containsError = (errors: string[]) => detailedErrors.map(x => x.code).filter(value => errors.includes(value)).length > 0;

          if (containsError(['invalid_ward'])) {
            translationKey = 'audit-submission.submission.unavailable-error';
          } else if (containsError(['invalid_fields'])) {
            translationKey = 'audit-submission.submission.fields-error';
            this.retryEnabled = false;
          } else if (containsError(['auto_cycle_not_complete'])) {
            translationKey = 'audit-submission.submission.auto-cycle-not-complete';
            this.retryEnabled = false;
          }

          this.translationService.get(translationKey).subscribe((message: string) => {
            this.errorMessage = message;
          });
        } else if (error.status === STATUS_NOT_FOUND || error.status === STATUS_FORBIDDEN) {
          // Will return 404 when audit form is not available or 403 when user doesn't have access to it
          this.logger.error('Submission request error', error);
          this.translationService.get('audit-submission.submission.unavailable-error').subscribe((message: string) => {
            this.errorMessage = message;
          });
        } else if (error.status === 0) {
          this.translationService.get('audit-submission.submission.network-error').subscribe((message: string) => {
            this.errorMessage = message;
          });
        } else {
          audit.errors = null;
          this.logger.error('Submission error', error);
        }
        // save audit to update errors and mark request as complete when finished
        this.auditService.saveAudit(audit).subscribe(() => this.complete = true);
      },
      () => this.complete = true,
    );
  }

  ngOnDestroy(): void {
    if (this.routeSubscription !== null) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = null;
    }
  }

  public logOut() {
    this.accountService.logOut(this.auditService, this.router);
  }
}
