import { Directive, ElementRef, ViewContainerRef, OnInit} from '@angular/core';
import { AccountService } from '../accounts.service';

declare var grecaptcha: any;
declare global {
    interface Window {
        reCaptchaLoad: any;
    }
}
@Directive({
    selector: '[megRecaptcha]'
})

export class RecaptchaDirective implements OnInit {
    private key = '6Lc3UW4aAAAAAMjNRB-tdJqU74EvbLApOL-AnUea';
    private widgetId: number;

    constructor(private accountService: AccountService, readonly element: ElementRef) {}

    ngOnInit() {
        this.addScript();
        this.renderRecaptchaWithCallback();
    }

    addScript(): void {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit&hl=en`;
        script.async = true;
        script.defer = true;
        script.id = 'captchaScript';
        const oldscript = window.document.getElementById('captchaScript');
        if (oldscript) {
            window.document.body.removeChild(oldscript);
        }
        window.document.body.appendChild(script);
    }

    renderRecaptchaWithCallback(): void {
        window.reCaptchaLoad = () => {
            const config = {
                'size' : 'normal',
                'tabindex' : 0,
                'sitekey': this.key,
                'callback': this.onSuccess.bind(this),
                'expired-callback': this.onExpired.bind(this)
            };
            this.widgetId = this.render(this.element.nativeElement, config);
        };
    }

    onSuccess(token: string): void {
        this.accountService.updateCaptchaToken(token);
    }

    onExpired(): void {
        this.accountService.clearCaptchaToken();
    }

    private render ( element: HTMLElement, config: any ): number {
        return grecaptcha.render(element, config);
    }
}
