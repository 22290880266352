import {Model} from './model';
import {ContentType} from './content-type';
import {Choice} from './audit-form-schema';
import {BasicAuditor} from './basic-auditor';
import {Term} from './institution';

export const OBSERVATION_MODEL_HAND_HYGIENE = 'handhygiene.models.HandHygieneAuditObservation';
export const OBSERVATION_MODEL_HAND_HYGIENE_UK = 'handhygiene_uk.models.HandHygieneUKAuditObservation';
export const OBSERVATION_MODEL_HAND_HYGIENE_US = 'handhygiene_us.models.HandHygieneUSAuditObservation';
export const OBSERVATION_MODEL_HIQA = 'generalenvironmental.models.HygieneEnvironmentAuditObservation';
export const OBSERVATION_MODEL_IPS = 'ipc.models.IPCAuditObservation';
export const OBSERVATION_MODEL_PVC = 'pvc.models.PeripheralVascularCatheterCareBundleAudit';
export const OBSERVATION_MODEL_CVC = 'cvc.models.CentralVascularCatheterCareBundleAudit';
export const OBSERVATION_MODEL_UCC = 'ucc.models.UrinaryCatheterCareBundleAudit';
export const OBSERVATION_MODEL_PVC_CVC = 'pvc_uk.models.PeripheralVascularCatheterCareUkObservation';
export const CUSTOM_OBSERVATION_NAME = 'audit_builder.models.CustomObservation';
export const CUSTOM_OBSERVATION_MODEL_NAME = 'customobservation';

export enum LayoutType {
  Default = 'default',
  Accordion = 'accordion',
}

export enum SubmissionDateRestriction {
  Everyone = 'everyone',
  LeadAuditors = 'lead',
  Nobody = 'nobody',
}


export class ConfigTerms {
  issue: Term;
  sub_issue: Term;
}

export class AuditFormConfig extends Model {
  pass_first: number;
  pass_second: number;
  enable_qip: boolean;
  backend_only_qip: boolean;
  question_only_qip: boolean;
  enable_session_comment: boolean;
  app_review_fields: string[];
  show_yes_to_all: number | null;
  show_na_to_all: number | null;
  form_layout: LayoutType;
  show_non_required_fields: boolean;
  autoselect: boolean;
  auto_cycle_field: string | null;
  wards: number[];
  require_signature: boolean;
  risk_levels: Choice[];
  show_action_taken: boolean;
  show_recommendation: boolean;
  enable_app_cc_reports: boolean;
  submission_date_restriction: SubmissionDateRestriction;
  enable_audio_recording: boolean;
  issue_handlers: number[];
  intro_note: string | null;
  help_text: string | null;
  save_next_observation: boolean;
  show_send_email_option: boolean;
  show_save_button: boolean;
  merge_submit_preview: boolean;
  enable_submission_for_all_wards: boolean;
  enable_answer_comments: boolean;
  terms: ConfigTerms;
  stages?: number;
}

export class AuditForm extends Model {
  name: string;
  slug: string;
  description: string;
  config: AuditFormConfig;
  observation_content_type: ContentType;
  observation_model: string;
  institution_ids: number[];
  auditors: BasicAuditor[];
  archive: boolean;
}
