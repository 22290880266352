<h1>{{'messaging.new-message' | translate}}</h1>

<mat-form-field>
  <input matInput #to [matAutocomplete]="auto" autocomplete="off" [placeholder]="'messaging.to' | translate" [formControl]="recipientControl"/>
  <mat-error *ngIf="recipientControl.errors?.model">
    {{'messaging.error-recipient' | translate}}
  </mat-error>
  <mat-autocomplete #auto [displayWith]="renderUser">
    <mat-option *ngFor="let user of users$|async" [value]="user">{{user.username}}</mat-option>
  </mat-autocomplete>
</mat-form-field>
<mat-form-field>
  <textarea matInput matTextareaAutosize #message [placeholder]="'messaging.message' | translate" [(ngModel)]="content" [disabled]="sending"></textarea>
</mat-form-field>
<button mat-button (click)="sendMessage()" [disabled]="sending || !message.value || recipientControl.invalid"><mat-icon>send</mat-icon> {{ 'messaging.send' | translate }}</button>
