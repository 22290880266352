import { AfterViewChecked, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MessagingService } from '../messaging.service';
import { CHANNEL_TYPE_PRIVATE, MESSAGE_FORMAT_HTML } from '../../api/models/messaging';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, map, mergeMap, retry } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { interval } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { TranslateService } from '@ngx-translate/core';
var MessageListComponent = /** @class */ (function () {
    function MessageListComponent(activatedRoute, messaging, logger, translation) {
        this.activatedRoute = activatedRoute;
        this.messaging = messaging;
        this.logger = logger;
        this.translation = translation;
        this.scrollingToBottom = true;
        this.checkInterval = 5000;
        this.sending = false;
        this.allowSendMessage = false;
    }
    Object.defineProperty(MessageListComponent.prototype, "channelId$", {
        get: function () {
            return this.activatedRoute.params.pipe(map(function (params) { return +params['channel_id']; }));
        },
        enumerable: true,
        configurable: true
    });
    MessageListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.channelId$.pipe(tap(function (channelId) {
            _this.channelId = channelId;
            _this.channel$ = _this.messaging.getChannel(channelId).pipe(tap(function (channel) { return _this.allowSendMessage = (channel.channel_type !== CHANNEL_TYPE_PRIVATE); }));
        }), mergeMap(function () { return _this.fetchMessages(); })).subscribe();
    };
    MessageListComponent.prototype.addMessages = function (messages) {
        var _a;
        if (this.messages === undefined)
            this.messages = messages;
        else if (messages.length === 0)
            return;
        else
            (_a = this.messages).push.apply(_a, messages);
        this.scrollingToBottom = true;
    };
    MessageListComponent.prototype.fetchMessages = function () {
        var _this = this;
        return this.messaging.getMessages(this.channelId).pipe(tap(function (messages) { return _this.addMessages(messages); }), tap(function () { return _this.timer = interval(_this.checkInterval).pipe(mergeMap(function () { return _this.checkNewMessages(); })).subscribe(); }));
    };
    MessageListComponent.prototype.checkNewMessages = function () {
        var _this = this;
        return this.messaging.getMessages(this.channelId, true).pipe(tap(function (messages) { return _this.addMessages(messages); }));
    };
    MessageListComponent.prototype.scrollToBottom = function () {
        try {
            var scrollElement = this.scrollContainer.nativeElement;
            scrollElement.scrollTop = scrollElement.scrollHeight;
        }
        catch (err) {
        }
    };
    MessageListComponent.prototype.isSafeHtml = function (message) {
        return message.format === MESSAGE_FORMAT_HTML;
    };
    MessageListComponent.prototype.ngAfterViewChecked = function () {
        if (this.scrollingToBottom)
            this.scrollToBottom();
    };
    MessageListComponent.prototype.onScroll = function (event) {
        this.scrollingToBottom = false;
    };
    MessageListComponent.prototype.ngOnDestroy = function () {
        if (this.timer) {
            this.timer.unsubscribe();
        }
    };
    MessageListComponent.prototype.sendMessage = function () {
        var _this = this;
        this.sending = true;
        var messageBox = this.messageInput.nativeElement;
        var message = messageBox.value;
        this.channelId$.pipe(mergeMap(function (id) { return _this.messaging.sendMessage(id, message); }), retry(3), catchError(function (e) {
            _this.sending = false;
            _this.logger.error(e);
            return _this.translation.get('messaging.send-error').pipe(map(function (msg) { return alert(msg); }));
        }), mergeMap(function () { return _this.checkNewMessages().pipe(retry(3), catchError(function () { return of(true); })); }), tap(function () { return _this.sending = false; }), tap(function () { return messageBox.value = ''; })).subscribe();
    };
    return MessageListComponent;
}());
export { MessageListComponent };
