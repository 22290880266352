import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, pattern: string = 'yyyy-MM-dd H:mm'): string | null {
    return super.transform(value, pattern);
  }
}
