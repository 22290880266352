import {Component, forwardRef, OnChanges, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {AnalyticsService} from '../../analytics.service';
import {TranslateService} from '@ngx-translate/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {isNullOrUndefined} from '../../utils/misc';

@Component({
  selector: 'meg-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimerComponent), multi: true}
  ],
})
export class TimerComponent implements ControlValueAccessor {
  public startTime: Date | null = null;
  public duration: string | null = null;
  private timerSubscription: Subscription | null = null;
  private formControl = new FormControl();

  private onChange = (value: number) => {};

  constructor(private analytics: AnalyticsService, private translateService: TranslateService) {
  }

  public start() {
    this.startTime = new Date();
    this.timerSubscription = timer(0, 1000).subscribe(() => this.updateDuration());
    this.analytics.trackButtonClick('Start Timer');
    this.analytics.trackAction('Timer input started');
  }

  public stop() {
    this.updateDuration();
    this.onChange(this.formControl.value);
    this.startTime = null;
    if (this.timerSubscription !== null) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
    this.analytics.trackButtonClick('Stop Timer');
    this.analytics.trackAction('Timer input stopped');
  }

  private updateDuration() {
    if (this.startTime !== null) {
      const duration: number = new Date().getTime() - this.startTime.getTime();
      const value = Math.floor(duration / 1000);
      this.formControl.setValue(value);
    }
    this.updateDisplay();
  }

  private updateDisplay() {
    const value = this.formControl.value;
    if (!isNullOrUndefined(value)) {
      const secondPlural = value === 1 ? this.translateService.instant('second') : this.translateService.instant('seconds');
      this.duration = `${value} ${secondPlural}`;
    } else {
      this.duration = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
    this.formControl.setValue(obj);
    this.updateDisplay();
  }
}
