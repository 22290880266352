<mat-form-field class="no-underline">
  <button matPrefix mat-icon-button class="button-icon" (click)="selectFile(); false;">
    <mat-icon>attach_file</mat-icon>
  </button>
  <button matPrefix mat-icon-button class="button-icon" (click)="takePhoto(); false;">
    <mat-icon>add_a_photo</mat-icon>
  </button>
  <input type="file" [formControl]="fileFormControl"
         [multiple]="multiple"
         (change)="onNativeInputFileSelect($event)" #inputFile hidden/>
  <input class="file-mat-input" readonly matInput
         [value]="valueLabel"
         (click)="selectFile(); false;"
  >
</mat-form-field>
