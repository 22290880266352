import {Component, OnInit} from '@angular/core';
import {MessagingService} from '../messaging.service';
import {Channel, CHANNEL_TYPE_GROUP, CHANNEL_TYPE_P2P} from '../../api/models/messaging';
import {Observable} from 'rxjs/internal/Observable';
import {tap} from 'rxjs/internal/operators/tap';
import {NewMessageDialogComponent} from '../new-message-dialog/new-message-dialog.component';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material';

@Component({
  selector: 'meg-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.css']
})
export class ChannelListComponent implements OnInit {
  channels: Channel[];

  constructor(private messaging: MessagingService, private bottomSheet: MatBottomSheet) {
  }

  public getChannelIcon(channel: Channel): string {
    if (channel.channel_type === CHANNEL_TYPE_GROUP) return 'groups';
    else if (channel.channel_type === CHANNEL_TYPE_P2P) return 'person';
    else return 'message';
  }

  ngOnInit() {
    this.messaging.getChannels().pipe(
      tap((channels) => this.channels = channels),
    ).subscribe();
  }

  public showNewMessageDialog() {
    this.bottomSheet.open(NewMessageDialogComponent);
  }
}
