// base urls
import {environment} from '../../environments/environment';

const BASE_API_V2 = '/api/v2/';

// API endpoints
export const API_LOGIN = `${BASE_API_V2}login/`;
export const API_LOGOUT = `${BASE_API_V2}logout/`;
export const API_TOKEN_LOGIN = `${BASE_API_V2}token-login/`;
export const API_INSTITUTIONS = `${BASE_API_V2}institution/`;
export const API_AUDIT_FORMS = `${BASE_API_V2}audit_form/`;
export const API_USERS = `${BASE_API_V2}user/`;
export const API_INFORMATION = `${BASE_API_V2}information/`;
export const API_DASHBOARD = `${BASE_API_V2}dashboard/?type=instant-report`;
export const API_ISSUE_HANDLERS = `${BASE_API_V2}issue_handler/`;
export const API_ISSUE_PHOTOS = `${BASE_API_V2}issuephoto/`;
export const API_AVATARS = `${BASE_API_V2}avatar/`;
export const API_AUDITORS = `${BASE_API_V2}auditor/`;
export const API_APP_VERSION = `${BASE_API_V2}app_version/current/`;
export const API_FEEDBACK = `${BASE_API_V2}feedback/`;
export const API_CALENDAR = `${BASE_API_V2}calendar`;
export const API_UPCOMING_AUDITS = `${BASE_API_V2}audit_schedule/upcoming/`;
export const API_LANGUAGES = `${BASE_API_V2}languages/`;
export const API_ISSUES = `${BASE_API_V2}issue/`;
export const API_AUTHENTICATION_CHECK = `${BASE_API_V2}auth-check/`;
export const API_CHANNELS = `${BASE_API_V2}channels/`;
export const API_CHANNELS_SEND_P2P_MESSAGE = `${API_CHANNELS}send_p2p_message/`;
export const API_ISSUES_STATUSES_CHOICES = `${BASE_API_V2}statuses/issue/`;

export const API_AUDIT_SESSION = `${BASE_API_V2}audit_session/`;
export const API_SUBMISSIONS_IN_PROGRESS = `${API_AUDIT_SESSION}in-progress/`;
export const API_ANALYTICS = `${BASE_API_V2}analytics/`;
export const API_REMOTE_FORM_FIELD_CHOICES = `${BASE_API_V2}remote-form-custom-field-choices/`;

// V3 API endpoints
const BASE_API_V3 = '/api/v3/';
export const API_AUDIT_FORMS_v3 = `${BASE_API_V3}audit_form/`;
export const API_VALIDATORS = `${BASE_API_V3}validators/`;
export const API_RELATED_FORM_DATA = `${BASE_API_V3}form_related_data/`;
export const API_BACKUP = `${BASE_API_V3}backup/`;

// Appended to backup url after backup id
export const API_BACKUP_MEDIA = '/media/';
export const API_BACKUP_UPLOAD_MEDIA = '/upload_media/';

// urls to be appended to API_AUDIT_FORMS after form id to fetch from schema, common issues etc.
export const AUDIT_FORM_SCHEMA = '/schema/';
export const AUDIT_FORM_COMMON_ISSUES = '/common_issues/';
export const AUDIT_FORM_SUBMIT = '/submit/';
export const AUDIT_FORM_CHECK_UPDATE = '/check_update/';
export const VALIDATORS_VALIDATE = '/validate/';

// other endpoints
export const MOBILE_FORGOT_PASSWORD = '/mobile_password_reset_username';
export const DASHBOARD_TOKEN_LOGIN = '/token-login';

export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LOCALE = 'en-GB';

// Channels sub-endpoints for messaging
// Example: /api/v2/channels/<id>/messages/
export const CHANNEL_MARK_READ = '/mark_read/';
export const CHANNEL_MESSAGES = '/messages/';
export const CHANNEL_UNREAD_MESSAGES = '/unread-messages/';
export const CHANNEL_SEND_MESSAGE = '/send_message/';

// Storage keys
export const DB_KEY_INFORMATIONS = 'information';

// documents sub-endpoints
export const DOCUMENTS_MANAGE = '/manage/list';
export const DOCUMENTS_VIEW = '/view/';
