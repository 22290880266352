/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-snackbar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "@angular/common";
import * as i5 from "./custom-snackbar.component";
import * as i6 from "@angular/material/snack-bar";
var styles_CustomSnackbarComponent = [i0.styles];
var RenderType_CustomSnackbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomSnackbarComponent, data: {} });
export { RenderType_CustomSnackbarComponent as RenderType_CustomSnackbarComponent };
function View_CustomSnackbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["color", "warn"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_1 = "warn"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.icon; _ck(_v, 2, 0, currVal_2); }); }
function View_CustomSnackbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["color", "accent"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var currVal_1 = "accent"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.icon; _ck(_v, 2, 0, currVal_2); }); }
export function View_CustomSnackbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomSnackbarComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomSnackbarComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "text-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.icon === "warning"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.icon !== "warning"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.message; _ck(_v, 7, 0, currVal_2); }); }
export function View_CustomSnackbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-custom-snackbar", [], null, null, null, View_CustomSnackbarComponent_0, RenderType_CustomSnackbarComponent)), i1.ɵdid(1, 114688, null, 0, i5.CustomSnackbarComponent, [i6.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomSnackbarComponentNgFactory = i1.ɵccf("meg-custom-snackbar", i5.CustomSnackbarComponent, View_CustomSnackbarComponent_Host_0, {}, {}, []);
export { CustomSnackbarComponentNgFactory as CustomSnackbarComponentNgFactory };
