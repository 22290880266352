import {AuditFormSchema, Field, FIELD_COMPLIANCE_IGNORE, FIELD_COMPLIANCE_REQUIRE, SubFormSchema} from '../api/models/audit-form-schema';
import {AuditSession} from '../api/models/audit-session';
import {Observation} from '../api/models/observation';
import {SimpleAnswer} from '../charts/charts_calculator';

/** Marks observation as incompliant */
export class Incompliant {
}

export const COMPLIANCE_COMPLIANT = 1.0;
export const COMPLIANCE_INCOMPLIANT = 0.0;
export const COMPLIANCE_INDETERMINATE = null;

/**
 * Calculates average (weighted) of multiple values by adding up individual values
 * and calculating average in getAverage() method
 */
export class AverageCalculator {
  constructor(private sum: number = 0, private weight: number = 0) {
  }

  /**
   * Add a single value to the calculator. If value is none, does nothing.
   * @param value the value, or None if unknown
   * @param weight the weight of the value in relation to other values in this calculator
   */
  public add(value: number | null, weight: number = 1) {
    if (value !== COMPLIANCE_INDETERMINATE && value !== undefined) {
      this.sum += value * weight;
      this.weight += weight;
    }
  }

  /**
   * Adds multiple items with the same weight
   * @param values a list of values to be added
   * @param weight each item's weight
   */
  public addMultiple(values: (number | null)[], weight: number = 1) {
    values.forEach((value: number | null) => this.add(value));
  }

  /**
   * Calculates average of all values (except null) passed to this calculator.
   * Returns null if no non-null values were passed in.
   */
  public getAverage(): number | null {
    if (this.weight === 0) return COMPLIANCE_INDETERMINATE;
    return this.sum / this.weight;
  }
}

export class ComplianceCalculator {
  /** List of fields used for compliance */
  private complianceFields: Field[];
  /**
   * List of subform fields used for compliance, mapped by the subform name
   * Only lists subforms that have compliance fields.
   * */
  private subFormComplianceFields: { [subformName: string]: Field[] } = {};

  constructor(private schema: AuditFormSchema) {
    const filterComplianceFields = (field: Field) => field.compliance_calculation !== FIELD_COMPLIANCE_IGNORE;
    this.complianceFields = schema.fields.filter(filterComplianceFields);
    schema.sub_forms.forEach((subform: SubFormSchema) => {
      const fields = subform.fields.filter(filterComplianceFields);
      if (fields.length > 0) this.subFormComplianceFields[subform.name] = fields;
    });
  }

  /**
   * Calculates average of values in the array
   * - null values are filtered out
   * - if value is
   * @param {(number | null)[]} values
   * @returns {number | null}
   */
  private static averageOrNull(values: (number | null)[]): number | null {
    const calc = new AverageCalculator();
    calc.addMultiple(values);
    return calc.getAverage();
  }

  /**
   * Calculates compliance for a single field given the field object and value entered by user.
   * @param {Field} field with its compliance information
   * @param {string | number | string[] | null} value Value entered by user
   * @returns {number | null} compliance value (between 0.0 and 1.0), or null if compliance is indeterminate
   */
  public calculateFieldCompliance(field: Field, value: string | number | null | string[] | undefined): number | null {
    if (Object.keys(field.answer_values).length === 0) return COMPLIANCE_INDETERMINATE;
    if (value === null || value === undefined) return field.required ? COMPLIANCE_INCOMPLIANT : COMPLIANCE_INDETERMINATE;
    let compliance: number | null;
    if (value instanceof Array) {
      // if answer is an array (multi choice), compliance is the average compliance of each selected choice
      const answerCompliances = value.map((v: string) => this.calculateFieldCompliance(field, v));
      compliance = ComplianceCalculator.averageOrNull(answerCompliances);
    } else {
      const valueString: string = value.toString();
      if (valueString === field.ignored_value) return COMPLIANCE_INDETERMINATE;
      compliance = field.answer_values[valueString];
      // compliance can be either null (by config), or undefined (missing in config)
      // if value is missing (undefined), it is deemed incompliant
      // if it's null, leave it null so the value is not take into account for compliance calculation
      if (compliance === undefined) compliance = COMPLIANCE_INCOMPLIANT;

    }
    if (compliance === COMPLIANCE_INCOMPLIANT && field.compliance_calculation === FIELD_COMPLIANCE_REQUIRE) throw new Incompliant();
    return compliance;
  }

  public calculateSubObservationCompliance(subObservation: { [fieldName: string]: any } | Array<{ [fieldName: string]: any }>,
                                            subForm: SubFormSchema): number | null {
    if (subObservation === undefined) return COMPLIANCE_INDETERMINATE;
    const fields: Field[] | undefined = this.subFormComplianceFields[subForm.name];
    // if this subform has no compliance fields
    if (fields === undefined) return COMPLIANCE_INDETERMINATE;

    const calc = new AverageCalculator();
    if (subObservation instanceof Array) {
      calc.addMultiple(subObservation.map((obs) => this.calculateSubObservationCompliance(obs, subForm)));
    } else {
      fields
        .map((field: Field) => {
          return [this.calculateFieldCompliance(field, subObservation[field.field_name]), field.compliance_weight];
        })
        .forEach((value: [number | null, number]) => {
          return calc.add(value[0], value[1]);
        });
    }

    return calc.getAverage();
  }


  /**
   * Calculates compliance for a single observation
   * @param observation observation observation object (maps field name to value entered by user)
   * @returns {number | null} compliance value between 0.0 (incompliant) and 1.0 (full compliance),
   * or null if compliance cannot be determined
   */
  public calculateObservationCompliance(observation: { [fieldName: string]: any }): number | null {
    try {
      const calc = new AverageCalculator();
      this.complianceFields.forEach((field: Field) => {
        const value = observation[field.field_name];
        const compliance = this.calculateFieldCompliance(field, value);
        calc.add(compliance, field.compliance_weight);
      });

      this.schema.sub_forms.forEach((subForm: SubFormSchema) => {
        const subObservation: { [fieldName: string]: any } | undefined | Array<{ [fieldName: string]: any }> = observation[subForm.name];
        if (subObservation !== undefined) {
          const compliance = this.calculateSubObservationCompliance(subObservation, subForm);
          calc.add(compliance);
        }
      });

      return calc.getAverage();
    } catch (e) {
      if (e instanceof Incompliant) return COMPLIANCE_INCOMPLIANT;
      else throw e;
    }
  }


  /**
   * Calculates fields compliance for single observation
   * @param {Field[]} fields The field to get compliance on or group observation compliance by
   * @param {Observation} observation
   * @return {[Field, number | null][]} returns list of tuples containing field with compliance
   */
  public calculateFieldsComplianceFromObservation(fields: Field[], observation: Observation): [Field, number][] {
    return fields.map((field: Field) => {
      const value = observation[field.field_name];
      let compliance = this.calculateFieldCompliance(field, value as SimpleAnswer);
      if (compliance !== null) compliance *= field.compliance_weight;
      return <[Field, number]> [field, compliance];
    }) as [Field, number][];
  }

  /**
   * Calculates compliance for the audit session.
   * @param {AuditSession} auditSession the session object
   * @returns {number | null} average compliance value between all observations in the session
   */
  public calculateSessionCompliance(auditSession: AuditSession): number | null {
    const compliances = auditSession.observations.map((observation) => this.calculateObservationCompliance(observation));
    return ComplianceCalculator.averageOrNull(compliances);
  }
}
