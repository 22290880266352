<section class="qip-create-issues-dialog">
  <div class="qip-create-issues-header"><h1 mat-dialog-title>{{'qip.new-issue' | translate:{'issue_label':issue_label | async} }}</h1></div>
  <mat-dialog-content class="custom-issue-content">
    <label>{{'qip.describe-issue' | translate:{'issue_label':issue_label | async} }}: *</label>
    <mat-form-field class="no-underline">
      <textarea matInput
                [(ngModel)]="issue.comment"
                spellcheck="true"
                autocomplete="true"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="4"
                (keydown.enter)="$event.target.blur();"></textarea>
    </mat-form-field>
    <meg-issue #issueComponent [issue]="issue" [audit]="audit"></meg-issue>
  </mat-dialog-content>
  <mat-dialog-actions class="qip-create-issues-actions">
    <button mat-raised-button color="primary" class="issue-btn-left dialog-btn-left" [mat-dialog-close]="true"><mat-icon>clear</mat-icon> {{'cancel' | translate}}</button>
    <button mat-raised-button color="accent" class="issue-btn-right dialog-btn-right" (click)="create()" [disabled]="!issue.comment"><mat-icon>check</mat-icon> {{'confirm' | translate}}</button>
  </mat-dialog-actions>
</section>
