<div class="avatar-centered-container">
  <img [src]="selectedAvatar?.image || defaultAvatarPath" class="selected-avatar">

  <div class="shadow-cover"></div>
  <div class="shadow-maker"></div>

  <mat-dialog-content>
    <mat-progress-spinner mode="indeterminate" *ngIf="avatars === null" class="avatars-progress"></mat-progress-spinner>
    <div class="avatars-container">
      <img *ngFor="let avatar of avatars" [src]="avatar.image" class="avatar-choice" [ngClass]="getAvatarClass(avatar)" (click)="onAvatarSelected(avatar)">
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button class="dialog-btn-left" color="primary" [mat-dialog-close]="false"><mat-icon>clear</mat-icon> {{'cancel' | translate}}</button>
    <button mat-raised-button class="dialog-btn-right" color="accent" [mat-dialog-close]="selectedAvatar" [disabled]="avatars === null"><mat-icon>check</mat-icon> {{'confirm' | translate}}</button>
  </mat-dialog-actions>
</div>
