<meg-field
  *ngIf="isField"
  [field]="field"
  [errors]="fieldErrors === null ? null : fieldErrors[field.field_name]"
  [form]="form"
  [observation]="observation"
  [audit]="audit"
  [auditForm]="auditForm"
  [auditFormSchema]="auditFormSchema"
  [subForm]="subForm"
  [ngClass]="field.required ? 'field-required' : 'field-optional'"
  [accordionIndex]="accordionIndex"
  [setValuePlaceholders]="setValuePlaceholders"
  [editing]="editing"
></meg-field>
<div *ngIf="!isField" class="meg-group">
  <meg-field
    *ngFor="let inlineField of field.fields"
    [field]="inlineField"
    [errors]="fieldErrors === null ? null : fieldErrors[inlineField.field_name]"
    [form]="form"
    [observation]="observation"
    [audit]="audit"
    [auditForm]="auditForm"
    [auditFormSchema]="auditFormSchema"
    [subForm]="subForm"
    [ngClass]="inlineField.required ? 'field-required columns cols-' + field.layout_columns : 'field-optional columns cols-' + field.layout_columns"
    [accordionIndex]="accordionIndex"
    [setValuePlaceholders]="setValuePlaceholders"
    [editing]="editing"
  ></meg-field>
</div>
