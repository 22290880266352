import {Component, OnInit} from '@angular/core';
import {CalendarService} from './calendar.service';
import {CalendarDay, CalendarMonth} from '../api/models/calendar';
import {MatDialog, MatSnackBar} from '@angular/material';
import {forkJoin, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AuditForm} from '../api/models/audit-form';
import {AuditFormService} from '../audit-form.service';
import {DayDialogComponent} from './day-dialog/day-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {validateSessionToken} from '../api/utils';
import {ApiService} from '../api/api.service';

@Component({
  selector: 'meg-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  public year: number;
  /** month number, 1 - based */
  public month: number;
  public monthName: string;
  public weeks: (CalendarDay | null)[][] | null = null;
  /** Maps audit form ids to respective audit form objects */
  private auditFormMap: { [id: number]: AuditForm; };
  public yearChoices: number[];
  public monthChoices: [number, string][] = [];
  public weekDays$: Observable<string[]>;
  private monthObj: CalendarMonth;

  constructor(private calendar: CalendarService, private auditFormService: AuditFormService, private snackBar: MatSnackBar,
              private dialog: MatDialog, private translateService: TranslateService, private logger: NGXLogger, private api: ApiService) {
    const date = new Date();
    this.year = date.getFullYear();
    this.month = date.getMonth() + 1;
  }

  ngOnInit() {
    this.yearChoices = this.calendar.createYearChoices();
    this.calendar.createMonthChoices().subscribe((choices) => this.monthChoices = choices);
    this.weekDays$ = this.calendar.getWeekDaysShort();

    validateSessionToken(this.api, this.translateService);
    this.loadData();
  }

  public loadData() {
    forkJoin(
      this.loadAudits(),
      this.fetchCalendar(),
    ).subscribe(
      () => {
      },
      (error: any) => {
        this.logger.error(error);
        this.weeks = [];
        this.snackBar.open(this.translateService.instant('calendar.load-error'), this.translateService.instant('retry'), {
          'duration': 5000,
        }).onAction().subscribe(() => this.loadData());
      }
    );
  }

  private loadAudits(): Observable<{[id: number]: AuditForm}> {
    return this.auditFormService.getAuditForms(null).pipe(
      map((auditForms: AuditForm[]) => {
        const result: {[id: number]: AuditForm} = {};
        auditForms.forEach((form) => result[form.id] = form);
        return result;
      }),
      tap((auditMap) => this.auditFormMap = auditMap),
    );
  }

  private fetchCalendar(): Observable<CalendarMonth> {
    this.weeks = null;
    return this.calendar.getMonthView(this.year, this.month).pipe(
      tap((month) => {
          this.monthObj = month;
          this.monthName = month.month_name;
          this.month = month.month;
          this.year = month.year;
          this.weeks = this.calendar.groupByWeek(month);
        },
      ));
  }

  public getAuditForm(id: number): AuditForm | null {
    return this.auditFormMap[id] || null;
  }

  public onDayClicked(day: CalendarDay | null) {
    if (day) {
      this.dialog.open(DayDialogComponent, {
        data: [day, this.monthObj],
      });
    }
  }

}
