import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {environment} from '../environments/environment';
import {tap} from 'rxjs/internal/operators/tap';
import {Region} from './api/models/region';
import {NGXLogger} from 'ngx-logger';

export class AppConfig {
  backend_url: string;
}

@Injectable()
export class AppConfigService {
  private appConfig: AppConfig;

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  loadAppConfig() {
    return this.http.get('assets/config.json').pipe(
      catchError(() => of({})),
      tap((data: AppConfig) => {
        // if file failed to load (file could be missing in dev ennv),
        // pre-populate some fields with build-time values
        if (!data.backend_url) data.backend_url = environment.baseURL;
      }),
      tap((data: AppConfig) => this.logger.debug('App config loaded', data)),
    ).toPromise().then(data => this.appConfig = data as AppConfig);
  }

  public getConfig() {
    return this.appConfig;
  }

  /**
   * Gets region targeted by this instance of the app.
   * This can be overridden after login if user belongs to another region.
   */
  public getDefaultRegion(): Region {
    return new Region(this.appConfig.backend_url);
  }
}
