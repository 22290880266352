<h1 class="title">{{'menu.settings' | translate}}</h1>

<mat-card class="limit-width center settings-card" [ngStyle]="{'animation-delay':'0.3s'}">
  <mat-card-title class="list-card-header"><p class="setting-title">{{'settings.text-size' | translate}}</p></mat-card-title>
  <mat-card-content class="setting-content setting-btn">
    <mat-radio-group [value]="settings?.scaleValue">
      <mat-radio-button *ngFor="let scaleLevel of scaleLevels$ | async" [value]="scaleLevel.value" (change)="changeZoom(scaleLevel)">
        {{ scaleLevel.name }}
      </mat-radio-button>
    </mat-radio-group>
  </mat-card-content>
</mat-card>

<mat-card class="limit-width center settings-card" [ngStyle]="{'animation-delay':'0.4s'}">
  <mat-card-title class="list-card-header"><p class="setting-title">{{'settings.refresh-audits-label' | translate}}</p></mat-card-title>
  <mat-card-content class="setting-content setting-btn">
    <mat-progress-bar *ngIf="downloadingForms" [mode]="downloadService.progress === 0 ? 'indeterminate' : 'determinate'" [value]="downloadService.progress"></mat-progress-bar>
    <button mat-button class="setting-btn" (click)="downloadForms()" [disabled]="downloadingForms"><mat-icon class="btn-icon">refresh</mat-icon> {{'settings.refresh-audits-btn' | translate}}</button>
  </mat-card-content>
</mat-card>

<mat-card class="limit-width center settings-card" [ngStyle]="{'animation-delay':'0.5s'}">
  <mat-card-title class="list-card-header"><p class="setting-title">{{'settings.audit-settings' | translate}}</p></mat-card-title>
  <mat-card-content class="setting-content">
    <mat-checkbox [checked]="settings?.showOptionalFields" (change)="changeShowOptionalFields($event)" align="end" matTooltip="{{'settings.optional-fields-tooltip' | translate}}">{{'settings.optional-fields-checkbox' | translate}}</mat-checkbox>
  </mat-card-content>
</mat-card>

<mat-card class="limit-width center settings-card" [ngStyle]="{'animation-delay':'0.6s'}">
  <mat-card-title class="list-card-header">
    <p class="setting-title">
      {{'settings.language-pref-label' | translate}}
      <mat-progress-spinner color="primary" *ngIf="prefLanguageUpdating" class="setting-language-spinner" mode="indeterminate" [diameter]="25"></mat-progress-spinner>
    </p>
  </mat-card-title>
  <mat-card-content class="setting-content">
    <mat-select *ngIf="settings" [value]="(user$|async).auditor.language" placeholder="{{'settings.language-pref-select'| translate}}" (selectionChange)="changeLanguageSelection($event)">
      <mat-option *ngFor="let language of availableLanguages" [value]="language.code">{{language.name}}</mat-option>
    </mat-select>
  </mat-card-content>
</mat-card>

<mat-card class="limit-width center settings-card" [ngStyle]="{'animation-delay':'0.7s'}">
  <mat-card-title class="list-card-header"><p class="setting-title">{{'settings.backup-audits' | translate}}</p></mat-card-title>
  <mat-card-content class="setting-content setting-btn">
    <mat-progress-bar *ngIf="backupNumForms > 0 && backupNumForms > backupProgress" mode="determinate" [value]="backupProgress / backupNumForms * 100"></mat-progress-bar>
    <button mat-button class="setting-btn" (click)="backupAudits()" [disabled]="backupNumForms > 0"><mat-icon class="btn-icon">backup</mat-icon> {{'settings.backup-audits-btn' | translate}}</button>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="appUpdateService.isCordovaBuild" class="limit-width center settings-card" [ngStyle]="{'animation-delay':'0.8s'}">
  <mat-card-title class="list-card-header"><p class="setting-title">{{'settings.check-app-version.label' | translate}}: {{appData.app_version}}</p></mat-card-title>
  <mat-card-content class="setting-content setting-btn">
    <mat-progress-bar *ngIf="appVersionChecking" mode="indeterminate"></mat-progress-bar>
    <button mat-button class="setting-btn" (click)="checkAppVersion()" [disabled]="appVersionChecking"><mat-icon class="btn-icon">refresh</mat-icon> {{'settings.check-app-version.btn' | translate}}</button>
  </mat-card-content>
</mat-card>

<meg-app-version></meg-app-version>
