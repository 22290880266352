import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material';
import {AlertComponent, AlertType} from '../alert.component';
import {ModalButton, ModalAlertData} from '../modal-alert-data';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/internal/Observable';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    public dialog: MatDialog,
    private translateService: TranslateService,
  ) { }

  /**
   * Opens custom modal with callback function.
   */
  public openCustomModal<T>(data: ModalAlertData): Observable<ModalButton> {
    return this.dialog.open(AlertComponent, {data: data}).afterClosed();
  }

  public openWarningModal<T>(message: Observable<string>, btnPositive?: ModalButton,
                             btnNegative?: ModalButton, btnNeutral?: ModalButton): Observable<ModalButton> {
    if (!btnPositive) btnPositive = this.btnOK();
    return this.openCustomModal(
      new ModalAlertData(this.translateService.get('warning'), message, AlertType.WARNING, btnPositive, btnNegative, btnNeutral));
  }

  public openErrorModal<T>(message: Observable<string>, btnPositive?: ModalButton,
                             btnNegative?: ModalButton): Observable<ModalButton> {
    if (!btnPositive) btnPositive = this.btnOK();
    return this.openCustomModal(
      new ModalAlertData(this.translateService.get('error'), message, AlertType.ERROR, btnPositive, btnNegative));
  }

  public openInfoModal<T>(message: Observable<string>, btnPositive?: ModalButton,
                             btnNegative?: ModalButton, btnNeutral?: ModalButton): Observable<ModalButton> {
    if (!btnPositive) btnPositive = this.btnOK();
    return this.openCustomModal(
      new ModalAlertData(this.translateService.get('info'), message, AlertType.INFO, btnPositive, btnNegative, btnNeutral));
  }

  public btnOK(onclick?: () => void): ModalButton {
    return new ModalButton(this.translateService.get('ok'), onclick, 'check');
  }

  public btnCancel(onclick?: () => void): ModalButton {
    return new ModalButton(this.translateService.get('cancel'), onclick, 'close');
  }
}
