import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {

  transform(value: number | null): string {
    if (value === null || isNaN(value)) {
      return `-`;
    } else {
      return `${(value * 100).toFixed(0)}%`;
    }
  }

}
