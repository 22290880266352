import {SubObservation} from '../../api/models/observation';
import {Field, SubFormSchema} from '../../api/models/audit-form-schema';
import {Errors} from '../../api/models/errors';

/**
 * Holds accordion item data inside observation component.
 * Used to bind sub-observation to its subform
 */
export class AccordionItem {

  constructor(public subform: SubFormSchema, public subObservation: SubObservation,
              public fieldErrors: Errors | null = null) {
  }

  public toString(): string {
    return this.subform.display_name;
  }

  /**
   * Whether this accordion should have "Add Another" button
   * @returns {boolean}
   */
  public get showAddAnotherBtn(): boolean {
    return this.subform.many;
  }

  public get description(): string {
    const fields = this.subform.fields;
    // if subform has no questions, return empty string
    if (fields.length === 0) return '';
    const firstFieldName: string = fields[0].field_name;
    let value = this.subObservation[firstFieldName];
    const field: Field | undefined = this.subform.fields.find(f => f.field_name === firstFieldName);
    if (field && field.choices) {
      const choice = field.choices.find(c => c.value === value);
      if (choice) value = choice.label;
    }
    return (value || '').toString();
  }
}
