import {ErrorHandler, Injectable} from '@angular/core';
import {InsightsService} from './insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private insights: InsightsService) {
    super();
  }

  handleError(error: any) {
    this.insights.logException(error);
  }
}
