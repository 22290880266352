import {Component, Injector, Input, OnInit} from '@angular/core';
import {Answer, Observation} from '../../api/models/observation';
import {Audit} from '../../audit-form/audit';
import {AuditFormSchema, Field, SubFormSchema} from '../../api/models/audit-form-schema';
import {AuditForm, LayoutType} from '../../api/models/audit-form';
import {Router} from '@angular/router';
import {Errors} from '../../api/models/errors';

import {isObjectEmpty} from '../../utils/misc';
import {BaseComponent} from '../../base.component';
import {Hints} from '../../hint.service';


export interface Column {
  columnDef: string;
  header: string;
}

@Component({
  selector: 'meg-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent extends BaseComponent implements OnInit {

  @Input() audit: Audit;
  @Input() auditFormSchema: AuditFormSchema;
  @Input() auditForm: AuditForm;

  columns: Column[] | null = null;

  displayedColumns: string[] | null = null;

  constructor(private router: Router, private injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.buildTableColumns();
  }

  private buildTableColumns() {
    this.translateService.get(['form', 'subform']).subscribe((translations: {[id: string]: string}) => {
      this.columns = [];
      if (this.auditForm.config.app_review_fields.length > 0) {
        for (const appReviewField of this.auditForm.config.app_review_fields) {
          const fieldName = appReviewField.split('.').pop();
          let field: Field | undefined = this.auditFormSchema.fields.find((f) => f.field_name === fieldName);
          if (field !== undefined) {
            this.columns.push(
              {columnDef: field.field_name, header: field.label},
            );
          } else {
            for (const subForm of this.auditFormSchema.sub_forms) {
              field = subForm.fields.find((f) => f.field_name === fieldName);
              if (field !== undefined) {
                this.columns.push(
                  {columnDef: field.field_name, header: field.label},
                );
              }
            }
          }
        }
      } else if (this.auditFormSchema.sub_forms.length > 0 && this.auditForm.config.form_layout === LayoutType.Default) {
        this.columns.push(
          { columnDef: 'sub_form',   header: translations['subform']},
        );
      } else {
        this.columns.push(
          { columnDef: 'form',   header: translations['form']},
        );
      }
      /**
       * list of values for columns in the table, position is hardcoded to display the number and cell_error if that observation has errors
       * @type {(string | any)[]}
       */
      this.displayedColumns = ['position', ...this.columns.map(c => c.columnDef), 'cell_error'];
    });
  }

  private isAccordionLayout(): boolean {
    return this.auditForm.config.form_layout === LayoutType.Accordion;
  }

  private getSubFormName(observation: Observation): string {
    return this.auditFormSchema.sub_forms
      .filter(subForm => (observation[subForm.name] !== undefined))
      .map((subForm: SubFormSchema) => subForm.display_name)
      .join(',');
  }

  /**
   * Returns a Answer or null for the cell from current observation row and field name
   * @param observation: Observation, current observation row
   * @param fieldName: String for field name
   */
  public getCellValue(observation: Observation, fieldName: string): Answer | null {
    if (this.auditForm.config.app_review_fields.length > 0) {
      for (const subForm of this.auditFormSchema.sub_forms) {
        const subObservation = observation[subForm.name];
        if (subObservation !== undefined) {
          if ((subObservation as Observation)[fieldName] as Answer) {
            return (subObservation as Observation)[fieldName] as Answer;
          }
        }
      }
      if (observation[fieldName] as Answer) {
        return observation[fieldName] as Answer;
      }

    } else if (this.auditFormSchema.sub_forms.length > 0 && this.auditForm.config.form_layout === LayoutType.Default) {
      return this.getSubFormName(observation);
    } else {
      return this.auditForm.name;
    }
    return null;
  }

  public onRowClicked(observation: {[fieldName: string]: any}, rowIndex: number) {
    this.hintService.markShown(Hints.ObservationEdit);
    if (this.auditFormSchema === undefined || this.audit === undefined) {
      throw Error('error loading audit form');
    } else if (!this.audit.submitted) {
      const subForm = this.auditFormSchema.sub_forms.find((subform: SubFormSchema) => observation[subform.name] !== undefined);
      if (subForm !== undefined && !this.isAccordionLayout()) {
        this.router.navigate([`audit-form`, this.audit.id, `subform`, subForm.name, 'edit', rowIndex]);
      } else {
        this.router.navigate([`audit-form`, this.audit.id, 'edit', rowIndex]);
      }
    }
  }

  /**
   * non-field errors in the observation
   * @param index index of the observation in session
   */
  public getObservationErrors(index: number): string[] {
    const errors = this.audit.errors;
    if (!errors) return [];
    const observationErrors: Errors = (errors.observations as Errors[])[index] as Errors;
    if (!observationErrors) return [];
    const result: string[] = [];
    if (observationErrors.non_field_errors) result.push(...observationErrors.non_field_errors);
    this.auditFormSchema.sub_forms.forEach((subform) => {
      const subformErrors = observationErrors[subform.name] as Errors;
      if (subformErrors && subformErrors.non_field_errors) {
        result.push(...subformErrors.non_field_errors);
      }
    });
    result.forEach(this.logger.log);
    return result;
  }

  public hasObservationErrors(index: number): boolean {
    if (this.audit.errors === null) {
      return false;
    } else if (this.audit.errors.observations !== undefined) {
      const observationErrors: Errors = (this.audit.errors.observations as Errors[])[index];
      return !isObjectEmpty(observationErrors);
    } else {
      return false;
    }
  }
}

