import {CommonIssue} from '../../api/models/common-issue';
import {Issue} from '../../api/models/issue';
import {Audit} from '../../audit-form/audit';
import {Errors} from '../../api/models/errors';
import {Observable} from 'rxjs';

export class IssueDialogArguments {
  constructor(public issues: Issue[], public audit: Audit, public commonIssues: CommonIssue[] = [],
              public field_name: string | null = null, public errors: Errors[] = [],
              public showNewIssueButton: Boolean = true, public title: string = 'qip.issues',
              public hideUnselectedCommonIssues: Boolean = false,
              public issue_label: Observable<string> | null = null, public issue_label_plural: Observable<string> | null = null,
              ) {
  }
}
