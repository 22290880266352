import {Component, Inject, Injector, OnInit} from '@angular/core';
import {DownloadService} from '../loading/download.service';
import {BaseComponent} from '../base.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'meg-download-audit-dialog',
  templateUrl: './download-audit-dialog.component.html',
  styleUrls: ['./download-audit-dialog.component.css']
})
export class DownloadAuditDialogComponent extends BaseComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public downloadService: DownloadService,
    private injector: Injector,
    private dialogRef: MatDialogRef<boolean>,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.logger.debug('Download audit dialog open', this.data);
    this.downloadForms();
  }

  public downloadForms() {
    if (this.data.id) this.downloadForm(this.data.id);
    else this.downloadAllForms();
  }

  private downloadAllForms() {
    this.downloadService.loadData().subscribe(
      () => {},
      (e) => {
        this.logger.error(e);
        alert(this.translateService.instant('settings.refresh-audits-error'));
      },
      () => this.dialogRef.close(true),
    );
  }

  private downloadForm(id: number) {
    this.logger.debug(`Downloading form ${id}`);
    this.downloadService.downloadAuditForm(id, null).subscribe(
      () => {},
      (e) =>  this.logger.error(e),
      () => this.dialogRef.close(true),
    );
  }

}
