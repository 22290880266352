var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from './alert/service/modal.service';
import { ModalButton } from './alert/modal-alert-data';
import { AccountService } from './accounts.service';
import { AuditService } from './audit-form/audit.service';
import { catchError, take } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
var IdleService = /** @class */ (function () {
    function IdleService(idle, keepalive, accountService, router, auditService, translateService, modalService) {
        this.idle = idle;
        this.keepalive = keepalive;
        this.accountService = accountService;
        this.router = router;
        this.auditService = auditService;
        this.translateService = translateService;
        this.modalService = modalService;
        /**
         * duration in seconds until idle modal pops up
         */
        this.idleDuration = 240;
        /**
         * duration in seconds after popup appears to log out user, after idleDuration + timeoutDuration seconds of inactivity,
         * the user will be considered timed out.
         */
        this.timeoutDuration = 60;
        /**
         * interval in seconds between keepalive pings
         */
        this.keepaliveInterval = 15;
        this.timedOut = false;
        this.lastPing = undefined;
        this.currentCountDown = 0;
        this.dialogRef = undefined;
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    }
    IdleService.prototype.logOut = function () {
        this.accountService.logOut(this.auditService, this.router);
    };
    IdleService.prototype.startWatching = function () {
        var _this = this;
        this.idle.onTimeoutWarning.subscribe(function (countdown) {
            _this.currentCountDown = countdown;
        });
        this.idle.onIdleEnd.subscribe(function () {
            // No longer idle
            _this.reset();
        });
        this.idle.onTimeout.subscribe(function () {
            // On Timeout logout the user and close the dialog
            _this.timedOut = true;
            _this.modalService.dialog.closeAll();
            _this.logOut();
        });
        this.idle.onIdleStart.subscribe(function () {
            // Going Idle
            if (_this.dialogRef) {
                // Prevent The popup from appearing twice
                return;
            }
            _this.dialogRef = _this.modalService.openWarningModal(_this.translateService.get('idle.prompt'), new ModalButton(_this.translateService.get('menu.logout'), function () { return _this.logOut(); }, 'exit_to_app'), new ModalButton(_this.translateService.get('idle.stay'), undefined, 'close'));
            _this.dialogRef.pipe(take(1)).subscribe(function () {
                _this.dialogRef = undefined;
            });
        });
        this.keepalive.onPing.subscribe(function () { return _this.lastPing = new Date(); });
        this.accountService.currentUser$.subscribe(function (user) { return __awaiter(_this, void 0, void 0, function () {
            var passwordPolicy;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.accountService.getPasswordPolicy().pipe(take(1), catchError(function (e) { return of(null); })).toPromise()];
                    case 1:
                        passwordPolicy = _a.sent();
                        this.idle.stop();
                        if (user && passwordPolicy && passwordPolicy.session_inactivity) {
                            this.setOptions(passwordPolicy.session_inactivity);
                            this.idle.watch();
                            this.timedOut = false;
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    IdleService.prototype.setOptions = function (idleDuration, timeoutDuration, keepaliveInterval) {
        if (idleDuration === void 0) { idleDuration = 240; }
        if (timeoutDuration === void 0) { timeoutDuration = 60; }
        if (keepaliveInterval === void 0) { keepaliveInterval = 15; }
        this.idleDuration = idleDuration;
        this.timeoutDuration = timeoutDuration;
        this.keepaliveInterval = keepaliveInterval;
        this.idle.setIdle(idleDuration);
        this.idle.setTimeout(this.timeoutDuration);
        this.keepalive.interval(this.keepaliveInterval);
    };
    IdleService.prototype.reset = function () {
        this.idle.watch();
        this.timedOut = false;
        this.currentCountDown = 0;
    };
    return IdleService;
}());
export { IdleService };
