import {HostListener, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AccountService} from '../accounts.service';
import {User} from '../api/models/user';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  constructor(private logger: NGXLogger, private accounts: AccountService) {
    accounts.currentUser$.subscribe(user => this.updateUserTags(user));
  }

  private updateUserTags(user: User | null) {
    const UAirship = (window as any).UAirship;
    if (UAirship === undefined) return;
    if (user) {
      UAirship.setNamedUser(`${user.id}:${user.username}`);
    } else {
      UAirship.setNamedUser('');
    }
    this.logger.debug('Updated named user', user);
  }

  public setupPushNotifications() {
    this.logger.debug('Setting up Push notifications');
    const UAirship = (window as any).UAirship;
    if (UAirship === undefined) {
      this.logger.info('UAirship is not defined. Skipping push notifications setup.');
      return;
    }
    UAirship.takeOff({
      production: environment.urbanairship_keys,
      development: environment.urbanairship_keys,
      site: 'US',
    });

    UAirship.setAndroidNotificationConfig({
      icon: 'ic_notification',
      largeIcon: 'ic_notification',
      accentColor: '#009EE2'
    });

    UAirship.setPresentationOptions(
      UAirship.presentationOptions.sound | UAirship.presentationOptions.alert
    );
    UAirship.setUserNotificationsEnabled(true, (enabled: boolean) => this.logger.debug('User notifications', enabled));
    UAirship.getChannelID((id: string) => this.logger.info('ChannelID', id));
    this.logger.debug('Push notifications setup complete.');
  }

  @HostListener('urbanairship.registration', ['$event'])
  private onAirshipRegistration(event: any) {
    this.logger.debug('Airship registered', event);
  }

  @HostListener('urbanairship.push', ['$event'])
  private onPushReceived(event: any) {
    this.logger.debug('Received push message:', event.message);
  }
}
