<mat-form-field class="no-underline">
  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
  <input matInput
    [matDatepicker]="picker"
    [formControl]="dateFormControl"
    (dateInput)="onDateInput('input', $event)"
    (focus)="picker.open()"
    (click)="picker.open()"
  >
  <mat-datepicker #picker
    startView="multi-year"
    (monthSelected)="chosenMonthHandler($event, picker)"
  >
  </mat-datepicker>
</mat-form-field>
