import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AuditService} from '../audit-form/audit.service';
import {AccountService} from '../accounts.service';
import {AnalyticsService} from '../analytics.service';
import {tap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {BaseComponent} from '../base.component';

@Component({
  selector: 'meg-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent extends BaseComponent implements  OnInit, OnDestroy {
  @Output() toggleMenuEvent = new EventEmitter<boolean>();
  @Input() sideMenuChange = false;
  fullMatLogoPath: string;
  showMenu = true;
  showInformation = false;
  showBackButton = false;
  private auditIdSubscription: Subscription | null = null;
  auditId: number | null = null;

  public numObservations = 0;
  public showNextButton = false;

  constructor(private router: Router,
              private auditService: AuditService,
              private accountService: AccountService,
              private analytics: AnalyticsService,
              private location: Location,
              private injector: Injector) {
    super(injector);
    this.fullMatLogoPath = 'assets/images/logos/MEG-Logo-White.svg';
  }

  ngOnInit() {
    /**
     * using router.url when navigating from walkThrough
     */
    this.showMenu = this.accountService.isAuthenticated() && this.router.url !== '/loading';
    this.showInformation = this.router.url === '/login';
    this.showBackButton = !this.accountService.isAuthenticated() && this.router.url !== '/login';

    /**
     * subscribing to router.events when reloading page
     */
    this.router.events.subscribe((url: any ) => {
      this.logger.trace('events url', url);
      if ((url['url']) !== undefined) {
        this.showMenu = this.accountService.isAuthenticated() && url['url'] !== '/loading';
        this.showInformation = url['url'] === '/login';
        this.showBackButton = !this.accountService.isAuthenticated() && url['url'] !== '/login';
      }
    });
    this.auditIdSubscription = this.auditService.auditId$.pipe(
      tap((auditId: number | null) => this.auditId = auditId),
      tap((auditId: number | null) => {
        if (auditId === null) this.numObservations = 0;
        else {
          this.auditService.getAuditWithFormSchema(auditId).subscribe(([audit, auditForm, auditFormSchema]) => {
            this.numObservations = audit.auditSession.observations.length;
            this.showNextButton = auditForm.config.save_next_observation && this.numObservations > 0;
          });
        }
      }),
    ).subscribe();
  }

  ngOnDestroy(): void {
    if (this.auditIdSubscription !== null) {
      this.auditIdSubscription.unsubscribe();
      this.auditIdSubscription = null;
    }
  }

  onToggleMenu() {
    this.sideMenuChange = !this.sideMenuChange;
    this.toggleMenuEvent.emit(this.sideMenuChange);
    this.analytics.trackButtonClick('Menu');
  }

  public get showAuditReviewButton(): boolean {
    if (!this.showNextButton) return false;
    const url = this.router.url;
    return this.auditId !== null && (!url.startsWith('/audit-preview') && !url.startsWith('/submit-preview'));
  }

  onAuditReviewPressed() {
      this.hintService.markShown(this.hints.SubmitAudit);
      this.router.navigate(['/audit-preview', this.auditId]);
  }

  onBackClicked() {
    this.location.back();
  }
}
