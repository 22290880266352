import {Injectable, OnDestroy} from '@angular/core';
import {LoginCredentials} from '../login/login_credentials';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {AccountService} from '../accounts.service';
import {LoginResponse} from './responses/login-response';
import {Observable, fromEvent, merge, of, Subscription} from 'rxjs';
import {
  API_APP_VERSION,
  API_AUDIT_FORMS,
  API_AUDIT_FORMS_v3, API_AUDIT_SESSION,
  API_AUDITORS,
  API_AUTHENTICATION_CHECK,
  API_AVATARS,
  API_BACKUP, API_BACKUP_MEDIA, API_BACKUP_UPLOAD_MEDIA,
  API_CALENDAR,
  API_CHANNELS,
  API_CHANNELS_SEND_P2P_MESSAGE,
  API_DASHBOARD,
  API_FEEDBACK,
  API_INFORMATION,
  API_INSTITUTIONS,
  API_ISSUE_HANDLERS,
  API_ISSUE_PHOTOS,
  API_ISSUES,
  API_ISSUES_STATUSES_CHOICES,
  API_LANGUAGES,
  API_LOGIN,
  API_RELATED_FORM_DATA, API_SUBMISSIONS_IN_PROGRESS,
  API_TOKEN_LOGIN,
  API_UPCOMING_AUDITS,
  API_USERS,
  API_VALIDATORS,
  API_ANALYTICS,
  API_REMOTE_FORM_FIELD_CHOICES,
  AUDIT_FORM_CHECK_UPDATE,
  AUDIT_FORM_COMMON_ISSUES,
  AUDIT_FORM_SCHEMA,
  AUDIT_FORM_SUBMIT,
  CHANNEL_MARK_READ,
  CHANNEL_MESSAGES,
  CHANNEL_SEND_MESSAGE,
  CHANNEL_UNREAD_MESSAGES,
  MOBILE_FORGOT_PASSWORD,
  VALIDATORS_VALIDATE
} from './constants';
import {PaginatedResponse} from './responses/paginated-response';
import {Institution} from './models/institution';
import {AuditForm} from './models/audit-form';
import {Information} from './models/information';
import {AuditFormSchema, Choice, FieldValidator} from './models/audit-form-schema';
import {AuditSession} from './models/audit-session';
import {appData} from '../../../appData';
import {CommonIssue} from './models/common-issue';
import {Dashboard} from './models/dashboard';
import {Issue, IssueHandler, IssuePhoto, QIP_STATUS_IN_PROGRESS_SLUG, QIP_STATUS_OPEN_SLUG} from './models/issue';
import {AppVersion} from './models/app-version';
import {Avatar} from './models/avatar';
import {Auditor} from './models/auditor';
import {AuditSchedule, CalendarMonth} from './models/calendar';
import {Feedback} from './models/feedback';
import {Language} from './models/language';
import {catchError, first, map, mergeMap, retry, tap, timeout} from 'rxjs/operators';
import {AuditFormUpdateResponse} from './models/audit-form-update-response';
import {NGXLogger} from 'ngx-logger';
import {Region} from './models/region';
import {Router} from '@angular/router';
import {fromPromise} from 'rxjs/internal-compatibility';
import {isNullOrUndefined} from '../utils/misc';
import {ValidationErrors} from '@angular/forms';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {AppConfigService} from '../app-config.service';
import {Channel, Message} from './models/messaging';
import {UserIdName} from './models/basic-auditor';
import {IssueStatus} from '../qip/issue-list/issue.service';
import {FormRelatedData, FormRelatedDataResponse} from './models/form-relation';
import {Audit} from '../audit-form/audit';
import {AuditBackup, AuditMediaBackup} from './models/backup';
import {ModelNameType} from './models/analytics';

export const STATUS_OK = 200;
export const STATUS_NOT_MODIFIED = 304;
export const STATUS_REQUEST_ERROR = 400;
export const STATUS_UNAUTHORISED = 401;
export const STATUS_FORBIDDEN = 403;
export const STATUS_NOT_FOUND = 404;
export const STATUS_SERVER_ERROR = 500;

export const HEADER_IF_MODIFIED_SINCE = 'If-Modified-Since';
export const HEADER_LAST_MODIFIED = 'Last-Modified';

export const IOS = 'ios';
export const ANDROID = 'android';
export const WINDOWS = 'windows';
export const WEB = 'web';

@Injectable()
export class ApiService implements OnDestroy {
  /**
   * Network Status, while true means that network is available
   */
  networkStatus = false;
  networkStatus$ = Subscription.EMPTY;

  constructor(private httpClient: HttpClient, private accounts: AccountService, private logger: NGXLogger, private router: Router,
              private appConfig: AppConfigService) {
    this.checkNetworkStatus();
  }
  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }

  public checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status;
      });
  }
  public getDeviceType(): string {
    const cordova = (window as any).cordova;
    if (cordova !== undefined) {
      return cordova.platformId.toLowerCase();
    } else {
      return WEB;
    }
  }

  public get region(): Observable<Region> {
    return this.accounts.getRegion().pipe(
      map((region: Region): Region => {
        if (isNullOrUndefined(region)) return this.appConfig.getDefaultRegion();
        else return region;
      }),
      catchError(() => of(this.appConfig.getDefaultRegion())),
    );
  }

  /** Adds default headers to the request, including authentication header if user is logged in */
  private buildHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'app-version': appData.app_version,
      'xVersion-code': appData.build_number.toString(),
      'Accept-Language': this.accounts.getLanguage() || navigator.language,
      'xPlatform': this.getDeviceType(),
      'Access-Control-Allow-Methods': '*',
    });
    if (this.accounts.isAuthenticated()) {
      headers = headers.set('Authorization', this.accounts.getAuthorizationHeader());
    }
    return headers;
  }

  /**
   * Pre-pend backend url to the relative url
   * @param path relative url
   * @param region override region for this single request
   */
  public buildUrl(path: string, region?: Region): Observable<string> {
    const observable: Observable<Region> = region === undefined ? this.region : of(region);
    return observable.pipe(
      map((reg: Region): string => reg.domain),
      map((domain: string): string => {
        const scheme = domain.indexOf(':') === -1 ? 'https' : 'http';
        return `${scheme}://${domain}${path}`;
      }),
    );
    // use http scheme if domain has a port number
  }

  public login(credentials: LoginCredentials, region?: Region): Observable<LoginResponse> {
    return this.buildUrl(API_LOGIN, region || this.appConfig.getDefaultRegion()).pipe(
      mergeMap((url) => this.httpClient.post<LoginResponse>(url, credentials, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public tokenLogin(token: string, region?: Region): Observable<LoginResponse> {
    return this.buildUrl(API_TOKEN_LOGIN, region).pipe(
      mergeMap((url) => this.httpClient.post<LoginResponse>(url, {
        token: token,
      }, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public fetchAuditForms(limit= 100, offset= 0): Observable<PaginatedResponse<AuditForm>> {
    return this.buildUrl(`${API_AUDIT_FORMS_v3}?limit=${limit}&offset=${offset}`).pipe(
      mergeMap((url) => this.httpClient.get<PaginatedResponse<AuditForm>>(url, {
        headers: this.buildHeaders(),
      })),
    );
  }

  /**
   * Gets all audit forms by making multiple paginated requests and returning them in a single list
   */
  public fetchAllAuditForms(pageSize= 500): Observable<AuditForm[]> {
    // Fetch first page
    return this.fetchAuditForms(pageSize).pipe(
      mergeMap((response: PaginatedResponse<AuditForm>): Observable<AuditForm[]> => {
        let forms: AuditForm[] = response.results.filter(form => !form.archive);

        // fetch subsequent pages
        const requests = [];
        for (let offset = pageSize; offset < response.count; offset += pageSize) {
          this.logger.debug(`Creating request for offset ${offset} of ${response.count}`);
          const pageRequest = this.fetchAuditForms(pageSize, offset).pipe(
            tap((pageResponse) => forms = forms.concat(pageResponse.results)),
          );
          requests.push(pageRequest);
        }
        if (requests.length === 0) return of(forms);
        return forkJoin(requests).pipe(
          map(() => forms),
        );
      }),
    );
  }

  public fetchChannels(): Observable<PaginatedResponse<Channel>> {
    return this.buildUrl(API_CHANNELS).pipe(
      tap((url) => this.logger.debug('Fetching channels from url', url)),
      mergeMap((url: string) => this.httpClient.get<PaginatedResponse<Channel>>(url, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public fetchChannel(channelId: number): Observable<Channel> {
    return this.buildUrl(API_CHANNELS + channelId).pipe(
      mergeMap((url: string) => this.httpClient.get<Channel>(url, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public fetchStatuses(issueId: number): Observable<IssueStatus[]> {
    return this.buildUrl(API_ISSUES_STATUSES_CHOICES + issueId).pipe(
      mergeMap((url: string) => this.httpClient.get<IssueStatus[]>(url, {
        headers: this.buildHeaders(),
      })),
    );
  }

  /**
   * Fetches messages from given feed url
   * @param messagesUrl
   */
  private fetchMessages(messagesUrl: string): Observable<PaginatedResponse<Message>> {
    return this.buildUrl(messagesUrl).pipe(
      mergeMap((url: string) => this.httpClient.get<PaginatedResponse<Message>>(url, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public fetchChannelMessages(channelId: number): Observable<PaginatedResponse<Message>> {
    return this.fetchMessages(API_CHANNELS + channelId + CHANNEL_MESSAGES);
  }

  public fetchChannelUnreadMessages(channelId: number): Observable<PaginatedResponse<Message>> {
    return this.fetchMessages(API_CHANNELS + channelId + CHANNEL_UNREAD_MESSAGES);
  }

  /**
   * Marks user's read position at given point in the channel
   * @param channelId channel ID
   * @param date date object or string representing time
   */
  public markMessagesAsRead(channelId: number, date: Date | string) {
    return this.buildUrl(API_CHANNELS + channelId + CHANNEL_MARK_READ).pipe(
      mergeMap((url: string) => this.httpClient.post(url, {
        last_read: date,
      }, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public sendChannelMessage(channelId: number, message: string): Observable<Message> {
    return this.buildUrl(API_CHANNELS + channelId + CHANNEL_SEND_MESSAGE).pipe(
      mergeMap(url => this.httpClient.post<Message>(url, {content: message}, {
        headers: this.buildHeaders(),
      })),
    );
  }

  /**
   * Sends message to individual user, returns channel object for conversation with that user.
   * @param userId recipient user
   * @param message message content
   */
  public sendP2pMessage(userId: number, message: string): Observable<Channel> {
    return this.buildUrl(API_CHANNELS_SEND_P2P_MESSAGE).pipe(
      mergeMap(url => this.httpClient.post<Channel>(url, {
        content: message,
        to: userId,
      }, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public fetchUsers(search?: string): Observable<PaginatedResponse<UserIdName>> {
    return this.buildUrl(API_USERS).pipe(
      mergeMap(url => {
        if (search) url = `${url}?search=${search}`;
        return this.httpClient.get<PaginatedResponse<UserIdName>>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public requestPasswordReset(username: string): Observable<string> {
    return this.buildUrl(MOBILE_FORGOT_PASSWORD).pipe(
      mergeMap((url) => {
        const body = new HttpParams().set('username', username);
        return this.httpClient.post(url, body.toString(), {
          headers: this.buildHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
          responseType: 'text',
        });
      }),
    );
  }

  public fetchInstitutions(limit= 10000): Observable<PaginatedResponse<Institution>> {
    return this.buildUrl(`${API_INSTITUTIONS}?limit=${limit}`).pipe(
      mergeMap((url) => this.httpClient.get<PaginatedResponse<Institution>>(url, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public fetchInformations(limit= 10000, offset = 0): Observable<PaginatedResponse<Information>> {
    return this.buildUrl(`${API_INFORMATION}?limit=${limit}&offset=${offset}`).pipe(
      mergeMap((url) => {
        return this.httpClient.get<PaginatedResponse<Information>>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public fetchIssueHandlers(limit= 10000, offset = 0): Observable<PaginatedResponse<IssueHandler>> {
    return this.buildUrl(`${API_ISSUE_HANDLERS}?limit=${limit}&offset=${offset}`).pipe(
      mergeMap((url) => {
        return this.httpClient.get<PaginatedResponse<IssueHandler>>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  /**
   * Gets all issue handlers by making multiple paginated requests and returning them in a single list
   */
  public fetchAllIssueHandlers(pageSize= 100): Observable<IssueHandler[]> {
    // Fetch first page
    return this.fetchIssueHandlers(pageSize).pipe(
      mergeMap((response: PaginatedResponse<IssueHandler>): Observable<IssueHandler[]> => {
        let handlers: IssueHandler[] = response.results;

        // fetch subsequent pages
        const requests = [];
        for (let offset = pageSize; offset < response.count; offset += pageSize) {
          this.logger.debug(`Creating request for offset ${offset} of ${response.count}`);
          const pageRequest = this.fetchIssueHandlers(pageSize, offset).pipe(
            tap((pageResponse) => handlers = handlers.concat(pageResponse.results)),
          );
          requests.push(pageRequest);
        }
        if (requests.length === 0) return of(handlers);
        return forkJoin(requests).pipe(
          map(() => handlers),
        );
      }),
    );
  }

  public fetchAuditFormSchema(formId: number, lastModified: string | null): Observable<HttpResponse<AuditFormSchema>> {
    return this.buildUrl(`${API_AUDIT_FORMS_v3}${formId}${AUDIT_FORM_SCHEMA}`).pipe(
      mergeMap((url) => {
        let headers = this.buildHeaders();
        if (lastModified !== null) headers = headers.set(HEADER_IF_MODIFIED_SINCE, lastModified);

        return this.httpClient.get<AuditFormSchema>(url, {
          headers: headers,
          observe: 'response',
        });
      }),
    );
  }

  public fetchCommonIssues(formId: number, lastModified: string | null): Observable<HttpResponse<CommonIssue[]>> {
    return this.buildUrl(`${API_AUDIT_FORMS}${formId}${AUDIT_FORM_COMMON_ISSUES}`).pipe(
      mergeMap((url) => {
        let headers = this.buildHeaders();
        if (lastModified !== null) headers = headers.set(HEADER_IF_MODIFIED_SINCE, lastModified);

        return this.httpClient.get<CommonIssue[]>(url, {
          headers: headers,
          observe: 'response',
        });
      }),
    );
  }

  public submitAudit(formId: number, auditData: AuditSession): Observable<AuditSession> {
    return this.buildUrl(`${API_AUDIT_FORMS}${formId}${AUDIT_FORM_SUBMIT}`).pipe(
      mergeMap((url) => {
        if (auditData.end_time == null) {
          auditData.end_time = new Date();
        }
        if (auditData.id === undefined) {
          return this.httpClient.post<AuditSession>(url, auditData, {
            headers: this.buildHeaders(),
          });
        } else {
          return this.httpClient.put<AuditSession>(url, auditData, {
            headers: this.buildHeaders(),
          });
        }

      }),
    );
  }

  public fetchDashboards(): Observable<PaginatedResponse<Dashboard>> {
    return this.buildUrl(API_DASHBOARD).pipe(
      mergeMap((url) => {
        return this.httpClient.get<PaginatedResponse<Dashboard>>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public uploadPhoto(photo: IssuePhoto): Observable<IssuePhoto> {
    return this.buildUrl(API_ISSUE_PHOTOS).pipe(
      mergeMap((url) => this.httpClient.post<IssuePhoto>(url, photo, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public checkAppVersion(appId: string): Observable<AppVersion> {
    return this.buildUrl(`${API_APP_VERSION}?app_id=${appId}`).pipe(
      mergeMap((url) => {
        return this.httpClient.get<AppVersion>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public getAvatars(): Observable<PaginatedResponse<Avatar>> {
    return this.buildUrl(API_AVATARS).pipe(
      mergeMap((url) => this.httpClient.get<PaginatedResponse<Avatar>>(url, {
        headers: this.buildHeaders(),
      })),
    );
  }

  /**
   * Get a month view of audit calendar with schedules and past audits
   * @param year
   * @param month
   */
  public getCalendar(year: number, month: number): Observable<CalendarMonth> {
    return this.buildUrl(`${API_CALENDAR}/${year}/${month}/`).pipe(
      mergeMap((url) => {
        return this.httpClient.get<CalendarMonth>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public updateAuditor(auditor: Auditor): Observable<Auditor> {
    return this.buildUrl(`${API_AUDITORS}${auditor.id}/`).pipe(
      mergeMap((url) => {
        return this.httpClient.put<Auditor>(url, auditor, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public sendFeedback(feedback: Feedback): Observable<Feedback> {
    return this.buildUrl(API_FEEDBACK).pipe(
      mergeMap((url) => {
        return this.httpClient.post<Feedback>(url, feedback, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public getLanguages(): Observable<Language[]> {
    return this.buildUrl(API_LANGUAGES).pipe(
      mergeMap((url) => {
        return this.httpClient.get<Language[]>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public checkAuditFormUpdate(auditFormId: number, lastModifiedDate: string | null): Observable<AuditFormUpdateResponse> {
    return this.buildUrl(`${API_AUDIT_FORMS}${auditFormId}${AUDIT_FORM_CHECK_UPDATE}`).pipe(
      mergeMap((url) => {
        let headers = this.buildHeaders();
        if (lastModifiedDate !== null) headers = headers.set(HEADER_IF_MODIFIED_SINCE, lastModifiedDate);
        return this.httpClient.get<AuditFormUpdateResponse>(url, {
          headers: headers, observe: 'response',
        });
      }),
    );
  }

  public fetchAuditForm(id: number, lastModifiedDate: string | null): Observable<AuditForm> {
    return this.buildUrl(`${API_AUDIT_FORMS_v3}${id}/`).pipe(
      mergeMap((url) => {
        let headers = this.buildHeaders();
        if (lastModifiedDate !== null) headers = headers.set(HEADER_IF_MODIFIED_SINCE, lastModifiedDate);
        return this.httpClient.get<AuditForm>(url, {
          headers: headers,
        });
      }),
    );
  }

  public fetchIssues(page: number = 0, pageSize: number = 30, statuses: string[] = [QIP_STATUS_OPEN_SLUG, QIP_STATUS_IN_PROGRESS_SLUG],
                     issueHandlerId?: number, auditFormId?: number, wardId?: number): Observable<PaginatedResponse<Issue>> {
    return this.buildUrl(`${API_ISSUES}?limit=${pageSize}&offset=${page}`).pipe(
      mergeMap((url) => {
        if (issueHandlerId) url += `&handler=${issueHandlerId}`;
        if (auditFormId) url += `&audit_form=${auditFormId}`;
        if (wardId) url += `&room__ward=${wardId}`;
        statuses.forEach((status: string) => url += `&status=${status}`);
        return this.httpClient.get<PaginatedResponse<Issue>>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public fetchScheduledAudits(): Observable<AuditSchedule[]> {
    return this.buildUrl(API_UPCOMING_AUDITS).pipe(
      mergeMap((url) => {
        return this.httpClient.get<AuditSchedule[]>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  /**
   * Backs up audit to cloud
   */
  public createBackup(audit: Audit): Observable<AuditBackup> {
    return this.buildUrl(API_BACKUP).pipe(
      mergeMap((url) => {
        this.logger.debug('Backing up', audit);
        return this.httpClient.post<AuditBackup>(url, {
          data: audit,
        }, {
          headers: this.buildHeaders(),
        }).pipe(
          tap((response: AuditBackup) => this.logger.debug('Backup response', response)),
          retry(3),
        );
      }),
    );
  }

  public uploadBackupMedia(backupId: number, media: AuditMediaBackup): Observable<boolean> {
    return this.buildUrl(`${API_BACKUP}${backupId}${API_BACKUP_UPLOAD_MEDIA}`).pipe(
      mergeMap(url => this.httpClient.post(url, media, {
        headers: this.buildHeaders(),
      })),
      retry(3),
      map(value => true),
    );
  }

  /**
   * Downloads QIP photos for given backup
   * @param backupId primary key of the backup object in the back-end
   */
  public getBackupMedia(backupId: number): Observable<AuditMediaBackup[]> {
    return this.buildUrl(`${API_BACKUP}${backupId}${API_BACKUP_MEDIA}`).pipe(
      mergeMap(url => this.httpClient.get<AuditMediaBackup[]>(url, {
        headers: this.buildHeaders(),
      })),
      retry(3),
    );
  }

  /**
   * Backs up audit to cloud
   */
  public updateBackup(audit: Audit, backupId: number): Observable<AuditBackup> {
    return this.buildUrl(`${API_BACKUP}${backupId}/`).pipe(
      mergeMap((url) => {
        this.logger.debug('Updating backed-up audit', audit);
        return this.httpClient.put<AuditBackup>(url, {
          data: audit,
        }, {
          headers: this.buildHeaders(),
        }).pipe(
          tap((response: AuditBackup) => this.logger.debug('Backup response', response)),
          retry(3),
        );
      }),
    );
  }

  public getBackupList(): Observable<AuditBackup[]> {
    return this.buildUrl(API_BACKUP).pipe(
      mergeMap(url => this.httpClient.get<PaginatedResponse<AuditBackup>>(url, {
        headers: this.buildHeaders(),
      })),
      map(response => response.results),
    );
  }
  public fetchIssueDetail(id: number): Observable<Issue> {
    return this.buildUrl(API_ISSUES + id).pipe(
      mergeMap((url) => {
        return this.httpClient.get<Issue>(url, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  /**
   * Make PATCH request ato an arbitrary url within region with any data dictionary
   * @param relativeUrl
   * @param object
   */
  public patch<T>(relativeUrl: string, object: { [key: string]: any }): Observable<T> {
    return this.buildUrl(relativeUrl).pipe(
      mergeMap((url) => {
        return this.httpClient.patch<T>(url, object, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  /**
   * Checks if user is still authenticated on the back end.
   * If session is expired, logs out and redirects user to the login screen.
   * Returns boolean false if user session expired.
   * In any other case, or error returns true.
   */
  public checkAuthentication(): Observable<boolean> {
    const token = this.accounts.token;
    return this.buildUrl(API_AUTHENTICATION_CHECK).pipe(
      mergeMap((url) => this.httpClient.get(
        url, {
          headers: this.buildHeaders(), observe: 'response',
        })),
      tap((r) => this.logger.debug(`Auth check for token ${this.accounts.token}`, r)),
      map(() => true),
      catchError((e) => {
        this.logger.debug('Auth check error', e);
        if (e instanceof HttpErrorResponse && e.status === 401) {
          if (token === this.accounts.token) return of(false);
          else {
            this.logger.warn('Token out of date. auth check invalid.');
            return of(true);
          }
        } else return of(true);
      }),
      mergeMap((loginValid: boolean): Observable<boolean> => {
        if (!loginValid) {
          this.accounts.clearLogin();
          const promise = this.router.navigate(['login']);
          return fromPromise(promise).pipe(
            map(() => loginValid),
          );
        } else {
          return of(loginValid);
        }
      }),
    );
  }

  public fetchRelatedFormData(config: FormRelatedData, answers: {[fieldName: string]: string}): Observable<FormRelatedDataResponse[]> {
    const relativeUrl = `${API_RELATED_FORM_DATA}${config.id}/get_data/`;
    return this.buildUrl(relativeUrl).pipe(
      mergeMap(url => this.httpClient.get<FormRelatedDataResponse[]>(url, {
        headers: this.buildHeaders(),
        params: new HttpParams({fromObject: answers}),
      })),
    );
  }

  public validateFormField(validator: FieldValidator, value: any): Observable<ValidationErrors> {
    return <Observable<ValidationErrors>>this.buildUrl(`${API_VALIDATORS}${validator.id}${VALIDATORS_VALIDATE}`).pipe(
      mergeMap(url => this.httpClient.post(url, {
        value: value,
      }, {
        headers: this.buildHeaders(), observe: 'response',
      })),
      map(() => {}),
      catchError((e) => {
        if (e instanceof HttpErrorResponse && e.status === 400) {
          const message = e.error.value[0];
          return of({
            'backend-validation': {
              message: message,
              value: value,
            },
          });
        } else return of({});
      }),
    );
  }

  public fetchSubmissionsList(): Observable<AuditSession[]> {
    return <Observable<AuditSession[]>>this.buildUrl(API_SUBMISSIONS_IN_PROGRESS).pipe(
      mergeMap(url => this.httpClient.get(url, {
        headers: this.buildHeaders(),
      })),
    );
  }

  public downloadAuditData(sessionId: number): Observable<AuditSession> {
    return <Observable<AuditSession>>this.buildUrl(`${API_AUDIT_SESSION}${sessionId}/submission-data/`).pipe(
      mergeMap(url => this.httpClient.get(url, {
        headers: this.buildHeaders(),
      })),
    );
  }
  public submitAnalyticsEntry(modelName: ModelNameType, modelObjectId: number): Observable<boolean> {
    return this.buildUrl(API_ANALYTICS).pipe(
      mergeMap((url) => {
        return this.httpClient.post<any>(url, {
          model_name: modelName, model_object_id: modelObjectId
        }, {
          headers: this.buildHeaders(),
        });
      }),
    );
  }

  public fetchRemoteFormModelFieldChoices(customFieldId: number, search: string): Observable<Choice[]> {
    return this.buildUrl(`${API_REMOTE_FORM_FIELD_CHOICES}${customFieldId}/`).pipe(
      mergeMap((url) => {
        return this.httpClient.get<any>(url, {
          headers: this.buildHeaders(),
          params: new HttpParams({fromObject: {q: search}})
        });
      }),
      map((data) => {
        const results = data['results'];
        if (!results || results.length === 0) return [];
        return results.map((result: any) => ({'value': result['id'], 'label': result['text']}));
      }),
    );
  }
}
