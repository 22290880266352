import {Injectable} from '@angular/core';
import {AuditForm} from './api/models/audit-form';
import {DownloadAuditDialogComponent} from './download-audit-dialog/download-audit-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {DownloadService} from './loading/download.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {ModalService} from './alert/service/modal.service';
import {ModalButton} from './alert/modal-alert-data';
import {tap} from 'rxjs/internal/operators/tap';
import {AnalyticsService, CATEGORY_UI} from './analytics.service';
import {AuditFormService} from './audit-form.service';

/**
 * AuditCheckStatus enum for the result of the audit form check
 * NO_UPDATE - there is no update available for the audit form passed in
 * UPDATE_NOT_DONE - there is an update but the user has declined to update now
 * UPDATE_COMPLETE - there is an update available and the user has updated that audit form
 */
export enum AuditCheckStatus {
  NO_UPDATE = 0,
  UPDATE_NOT_DONE = 1,
  UPDATE_COMPLETE = 2,
}

@Injectable({
  providedIn: 'root',
})
export class CheckAuditFormService {

  constructor(
    private translateService: TranslateService,
    private downloadService: DownloadService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private modal: ModalService,
    private analytics: AnalyticsService,
    private auditFormService: AuditFormService,
  ) { }

  /**
   * Check audit for if update is available, if there is an update it will show a SnackBar dialog with option to update
   * Shows the Download Audit Dialog if the update button is pressed. The method also takes an optional callback method that
   * returns AuditCheckStatus
   * @param auditForm: the form which to check
   * @param callBack: optional call back once download is complete
   */
  public checkAuditFormUpdate(auditForm: AuditForm, callBack?: (checkStatus: AuditCheckStatus) => any) {
    this.auditFormService.isSchemaSaved(auditForm.id).pipe(
      tap((isSaved: boolean) => {
        if (isSaved) this.showUpdateDialog(auditForm, callBack);
        else this.showDownloadDialog(auditForm, callBack);
      }),
    ).subscribe(undefined, () => {
      if (callBack) callBack(AuditCheckStatus.NO_UPDATE);
    }, undefined);
  }

  /**
   * Shows dialog asking user to download the form if it's not downloaded
   */
  private showDownloadDialog(auditForm: AuditForm, callBack?: ((checkStatus: AuditCheckStatus) => any)) {
    const formName: string = auditForm.name;
    const formId: number = auditForm.id;
    const updateBtn = new ModalButton(this.translateService.get('form-update.download'), () => {
      this.dialog.open(DownloadAuditDialogComponent, {data: {id: formId, name: formName}}).afterClosed()
        .pipe(
          tap((dialogResult: boolean) => {
            if (callBack) callBack(dialogResult ? AuditCheckStatus.UPDATE_COMPLETE : AuditCheckStatus.UPDATE_NOT_DONE);
          })
        ).subscribe();
    }, 'cloud_download');
    this.modal.openWarningModal(
      this.translateService.get('form-update.form_needs_download'), updateBtn,
      this.modal.btnCancel(),
    ).subscribe((button: ModalButton | boolean) => {
      if (button instanceof ModalButton) button.label.subscribe((label) => this.analytics.trackUserAction('Form update', label));
      else this.analytics.trackUserAction('Form update', 'clicked outside of update dialog');
    });
  }

  /**
   * Checks if current form is up to date and displays update dialog if its not
   */
  private showUpdateDialog(auditForm: AuditForm, callBack?: ((checkStatus: AuditCheckStatus) => any)) {
    const formName: string = auditForm.name;
    const formId: number = auditForm.id;
    this.downloadService.checkAuditFormUpdate(formId).subscribe((updateAvailable: boolean) => {
      if (!updateAvailable && callBack) callBack(AuditCheckStatus.NO_UPDATE);
      if (updateAvailable) {
        this.dialog.open(DownloadAuditDialogComponent, {data: {id: formId, name: formName}}).afterClosed()
          .pipe(
            tap((dialogResult: boolean) => {
              if (callBack) callBack(dialogResult ? AuditCheckStatus.UPDATE_COMPLETE : AuditCheckStatus.UPDATE_NOT_DONE);
            })
          ).subscribe();
        this.analytics.trackEvent(CATEGORY_UI, 'Auto updating form');
      }
    });
  }
}
