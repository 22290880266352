import {Component, Input, OnInit} from '@angular/core';
import {Issue, IssuePhoto} from '../../api/models/issue';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {QipService} from '../../qip/qip.service';
import {Audit} from '../../audit-form/audit';
import {AuditFormSchema} from '../../api/models/audit-form-schema';
import {AuditForm} from '../../api/models/audit-form';
import {SubForm} from '../../api/models/subform';
import {SubObservation} from '../../api/models/observation';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'meg-issue-preview',
  templateUrl: './issue-preview.component.html',
  styleUrls: ['./issue-preview.component.css']
})
export class IssuePreviewComponent implements OnInit {

  @Input() issue: Issue;
  @Input() audit: Audit;
  @Input() auditFormSchema: AuditFormSchema;
  @Input() auditForm: AuditForm;

  public photos$: Observable<IssuePhoto[]>;
  public formName: string | null = null;

  constructor(private qipService: QipService, private logger: NGXLogger) { }

  ngOnInit() {
    this.findFormOrSubFormNameForIssue();
    this.getPhotos();
  }

  public getPhotos() {
    this.photos$ = this.qipService.getIssuePhotos(this.audit, this.issue).pipe(
      tap((value: IssuePhoto[]) => this.logger.debug('Updated photos display', value)),
    );
  }

  private findFormOrSubFormNameForIssue() {
    const findIssue = (issues: Issue[], subFormName: string) => {
      for (const issue of issues) {
        if (this.issue === issue) {
          this.formName = subFormName;
          break;
        }
      }
    };

    this.audit.auditSession.observations.forEach((observation) => {
      this.auditFormSchema.sub_forms.forEach((subForm) => {
        if (!observation[subForm.name]) return;
        const subObservations: SubObservation[] = [];
        if (Array.isArray(observation[subForm.name])) subObservations.push(...<SubObservation[]>observation[subForm.name]);
        else subObservations.push(<SubObservation>observation[subForm.name]);

        subObservations.forEach(
          (subObservation: SubObservation) => findIssue(subObservation.issues, subForm.display_name)
        );
      });
    });

    if (this.formName === null) {
      this.formName = this.auditForm.name;
    }
  }

}
