import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatDialog} from '@angular/material';
import {TimePickerDialogComponent} from './time-picker-dialog/time-picker-dialog.component';

@Component({
  selector: 'meg-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimeFieldComponent), multi: true },
  ]
})
export class TimeFieldComponent implements ControlValueAccessor {

  timeFormControl = new FormControl();

  constructor(private dialog: MatDialog) {
  }

  showPicker() {
    const dialogRef = this.dialog.open(TimePickerDialogComponent, {
      data: this.timeFormControl.value,
    });
    dialogRef.afterClosed().subscribe((result: string | boolean) => {
      if (result === false) {
        return;
      } else {
        this.timeFormControl.setValue(result);
        this.onDateChange(result as string);
      }
    });
  }

  // ---------- ControlValueAccessor methods start ------------- //

  // Write a value to the input
  writeValue(value: any) {
    if (value !== undefined || value !== null) {
      this.timeFormControl.setValue(value);
    }
  }

  // Function to call when the date change.
  onDateChange = (_: string) => {};

  // Allows Angular to register a function to call when the model (date) changes.
  // Save the function as a property to call later here.
  registerOnChange(fn: any) {
    this.onDateChange = fn;
  }

  // Register a function to tell Angular when the input has been touched
  registerOnTouched() {}

  // ---------- ControlValueAccessor methods end ------------ //

}
