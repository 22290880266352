import { HttpClient } from '@angular/common/http';
import 'hammerjs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfigService } from './app-config.service';
var ɵ0 = { hasBackdrop: true }, ɵ1 = function (appConfig) { return function () { return appConfig.loadAppConfig(); }; };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export { ɵ0, ɵ1 };
