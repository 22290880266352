import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Audit } from '../../audit-form/audit';
import { AuditForm, SubmissionDateRestriction } from '../../api/models/audit-form';
import { AuditFormSchema } from '../../api/models/audit-form-schema';
import { AnalyticsService, CATEGORY_UI } from '../../analytics.service';
import { AccountService } from '../../accounts.service';
import { AuditService } from '../../audit-form/audit.service';
import { TranslateService } from '@ngx-translate/core';
import { QipService } from '../../qip/qip.service';
import { StorageService } from '../../storage.service';
import { SignatureDialogComponent } from '../../signature-dialog/signature-dialog.component';
import { AuditSignature } from '../../api/models/signature';
import { MatDialog } from '@angular/material';
import { isNullOrUndefined } from '../../utils/misc';
import { AuditFormService } from '../../audit-form.service';
import { LocalizedDatePipe } from '../../localized-date.pipe';
var SubmitPreviewFormComponent = /** @class */ (function () {
    function SubmitPreviewFormComponent(accountService, analytics, auditFormService, auditService, dialog, localizedDatePipe, qipService, storageService, translateService) {
        this.accountService = accountService;
        this.analytics = analytics;
        this.auditFormService = auditFormService;
        this.auditService = auditService;
        this.dialog = dialog;
        this.localizedDatePipe = localizedDatePipe;
        this.qipService = qipService;
        this.storageService = storageService;
        this.translateService = translateService;
        this.allowSubmissionDateChange$ = false;
        this.startDateFormControl = new FormControl(Validators.required);
        this.endDateFormControl = new FormControl(Validators.required);
        this.allIssues = [];
        this.auditSize = 0;
    }
    SubmitPreviewFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.startDateFormControl.valueChanges.subscribe(function (date) { return _this.startDateChange(date); });
        this.endDateFormControl.valueChanges.subscribe(function (date) { return _this.endDateChange(date); });
        if (this.auditForm && this.auditForm.config) {
            var restriction = this.auditForm.config.submission_date_restriction;
            if (restriction === SubmissionDateRestriction.Everyone) {
                this.allowSubmissionDateChange$ = true;
            }
            else if (restriction === SubmissionDateRestriction.Nobody) {
                this.allowSubmissionDateChange$ = false;
            }
            else if (restriction === SubmissionDateRestriction.LeadAuditors) {
                this.accountService.hasPermission('megforms.change_submission_date').subscribe(function (hasPermission) { return _this.allowSubmissionDateChange$ = hasPermission; });
            }
            else {
                this.allowSubmissionDateChange$ = true;
            }
            this.issue_label_plural = this.auditService.getIssueLabel(this.auditForm, true);
        }
        if (this.audit && this.audit.auditSession) {
            this.startDateFormControl.setValue(this.localizedDatePipe.transform(this.audit.auditSession.start_time));
            if (isNullOrUndefined(this.audit.auditSession.end_time)) {
                this.audit.auditSession.end_time = new Date();
            }
            this.endDateFormControl.setValue(this.localizedDatePipe.transform(this.audit.auditSession.end_time));
            this.auditFormService.getAuditFormSchema(this.audit.auditFormId).pipe(map(function (schema) {
                // Get all issues attached to the audit
                return _this.qipService.getAllIssuesForAudit(schema, _this.audit.auditSession.observations);
            })).subscribe(function (issues) {
                _this.allIssues = issues;
            });
        }
        if (this.audit) {
            this.calculateAuditSize(this.audit);
        }
    };
    SubmitPreviewFormComponent.prototype.startDateChange = function (dateString) {
        if (this.audit) {
            this.audit.auditSession.start_time = dateString.toDate() || new Date();
            this.validateSubmissionDates();
            this.analytics.trackEvent(CATEGORY_UI, 'start date change');
        }
    };
    SubmitPreviewFormComponent.prototype.endDateChange = function (dateString) {
        if (this.audit) {
            this.audit.auditSession.end_time = dateString.toDate() || new Date();
            this.validateSubmissionDates();
            this.analytics.trackEvent(CATEGORY_UI, 'end date change');
        }
    };
    SubmitPreviewFormComponent.prototype.validateSubmissionDates = function () {
        if (this.audit.auditSession.end_time) {
            if (this.audit.auditSession.start_time > this.audit.auditSession.end_time) {
                this.setFieldError('start_time', this.translateService.instant('audit-submission.start-time-error'));
                this.setFieldError('end_time', this.translateService.instant('audit-submission.end-time-error'));
            }
            else {
                this.removeFieldError('start_time');
                this.removeFieldError('end_time');
            }
        }
    };
    SubmitPreviewFormComponent.prototype.submit = function () {
        if (isNaN(this.audit.auditSession.start_time.getDate())) {
            this.setFieldError('start_time', this.translateService.instant('audit-form.field.field-empty'));
            return false;
        }
        if (!this.audit.auditSession.end_time || isNaN(this.audit.auditSession.end_time.getDate())) {
            this.setFieldError('end_time', this.translateService.instant('audit-form.field.field-empty'));
            return false;
        }
        if (this.hasError('start_time') || this.hasError('end_time')) {
            return false;
        }
        var config = this.auditForm.config;
        if (config && config.require_signature && this.audit.auditSession.signatures.length === 0) {
            alert(this.translateService.instant('audit-submission.signature-required-error'));
            return false;
        }
        return true;
    };
    SubmitPreviewFormComponent.prototype.hasError = function (key) {
        if (this.audit.errors)
            return this.audit.errors[key] !== undefined;
        else
            return false;
    };
    SubmitPreviewFormComponent.prototype.setFieldError = function (key, errorValue) {
        if (this.audit.errors) {
            this.audit.errors[key] = [errorValue];
        }
        else {
            this.audit.errors = {};
            this.audit.errors[key] = [errorValue];
        }
    };
    SubmitPreviewFormComponent.prototype.removeFieldError = function (key) {
        if (this.audit.errors)
            this.audit.errors[key] = undefined;
    };
    Object.defineProperty(SubmitPreviewFormComponent.prototype, "errors", {
        get: function () {
            return this.audit.errors || {};
        },
        enumerable: true,
        configurable: true
    });
    SubmitPreviewFormComponent.prototype.calculateAuditSize = function (audit) {
        var _this = this;
        this.qipService.getAuditPhotoKeys(audit).pipe(mergeMap(function (keys) {
            var _a;
            return (_a = _this.storageService).getItemSizes.apply(_a, keys);
        }), map(function (size) { return size + JSON.stringify(audit.auditSession).length; })).subscribe(function (size) { return _this.auditSize = size; });
    };
    /**
     * Opens signature dialog and adds resulting signature to audit when clicked
     */
    SubmitPreviewFormComponent.prototype.addSignature = function () {
        var _this = this;
        this.dialog.open(SignatureDialogComponent).afterClosed().subscribe(function (result) {
            if (result instanceof AuditSignature) {
                _this.audit.auditSession.signatures = [result];
            }
        });
    };
    return SubmitPreviewFormComponent;
}());
export { SubmitPreviewFormComponent };
