import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {AuditSchedule, CalendarAuditSession, CalendarDay, CalendarMonth} from '../../api/models/calendar';
import {AuditForm} from '../../api/models/audit-form';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {AuditFormService} from '../../audit-form.service';
import {forkJoin} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'meg-day-dialog',
  templateUrl: './day-dialog.component.html',
  styleUrls: ['./day-dialog.component.css']
})
export class DayDialogComponent implements OnInit {
  public day: CalendarDay;
  public month: CalendarMonth;
  public events$: Observable<{ name: string, type: string }[]> = of([]);

  constructor(@Inject(MAT_DIALOG_DATA) data: [CalendarDay, CalendarMonth], private auditFormService: AuditFormService) {
    this.day = data[0];
    this.month = data[1];
  }

  ngOnInit() {
    let events: (CalendarAuditSession | AuditSchedule)[] = this.day.schedules;
    events = events.concat(this.day.audit_sessions);
    if (events.length) {
      const observables: Observable<{ name: string, type: string }>[] = events.map((event) => {
        const type = this.day.schedules.indexOf(<AuditSchedule>event) === -1 ? 'audit' : 'schedule';
        return this.auditFormService.getAuditForm(event.audit_form).pipe(
          map((auditForm: AuditForm) => {
            return {name: auditForm.name, type: type};
          }),
        );
      });
      this.events$ = forkJoin(observables);
    }
  }

}
