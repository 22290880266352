var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Institution } from '../api/models/institution';
import { BaseLoginRequiredComponent } from '../base';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../accounts.service';
import { InstitutionService } from '../institution.service';
import { AuditFormService } from '../audit-form.service';
import { AuditService } from '../audit-form/audit.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { AnalyticsService } from '../analytics.service';
import { AuditCheckStatus, CheckAuditFormService } from '../check-audit-form.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { ModalService } from '../alert/service/modal.service';
import { NGXLogger } from 'ngx-logger';
import { throwError } from 'rxjs/internal/observable/throwError';
import { CalendarService } from '../calendar/calendar.service';
import { ApiService } from '../api/api.service';
import { validateSessionToken } from '../api/utils';
import { parseBase64Object } from '../utils/misc';
import { AppUpdateService } from '../app-update.service';
var AuditSelectComponent = /** @class */ (function (_super) {
    __extends(AuditSelectComponent, _super);
    function AuditSelectComponent(institutionService, auditFormService, auditService, route, analytics, checkAuditService, router, accountService, translateService, modalService, snackBar, accountsService, calendarService, activatedRoute, appUpdateService, api, logger) {
        var _this = _super.call(this, router, route, accountService, logger) || this;
        _this.institutionService = institutionService;
        _this.auditFormService = auditFormService;
        _this.auditService = auditService;
        _this.route = route;
        _this.analytics = analytics;
        _this.checkAuditService = checkAuditService;
        _this.translateService = translateService;
        _this.modalService = modalService;
        _this.snackBar = snackBar;
        _this.accountsService = accountsService;
        _this.calendarService = calendarService;
        _this.activatedRoute = activatedRoute;
        _this.appUpdateService = appUpdateService;
        _this.api = api;
        _this.institutions = null;
        _this.departments = null;
        _this.wards = null;
        _this.auditForms = null;
        _this.valid = false;
        _this.institution = null;
        _this.department = null;
        _this.ward = null;
        _this.auditForm = null;
        _this.institutionLogo = null;
        _this.user = null;
        _this.upcomingAudits$ = of([]);
        return _this;
    }
    AuditSelectComponent.prototype.goToLogin = function (nextUrl) {
        var _this = this;
        if (nextUrl === void 0) { nextUrl = null; }
        this.newAuth.subscribe(function (auth) {
            if (auth === null)
                _super.prototype.goToLogin.call(_this);
            else {
                _this.logger.warn('token auth');
                _this.router.navigate(['login'], {
                    queryParams: {
                        auth: auth,
                        next: nextUrl,
                    }
                });
            }
        });
    };
    Object.defineProperty(AuditSelectComponent.prototype, "newAuth", {
        /**
         * emits new auth token if one was passed in query param, or null if none was passed
         */
        get: function () {
            return this.activatedRoute.queryParams.pipe(map(function (params) { return params.auth || null; }));
        },
        enumerable: true,
        configurable: true
    });
    AuditSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.auditService.setAuditId(null);
        this.departmentLabel = this.institutionService.getDepartmentLabel(null, false, true);
        this.wardLabel = this.institutionService.getWardLabel(null, false, true);
        this.newAuth.pipe(tap(function (authBase64) {
            if (authBase64 !== null) {
                var auth = parseBase64Object(authBase64);
                _this.logger.debug('new token, redirecting to login', auth);
                _this.goToLogin(_this.router.url);
            }
        })).subscribe();
        if (this.isLoggedIn()) {
            this.accountsService.getUser().pipe(catchError(function (e) {
                _this.goToLogin();
                return throwError(e);
            }), tap(function (user) { return _this.user = user || null; }), mergeMap(function () { return _this.loadDeepLinkPresets(); })).subscribe(function (deepLinked) {
                if (!deepLinked)
                    _this.loadInstitutions();
            });
            validateSessionToken(this.api, this.translateService);
            this.accountsService.getUser().subscribe(function (user) {
                _this.institutionService.getInstitutionOrNull(user.auditor.institution).subscribe(function (institution) {
                    _this.institutionLabel = _this.institutionService.getInstitutionLabel(institution, false, true);
                    if (institution.group) {
                        _this.institutionLogo = institution.group.logo;
                    }
                    else {
                        _this.institutionLogo = institution.logo;
                    }
                });
            });
        }
        this.upcomingAudits$ = this.calendarService.getUpcomingAudits();
        this.appUpdateService.checkAppVersion().subscribe(function (version) {
            if (!version)
                return;
            _this.translateService.get(['login.update.update-available', 'login.update.update'], { 'version': version.app_version }).subscribe(function (translations) {
                _this.snackBar.open(translations['login.update.update-available'], translations['login.update.update'])
                    .onAction().subscribe(function () { return _this.appUpdateService.openAppStore(); });
            });
        }, (function (error) { return _this.logger.error('Cannot check app update', error, 'Ignore this error if this is not a cordova build.'); }));
    };
    Object.defineProperty(AuditSelectComponent.prototype, "username", {
        get: function () {
            if (this.user === null)
                return '';
            else if (!this.user.first_name)
                return this.user.username;
            else
                return this.user.first_name;
        },
        enumerable: true,
        configurable: true
    });
    AuditSelectComponent.prototype.itemToString = function (item) {
        return item.name;
    };
    AuditSelectComponent.prototype.getDeepLink = function () {
        var _this = this;
        return this.route.params.pipe(mergeMap(function (params) {
            var institutionId = +params['institution_id'];
            var auditFormId = +params['audit_form_id'];
            if (institutionId && auditFormId) {
                return forkJoin(_this.institutionService.getInstitutionOrNull(institutionId), _this.auditFormService.getAuditForm(auditFormId)).pipe(map(function (result) {
                    var institution = result[0], auditForm = result[1];
                    if (institution && auditForm)
                        return [institution, auditForm];
                    else
                        return null;
                }), catchError(function (error) {
                    _this.logger.warn('Could not pre-select institution or audit form', error);
                    return of(null);
                }));
            }
            else
                return of(null);
        }));
    };
    AuditSelectComponent.prototype.loadDeepLinkPresets = function () {
        var _this = this;
        return this.getDeepLink().pipe(tap(function (result) { return _this.logger.info('Deep link', result); }), tap(function (result) {
            if (result !== null) {
                _this.institution = result[0], _this.auditForm = result[1];
                _this.institutions = [_this.institution];
                _this.departments = _this.institution.departments;
                _this.onInstitutionSelected();
                _this.onAuditFormSelected();
            }
        }), map(function (result) { return result !== null; }));
    };
    AuditSelectComponent.prototype.loadInstitutions = function () {
        var _this = this;
        this.institutionService.getInstitutions().subscribe(function (institutions) {
            _this.institutions = institutions;
            if (institutions.length === 1)
                _this.institution = institutions[0];
            if (_this.institution !== null)
                _this.onInstitutionSelected();
        });
    };
    /**
     * Filters the departments by:
     * 1. Wards set in the audit form config
     * 2. Departments that don't have any wards
     * Lastly calls `selectDepartmentIfOne` function.
     */
    AuditSelectComponent.prototype.updateDepartments = function () {
        var _this = this;
        if (this.auditForm && this.institution) {
            this.departments = this.institution.departments.filter(function (department) { return _this.getAvailableWards(department).length > 0; });
            this.selectDepartmentIfOne();
        }
    };
    /**
     * Select the first department if there is only one in the list
     */
    AuditSelectComponent.prototype.selectDepartmentIfOne = function () {
        if (this.departments && this.departments.length === 1) {
            this.department = this.departments[0];
            this.onDepartmentSelected();
        }
    };
    AuditSelectComponent.prototype.onInstitutionSelected = function () {
        var _this = this;
        this.wards = [];
        var selectedDepartment = this.department;
        var selectedWard = this.ward;
        this.department = null;
        this.ward = null;
        this.institutionLabel = this.institutionService.getInstitutionLabel(this.institution, false, true);
        if (this.institution && this.institution.logo == null && this.institution.group) {
            this.institutionLogo = this.institution.group.logo;
        }
        else {
            this.institutionLogo = this.institution.logo;
        }
        this.departmentLabel = this.institutionService.getDepartmentLabel(this.institution, false, true);
        this.wardLabel = this.institutionService.getWardLabel(this.institution, false, true);
        if (this.institution === null) {
            this.departments = [];
            this.auditForms = [];
        }
        else {
            this.logger.debug('Selected institution', this.institution);
            this.departments = this.institution.departments;
            if (selectedDepartment) {
                this.department = this.departments.find(function (d) { return d.id === selectedDepartment.id; }) || null;
                this.ward = selectedWard;
                this.onDepartmentSelected();
            }
            if (!this.department) {
                this.selectDepartmentIfOne();
                this.ward = null;
            }
            this.auditFormService.getAuditForms(this.institution).pipe(tap(function (auditForms) { return _this.auditForms = auditForms; })).subscribe(function (auditForms) {
                if (auditForms.length === 1) {
                    _this.auditForm = auditForms[0];
                    _this.onAuditFormSelected();
                }
                else if (_this.auditForm) {
                    // Persist audit form selection by updating selected audit form with instance from the list
                    var currentForm_1 = _this.auditForm;
                    _this.auditForm = auditForms.find(function (form) { return form.id === currentForm_1.id; }) || null;
                    if (_this.auditForm)
                        _this.onAuditFormSelected();
                }
            }, function (e) { return _this.logger.error(e); });
        }
        this.valid = this.isSelectionValid();
    };
    AuditSelectComponent.prototype.onAuditFormSelected = function () {
        this.updateDepartments();
        this.updateWards();
        if (this.ward != null && this.auditForm != null && this.auditForm.config != null
            && this.auditForm.config.wards.length && !this.auditForm.config.wards.includes(this.ward.id)) {
            this.ward = null;
        }
        this.valid = this.isSelectionValid();
    };
    /**
     * Updates wards list based off selected audit form and department
     */
    AuditSelectComponent.prototype.updateWards = function () {
        if (this.department != null) {
            this.wards = this.getAvailableWards(this.department);
            if (this.ward) {
                var selectedWard_1 = this.ward;
                this.ward = this.wards.find(function (w) { return w.id === selectedWard_1.id; }) || null;
            }
            if (!this.ward && this.wards.length === 1) {
                this.ward = this.wards[0];
            }
        }
    };
    AuditSelectComponent.prototype.getAvailableWards = function (department) {
        var result = department.wards;
        if (this.auditForm && this.auditForm.config.wards.length > 0) {
            var wardIds_1 = this.auditForm.config.wards;
            result = result.filter(function (ward) { return wardIds_1.indexOf(ward.id) !== -1; });
        }
        var enableSubmitAllWards = this.auditForm && this.auditForm.config ? this.auditForm.config.enable_submission_for_all_wards : false;
        if (this.user && this.user.auditor.wards.length > 0 && !enableSubmitAllWards) {
            var wardIds_2 = this.user.auditor.wards;
            result = result.filter(function (ward) { return wardIds_2.indexOf(ward.id) !== -1; });
        }
        return result;
    };
    AuditSelectComponent.prototype.onDepartmentSelected = function () {
        this.updateWards();
        this.valid = this.isSelectionValid();
    };
    AuditSelectComponent.prototype.proceedToNextScreen = function () {
        var _this = this;
        var auditForm = this.auditForm;
        var ward = this.ward;
        if (auditForm != null && ward != null) {
            this.auditService.setAuditFormId(auditForm.id);
            this.accountService.getUser().subscribe(function (user) {
                _this.auditService.createAudit(auditForm, ward, user).subscribe(function (audit) { return _this.router.navigate(['select-form', audit.id]); }, function (e) { return _this.logger.error(e); });
            });
        }
    };
    /**
     * Checks that all items have been selected and selection is consistent
     * @returns {boolean}
     */
    AuditSelectComponent.prototype.isSelectionValid = function () {
        if (this.institution && this.department && this.ward) {
            return (this.department.institution === this.institution.id && this.ward.department === this.department.id);
        }
        return false;
    };
    AuditSelectComponent.prototype.onStartClicked = function () {
        var _this = this;
        this.analytics.trackButtonClick('Start');
        this.analytics.trackButtonClick('Audit start');
        if (this.isSelectionValid()) {
            var name_1 = this.auditForm.name;
            this.checkAuditService.checkAuditFormUpdate(this.auditForm, function (result) {
                if (result === AuditCheckStatus.UPDATE_COMPLETE) {
                    _this.snackBar.open(_this.translateService.instant('audit-select.audit-updated', { audit_name: name_1 }), 'OK', { duration: 3000 });
                }
                if (_this.auditForm && _this.auditForm.config.intro_note && !_this.auditForm.config.intro_note.isEmpty()) {
                    _this.modalService.openInfoModal(of(_this.auditForm.config.intro_note)).subscribe();
                }
                _this.proceedToNextScreen();
            });
        }
    };
    AuditSelectComponent.prototype.selectAudit = function (audit) {
        this.logger.debug('Audit selected', audit);
        this.auditForm = audit.auditForm;
        if (this.institutions) {
            if (audit.wardData) {
                this.logger.debug('Selected ward', audit.wardData);
                var _a = audit.wardData, institution_1 = _a[0], department_1 = _a[1], ward_1 = _a[2];
                this.institution = this.institutions.find(function (i) { return i.id === institution_1.id; }) || institution_1;
                this.department = this.institution.departments.find(function (d) { return d.id === department_1.id; }) || department_1;
                this.ward = this.department.wards.find(function (w) { return w.id === ward_1.id; }) || ward_1;
                this.onInstitutionSelected();
                this.onDepartmentSelected();
            }
            else {
                // pick institution based on audit form if currently selected institution does not have the selected audit
                var institutionIds_1 = audit.auditForm.institution_ids;
                if (!this.institution || institutionIds_1.indexOf(this.institution.id) === -1) {
                    this.institution = this.institutions.find(function (institution) { return institutionIds_1.indexOf(institution.id) > -1; }) || null;
                }
                this.onInstitutionSelected();
            }
        }
    };
    return AuditSelectComponent;
}(BaseLoginRequiredComponent));
export { AuditSelectComponent };
