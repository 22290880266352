import {Region} from '../models/region';

export class UserAuth {
  token: string;
  username: string;
  region?: Region;


  constructor(token: string, username?: string, region?: Region) {
    this.username = username || '';
    this.token = token;
    this.region = region;
  }
}
