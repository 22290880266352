import {Injectable} from '@angular/core';
import {StorageService} from '../storage.service';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {Settings} from './settings';
import {tap} from 'rxjs/operators';
import {NORMAL_SCALE} from './scale_level';
import {NGXLogger} from 'ngx-logger';

const DB_KEY_SETTINGS = 'settings';

@Injectable()
export class SettingsService {
  public scaleLevel$: BehaviorSubject<number> = new BehaviorSubject<number>(NORMAL_SCALE);
  private settingsCache: Settings | null = null;

  constructor(private storageService: StorageService, private logger: NGXLogger) {
    this.readSettings().subscribe(
      (setting: Settings) => this.scaleLevel$.next(setting.scaleValue)
    );
  }

  public readSettings(): Observable<Settings> {
    if (this.settingsCache !== null) return of(this.settingsCache);
    return this.storageService.getItemOrDefault(DB_KEY_SETTINGS, new Settings()).pipe(
      // set default values for any new settings here
      tap((settings) => this.settingsCache = settings),
      tap((settings) => this.logger.debug('Settings read:', settings)),
    );
  }

  public saveSettings(settings: Settings): Observable<boolean> {
    this.scaleLevel$.next(settings.scaleValue);
    return this.storageService.setItem(DB_KEY_SETTINGS, settings).pipe(
      tap(() => this.settingsCache = settings),
      tap((result: boolean) => {
        if (result) this.logger.debug('Saved settings', settings);
      }),
    );
  }

}
