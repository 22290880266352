<div style="top: 100px;" *ngIf="!complete" class="submission-progress-bar limit-width center">
  <h1>{{'audit-submission.submission.submitting' | translate}}</h1>
  <mat-progress-bar [mode]="submissionService.numPhotos ? 'determinate' : 'indeterminate'" [value]="100 * (submissionService.photosUploaded + submissionService.photosFailed) / (submissionService.numPhotos || 0)"></mat-progress-bar>
</div>

<div *ngIf="complete" class="limit-width center">
  <div *ngIf="!error">
    <h1 class="title" translate>audit-submission.submission.success-message</h1>
    <div class="img-container">
      <div class="speech-bubble submitted-bubble">
        <p class="larger-text">{{'audit-submission.submission.success-message' | translate}}</p>
        <p class="smaller-text">{{'audit-submission.submission.success-subtext' | translate}}</p>
      </div>
      <img class="message-image" src="assets/images/audits-empty.png" alt="" />
      <div class="lone-btn-container">
        <button mat-raised-button class="lone-audit-button" routerLink="/" color="primary"><mat-icon>add</mat-icon> {{'audit-list.new-audit' | translate}}</button>
        <button type="button" mat-button (click)="logOut()">{{'menu.logout' | translate}}</button>
      </div>
    </div>
    <div class="unsubmitted-photos-message" *ngIf="submissionService.photosFailed">
      {{submissionService.photosFailed}} {{'audit-submission.submission.num_photos_failed' | translate}}
    </div>
  </div>
  <div *ngIf="error" class="error-msg">
    <h1 class="title">{{'error' | translate}}</h1>
    <div class="img-container">
      <div class="speech-bubble">
        <p class="larger-text">{{'audit-submission.submission.error-message' | translate}}</p>
        <p class="smaller-text">{{'audit-submission.submission.error-subtext' | translate}}</p>
      </div>
      <img class="message-image" src="assets/images/error-meg.png" alt="" />
    </div>
    <p class="subtitle">{{ errorMessage || error }}</p>
    <div class="subtitle">
    <button mat-raised-button class="error-btn" color="primary" *ngIf="audit && retryEnabled" (click)="submit(audit)"><mat-icon>replay</mat-icon> {{'retry' | translate}}</button>
    <button mat-raised-button class="error-btn" color="primary" *ngIf="audit && !audit.submitted" routerLink="/audit-preview/{{ audit.id }}"><mat-icon>edit</mat-icon> {{'audit-submission.submission.review-data' | translate}}</button>
    <button mat-raised-button class="error-btn" color="primary" *ngIf="!audit" routerLink="/draft-audits"><mat-icon>visibility</mat-icon> {{ 'menu.saved-audits' | translate}}</button>
    </div>
  </div>
</div>
