/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./channel-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i8 from "@angular/router";
import * as i9 from "@angular/material/list";
import * as i10 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "@ngx-translate/core";
import * as i13 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i14 from "@angular/material/button";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "./channel-list.component";
import * as i17 from "../messaging.service";
import * as i18 from "@angular/material/bottom-sheet";
var styles_ChannelListComponent = [i0.styles];
var RenderType_ChannelListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChannelListComponent, data: {} });
export { RenderType_ChannelListComponent as RenderType_ChannelListComponent };
function View_ChannelListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ChannelListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "a", [["class", "mat-list-item"], ["mat-list-item", ""]], [[1, "target", 0], [8, "href", 4], [2, "mat-list-item-avatar", null], [2, "mat-list-item-with-avatar", null]], [[null, "click"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleFocus() !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleBlur() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i7.View_MatListItem_0, i7.RenderType_MatListItem)), i1.ɵdid(1, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1097728, null, 3, i9.MatListItem, [i1.ElementRef, [2, i9.MatNavList]], null, null), i1.ɵqud(603979776, 1, { _lines: 1 }), i1.ɵqud(335544320, 2, { _avatar: 0 }), i1.ɵqud(335544320, 3, { _icon: 0 }), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "mat-icon", [["class", "mat-icon mat-list-icon"], ["matListIcon", ""], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(7, 638976, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null]], null, null), i1.ɵdid(8, 16384, [[3, 4]], 0, i9.MatListIconCssMatStyler, [], null, null), (_l()(), i1.ɵted(9, 0, ["", ""])), (_l()(), i1.ɵted(10, 2, [" ", " "]))], function (_ck, _v) { var currVal_4 = i1.ɵinlineInterpolate(1, "/channels/", _v.context.$implicit.id, "/messages"); _ck(_v, 1, 0, currVal_4); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; var currVal_2 = (i1.ɵnov(_v, 2)._avatar || i1.ɵnov(_v, 2)._icon); var currVal_3 = (i1.ɵnov(_v, 2)._avatar || i1.ɵnov(_v, 2)._icon); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 7).inline; _ck(_v, 6, 0, currVal_5); var currVal_6 = _co.getChannelIcon(_v.context.$implicit); _ck(_v, 9, 0, currVal_6); var currVal_7 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_7); }); }
export function View_ChannelListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "channels-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChannelListComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "mat-nav-list", [["class", "mat-nav-list"], ["role", "navigation"]], null, null, null, i7.View_MatNavList_0, i7.RenderType_MatNavList)), i1.ɵdid(7, 49152, null, 0, i9.MatNavList, [], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ChannelListComponent_2)), i1.ɵdid(9, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "fixed-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "button", [["color", "accent"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showNewMessageDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_MatButton_0, i13.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i14.MatButton, [i1.ElementRef, i4.Platform, i15.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(14, 638976, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["comment"])), (_l()(), i1.ɵted(16, 0, [" ", ""])), i1.ɵpid(131072, i12.TranslatePipe, [i12.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.channels === undefined); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.channels; _ck(_v, 9, 0, currVal_2); var currVal_5 = "accent"; _ck(_v, 12, 0, currVal_5); _ck(_v, 14, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("messaging.messages")); _ck(_v, 2, 0, currVal_0); var currVal_3 = (i1.ɵnov(_v, 12).disabled || null); var currVal_4 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 14).inline; _ck(_v, 13, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("messaging.new-message")); _ck(_v, 16, 0, currVal_7); }); }
export function View_ChannelListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-channel-list", [], null, null, null, View_ChannelListComponent_0, RenderType_ChannelListComponent)), i1.ɵdid(1, 114688, null, 0, i16.ChannelListComponent, [i17.MessagingService, i18.MatBottomSheet], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChannelListComponentNgFactory = i1.ɵccf("meg-channel-list", i16.ChannelListComponent, View_ChannelListComponent_Host_0, {}, {}, []);
export { ChannelListComponentNgFactory as ChannelListComponentNgFactory };
