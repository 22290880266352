import {Injectable} from '@angular/core';
import {Institution, InstitutionGroup} from './api/models/institution';
import {StorageService} from './storage.service';
import {Observable} from 'rxjs';
import {Ward} from './api/models/ward';
import {map} from 'rxjs/operators';
import {Department} from './api/models/department';
import {TranslateService} from '@ngx-translate/core';
import {of} from 'rxjs/internal/observable/of';
import {capitalizeFirstLetter, flattenArrays} from './utils/misc';
import {Room} from './api/models/issue';

export const DB_KEY_INSTITUTIONS = 'institutions';
/**
 * Stores information about institutions, departments and wards
 */
@Injectable()
export class InstitutionService {

  constructor(private storageService: StorageService, private translateService: TranslateService) {
  }

  public saveInstitutions(institutions: Institution[]): Observable<boolean> {
    return this.storageService.setItem(DB_KEY_INSTITUTIONS, institutions);
  }

  public getInstitutions(): Observable<Institution[]> {
    return this.storageService.getItem<Institution[]>(DB_KEY_INSTITUTIONS);
  }

  public getInstitutionOrNull(id: number): Observable<Institution | null> {
    return this.storageService.getItem<Institution[]>(DB_KEY_INSTITUTIONS).pipe(
      map((institutions: Institution[]) => institutions.find(institution => institution.id === id) || null),
    );
  }

  public getWard(wardId: number): Observable<Ward> {
    return this.getWardData(wardId).pipe(
      map((data: [Institution, Department, Ward]) => data[2]),
    );
  }

  /**
   * Gets wards with specific IDs or all wards if the list is empty.
   * @param wardIds or null to return unfiltered wards
   */
  public getWards(wardIds: number[]): Observable<Ward[]> {
    return this.getInstitutions().pipe(
      // Collect wards from all user's institutions and departments
      map((institutions: Institution[]) => {
        return institutions.map((institution: Institution) => {
          return institution.departments.map((department: Department) => department.wards);
        });
      }),
      // flatten the resulting list into a single list
      map((wards: Ward[][][]): Ward[][] => flattenArrays(wards)),
      map((wards: Ward[][]): Ward[] => flattenArrays(wards)),
      // filter wards
      map((wards: Ward[]): Ward[] => {
        if (wardIds.length === 0) return wards;
        return wards.filter((ward: Ward): boolean => wardIds.indexOf(ward.id) !== -1);
      }),
    );
  }

  /** Given ward id, fetches ward object from database together with its institution and department */
  public getWardData(wardId: number): Observable<[Institution, Department, Ward]> {
    function findWard(institutions: Institution[]): [Institution, Department, Ward] {
      for (const institution of institutions)
        for (const department of institution.departments)
          for (const ward of department.wards)
            if (ward.id === wardId)
            return [institution, department, ward];
      throw new Error(`Could not find ward with id ${wardId}`);
    }
    return this.getInstitutions().pipe(
      map(findWard),
    );
  }

  /**
   * Gets Room by id together with its Ward
   * Raises error if room cannot be found
   * @param roomId
   */
  public getRoomData(roomId: number): Observable<[Institution, Department, Ward, Room]> {
    return this.getInstitutions().pipe(
      map((institutions: Institution[]): [Institution, Department, Ward, Room] => {
        for (const institution of institutions)
          for (const department of institution.departments)
            for (const ward of department.wards)
              for (const room of ward.rooms)
                if (room.id === roomId) return [institution, department, ward, room];
        throw Error(`No room with id ${roomId}`);
      }),
    );
  }

  /**
   * Gets observable string term used to describe Institution in current institution group
   * @param {Institution | null} institution leave null to get default term
   * @param {boolean} plural whether the term should be in plural form
   * @param {boolean} capitalize whether the first letter should be uppercase
   */
  public getInstitutionLabel(institution: Institution | null, plural = false, capitalize = false): Observable<string> {
    let result: Observable<string>;
    if (institution === null || institution.terms === undefined || institution.terms.institution === undefined) {
      // can be undefined if old cached version is used or institution is not provided
      result = this.translateService.get('institution');
    } else {
      const term = institution.terms['institution'];
      result = of(term[plural ? 'plural' : 'singular']);
    }
    return result.pipe(
      map((s: string) => capitalize ? capitalizeFirstLetter(s) : s),
    );
  }

  /**
   * Gets observable string term used to describe Department in current institution
   * @param {Institution | null} institution leave null to get default term
   * @param {boolean} plural whether the term should be in plural form
   * @param {boolean} capitalize whether the first letter should be uppercase
   */
  public getDepartmentLabel(institution: Institution | null, plural = false, capitalize = false): Observable<string> {
    let result: Observable<string>;
    if (institution === null || institution.terms === undefined) {
      // can be undefined if old cached version is used or institution is not provided
      result = this.translateService.get('department');
    } else {
      const term = institution.terms['department'];
      result = of(term[plural ? 'plural' : 'singular']);
    }
    return result.pipe(
      map((s: string) => capitalize ? capitalizeFirstLetter(s) : s),
    );
  }

  /**
   * Gets observable string term used to describe Ward  in current institution
   * @param {Institution | null} institution leave null to get default term
   * @param {boolean} plural whether the term should be in plural form
   * @param {boolean} capitalize whether the first letter should be uppercase
   */
  public getWardLabel(institution: Institution | null, plural = false, capitalize = false): Observable<string> {
    let result: Observable<string>;
    if (institution === null || institution.terms === undefined) {
      // can be undefined if old cached version is used or institution is not provided
      result = this.translateService.get('ward');
    } else {
      const term = institution.terms['ward'];
      result = of(term[plural ? 'plural' : 'singular']);
    }
    return result.pipe(
      map((s: string) => capitalize ? capitalizeFirstLetter(s) : s),
    );
  }
}
