<div class="issue-body" *ngIf="issue">
  <h3 class="preview-issue-title-long" [class.preview-issue-title]="issue.audio || photos$">{{issue.comment}}</h3>
  <div class="icon-container">
    <mat-icon *ngIf="issue.audio">mic</mat-icon>
    <mat-icon *ngIf="photos$ && (photos$ | async).length > 0">photo</mat-icon>
    <p class="photo-count" *ngIf="photos$ && (photos$ | async).length > 1">{{(photos$ | async).length}}×</p>
  </div>
  <div class="preview-issue-info" *ngIf="issue.room">{{'audit-submission.room' | translate}} {{issue?.room?.name}}</div>
  <div class="preview-issue-info" *ngIf="!issue.room">{{'audit-submission.room' | translate}} -</div>
  <div class="preview-issue-info long" *ngIf="formName">{{formName}}</div>
  <div class="preview-issue-info long" *ngIf="!formName">{{'audit-submission.form' | translate}} -</div>
</div>
