var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, NgZone, OnInit } from '@angular/core';
import { LoginCredentials } from './login_credentials';
import { AccountService } from '../accounts.service';
import { InstitutionService } from '../institution.service';
import { ApiService, STATUS_REQUEST_ERROR, STATUS_SERVER_ERROR, STATUS_UNAUTHORISED } from '../api/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AnalyticsService } from '../analytics.service';
import { WalkthroughService } from '../walkthrough/walkthrough.service';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
import { BaseComponent } from '../base.component';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { UserAuth } from '../api/responses/user-auth';
import { parseBase64Object } from '../utils/misc';
export var contactUs = 'login.contact-us';
export var requestTrial = 'login.free-trial';
var LoginComponent = /** @class */ (function (_super) {
    __extends(LoginComponent, _super);
    function LoginComponent(accountService, institutionService, apiService, router, analytics, snackBar, walkthroughService, dialog, activatedRoute, injector, zone) {
        var _this = _super.call(this, injector) || this;
        _this.accountService = accountService;
        _this.institutionService = institutionService;
        _this.apiService = apiService;
        _this.router = router;
        _this.analytics = analytics;
        _this.snackBar = snackBar;
        _this.walkthroughService = walkthroughService;
        _this.dialog = dialog;
        _this.activatedRoute = activatedRoute;
        _this.injector = injector;
        _this.zone = zone;
        _this.credentials = new LoginCredentials('', '');
        _this.sendingRequest = false;
        _this.errors = null;
        _this.requestTrialText = requestTrial;
        _this.nextUrl = null;
        return _this;
    }
    LoginComponent.prototype.handleDeepLink = function (eventData) {
        var _this = this;
        this.logger.debug('Received deep link event', eventData);
        var params = eventData.params;
        if (params.next !== undefined)
            this.nextUrl = params.next;
        var auth = null;
        if (params.auth !== undefined)
            auth = parseBase64Object(params.auth);
        else if (params.auth_token)
            auth = new UserAuth(params.auth_token);
        if (auth === null || auth.token === null) {
            this.sendingRequest = false;
        }
        else {
            this.sendingRequest = true;
            this.apiService.tokenLogin(auth.token, auth.region).subscribe(function (loginResponse) { return _this.onLoginSuccess(loginResponse); });
        }
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        var cordova = window.cordova;
        if (cordova !== undefined && cordova.platformId === 'ios') {
            this.requestTrialText = contactUs;
        }
        this.apiService.buildUrl('/app/').pipe(tap(function (url) { return _this.logger.debug('Single sign-on url', url); }), tap(function (url) { return _this.ssoLoginUrl = url; })).subscribe();
        // clear previous login data before logging in a new user
        this.accountService.clearLogin().pipe(mergeMap(function () { return _this.autoLogin(); }), tap(function (loggedIn) {
            // show tutorial only when user is not being logged-in automatically
            if (!loggedIn)
                _this.showWalkthrough();
        })).subscribe();
        var UniversalLinks = window.universalLinks;
        if (UniversalLinks) {
            UniversalLinks.subscribe(null, function (eventData) { return _this.zone.run(function () { return _this.handleDeepLink(eventData); }); });
        }
    };
    /**
     * Authenticate user automatically using token passed in url.
     * Does nothing if auth_token was not passed.
     */
    LoginComponent.prototype.autoLogin = function () {
        var _this = this;
        return this.activatedRoute.queryParams.pipe(map(function (params) {
            if (params.next !== undefined)
                _this.nextUrl = params.next;
            if (params.auth !== undefined)
                return parseBase64Object(params.auth);
            else if (params.auth_token)
                return new UserAuth(params.auth_token);
            else
                return null;
        }), tap(function (auth) {
            if (auth === null || auth.token === null)
                throw new Error();
            else
                _this.sendingRequest = true;
        }), mergeMap(function (auth) {
            _this.logger.debug('Using token for login', auth);
            return _this.apiService.tokenLogin(auth.token, auth.region);
        }), tap(function (loginResponse) { return _this.onLoginSuccess(loginResponse); }), map(function () { return true; }), catchError(function () {
            _this.sendingRequest = false;
            return of(false);
        }));
    };
    /** Shows walkthrough to user it it was not shown yet. Returns true if user is redirected to walkthrough */
    LoginComponent.prototype.showWalkthrough = function () {
        var show = !this.walkthroughService.wasShown();
        if (show)
            this.router.navigate(['intro']);
        return show;
    };
    LoginComponent.prototype.onLoginClicked = function () {
        this.attemptLogin();
    };
    /**
     * Sends login request to back-end
     */
    LoginComponent.prototype.attemptLogin = function (region) {
        var _this = this;
        this.sendingRequest = true;
        this.errors = null;
        var credentials = this.credentials;
        this.logger.debug("Logging in as " + credentials);
        credentials.captcha = this.accountService.captchaTokenSubj;
        this.apiService.login(credentials, region).subscribe(function (loginResponse) { return _this.onLoginSuccess(loginResponse); }, function (error) { return _this.onLoginError(error); });
        this.analytics.trackButtonClick('Login');
    };
    LoginComponent.prototype.onRequestFreeTrialClicked = function () {
        var _this = this;
        this.analytics.trackButtonClick('Request free trial');
        this.analytics.trackAction('Request free trial');
        this.dialog.open(FeedbackDialogComponent, {
            width: '100%',
            data: null,
        }).afterClosed().pipe(mergeMap(function (result) {
            if (result)
                return _this.modalService.openInfoModal(_this.translateService.get('contact.success-message'));
            else
                return of(false);
        })).subscribe();
    };
    LoginComponent.prototype.cancelLogin = function () {
        this.credentials.password = '';
        this.sendingRequest = false;
    };
    LoginComponent.prototype.onLoginSuccess = function (loginResponse) {
        var _this = this;
        this.accountService.setLoggedIn(loginResponse).subscribe(function (result) {
            _this.analytics.trackAction('Login successful');
            if (loginResponse.require_token) {
                _this.logger.debug(loginResponse);
                var token = prompt(_this.translateService.instant('login.enter-mfa-token'));
                if (token) {
                    _this.credentials.two_factor_token = token;
                    // make second factor request directly to the downstream region as
                    // now we know the exact region where user has their account.
                    _this.attemptLogin(loginResponse.region || undefined);
                }
                else {
                    _this.cancelLogin();
                }
            }
            else {
                var user = loginResponse.user;
                _this.accountService.setLanguage(user.auditor.default_language);
                _this.logger.debug("Successfully logged in as " + user.username);
                _this.router.navigateByUrl(_this.nextUrl ? "/loading?next=" + _this.nextUrl : '/loading');
            }
        }, function (e) {
            _this.logger.error(e);
            _this.cancelLogin();
            _this.snackBar.open('default-error', undefined, {
                duration: 3000,
            });
        });
    };
    LoginComponent.prototype.onLoginError = function (error) {
        this.logger.error(error);
        this.cancelLogin();
        if (error instanceof HttpErrorResponse) {
            switch (error.status) {
                case STATUS_REQUEST_ERROR:
                    this.errors = error.error;
                    this.analytics.trackAction('Login error: invalid request');
                    break;
                case STATUS_UNAUTHORISED:
                    this.modalService.openErrorModal(this.translateService.get('login.unauthorised-error')).subscribe();
                    this.analytics.trackAction('Login error: invalid password');
                    break;
                case STATUS_SERVER_ERROR:
                    this.modalService.openErrorModal(this.translateService.get('server-error')).subscribe();
                    this.analytics.trackAction('Login error: server error');
                    break;
                default:
                    this.modalService.openErrorModal(this.translateService.get('no-internet-error')).subscribe();
                    this.analytics.trackAction('Login error: offline');
            }
        }
    };
    LoginComponent.prototype.onForgotPasswordClicked = function () {
        this.analytics.trackButtonClick('Forgot Password');
        this.analytics.trackAction('Forgot password');
        this.dialog.open(ForgotPasswordDialogComponent);
    };
    return LoginComponent;
}(BaseComponent));
export { LoginComponent };
