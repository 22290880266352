import { NGXLogger } from 'ngx-logger';
import { AccountService } from '../accounts.service';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "ngx-logger";
import * as i2 from "../accounts.service";
var PushService = /** @class */ (function () {
    function PushService(logger, accounts) {
        var _this = this;
        this.logger = logger;
        this.accounts = accounts;
        accounts.currentUser$.subscribe(function (user) { return _this.updateUserTags(user); });
    }
    PushService.prototype.updateUserTags = function (user) {
        var UAirship = window.UAirship;
        if (UAirship === undefined)
            return;
        if (user) {
            UAirship.setNamedUser(user.id + ":" + user.username);
        }
        else {
            UAirship.setNamedUser('');
        }
        this.logger.debug('Updated named user', user);
    };
    PushService.prototype.setupPushNotifications = function () {
        var _this = this;
        this.logger.debug('Setting up Push notifications');
        var UAirship = window.UAirship;
        if (UAirship === undefined) {
            this.logger.info('UAirship is not defined. Skipping push notifications setup.');
            return;
        }
        UAirship.takeOff({
            production: environment.urbanairship_keys,
            development: environment.urbanairship_keys,
            site: 'US',
        });
        UAirship.setAndroidNotificationConfig({
            icon: 'ic_notification',
            largeIcon: 'ic_notification',
            accentColor: '#009EE2'
        });
        UAirship.setPresentationOptions(UAirship.presentationOptions.sound | UAirship.presentationOptions.alert);
        UAirship.setUserNotificationsEnabled(true, function (enabled) { return _this.logger.debug('User notifications', enabled); });
        UAirship.getChannelID(function (id) { return _this.logger.info('ChannelID', id); });
        this.logger.debug('Push notifications setup complete.');
    };
    PushService.prototype.onAirshipRegistration = function (event) {
        this.logger.debug('Airship registered', event);
    };
    PushService.prototype.onPushReceived = function (event) {
        this.logger.debug('Received push message:', event.message);
    };
    PushService.ngInjectableDef = i0.defineInjectable({ factory: function PushService_Factory() { return new PushService(i0.inject(i1.NGXLogger), i0.inject(i2.AccountService)); }, token: PushService, providedIn: "root" });
    return PushService;
}());
export { PushService };
