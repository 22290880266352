<meg-issue-filter></meg-issue-filter>
<mat-nav-list *ngIf="items !== undefined">
  <a mat-list-item class="issue" *ngFor="let item of items; last as last" routerLink="/qip/{{item.issue.id}}">
    <h3 matLine>{{item.issue.comment}}</h3>
    <mat-chip-list class="status-container small-status-container">
      <mat-chip color="primary" class="issue-status small-issue-status" [ngStyle]="{backgroundColor: item.status?.color}">{{item.status?.label|translate}}</mat-chip>
    </mat-chip-list>
    <mat-icon>keyboard_arrow_right</mat-icon>
    <p matLine>
      <span class="dark-text">{{'qip.location' | translate}}</span> {{item.location$|async}}
    </p>
    <p matLine>
      <span class="dark-text">{{'qip.assigned-to' | translate}}</span> {{item.handler$|async}}
    </p>
  </a>
  <div *ngIf="items.length === 0" class="img-container">
    <div class="speech-bubble submitted-bubble">
      <p class="smaller-text" *ngIf="!error">{{'qip.issue-list.list-empty'|translate}}</p>
      <p class="smaller-text" *ngIf="error">{{'qip.issue-list.error'|translate}}</p>
    </div>
    <img class="message-image" src="assets/images/audits-empty.png" alt="" />
  </div>
</mat-nav-list>
<mat-progress-spinner mode="indeterminate" *ngIf="items === undefined"></mat-progress-spinner>
