var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseChart } from './base_chart';
var LineChart = /** @class */ (function (_super) {
    __extends(LineChart, _super);
    function LineChart() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LineChart.prototype.buildChart = function (widget) {
        var chartDataValues = this.chartCalculator.getFieldChartData(widget);
        var chartValues = this.buildChartData(chartDataValues);
        var dataSets = [{ data: chartValues.data, borderColor: this.chartColors[0], fill: false }];
        var chartData = { labels: chartValues.labels, datasets: dataSets };
        var options = {
            title: {
                display: true,
                text: this.getChartTitles(widget),
            },
            legend: {
                display: false
            },
            scales: {
                xAxes: [{
                        display: true
                    }],
                yAxes: [{
                        display: true
                    }],
            }
        };
        return { type: 'line', data: chartData, options: options };
    };
    return LineChart;
}(BaseChart));
export { LineChart };
