var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DASHBOARD_WIDGET_SUB_FORM_BAR } from '../../api/models/dashboard';
import { BaseChart } from './base_chart';
var BarChart = /** @class */ (function (_super) {
    __extends(BarChart, _super);
    function BarChart() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BarChart.prototype.getChartData = function (widget) {
        var chartDataValues = [];
        if (widget.widget === DASHBOARD_WIDGET_SUB_FORM_BAR) {
            chartDataValues = this.chartCalculator.getSubFormChartData();
        }
        else {
            chartDataValues = this.chartCalculator.getFieldChartData(widget);
        }
        var chartValues = this.buildChartData(chartDataValues);
        return chartValues.labels.map(function (value, index) {
            return ["(" + (index + 1) + "): " + chartValues.labels[index], chartValues.colors[index]];
        });
    };
    BarChart.prototype.buildChart = function (widget) {
        var chartDataValues;
        if (widget.widget === DASHBOARD_WIDGET_SUB_FORM_BAR) {
            chartDataValues = this.chartCalculator.getSubFormChartData();
        }
        else {
            chartDataValues = this.chartCalculator.getFieldChartData(widget);
        }
        var chartValues = this.buildChartData(chartDataValues);
        var labels = Array.apply(null, { length: chartValues.labels.length }).map(function (value, index) {
            return "" + (index + 1);
        });
        var dataSets = [{ data: chartValues.data, backgroundColor: chartValues.colors }];
        var chartData = { labels: labels, datasets: dataSets };
        var scales = {};
        if (widget.config.compliance) {
            scales = {
                xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                        }
                    }],
                yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                            callback: function (value) {
                                return value + "%";
                            },
                        },
                        scaleLabel: {
                            display: false,
                        }
                    }]
            };
        }
        else {
            scales = {
                xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                        }
                    }],
                yAxes: [{
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            callback: function (value) {
                                return value;
                            },
                        },
                        scaleLabel: {
                            display: false,
                        }
                    }]
            };
        }
        var options = {
            legend: {
                display: false,
            },
            responsive: true,
            title: {
                display: true,
                text: this.getChartTitles(widget),
            },
            scales: scales,
        };
        return { type: 'bar', data: chartData, options: options };
    };
    return BarChart;
}(BaseChart));
export { BarChart };
