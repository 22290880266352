/**
 * Holds accordion item data inside observation component.
 * Used to bind sub-observation to its subform
 */
var AccordionItem = /** @class */ (function () {
    function AccordionItem(subform, subObservation, fieldErrors) {
        if (fieldErrors === void 0) { fieldErrors = null; }
        this.subform = subform;
        this.subObservation = subObservation;
        this.fieldErrors = fieldErrors;
    }
    AccordionItem.prototype.toString = function () {
        return this.subform.display_name;
    };
    Object.defineProperty(AccordionItem.prototype, "showAddAnotherBtn", {
        /**
         * Whether this accordion should have "Add Another" button
         * @returns {boolean}
         */
        get: function () {
            return this.subform.many;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccordionItem.prototype, "description", {
        get: function () {
            var fields = this.subform.fields;
            // if subform has no questions, return empty string
            if (fields.length === 0)
                return '';
            var firstFieldName = fields[0].field_name;
            var value = this.subObservation[firstFieldName];
            var field = this.subform.fields.find(function (f) { return f.field_name === firstFieldName; });
            if (field && field.choices) {
                var choice = field.choices.find(function (c) { return c.value === value; });
                if (choice)
                    value = choice.label;
            }
            return (value || '').toString();
        },
        enumerable: true,
        configurable: true
    });
    return AccordionItem;
}());
export { AccordionItem };
