var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, throwError } from 'rxjs';
import { AuditService } from '../audit-form/audit.service';
import { SubmissionService } from './submission.service';
import { BaseLoginRequiredComponent } from '../base';
import { AccountService } from '../accounts.service';
import { STATUS_REQUEST_ERROR, STATUS_NOT_FOUND, STATUS_FORBIDDEN } from '../api/api.service';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from '../loading/download.service';
import { AuditFormService } from '../audit-form.service';
import { NGXLogger } from 'ngx-logger';
import { AnalyticsService } from '../analytics.service';
var SubmissionComponent = /** @class */ (function (_super) {
    __extends(SubmissionComponent, _super);
    function SubmissionComponent(router, route, accountService, activatedRoute, auditService, submissionService, translationService, analytics, downloadService, auditFormService, logger) {
        var _this = _super.call(this, router, route, accountService, logger) || this;
        _this.activatedRoute = activatedRoute;
        _this.auditService = auditService;
        _this.submissionService = submissionService;
        _this.translationService = translationService;
        _this.analytics = analytics;
        _this.downloadService = downloadService;
        _this.auditFormService = auditFormService;
        _this.complete = false;
        _this.retryEnabled = true;
        _this.routeSubscription = null;
        _this.errorMessage = null;
        _this.error = null;
        return _this;
    }
    SubmissionComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.isLoggedIn()) {
            this.routeSubscription = this.activatedRoute.params.pipe(switchMap(function (params) {
                var auditId = +params['audit_id'];
                return _this.auditService.getAudit(auditId);
            }), tap(function (audit) { return _this.audit = audit; })).subscribe(function (audit) { return _this.submit(audit); }, function (error) {
                _this.translationService.get('audit-submission.submission.already-submitted').subscribe(function (message) {
                    _this.errorMessage = message;
                });
                _this.complete = true;
            });
        }
    };
    SubmissionComponent.prototype.submit = function (audit) {
        var _this = this;
        this.complete = false;
        this.errorMessage = null;
        this.error = null;
        this.retryEnabled = true;
        // Reset current audit to hide audit counter from Toolbar
        this.auditService.setAuditId(null);
        this.submissionService.submitAudit(audit).pipe(mergeMap(function (result) {
            if (!result) {
                return _this.translationService.get(['error', 'audit-submission.submission.media-error']).pipe(tap(function (translations) {
                    _this.error = translations['error'];
                    _this.errorMessage = translations['audit-submission.submission.media-error'];
                }), map(function () { return result; }));
            }
            else {
                return of(result);
            }
        }), catchError(function (error, responseObservable) {
            if (error.status === STATUS_REQUEST_ERROR) {
                return _this.auditFormService.getAuditFormSchemaLastModified(audit.auditFormId).pipe(mergeMap(function (lastModifiedDate) { return _this.downloadService.downloadAuditForm(audit.auditFormId, lastModifiedDate); })).pipe(mergeMap(function () { return throwError(error); }));
            }
            else {
                return throwError(error);
            }
        })).subscribe(function () {
            _this.analytics.trackAction('Audit submitted');
        }, function (error) {
            _this.analytics.trackAction('Audit submission error');
            _this.error = error;
            _this.errorMessage = error.statusText;
            if (error.status === STATUS_REQUEST_ERROR) {
                _this.logger.error('Submission request error', error);
                audit.errors = error.error;
                var translationKey = 'audit-submission.submission.request-error';
                var detailedErrors_1 = error.error.non_field_detailed_errors;
                var containsError = function (errors) { return detailedErrors_1.map(function (x) { return x.code; }).filter(function (value) { return errors.includes(value); }).length > 0; };
                if (containsError(['invalid_ward'])) {
                    translationKey = 'audit-submission.submission.unavailable-error';
                }
                else if (containsError(['invalid_fields'])) {
                    translationKey = 'audit-submission.submission.fields-error';
                    _this.retryEnabled = false;
                }
                else if (containsError(['auto_cycle_not_complete'])) {
                    translationKey = 'audit-submission.submission.auto-cycle-not-complete';
                    _this.retryEnabled = false;
                }
                _this.translationService.get(translationKey).subscribe(function (message) {
                    _this.errorMessage = message;
                });
            }
            else if (error.status === STATUS_NOT_FOUND || error.status === STATUS_FORBIDDEN) {
                // Will return 404 when audit form is not available or 403 when user doesn't have access to it
                _this.logger.error('Submission request error', error);
                _this.translationService.get('audit-submission.submission.unavailable-error').subscribe(function (message) {
                    _this.errorMessage = message;
                });
            }
            else if (error.status === 0) {
                _this.translationService.get('audit-submission.submission.network-error').subscribe(function (message) {
                    _this.errorMessage = message;
                });
            }
            else {
                audit.errors = null;
                _this.logger.error('Submission error', error);
            }
            // save audit to update errors and mark request as complete when finished
            _this.auditService.saveAudit(audit).subscribe(function () { return _this.complete = true; });
        }, function () { return _this.complete = true; });
    };
    SubmissionComponent.prototype.ngOnDestroy = function () {
        if (this.routeSubscription !== null) {
            this.routeSubscription.unsubscribe();
            this.routeSubscription = null;
        }
    };
    SubmissionComponent.prototype.logOut = function () {
        this.accountService.logOut(this.auditService, this.router);
    };
    return SubmissionComponent;
}(BaseLoginRequiredComponent));
export { SubmissionComponent };
