import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepickerInputEvent, MatDialog} from '@angular/material';
import {TimePickerDialogComponent} from '../time-field/time-picker-dialog/time-picker-dialog.component';
import * as moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {CustomDatePickerComponent} from '../custom-date-picker/custom-date-picker.component';
import {TranslateService} from '@ngx-translate/core';

export const ISO_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY-MM-DD',
  },
};

@Component({
  selector: 'meg-date-time-field',
  templateUrl: './date-time-field.component.html',
  styleUrls: ['./date-time-field.component.css'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimeFieldComponent), multi: true },
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: ISO_DATE_FORMATS},
  ]
})
export class DateTimeFieldComponent extends CustomDatePickerComponent implements OnChanges {

  @Input() formEnabled = true;

  public format = ISO_DATE_FORMATS.parse.dateInput;
  public dateTimeFormControl = new FormControl();
  public datePart = '';
  public timePart = '';
  public cssClass = '';
  private dateTimeValue = '';

  constructor(private dialog: MatDialog, adapter: DateAdapter<any>, translateService: TranslateService) {
    super(adapter, translateService);
  }

  ngOnChanges(): void {
    this.cssClass = this.formEnabled ? '' : 'disabled-view';
  }

  private splitDateTime(dateTime: string) {
    const dateTimeParts: string[] = dateTime.split(' ');
    this.datePart = dateTimeParts[0];
    this.timePart = dateTimeParts[1];
  }

  private updateFormControl() {
    // only update the form control if both values are entered.
    if (!this.datePart.isEmpty() && !this.timePart.isEmpty()) {
      this.dateTimeValue = `${this.datePart} ${this.timePart}`;
      this.onDateChange(this.dateTimeValue);
    }
  }

  showPicker() {
    const dialogRef = this.dialog.open(TimePickerDialogComponent, {
      data: this.timePart
    });
    dialogRef.afterClosed().subscribe((result: string | boolean) => {
      if (result === false || result === undefined) {
        return;
      } else {
        this.timePart = result as string;
        this.updateFormControl();
      }
    });
  }

  onWriteValueChange(date: string) {
    if (date) {
      this.dateTimeFormControl.setValue(date);
      this.splitDateTime(date);
    } else {
      this.timePart = '';
      this.datePart = '';
    }
  }

  onDateInput(type: string, event: MatDatepickerInputEvent<Date>) {
    if (event.value) {
      const dateValue = moment(event.value);
      this.datePart = dateValue.format(this.format);
      this.updateFormControl();
    }
  }
}
