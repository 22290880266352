<mat-form-field class="no-underline">
  <input matInput readonly
    [max]="maxDate"
    [matDatepicker]="picker"
    [formControl]="dateFormControl"
    (dateInput)="onDateInput('input', $event)"
    (focus)="picker.open()"
    (click)="picker.open()"
  >
  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker
    [startView]="startView"
    [startAt]="startDate"
  >
  </mat-datepicker>
</mat-form-field>
