var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { ApiService } from '../api/api.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuditService } from '../audit-form/audit.service';
import { AccountService } from '../accounts.service';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BackupService } from '../backup.service';
import { InstitutionService } from '../institution.service';
import { DownloadService } from '../loading/download.service';
var SubmissionPickerComponent = /** @class */ (function () {
    function SubmissionPickerComponent(dialogRef, logger, api, auditService, changeDetectorRef, accounts, institutionService, backupService, trans, snackbar, downloadService) {
        this.dialogRef = dialogRef;
        this.logger = logger;
        this.api = api;
        this.auditService = auditService;
        this.changeDetectorRef = changeDetectorRef;
        this.accounts = accounts;
        this.institutionService = institutionService;
        this.backupService = backupService;
        this.trans = trans;
        this.snackbar = snackbar;
        this.downloadService = downloadService;
        this.loading = true;
        this.subscriptions = [];
        this.options = [];
    }
    SubmissionPickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var subscription = forkJoin([
            this.api.fetchSubmissionsList(),
            this.api.getBackupList()
        ]).pipe(mergeMap(function (_a) {
            var submissions = _a[0], backups = _a[1];
            var wardIds = submissions.filter(function (x) { return !!x.ward; }).map(function (x) { return x.ward; }).concat(backups.map(function (x) { return x.data.wardId; }));
            var formIds = submissions.filter(function (x) { return !!x.audit_form; }).map(function (x) { return x.audit_form; }).concat(backups.map(function (x) { return x.data.auditFormId; }));
            return forkJoin([_this.downloadService.getAuditFormsWithSchema(formIds, true), _this.institutionService.getWards(wardIds)]).pipe(map(function (_a) {
                var forms = _a[0], wards = _a[1];
                var formMap = forms.reduce(function (m, _a) {
                    var form = _a[0];
                    var _b;
                    return (__assign({}, m, (_b = {}, _b[form.id] = form, _b)));
                }, {});
                var schemaMap = forms.reduce(function (m, _a) {
                    var form = _a[0], _b = _a[1], schema = _b === void 0 ? null : _b;
                    var _c;
                    return (__assign({}, m, (_c = {}, _c[form.id] = schema, _c)));
                }, {});
                var wardMap = wards.reduce(function (m, obj) {
                    var _a;
                    return (__assign({}, m, (_a = {}, _a[obj.id] = obj, _a)));
                }, {});
                return backups.filter(function (s) { return formIds.indexOf(s.data.auditFormId) > -1; }).map(function (backup) {
                    var audit = backup.data;
                    var session = backup.data.auditSession;
                    var auditForm = formMap[audit.auditFormId] || undefined;
                    var schema = schemaMap[audit.auditFormId] || undefined;
                    var ward = wardMap[audit.wardId] || undefined;
                    return {
                        auditSession: session,
                        backup: backup,
                        isMultiStage: false,
                        formLabel: !auditForm ? '' : auditForm.name,
                        objectLabel: _this.auditService.getObservationRepr(auditForm, session, schema),
                        wardLabel: !ward ? '' : ward.name
                    };
                }).concat(submissions.filter(function (s) { return formIds.indexOf(s.audit_form) > -1; }).map(function (session) {
                    var auditForm = formMap[session.audit_form] || undefined;
                    var schema = schemaMap[session.audit_form] || undefined;
                    var ward = wardMap[session.ward] || undefined;
                    return {
                        auditSession: session,
                        isMultiStage: auditForm && auditForm.config.stages ? auditForm.config.stages > 1 : false,
                        formLabel: !auditForm ? '' : auditForm.name,
                        objectLabel: _this.auditService.getObservationRepr(auditForm, session, schema),
                        wardLabel: !ward ? '' : ward.name
                    };
                }));
            }));
        })).subscribe(function (options) {
            _this.changeDetectorRef.detectChanges();
            _this.options = options;
            _this.setLoading(false);
        }, function () { return _this.setLoading(false); });
        this.subscriptions.push(subscription);
    };
    SubmissionPickerComponent.prototype.setLoading = function (value) {
        this.loading = value;
        this.changeDetectorRef.detectChanges();
    };
    SubmissionPickerComponent.prototype.selectSubmission = function (submission) {
        var _this = this;
        if (!submission.id)
            return this.dialogRef.close();
        this.setLoading(true);
        var subscription = this.api.downloadAuditData(submission.id)
            .pipe(mergeMap(function (s) { return _this.accounts.getUser().pipe(map(function (user) { return user.id; }), map(function (userId) { return _this.auditService.createPrepopulatedAudit(s, userId); })); }))
            .subscribe(function (audit) { return _this.dialogRef.close(audit); }, function () { return _this.setLoading(false); });
        this.subscriptions.push(subscription);
    };
    SubmissionPickerComponent.prototype.selectBackup = function (backup) {
        var _this = this;
        if (!backup.id)
            return this.dialogRef.close();
        this.setLoading(true);
        var sub = this.auditService.restoreBackupAudit(backup).pipe(mergeMap(function (audit) { return _this.backupService.restoreAuditMedia(backup); }), catchError(function () { return _this.showMessage(_this.trans.get('audit-list.backup-download-error')).pipe(map(function () { return false; })); })).subscribe(function (success) {
            if (success)
                _this.dialogRef.close(backup.data);
            _this.loading = false;
            _this.changeDetectorRef.detectChanges();
        }, function () { return _this.setLoading(false); });
        this.subscriptions.push(sub);
    };
    SubmissionPickerComponent.prototype.selectItem = function (item) {
        if (item.backup) {
            this.selectBackup(item.backup);
        }
        else {
            this.selectSubmission(item.auditSession);
        }
    };
    SubmissionPickerComponent.prototype.showMessage = function (message) {
        var _this = this;
        return forkJoin([message, this.trans.get('ok')]).pipe(mergeMap(function (msg) { return _this.snackbar.open(msg[0], msg[1], { duration: 5000 }).afterDismissed(); }));
    };
    SubmissionPickerComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
        this.subscriptions = [];
    };
    return SubmissionPickerComponent;
}());
export { SubmissionPickerComponent };
