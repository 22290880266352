import { OnInit } from '@angular/core';
import { WalkthroughService } from './walkthrough.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
var WalkthroughComponent = /** @class */ (function () {
    function WalkthroughComponent(walkthroughService, router) {
        this.walkthroughService = walkthroughService;
        this.router = router;
        this.pageNumber = 0;
        this.walkthroughPages = [];
        this.action = null;
        // constant for swipe action: left or right
        this.SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
    }
    WalkthroughComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.walkthroughService.showing = true;
        this.walkthroughService.getAllPages().pipe(tap(function (pages) { return _this.walkthroughPages = pages; }), tap(function () { return _this.updatePage(); })).subscribe(function () { });
    };
    WalkthroughComponent.prototype.updatePage = function () {
        this.currentPage = this.walkthroughPages[this.pageNumber];
    };
    WalkthroughComponent.prototype.nextPage = function () {
        this.swipe(this.pageNumber);
    };
    WalkthroughComponent.prototype.finish = function () {
        this.walkthroughService.markShown();
        this.router.navigate(['login']);
        this.walkthroughService.showing = false;
    };
    Object.defineProperty(WalkthroughComponent.prototype, "isLastPage", {
        get: function () {
            return (this.walkthroughPages.length - 1) === this.pageNumber;
        },
        enumerable: true,
        configurable: true
    });
    WalkthroughComponent.prototype.isCurrentPage = function (page) {
        return (this.currentPage === page);
    };
    // action triggered when user swipes
    WalkthroughComponent.prototype.swipe = function (currentIndex, action) {
        if (action === void 0) { action = this.SWIPE_ACTION.LEFT; }
        this.action = action;
        var nextIndex = currentIndex;
        // swipe right
        if (action === this.SWIPE_ACTION.RIGHT) {
            nextIndex -= 1;
            this.pageNumber = nextIndex > 0 ? nextIndex : 0;
        }
        // swipe left
        if (action === this.SWIPE_ACTION.LEFT) {
            nextIndex += 1;
            this.pageNumber = nextIndex < this.walkthroughPages.length - 1 ? nextIndex : this.walkthroughPages.length - 1;
        }
        if (nextIndex >= this.walkthroughPages.length) {
            this.finish();
            return;
        }
        this.currentPage = this.walkthroughPages[this.pageNumber];
    };
    return WalkthroughComponent;
}());
export { WalkthroughComponent };
