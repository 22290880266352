var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { AuditService } from '../audit-form/audit.service';
import { BaseLoginRequiredComponent } from '../base';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../accounts.service';
import { InstitutionService } from '../institution.service';
import { AuditFormService } from '../audit-form.service';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AnalyticsService } from '../analytics.service';
import { AuditContainer } from './audit-container';
import { forkJoin, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CheckAuditFormService } from '../check-audit-form.service';
import { NGXLogger } from 'ngx-logger';
import { ApiService } from '../api/api.service';
import { validateSessionToken } from '../api/utils';
import { MatDialog } from '@angular/material';
import { SubmissionPickerComponent } from '../submission-picker/submission-picker.component';
import { ACCOUNT_TYPE_PUBLIC } from '../api/models/auditor';
import { DownloadService } from '../loading/download.service';
var AuditListComponent = /** @class */ (function (_super) {
    __extends(AuditListComponent, _super);
    function AuditListComponent(auditService, institutionService, auditFormService, analytics, router, route, accountService, api, translateService, checkAuditService, dialog, downloadService, logger) {
        var _this = _super.call(this, router, route, accountService, logger) || this;
        _this.auditService = auditService;
        _this.institutionService = institutionService;
        _this.auditFormService = auditFormService;
        _this.analytics = analytics;
        _this.api = api;
        _this.translateService = translateService;
        _this.checkAuditService = checkAuditService;
        _this.dialog = dialog;
        _this.downloadService = downloadService;
        _this.hideDownloadButton = true;
        _this.dialogOpen = false;
        return _this;
    }
    AuditListComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.accountService.isAuthenticated()) {
            validateSessionToken(this.api, this.translateService);
            this.auditService.setAuditId(null);
            this.loadAudits();
        }
        this.accountService.getUser().subscribe(function (user) {
            _this.hideDownloadButton = user.auditor.account_type === ACCOUNT_TYPE_PUBLIC;
        });
    };
    /**
     * Checks and downloads form update
     */
    AuditListComponent.prototype.updateForm = function (formId) {
        var _this = this;
        return this.downloadService.checkAuditFormUpdate(formId).pipe(mergeMap(function (updateAvailable) {
            if (updateAvailable)
                return _this.downloadService.downloadAuditForm(formId, null);
            else
                return of(false);
        }), map(function () { return true; }));
    };
    AuditListComponent.prototype.onDownloadClick = function () {
        var _this = this;
        this.dialogOpen = true;
        this.dialog.open(SubmissionPickerComponent).afterClosed().pipe(mergeMap(function (audit) {
            _this.dialogOpen = false;
            if (audit === undefined)
                return of();
            // Check for form updates then open the form
            return _this.updateForm(audit.auditFormId).pipe(mergeMap(function (result) {
                _this.logger.debug('Updated form ', result);
                return _this.auditService.activateAudit(audit).pipe(tap(function (a) {
                    _this.logger.debug('Selected submission', audit);
                    _this.onEditAudit(a);
                }));
            }));
        })).subscribe();
    };
    AuditListComponent.prototype.loadAudits = function () {
        var _this = this;
        this.accountService.getUser().pipe(mergeMap(function (user) {
            return _this.auditService.getAuditsByUser(user.id, true).pipe(mergeMap(function (audits) {
                // Bundle each audit with its audit form and ward objects
                var observables = audits.map(function (audit) { return forkJoin(_this.auditFormService.getAuditFormWithSchema(audit.auditFormId), _this.institutionService.getWard(audit.wardId).pipe(catchError(function (e) { return of(null); }))).pipe(map((function (_a) {
                    var _b = _a[0], form = _b[0], schema = _b[1], ward = _a[1];
                    return new AuditContainer(audit, form, ward, _this.auditService.getObservationRepr(form, audit.auditSession, schema));
                })), catchError(function (e) {
                    // Expecting an error if user can no longer see the audit form or ward
                    _this.logger.error('Skipping audit', audit, e);
                    return of(null);
                })); });
                if (observables.length === 0)
                    return of([]);
                return forkJoin.apply(void 0, observables).pipe(map(function (results) {
                    return results.filter(function (result) { return result !== null; });
                }));
            }), tap(function (audits) { return _this.items = audits; }));
        })).subscribe(function () { }, function (error) { return _this.logger.error(error); });
    };
    AuditListComponent.prototype.deleteAudit = function (audit) {
        var _this = this;
        this.analytics.trackButtonClick('Delete');
        this.analytics.trackAction('Audit delete');
        if (confirm(this.translateService.instant('audit-list.delete-audit-prompt'))) {
            this.auditService.deleteAudit(audit).subscribe(function (result) { return _this.items = _this.items.filter(function (item) { return item.audit !== audit; }); });
        }
    };
    AuditListComponent.prototype.onEditAudit = function (audit) {
        var _this = this;
        this.analytics.trackAction('Audit edit');
        this.auditFormService.getAuditForm(audit.auditFormId).pipe(tap(function (auditForm) { return _this.checkAuditService.checkAuditFormUpdate(auditForm); })).subscribe(function (result) {
            _this.router.navigate(['audit-preview', audit.id]);
        });
    };
    return AuditListComponent;
}(BaseLoginRequiredComponent));
export { AuditListComponent };
