import {Component, OnInit} from '@angular/core';
import {LoginErrorResponse} from '../api/responses/login-error-response';
import {ApiService} from '../api/api.service';
import {AnalyticsService} from '../analytics.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'meg-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.css']
})
export class ForgotPasswordDialogComponent implements OnInit {

  public username = '';
  public sendingRequest = false;
  public errors: LoginErrorResponse | null = null;

  constructor(private apiService: ApiService,
              private analytics: AnalyticsService,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private translate: TranslateService,
              private logger: NGXLogger) {
  }

  ngOnInit() {
  }

  public onSubmitClicked() {
    this.analytics.trackButtonClick('Submit password reset');
    this.sendingRequest = true;
    this.errors = null;
    this.apiService.requestPasswordReset(this.username).subscribe(
      response => this.onForgotPasswordRequested(response, this.username),
      error => this.onRequestPasswordRequestError(error),
    );
  }

  onForgotPasswordRequested(response: any, username: string) {
    this.snackBar.open(
      this.translate.instant('login.password-reset.email-sent', {'username': username}),
      this.translate.instant('ok'),
    );
    this.dialog.closeAll();
    this.sendingRequest = false;
    this.logger.debug('Successfully requested to reset password');
  }

  onRequestPasswordRequestError(error: any) {
    this.snackBar.open(
      this.translate.instant('login.password-reset.username-not-registered'),
      this.translate.instant('ok'),
    );
    this.sendingRequest = false;
    this.logger.error(error);
  }

}
