import {Component, Inject, Injector, OnInit, QueryList, ViewChildren, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatCheckboxChange, MatDialogRef, MatDialog} from '@angular/material';
import {IssueDialogArguments} from '../issue-dialog-arguments';
import {CommonIssue} from '../../../api/models/common-issue';
import {Issue} from '../../../api/models/issue';
import {QipService} from '../../qip.service';
import {Audit} from '../../../audit-form/audit';
import {IssueComponent} from '../issue/issue.component';
import {Errors} from '../../../api/models/errors';
import {BaseComponent} from '../../../base.component';
import {isNullOrUndefined, isObjectEmpty} from '../../../utils/misc';
import {AuditFormService} from '../../../audit-form.service';
import {Observable, of} from 'rxjs';
import {Field} from '../../../api/models/audit-form-schema';
import {map, tap} from 'rxjs/operators';

@Component({
  selector: 'meg-issues-dialog',
  templateUrl: './issues-dialog.component.html',
  styleUrls: ['./issues-dialog.component.css', '../../../../animations.css']
})
export class IssuesDialogComponent extends BaseComponent implements OnInit {
  public audit: Audit;
  public commonIssues: CommonIssue[];
  // list of issues that have been added, that is linked to the observation
  public originalIssues: Issue[] = [];
  // contains a copy of the original issues along with any new issues added in this component
  public issues: Issue[] = [];
  // list of available issues, including custom issues and not-added common issues
  public items: Issue[] = [];
  private field_name: string | null;
  isDisabled = true;
  @ViewChildren('issueComponents') private issueComponents !: QueryList<IssueComponent>;
  @ViewChild('newIssueComponent') private newIssueComponent !: IssueComponent;
  public errors: Errors[];
  public commentsErrors: Errors = {};
  private fieldNameObservables: { [fieldName: string]: Observable<string | null> } = {};
  public showNewIssue: Boolean = false;
  public newIssue: Issue = new Issue('');
  public showNewIssueButton: Boolean = true;
  public title: string;
  private hideUnselectedCommonIssues: Boolean = false;
  public issue_label: Observable<string> | null;
  public issue_label_plural: Observable<string> | null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IssueDialogArguments,
              private qipService: QipService,
              private dialogRef: MatDialogRef<IssuesDialogComponent>,
              private dialog: MatDialog,
              private auditFormService: AuditFormService,
              private injector: Injector) {
    super(injector);
    this.commonIssues = data.commonIssues;
    this.originalIssues = data.issues;
    this.audit = data.audit;
    this.field_name = data.field_name;
    this.dialogRef.disableClose = true;
    this.errors = data.errors;
    this.showNewIssueButton = data.showNewIssueButton;
    this.title = data.title;
    this.hideUnselectedCommonIssues = data.hideUnselectedCommonIssues;
    this.issue_label = data.issue_label;
    this.issue_label_plural = data.issue_label_plural;
  }

  ngOnInit() {
    // Makes a copy of original issues by creating a new object for each issue
    this.issues = this.originalIssues.map(x => Object.assign({}, x));
    // Add any created custom issues to the list
    const commonIssues: Issue[] = this.commonIssues
      .map((commonIssue: CommonIssue) => {
        const issue = new Issue(commonIssue.comment);
        issue.field_name = this.field_name;
        return issue;
      })
      .filter((commonIssue: Issue) => !this.isAdded(commonIssue));
    if (this.hideUnselectedCommonIssues) {
      this.items = this.issues.filter(issue => this.field_name === null || issue.field_name === this.field_name);
    } else {
      this.items = this.issues.filter(issue => this.field_name === null || issue.field_name === this.field_name).concat(commonIssues);
    }
    this.sortItemsByComment();
  }

  /** Sort common issues and newly added issues */
  public sortItemsByComment(): void {
    this.items.sort((a, b) => {
      const commentA = a.comment.toLowerCase();
      const commentB = b.comment.toLowerCase();

      if (commentA < commentB) {
        return -1;
      } else if (commentA > commentB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  /** Checks whether issue is added to the observation */
  public isAdded(issue: Issue): boolean {
    return this.issues.some((i) => i.comment === issue.comment && i.field_name === issue.field_name);
  }

  /**
   * Adds removes Issue based on checkbox state
   * @param {Issue} issue issue to be added/removed from the observation
   * @param {MatCheckboxChange} event checkbox click event
   */
  public toggleIssue(issue: Issue, event: MatCheckboxChange) {
    if (event.checked) {
      this.addIssue(issue);
    } else {
      event.source.checked = !this.removeIssue(issue);
    }
  }
  public toggleNewIssue(newIssue: Issue, event: MatCheckboxChange) {
    if (event.checked) {
      this.addNewIssue(newIssue);
    } else {
      const removed = !this.removeIssue(newIssue);
      event.source.checked = removed;
      this.newIssue = new Issue('');
      this.showNewIssue = removed;
    }
  }

  /**
   * Adds issue to observation, checks beforehand to make sure issue isn't duplicated
   * @param issue
   */
  private addIssue(issue: Issue) {
    if (!this.isAdded(issue)) {
      issue.field_name = this.field_name;
      this.issues.unshift(issue);
      this.logger.debug('Issue added', issue);
    }
  }
  private addNewIssue(newIssue: Issue) {
      this.addIssue(newIssue);
      this.newIssueComponent.updateMedia();
      this.newIssue = new Issue('');
      this.showNewIssue = false;
      this.items = [newIssue].concat(this.items);
      this.sortItemsByComment();

  }

  /**
   * Removes issue and related pics from observation, checks beforehand to make sure issue exists
   * @param issue
   */
  private removeIssue(issue: Issue): boolean {
    const index = this.issues
      .findIndex((currentIssue: Issue) => currentIssue.comment === issue.comment && currentIssue.field_name === issue.field_name);
    if (index > -1) {
      // also remove the item from the list if it's a duplicate
      const itemIndex = this.items
        .findIndex((currentIssue: Issue) => currentIssue.comment === issue.comment && currentIssue.field_name === issue.field_name);
      const isDuplicate = this.items
        .some((i: Issue) => i.comment === issue.comment && i !== issue && (i.field_name === null || !this.isAdded(i)));
      this.issues.splice(index, 1);
      if (isDuplicate && itemIndex > -1) this.items.splice(itemIndex, 1);
    }
    return (index > -1);
  }

  /**
   * Invoked when issue details have been edited inside the accordion.
   */
  onEdited(issue: Issue) {
    this.addIssue(issue);
  }

  /**
   * Finds any issues that have been deleted, and delete the photos attached
   */
  private removeDeletedIssuePhotos() {
    const deletedIssues = this.originalIssues.filter((issue: Issue) =>
      this.issues.findIndex((findIssue: Issue) => findIssue.comment === issue.comment) === -1);
    for (const deletedIssue of deletedIssues) {
      this.qipService.removeIssuePhotos(this.audit, deletedIssue).subscribe(() =>
        this.issueComponents.forEach((issueComponent: IssueComponent) => issueComponent.updateMedia()));
      this.logger.debug(`Issue removed: ${deletedIssue.comment}`);
    }
  }

  public onCommentChange(comment: string, index: number) {
    if (comment.isEmpty()) this.commentsErrors[`comment_${index}`] = this.translateService.instant('audit-form.field.field-required');
    else {
      delete this.commentsErrors[`comment_${index}`];
      delete this.errors[index];
    }
  }
  public onNewIssueCommentChange(comment: string) {
    if (comment.isEmpty()) this.commentsErrors[`comment_new_issue`] = this.translateService.instant('audit-form.field.field-required');
    else {
      delete this.commentsErrors[`comment_new_issue`];
    }
  }

  /**
   * Checks if issues has errors at index
   * @param index
   * @return boolean result
   */
  public hasError(index: number): boolean {
    if (isNullOrUndefined(this.errors)) return false;
    const errors = this.errors[index];
    return !isNullOrUndefined(errors) && !isObjectEmpty(errors);
  }


  public getIssueQuestion(issue: Issue): Observable<string | null> {
    let result = this.fieldNameObservables[issue.field_name || ''];
    if (result === undefined) {
      if (issue.field_name) {
        result = this.auditFormService.getFieldByName(this.audit.auditFormId, issue.field_name).pipe(
          tap((field: Field) => this.logger.debug('field', field)),
          map((field: Field) => field.label),
        );
        this.fieldNameObservables[issue.field_name] = result;
      } else {
        result = of(null);
        this.fieldNameObservables[''] = result;
      }
    }
    return result;
  }

  /**
   * First it removes any deleted issue photos.
   * Then it removes all original issues and assigns the issues added from this component back.
   */
  public onConfirm() {
    const issues = this.issues.filter((issue: Issue) => issue.comment.isEmpty());
    if (issues.length > 0) {
      this.modalService.openErrorModal(this.translateService.get('audit-form.required-fields-error')).subscribe();
    } else if (this.showNewIssue && this.newIssue.comment.isEmpty()) {
      this.onNewIssueCommentChange(this.newIssue.comment);
      this.modalService.openErrorModal(this.translateService.get('audit-form.required-fields-error')).subscribe();
    } else {
      if (this.newIssue.comment) this.addNewIssue(this.newIssue);
      if (this.newIssue.comment || this.issues.length > 0) {
        this.issueComponents.forEach((issueComponent: IssueComponent) => issueComponent.updateMedia());
        this.removeDeletedIssuePhotos();
        this.originalIssues.splice(0, this.originalIssues.length);
        for (const currentIssue of this.issues) {
          this.originalIssues.unshift(currentIssue);
        }
      }
      this.logger.debug(`Issue count: ${this.originalIssues.length}`);
      this.dialogRef.close();
    }
  }

  public openNewIssue() {
    this.showNewIssue = true;
  }
  public cancelNewIssue() {
    this.showNewIssue = false;
    this.newIssue = new Issue('');
  }

  public isNewIssue(newIssue: Issue): boolean {
    return this.commonIssues.findIndex((findIssue: CommonIssue) => findIssue.comment === newIssue.comment) === -1;
  }
}
