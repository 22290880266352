import {Component, EventEmitter, ElementRef, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatOption, MatSelectChange, MatSelect} from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


@Component({
  selector: 'meg-searchable-dropdown',
  templateUrl: './meg-searchable-dropdown.html',
  styleUrls: ['./meg-searchable-dropdown.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MegSearchableDropdownComponent implements OnInit {
  private _allItems: any[] | null;
  private _selectedItem: any;
  public filteredItems: Observable<any[]>;
  public itemSearchBarControl: FormControl = new FormControl('');
  @Input() disabled: boolean;
  @Input() itemToString: (a: any) => string;
  @Input() searchbarClass: string;
  @Input() selectionPlaceholderText: string;
  @Input() selectionClass: string;
  @Input() searchbarPlaceholderText: string;
  @ViewChild('searchableDropdownSearchbar') searchableDropdownSearchbar: ElementRef<HTMLInputElement>;
  @ViewChild('searchableDropdownMatSelectObject') searchableDropdownMatSelectObject: MatSelect;
  @Output() readonly selectedItemChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly selectionChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  get selectedItem(): any {
    return this._selectedItem;
  }

  set selectedItem(newValue: any) {
    this._selectedItem = newValue;
    this.selectedItemChange.emit(newValue);
  }

  @Input()
  get allValues(): any[] | null {
    return this._allItems;
  }

  set allValues(newValues: any[] | null) {
    this._allItems = newValues;
    this.itemSearchBarControl.setValue('');
  }

  constructor() {}

  ngOnInit(): void {
    /** Connect the MatSelect panel's options to the values of the searchbar and _allItems. */
    this.filteredItems = this.itemSearchBarControl.valueChanges.pipe(
      startWith(''),
      map(value => this.searchbarValueChange(value as string)),
    );
  }

  public searchbarPanelToggled(opened: boolean): void {
    /**
     * Focus the searchbar when the MatSelect panel is opened so that the user may begin typing.
     * Reset the searchbar when the MatSelect panel is closed so that the user can use the Arrow keys over the full array of _allItems.
     */
    if (opened) {
      this.searchableDropdownSearchbar.nativeElement.focus();
    } else {
      this.itemSearchBarControl.setValue('');
    }
  }

  public searchBarClicked(event: MouseEvent): void {
    /**
     * Handle the clicking of the searchbar so that it is not treated as an Option selection.
     * This appears to only work on the <input> HTML.
     */
    this.searchableDropdownSearchbar.nativeElement.focus();
    event.preventDefault();
    event.stopPropagation();
  }

  public searchBarFocused(): void {
    /**
     * Set the searchbar as the MatSelect panel's focused item when the searchbar is focused.
     * This is necessary for ensuring Arrow key behaviours start from the searchbar, and for keeping the correct items visually highlighted.
     */
    this.searchableDropdownMatSelectObject._keyManager.setFirstItemActive();
  }

  private searchbarValueChange(value: string): any[] {
    /**
     * For the given value of the searchbar, return a subArray of _allItems whose string representation includes the searchbar value.
     * All of _allItems matches an empty string. An empty Array should be returned if _allItems is null.
     */
    const filterValue: string = value.toLowerCase();
    if (this._allItems) {
      if (filterValue) {
        if (this.selectedItem && this.itemToString(this.selectedItem) !== filterValue) {
          this.selectedItem = null;
        }
        return this._allItems.filter(item => this.itemToString(item).toLowerCase().includes(filterValue));
      } else {
        return this._allItems.slice();
      }
    } else {
      return [];
    }
  }

  public onSelectionChange(selectionEvent: MatSelectChange): void {
    /** Propagate the MatSelect panel's change event to this component's subscriber(s) */
    this.selectionChange.emit(selectionEvent);
  }

  public matSelectKeyboardEvents(event: KeyboardEvent): void {
    /**
     * If the user is navigating the open MatSelect panel with the arrow keys and moves back up to the searchbar,
     * refocus the searchbar so that they may resume typing in it.
     */
    const key: string = event.key;
    if (key === 'ArrowUp') {
      const activeItem: MatOption | null = this.searchableDropdownMatSelectObject._keyManager.activeItem;
      if (activeItem && activeItem.viewValue === '' && this.searchableDropdownMatSelectObject.panelOpen) {
        this.searchableDropdownSearchbar.nativeElement.focus();
      }
    }
  }

  public searchbarKeyboardEvents(event: KeyboardEvent): void {
    /**
     * Prevent certain KeyboardEvents from propagating.
     * Shift focus from searchbar to the MatSelect panel. This allows the user to navigate and select from the panel
     * with the keyboard when they use Arrow keys.
     */
    const key: string = event.key;
    const isTextControlKey: boolean = key === ' ' || key === 'Home' || key === 'End' || key === 'Enter' || (key >= 'a' && key <= 'z');
    if (key === 'ArrowDown' || key === 'ArrowUp') {
      this.searchableDropdownSearchbar.nativeElement.blur();
      this.searchableDropdownMatSelectObject.focus();
    }
    if (isTextControlKey) { event.stopPropagation(); }
  }

  public searchbarInnerHTML(item: any): string {
    /**
     * Put bold tags around the matched substring of the dropdown item.
     * Has to use the innerHTML property to include html tags.
     * Uses substrings of the string representation of the dropdown item to preserve capitalisation.
     */
    const searchbarValue: string = this.itemSearchBarControl.value.toLowerCase();
    const itemString: string = this.itemToString(item);
    if (searchbarValue) {
      const searchableString: string = itemString.toLowerCase();

      const matchStartIndex: number = searchableString.indexOf(searchbarValue);
      const matchEndIndex: number = matchStartIndex + searchbarValue.length;

      const matchString: string = itemString.substring(matchStartIndex, matchEndIndex);
      return itemString.replace(matchString, '<b>$&</b>');
    }
    return itemString;
  }
}
