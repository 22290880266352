import {Injectable} from '@angular/core';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from './alert/service/modal.service';
import {ModalButton} from './alert/modal-alert-data';
import {AccountService} from './accounts.service';
import {AuditService} from './audit-form/audit.service';
import {User} from './api/models/user';
import {catchError, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Region} from './api/models/region';
import {isNullOrUndefined} from './utils/misc';
import {of} from 'rxjs/internal/observable/of';
import {PasswordPolicy} from './api/models/password-policy';

@Injectable()
export class IdleService {
  /**
   * duration in seconds until idle modal pops up
   */
  idleDuration = 240;
  /**
   * duration in seconds after popup appears to log out user, after idleDuration + timeoutDuration seconds of inactivity,
   * the user will be considered timed out.
   */
  timeoutDuration = 60;
  /**
   * interval in seconds between keepalive pings
   */
  keepaliveInterval = 15;
  timedOut = false;
  lastPing?: Date = undefined;
  currentCountDown = 0;
  dialogRef?: Observable<ModalButton> = undefined;

  constructor(private idle: Idle, private keepalive: Keepalive, private accountService: AccountService,
              private router: Router, private auditService: AuditService,
              private translateService: TranslateService, private modalService: ModalService) {
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  }

  private logOut() {
    this.accountService.logOut(this.auditService, this.router);
  }

  startWatching() {
    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.currentCountDown = countdown;
    });
    this.idle.onIdleEnd.subscribe(() => {
      // No longer idle
      this.reset();
    });
    this.idle.onTimeout.subscribe(() => {
      // On Timeout logout the user and close the dialog
      this.timedOut = true;
      this.modalService.dialog.closeAll();
      this.logOut();
    });
    this.idle.onIdleStart.subscribe(() => {
      // Going Idle
      if (this.dialogRef) {
        // Prevent The popup from appearing twice
        return;
      }
      this.dialogRef = this.modalService.openWarningModal(
        this.translateService.get('idle.prompt'),
        new ModalButton(this.translateService.get('menu.logout'), () => this.logOut(), 'exit_to_app'),
        new ModalButton(this.translateService.get('idle.stay'), undefined, 'close'),
      );
      this.dialogRef.pipe(take(1)).subscribe(() => {
        this.dialogRef = undefined;
      });
    });
    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.accountService.currentUser$.subscribe(async (user: User | null) => {
      const passwordPolicy = await this.accountService.getPasswordPolicy().pipe(take(1), catchError((e) => of(null))).toPromise();
      this.idle.stop();
      if (user && passwordPolicy && passwordPolicy.session_inactivity) {
          this.setOptions(passwordPolicy.session_inactivity);
          this.idle.watch();
          this.timedOut = false;
      }
    });
  }

  setOptions(idleDuration: number = 240, timeoutDuration: number = 60, keepaliveInterval: number = 15) {
    this.idleDuration = idleDuration;
    this.timeoutDuration = timeoutDuration;
    this.keepaliveInterval = keepaliveInterval;
    this.idle.setIdle(idleDuration);
    this.idle.setTimeout(this.timeoutDuration);
    this.keepalive.interval(this.keepaliveInterval);
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
    this.currentCountDown = 0;
  }
}
