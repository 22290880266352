import {Component, Inject, Injector, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ApiService} from '../../api/api.service';
import {Avatar, DEFAULT_AVATAR_PATH} from '../../api/models/avatar';
import {BaseComponent} from '../../base.component';
import {map} from 'rxjs/operators';

@Component({
  selector: 'meg-select-avatar-dialog',
  templateUrl: './select-avatar-dialog.component.html',
  styleUrls: ['./select-avatar-dialog.component.css']
})
export class SelectAvatarDialogComponent extends BaseComponent implements OnInit {
  public selectedAvatar: Avatar | null;
  public avatars: Avatar[] | null = null;
  public defaultAvatarPath = DEFAULT_AVATAR_PATH;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Avatar | null, private dialogRef: MatDialogRef<SelectAvatarDialogComponent>,
              private apiService: ApiService, private injector: Injector) {
    super(injector);
    this.selectedAvatar = data;
  }

  ngOnInit() {
    this.subscriptions.push(this.apiService.getAvatars().pipe(
      map((response) => response.results),
      map((avatars: Avatar[]) => {
        const defaultMEG = new Avatar();
        defaultMEG.name = 'MEG';
        defaultMEG.image = DEFAULT_AVATAR_PATH;
        avatars.push(defaultMEG);
        return avatars;
      })
    ).subscribe(
      (avatars: Avatar[]) => this.avatars = avatars,
      () => alert(this.translateService.instant('default-error')),
    ));
  }

  public onAvatarSelected(avatar: Avatar) {
    if (avatar.image !== DEFAULT_AVATAR_PATH) this.selectedAvatar = avatar;
    else this.selectedAvatar = null;
  }

  public getAvatarClass(avatar: Avatar): string {
    if (this.selectedAvatar) {
      return (avatar.id === this.selectedAvatar.id) ? 'selected' : 'choice';
    } else {
      return (avatar.image === DEFAULT_AVATAR_PATH) ? 'selected' : 'choice';
    }
  }

}
