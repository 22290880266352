<div class="images-wrapper">
  <mat-progress-spinner mode="indeterminate" *ngIf="updatingAvatar === true" class="avatar-progress"></mat-progress-spinner>
  <div class="avatar-wrapper" *ngIf="updatingAvatar === false">
    <img class="profile-image" [src]="userAvatar?.image || defaultAvatarPath">
    <button class="edit-avatar" mat-button color="primary" (click)="editAvatar()">
      <mat-icon>edit</mat-icon> {{'profile.avatar-btn' | translate}}
    </button>
  </div>
  <img *ngIf="institution?.logo" class="institution-logo" [src]="institution?.logo">
</div>

<div class="user-info">
  <p>
    {{user?.first_name}} {{user?.last_name}} {{user?.username}} <br>
    {{institution?.name}}<br>
    {{user?.email}}<br>
    <span *ngIf="region">{{'menu.region' | translate}}: {{region?.name}}</span>
    <br><br>
  </p>
  <span class="submitted-audits">{{'profile.submitted-audits' | translate}} {{user?.auditor.num_audits}}</span>
</div>
