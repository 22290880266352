var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ApiService } from '../../api/api.service';
import { Avatar, DEFAULT_AVATAR_PATH } from '../../api/models/avatar';
import { BaseComponent } from '../../base.component';
import { map } from 'rxjs/operators';
var SelectAvatarDialogComponent = /** @class */ (function (_super) {
    __extends(SelectAvatarDialogComponent, _super);
    function SelectAvatarDialogComponent(data, dialogRef, apiService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.data = data;
        _this.dialogRef = dialogRef;
        _this.apiService = apiService;
        _this.injector = injector;
        _this.avatars = null;
        _this.defaultAvatarPath = DEFAULT_AVATAR_PATH;
        _this.selectedAvatar = data;
        return _this;
    }
    SelectAvatarDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.apiService.getAvatars().pipe(map(function (response) { return response.results; }), map(function (avatars) {
            var defaultMEG = new Avatar();
            defaultMEG.name = 'MEG';
            defaultMEG.image = DEFAULT_AVATAR_PATH;
            avatars.push(defaultMEG);
            return avatars;
        })).subscribe(function (avatars) { return _this.avatars = avatars; }, function () { return alert(_this.translateService.instant('default-error')); }));
    };
    SelectAvatarDialogComponent.prototype.onAvatarSelected = function (avatar) {
        if (avatar.image !== DEFAULT_AVATAR_PATH)
            this.selectedAvatar = avatar;
        else
            this.selectedAvatar = null;
    };
    SelectAvatarDialogComponent.prototype.getAvatarClass = function (avatar) {
        if (this.selectedAvatar) {
            return (avatar.id === this.selectedAvatar.id) ? 'selected' : 'choice';
        }
        else {
            return (avatar.image === DEFAULT_AVATAR_PATH) ? 'selected' : 'choice';
        }
    };
    return SelectAvatarDialogComponent;
}(BaseComponent));
export { SelectAvatarDialogComponent };
