import {ComplianceService} from '../compliance/compliance.service';
import {AuditFormSchema, Field} from '../api/models/audit-form-schema';
import {AuditForm} from '../api/models/audit-form';
import {ComplianceCalculator} from '../compliance/calculator';
import {Widget} from '../api/models/dashboard';
import {Answer, Observation, SubObservation} from '../api/models/observation';
import {Error} from 'tslint/lib/error';
import {AuditFormService} from '../audit-form.service';

/**
 * Tuple type defined for storing name, compliance value and compliance level
 * Name for the chart label, compliance value for chart score and compliance level for chart color/s
 * */
export type MEGChartData = [string, number, number];
export type SimpleAnswer = string | number | string[];

interface TableRowData {
  compliance: number | null;
  no_observation: number;
  value: Answer;
  answer: Answer;
}

export class ChartsCalculator {

  constructor(private observations: Observation[],
              private auditFormSchema: AuditFormSchema,
              private auditForm: AuditForm,
              private complianceService: ComplianceService) {
  }

  private getFieldsFromAuditForm(fieldNames: string[]): Field[] {
    const fields: Field[] = [];
    for (const subFrom of this.auditFormSchema.sub_forms) {
      for (const field of subFrom.fields) {
        if (fieldNames.indexOf(field.field_name) > -1) {
          fields.push(field);
        }
      }
    }
    for (const field of this.auditFormSchema.fields) {
      if (fieldNames.indexOf(field.field_name) > -1) {
        fields.push(field);
      }
    }
    return fields;
  }

  private getField(name: string): Field {
    const results = this.getFieldsFromAuditForm([name]);
    if (results.length === 1) return results[0];
    else throw new Error(`Field ${name} does not exist in this audit`);
  }

  public getValuesFromObservation(field: Field, observation: Observation): Answer[] {
    const answers: Answer[] = [];

    this.auditFormSchema.sub_forms.forEach((subForm) => {
      if (!observation[subForm.name]) return;
      // add issues from each subobservation
      const subObservations: SubObservation[] = [];
      if (Array.isArray(observation[subForm.name])) subObservations.push(...<SubObservation[]>observation[subForm.name]);
      else subObservations.push(<SubObservation>observation[subForm.name]);

      subObservations.forEach(
        (subObservation: SubObservation) => {
          const value = subObservation[field.field_name];
          if (value !== null && value !== undefined) {
            answers.push(AuditFormService.getFieldAnswer(field, value));
          }
        }
      );
    });

    if (observation[field.field_name] !== null && observation[field.field_name] !== undefined) {
      const value: Answer = observation[field.field_name] as Answer;
      answers.push(AuditFormService.getFieldAnswer(field, value));
    }
    return answers;
  }

  public getNoOfAnswers(fieldName: string, observation: Observation): number {
    let count = 0;

    this.auditFormSchema.sub_forms.forEach((subForm) => {
      if (!observation[subForm.name]) return;
      // add issues from each subobservation
      const subObservations: SubObservation[] = [];
      if (Array.isArray(observation[subForm.name])) subObservations.push(...<SubObservation[]>observation[subForm.name]);
      else subObservations.push(<SubObservation>observation[subForm.name]);

      subObservations.forEach(
        (subObservation: SubObservation) => {
          if (subObservation[fieldName] !== null && subObservation[fieldName] !== undefined) count++;
        }
      );
    });
    if (observation[fieldName] !== null && observation[fieldName] !== undefined ) count++;
    return count;
  }

  /**
   * Getting chart data for scoring based on a form field
   * @param {Widget} widget
   * @returns {MEGChartData[]} returns array of tuple containing chart data
   */
  public getFieldChartData(widget: Widget): MEGChartData[] {
    const data: MEGChartData[] = [];
    const fieldComplianceDict: { [fieldName: string]: {sumOfCompliance: number, sumOfWeights: number} } = {};

    for (const observation of this.observations) {
      if (widget.config.field_name !== null && widget.config.field_name !== undefined) {

        const fields = this.getFieldsFromAuditForm([widget.config.field_name]);
        const complianceValue = this.complianceService.calculateObservationCompliance(this.auditFormSchema, observation);
        if (fields.length > 0 && complianceValue != null) {
          const values = this.getValuesFromObservation(this.getField(fields[0].field_name), observation);
          for (const value of values) {
            if (value instanceof Array || value instanceof Date) continue;
            if (fieldComplianceDict[value] === undefined) {
              fieldComplianceDict[value] = {sumOfCompliance: complianceValue, sumOfWeights: fields[0].compliance_weight};
            } else {
              fieldComplianceDict[value].sumOfCompliance += complianceValue;
              fieldComplianceDict[value].sumOfWeights += fields[0].compliance_weight;
            }
          }
        }
      } else if (widget.config.field_names !== null) {

        const calc = new ComplianceCalculator(this.auditFormSchema);
        const fieldsCompliances = calc.calculateFieldsComplianceFromObservation(this.getFieldsFromAuditForm(widget.config.field_names),
          observation) as [Field, number][];
        for (const fieldCompliance of fieldsCompliances)  {
          if (fieldComplianceDict[fieldCompliance[0].label] === undefined) {
            fieldComplianceDict[fieldCompliance[0].label] = {
              sumOfCompliance: fieldCompliance[1],
              sumOfWeights: fieldCompliance[0].compliance_weight
            };
          } else {
            fieldComplianceDict[fieldCompliance[0].label].sumOfCompliance += fieldCompliance[1];
            fieldComplianceDict[fieldCompliance[0].label].sumOfWeights += fieldCompliance[0].compliance_weight;
          }
        }
      }
    }
    for (const key in fieldComplianceDict) {
      if (fieldComplianceDict.hasOwnProperty(key)) {
        const complianceScore = fieldComplianceDict[key];
        let complianceLevel = 0;
        const complianceValue = complianceScore.sumOfCompliance / complianceScore.sumOfWeights;
        if (this.auditForm) {
          complianceLevel = this.complianceService.getComplianceLevel(this.auditForm, complianceValue);
        }
        const compliance = widget.config.compliance ? complianceValue * 100 : complianceValue;
        const chartData: MEGChartData = [key, compliance, complianceLevel];
        data.push(chartData);
      }
    }
    return data;
  }

  /**
   * Getting chart data for compliance scoring based on sub forms
   * @returns {MEGChartData[]} returns array of tuple containing chart data
   */
  public getSubFormChartData(): MEGChartData[] {
    const data: MEGChartData[] = [];

    let totalCompliantValue = 0;
    const complianceCalculator = new ComplianceCalculator(this.auditFormSchema);
    this.observations.forEach((observation: any, index: number) => {

      totalCompliantValue = 0;
      let subFormName = '';

      this.auditFormSchema.sub_forms.forEach((subForm) => {
        if (!observation[subForm.name]) return;
        // add issues from each subobservation
        const subObservations: SubObservation[] = [];
        if (Array.isArray(observation[subForm.name])) subObservations.push(...<SubObservation[]>observation[subForm.name]);
        else subObservations.push(<SubObservation>observation[subForm.name]);

        subFormName = subForm.display_name;
        subObservations.forEach(
          (subObservation: SubObservation) =>
            totalCompliantValue = complianceCalculator.calculateSubObservationCompliance(subObservation, subForm) || 0
        );
      });

      let complianceLevel: number | null = null;
      if (this.auditForm !== null) {
        complianceLevel = this.complianceService.getComplianceLevel(this.auditForm, totalCompliantValue);
      }
      const valuePercentage = (totalCompliantValue * 100);
      const keyPercentage = `${subFormName}:  ${valuePercentage.toFixed(0)}%`;
      if (complianceLevel === null) {
        complianceLevel = 0;
      }
      const chartData: MEGChartData = [keyPercentage, valuePercentage, complianceLevel];
      data.push(chartData);
    });
    return data;
  }

  /**
   * Getting chart data based on the number of entries made on a form field
   * @param {Widget} widget
   * @returns {MEGChartData[]} returns array of tuple containing chart data
   */
  public calculateNoOfEntries(widget: Widget): MEGChartData[] {
    const countEntries: { [index: number]: number } = {};
    const fieldName = widget.config.field_name;
    if (fieldName !== null) {
      const fields = this.getFieldsFromAuditForm([fieldName]);
      if (fields.length > 0) {
        this.observations.forEach((observation: any, index: number) => {
          if (fields[0].field_name === fieldName) {
            const values = this.getValuesFromObservation(this.getField(fieldName), observation);
          countEntries[index] = values.length;
          }
        });
      }
    }

    const data: MEGChartData[] = [];
    const percentPerObservation = 100 / this.observations.length;
    const percentUsing  = Object.keys(countEntries).length * percentPerObservation;
    const percentNotUsing = 100 - percentUsing;

    const chartDataYes: MEGChartData = [`Yes: ${percentUsing.toFixed(0)}%`, percentUsing, 0];
    const chartDataNo: MEGChartData = [`No: ${percentNotUsing.toFixed(0)}%`, percentNotUsing, 0];
    data.push(chartDataYes);
    data.push(chartDataNo);

    return data;
  }

  /**
   * Getting chart data based on field value being entered
   * @param {Widget} widget
   * @returns {MEGChartData[]} returns array of tuple containing chart data
   */
  public getPieChartData(widget: Widget): MEGChartData[] {
    const choices: { [fieldName: string]: number } = {};
    let totalValuesAnswered = 0;
    if (widget.config.field_name !== null && widget.config.field_name !== undefined) {
      const fieldName = widget.config.field_name;
      for (const observation of this.observations) {
        const values = this.getValuesFromObservation(this.getField(fieldName), observation);
        for (const value of values) {
          if (value instanceof Array || value instanceof Date) continue;
          totalValuesAnswered += 1;
          let previousValue = choices[value];
          if (previousValue) {
            previousValue += 1;
          } else {
            previousValue = 1;
          }
          choices[value] = previousValue;
        }
      }
    }
    const data: MEGChartData[] = [];
    for (const key in choices) {
      if (choices.hasOwnProperty(key)) {
        const value: number = (choices[key]);
        const valuePercentage = (value * 100) / totalValuesAnswered;
        const keyPercentage = `${key}:  ${valuePercentage.toFixed(0)}%`;
        const chartData: MEGChartData = [keyPercentage, valuePercentage, 0];
        data.push(chartData);
      }
    }
    return data;
  }

  /**
   * Getting table chart data based on compliance scoring per form
   * @param {Widget} widget
   * @returns {{}} returns dictionary of data for table
   */
  public getTableData(widget: Widget): { [fieldName: string]: any }[] {
    const tableData: { [fieldName: string]: any }[] = [];
    let fieldName = '';
    if (widget.config !== null && widget.config.field_name !== null) {
      fieldName = widget.config.field_name;
    }
    const field: Field = this.getField(fieldName);
    const calculator: ComplianceCalculator = new ComplianceCalculator(this.auditFormSchema);
    const complianceScores: TableRowData[] = this.observations.map((observation: Observation) => {
      const value: Answer = observation[fieldName] as Answer;
      return {
        compliance: calculator.calculateObservationCompliance(observation),
        no_observation: 1,
        value: value,
        answer: AuditFormService.getFieldAnswer(field, value),
      };
    });

    for (const complianceScore of complianceScores) {
      const data: { [fieldName: string]: any } | undefined = tableData.find(
        (rowData: TableRowData) => rowData.value === complianceScore.value);
      if (data !== undefined) {
        data.no_observation += 1;
        data.compliance = (data.compliance + complianceScore.compliance) / data.no_observation;
      } else {
        tableData.push(complianceScore);
      }
    }
    return tableData;
  }

}
