import { ApiService } from '../api/api.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { NGXLogger } from 'ngx-logger';
import * as i0 from "@angular/core";
import * as i1 from "../api/api.service";
import * as i2 from "ngx-logger";
var MessagingService = /** @class */ (function () {
    function MessagingService(api, logger) {
        this.api = api;
        this.logger = logger;
    }
    MessagingService.prototype.getChannels = function () {
        return this.api.fetchChannels().pipe(map(function (response) { return response.results; }));
    };
    /**
     * Gets list of messages in the channel and marks channel as read at the point of last messages.
     * Returns messages in reverse order (newest last)
     */
    MessagingService.prototype.getMessages = function (channelId, onlyUnread) {
        var _this = this;
        if (onlyUnread === void 0) { onlyUnread = false; }
        var source = onlyUnread ? this.api.fetchChannelUnreadMessages(channelId) : this.api.fetchChannelMessages(channelId);
        return source.pipe(map(function (result) { return result.results; }), switchMap(function (messages) {
            if (messages.length === 0)
                return of(messages);
            return _this.markMessagesAsRead(channelId, messages[0].created).pipe(map(function () { return messages; }));
        }), map(function (result) { return result.reverse(); }));
    };
    MessagingService.prototype.getChannel = function (channelId) {
        return this.api.fetchChannel(channelId);
    };
    MessagingService.prototype.markMessagesAsRead = function (channelId, date) {
        this.logger.debug('Marking last message as read', date);
        return this.api.markMessagesAsRead(channelId, date).pipe(map(function () { return true; }), catchError(function () { return of(false); }));
    };
    MessagingService.prototype.sendMessage = function (channelId, message) {
        return this.api.sendChannelMessage(channelId, message).pipe(map(function () { return true; }));
    };
    MessagingService.prototype.sendP2pMessage = function (userId, message) {
        return this.api.sendP2pMessage(userId, message);
    };
    MessagingService.prototype.getContacts = function (search) {
        if (search.length === 0)
            return of([]);
        return this.api.fetchUsers(search).pipe(map(function (result) { return result.results; }));
    };
    MessagingService.ngInjectableDef = i0.defineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.inject(i1.ApiService), i0.inject(i2.NGXLogger)); }, token: MessagingService, providedIn: "root" });
    return MessagingService;
}());
export { MessagingService };
