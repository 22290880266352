import {Inject, Injectable} from '@angular/core';
import {User} from './api/models/user';
import {Error} from 'tslint/lib/error';
import {DB_KEY_QIP_FILTER_STATE, StorageService} from './storage.service';
import {combineLatest, concat, forkJoin, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {DB_KEY_AUDIT_FORMS} from './audit-form.service';
import {catchError, map, mergeMap, take, tap} from 'rxjs/operators';
import {DB_KEY_INSTITUTIONS} from './institution.service';
import {AuditService, DB_KEY_AUDIT_FORM_ID} from './audit-form/audit.service';
import {DB_KEY_DASHBOARDS} from './charts/dashboard.service';
import {DB_KEY_ISSUE_HANDLERS} from './qip/qip.service';
import {DB_KEY_INFORMATIONS, DEFAULT_LANGUAGE} from './api/constants';
import {NGXLogger} from 'ngx-logger';
import {Region} from './api/models/region';
import {PasswordPolicy} from './api/models/password-policy';
import {LoginResponse} from './api/responses/login-response';
import {Router} from '@angular/router';
import {ACCOUNT_TYPE_PUBLIC} from './api/models/auditor';
import {DOCUMENT} from '@angular/common';

const STORAGE_KEY_API_TOKEN = 'api-token';
const STORAGE_KEY_USER = 'user';
const STORAGE_KEY_REGION = 'region';
const STORAGE_KEY_PASSWORD_POLICY = 'password_policy';
const STORAGE_LANGUAGE = 'language';

const COOKIES_CLEARED_ON_LOGOUT: string[] = [
  STORAGE_KEY_API_TOKEN,
  DB_KEY_AUDIT_FORM_ID,
];

const ITEMS_CLEARED_ON_LOGOUT: string[] = [
  STORAGE_KEY_USER,
  STORAGE_KEY_REGION,
  STORAGE_KEY_PASSWORD_POLICY,
  DB_KEY_AUDIT_FORMS,
  DB_KEY_INSTITUTIONS,
  DB_KEY_INFORMATIONS,
  DB_KEY_DASHBOARDS,
  DB_KEY_ISSUE_HANDLERS,
  DB_KEY_QIP_FILTER_STATE,
];

// Django permissions
export const PERMISSION_CHANGE_ISSUE = 'megforms.change_issue';
export const PERMISSION_DOCS_VIEW: string[] = [
  'megdocs.view_document',
  'megdocs.view_folder',
  'megdocs.view_folderpermissionrule'
];
export const PERMISSION_DOCS_MANAGE: string[] = PERMISSION_DOCS_VIEW.concat([
  'comments.view_comment',
  'comments.add_comment',
  'megdocs.add_bookmark',
  'megdocs.change_bookmark',
  'megdocs.delete_bookmark',
  'megdocs.view_bookmark',
  'megdocs.add_document',
  'megdocs.change_document',
  'megdocs.delete_document',
  'megdocs.change_document_owner',
  'megdocs.add_folder',
  'megdocs.change_folder',
  'megdocs.delete_folder',
  'megdocs.add_version',
  'megdocs.approve_version',
  'megdocs.change_version',
  'megdocs.delete_version',
]);

/**
 * Keeps user authentication information
 */
@Injectable()
export class AccountService {
  token: string | null;
  user$: Subject<User | null> = new ReplaySubject<User|null>(0);
  captchaTokenSubj: string | null;

  constructor(private storageService: StorageService, private auditService: AuditService, private logger: NGXLogger,
              @Inject(DOCUMENT) private document: Document) {
    this.token = storageService.getString(STORAGE_KEY_API_TOKEN);
    if (this.token) {
      this.getUser().subscribe(() => {
      }, (error) => logger.error(error));
    }
  }

  public setLoggedIn(response: LoginResponse): Observable<boolean> {
    if (!response.token) return of(false);
    const user = response.user as User;
    const token: string = response.token;
    this.token = token;
    this.storageService.setString(STORAGE_KEY_API_TOKEN, token);
    return combineLatest([
      this.storageService.setItem<Region | null>(STORAGE_KEY_REGION, response.region),
      this.storageService.setItem<PasswordPolicy | null>(STORAGE_KEY_PASSWORD_POLICY, response.policy)
    ]).pipe(
      mergeMap(() => this.saveUserData(user)),
      tap((...result: boolean[]) => {
        if (result.every(x => x)) {
          this.token = response.token;
          this.storageService.setString(STORAGE_KEY_API_TOKEN, token);
        }
        this.user$.next(response.user);
      }),
    );
  }

  public saveUserData(user: User): Observable<boolean> {
    return this.storageService.setItem<User>(STORAGE_KEY_USER, user);
  }

  /**
   * Marks user as not logged in
   * @returns {Observable<boolean>}
   */
  public clearLogin(): Observable<boolean> {
    this.auditService.setAuditId(null);
    this.auditService.clearAuditFormId();
    this.token = null;
    this.storageService.removeString(STORAGE_LANGUAGE);
    this.user$.next(null);
    COOKIES_CLEARED_ON_LOGOUT.forEach((key: string) => this.storageService.removeString(key));
    const observables = ITEMS_CLEARED_ON_LOGOUT.map((key: string) => this.storageService.removeItem(key));
    return forkJoin(...observables).pipe(
      map((results: boolean[]) => results.every(value => value)),
    );
  }

  /**
   * Logs user out and performs related actions like clearing out empty audit and showing the loging screen
   */
  public logOut(auditService: AuditService, router: Router) {
    forkJoin(
      this.getUser().pipe(
        mergeMap((user: User) => {
          if (user.auditor.account_type === ACCOUNT_TYPE_PUBLIC) return this.auditService.deleteUserAudits(user).pipe(map(() => user));
          return this.auditService.deleteEmptyAudits(user).pipe(map(() => user));
        }),
      ),
      this.clearLogin(),
    ).pipe(
      map((result: [User, boolean]): string | null => {
        const user: User = result[0];
        return user.logout_redirect || null;
      }),
      tap((url?: string) => {
        router.navigateByUrl('/login').then(() => {
          if (url) this.document.location.href = url;
        });
      }),
    ).subscribe();
  }

  /**
   * Retrieves currently logged in user from the database
   */
  public getUser(): Observable<User> {
    return this.storageService.getItem<User>(STORAGE_KEY_USER);
  }

  public getRegion(): Observable<Region> {
    return this.storageService.getItem<Region>(STORAGE_KEY_REGION);
  }

  public getPasswordPolicy(): Observable<PasswordPolicy> {
    return this.storageService.getItem<PasswordPolicy>(STORAGE_KEY_PASSWORD_POLICY);
  }

  public getUserPermissions(): Observable<string[]> {
    return this.getUser().pipe(
      map((user: User): string[] => user.permissions || []),
    );
  }

  public hasPermission(permission: string): Observable<boolean> {
    return this.getUserPermissions().pipe(
      map((userPermissions: string[]): boolean => userPermissions.indexOf(permission) > -1),
      tap((hasEditPermission: boolean) => this.logger.debug('Has permission', permission, hasEditPermission)),
    );
  }

  public hasPermissions(permissions: Array<string>): Observable<boolean> {
    return this.getUserPermissions().pipe(
      map((userPermissions: string[]): boolean => permissions.every(permission => {
        return userPermissions.indexOf(permission) > -1;
      })),
      tap((hasEditPermission: boolean) => this.logger.debug('Has permissions', permissions, hasEditPermission)),
    );
  }

  /**
   * an observable emitting current user, followed by updated user object if user changes.
   * Emits null when user is/changes to null (not logged in)
   */
  get currentUser$(): Observable<User | null> {
    return concat(
      this.getUser().pipe(
        take(1),
        // emit null if no user is currently logged in
        catchError((e) => of(null)),
      ),
      this.user$,
    );
  }

  /**
   * Checks whether user is logged in
   */
  public isAuthenticated(): boolean {
    return this.token != null;
  }

  public getAuthorizationHeader(): string {
    if (!this.isAuthenticated()) {
      throw new Error('Not logged in');
    }
    return `Token ${this.token}`;
  }

  /**
   * Sets current user's preferred language.
   * The preference is stored locally for the duration of the login session.
   */
  public setLanguage(language: string | null) {
    const value = language || DEFAULT_LANGUAGE;
    this.logger.debug('User language set to', value);
    this.storageService.setString(STORAGE_LANGUAGE, value);
  }

  /**
   * Gets user-preferred language.
   * This reads local preference as set by setLanguage()
   */
  public getLanguage(): string | null {
    return this.storageService.getString(STORAGE_LANGUAGE);
  }

  public updateCaptchaToken(newToken: string) {
    this.captchaTokenSubj = newToken;
  }

  public clearCaptchaToken(): void {
    this.captchaTokenSubj = null;
  }
}
