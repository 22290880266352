import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Observable} from 'rxjs/internal/Observable';
import {Audit} from './audit-form/audit';
import {defaultIfEmpty, map, mergeMap, tap} from 'rxjs/operators';
import {AuditService} from './audit-form/audit.service';
import {AuditBackup, AuditMediaBackup} from './api/models/backup';
import {QipService} from './qip/qip.service';
import {IssuePhoto} from './api/models/issue';
import {StorageService} from './storage.service';
import {forkJoin, of} from 'rxjs';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class BackupService {

  constructor(private apiService: ApiService, private audits: AuditService, private qip: QipService, private storage: StorageService,
              private logger: NGXLogger) {
  }

  private uploadBackup(audit: Audit): Observable<AuditBackup> {
    const backupId = audit.auditSession.backupId;
    if (backupId) return this.apiService.updateBackup(audit, backupId);
    else return this.apiService.createBackup(audit).pipe(
      mergeMap((response: AuditBackup) => {
        // Copy backup id for future updates
        audit.auditSession.backupId = response.id;
        return this.audits.saveAudit(audit).pipe(map(() => response));
      }),
    );
  }

  /**
   * Strips prefix generated by `getAuditPhotoKeyPrefix`
   *
   * @param key String starting with "audit-${audit.id}-issue-photos-", followed by a key
   * @return portion of the key string after the prefix
   */
  private stripAuditIdPrefixFromKey(key: string): string {
    const token = '-issue-photos-';
    const pos = key.indexOf(token);
    if (pos > 0) {
      return key.substr(pos + token.length);
    }
    return key;
  }

  /**
   * Gets issue photos and their corresponding storage keys
   */
  private uploadIssuePhotos(audit: Audit, backupId: number): Observable<number> {
    return this.qip.getAuditPhotoKeys(audit).pipe(
      mergeMap(keys => {
        // Create observables that take photo for each key and upload it to the API
        const photos: Observable<boolean>[] = keys.map((key: string): Observable<boolean> => this.storage.getItemOrDefault(key, []).pipe(
          mergeMap((value: IssuePhoto[]): Observable<boolean> => this.apiService.uploadBackupMedia(backupId, {
            key: this.stripAuditIdPrefixFromKey(key),
            data: value,
          })),
        ));

        // short-circuit of there are no photos to avoid forkJoin crash
        if (photos.length === 0) return of(0);

        return forkJoin(...photos).pipe(
          map(value => photos.length),
        );
      }),
    );
  }

  public backup(audit: Audit): Observable<Audit> {
    if (audit.auditSession.backupId) this.apiService.updateBackup(audit, audit.auditSession.backupId);
    return this.uploadBackup(audit).pipe(
      mergeMap((backup: AuditBackup) => this.uploadIssuePhotos(backup.data, <number>backup.id).pipe(
        tap(result => this.logger.debug('Uploaded photo entries:', result)),
        map(result => backup),
      )),
      map((backup: AuditBackup) => backup.data),
    );
  }

  public restoreAuditMedia(backup: AuditBackup): Observable<boolean> {
    return this.apiService.getBackupMedia(<number>backup.id).pipe(
      // update storage keys in case audit id has changed (likely if audit was created on another device)
      map((media: AuditMediaBackup[]) => media.map(m => {
        m.key = this.qip.getAuditPhotoKeyPrefix(backup.data) + m.key;
        this.logger.debug('Downloaded photos for key', m.key);
        return m;
      })),
      // Save photos to storage
      mergeMap((media: AuditMediaBackup[]) => {
        const observables = media.map((m: AuditMediaBackup): Observable<boolean> => this.storage.setItem(m.key, m.data));
        return forkJoin(observables).pipe(
          defaultIfEmpty([]),
        );
      }),
      map(() => true),
    );
  }
}
