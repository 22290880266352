export class LoginCredentials {
  constructor(
    public username: string,
    public password: string,
    public captcha: string | null = null,
    public two_factor_token: string | null = null,
  ) {}

  public toString(): string {
    return this.username;
  }
}
