import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {AuditFormComponent} from './audit-form.component';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AuditFormDeactivateGuard implements CanDeactivate<AuditFormComponent> {
  constructor(private translateService: TranslateService) {
  }

  canDeactivate(component: AuditFormComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return !component.isFormDirty() || confirm(this.translateService.instant('audit-form.audit-form-deactivate-prompt'));
  }
}
