import {Injectable} from '@angular/core';
import {AuditForm, CUSTOM_OBSERVATION_NAME} from '../api/models/audit-form';
import {Ward} from '../api/models/ward';
import {User} from '../api/models/user';
import {AuditSession} from '../api/models/audit-session';
import {Audit} from './audit';
import {StorageService} from '../storage.service';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {AccordionFieldAnswer, AuditFormSchema} from '../api/models/audit-form-schema';
import {AuditFormService} from '../audit-form.service';
import {Answer, Observation, SubObservation} from '../api/models/observation';
import {QipService} from '../qip/qip.service';
import {NGXLogger} from 'ngx-logger';
import {capitalizeFirstLetter, isNullOrUndefined} from '../utils/misc';
import {Term} from '../api/models/institution';
import {TranslateService} from '@ngx-translate/core';
import {AuditBackup} from '../api/models/backup';

/**
 * Key storing highest audit id, incremented every time a new audit is started
 */
export const DB_KEY_LAST_AUDIT_ID = 'last-audit-id';
export const DB_KEY_AUDIT_FORM_ID = 'audit-form-id';

@Injectable()
export class AuditService {
  private auditFormId: number | null = null;
  private auditId: number | null = null;
  public auditFormId$: BehaviorSubject<number | null>;
  public auditId$: BehaviorSubject<number | null>;
  public auditChanges$: Subject<Audit> = new Subject<Audit>();
  public accordionAnswers$: Subject<AccordionFieldAnswer[]> = new Subject<AccordionFieldAnswer[]>();
  private accordionAnswersCache: AccordionFieldAnswer[] = [];

  constructor(private storageService: StorageService, private auditFormService: AuditFormService, private qipService: QipService,
              private logger: NGXLogger, private translateService: TranslateService) {
    const auditFormId: string | null = this.storageService.getString(DB_KEY_AUDIT_FORM_ID);
    this.auditFormId = auditFormId === null ? null : +auditFormId;
    this.auditFormId$ = new BehaviorSubject<number | null>(this.auditFormId);
    this.auditId$ = new BehaviorSubject<number | null>(this.auditId);
  }

  private generateAuditId(): number {
    const highestId: string | null = this.storageService.getString(DB_KEY_LAST_AUDIT_ID);
    const item: number = highestId == null ? 1 : +highestId;
    this.storageService.setString(DB_KEY_LAST_AUDIT_ID, (item + 1).toString());
    return item;
  }

  /**
   * Creates key under which the audit is stored in the indexed DB
   * @param {number} id id of the audit
   * @returns {string} key
   */
  private getAuditKey(id: number) {
    return `audit-${id}-data`;
  }

  /**
  * Stores currently selected audit form id
  * @param {number} auditFormId audit form id
  */
  public setAuditFormId(auditFormId: number) {
    this.auditFormId = auditFormId;
    this.storageService.setString(DB_KEY_AUDIT_FORM_ID, auditFormId.toString());
    this.auditFormId$.next(auditFormId);
  }
  public clearAuditFormId() {
    this.auditFormId = null;
    this.storageService.removeString(DB_KEY_AUDIT_FORM_ID);
    this.auditFormId$.next(null);
  }

  private getAllAudits(): Observable<Audit[]> {
    const pattern = new RegExp('audit-(\\d+)-data');

    return this.storageService.getKeys().pipe(
      mergeMap((keys: string[]) => {
        const auditObservables: Observable<Audit | null>[] = keys
          .filter((key: string) => pattern.test(key))
          .map((key: string) => {
            // extract audit id from key and return observable of Audit
            const matches = pattern.exec(key) || [];
            const id: number = +matches[1];
            if (isNaN(id)) throw new Error(`Parsed id is  NaN for ${key}`);
            return this.getAudit(id).pipe(
              // If audit fails to load, print error and replace audit with null
              catchError((error) => {
                this.logger.error(error);
                return of(null);
              }),
            );
          });
        if (auditObservables.length === 0) {
          // return empty list if there are no observables,
          // otherwise no event would be triggered from forkJoin
          return of([]);
        } else return forkJoin(auditObservables).pipe(
          // remove null audits from list
          map((audits: (Audit | null)[]): Audit[] => <Audit[]>audits.filter((audit) => audit !== null)),
        );
      }),
    );
  }

  public getAuditsByUser(userId: number, excludeEmpty = false): Observable<Audit[]> {
    return this.getAllAudits().pipe(
      map((audits: Audit[]) => audits.filter((audit: Audit) => audit.userId === userId)),
      map((audits: Audit[]) => {
        if (excludeEmpty) return audits.filter((audit: Audit) => audit.auditSession.observations.length);
        else return audits;
      }),
    );
  }

  public saveAudit(audit: Audit): Observable<boolean> {
    return this.storageService.setItem(this.getAuditKey(audit.id), audit).pipe(
      tap(() => this.auditChanges$.next(audit)),
    );
  }

  public getAudit(id: number): Observable<Audit> {
    return this.storageService.getItem(this.getAuditKey(id));
  }

  public getAuditWithFormSchema(id: number): Observable<[Audit, AuditForm, AuditFormSchema]> {
    return this.getAudit(id).pipe(
      mergeMap((audit: Audit) => this.auditFormService.getAuditFormWithSchema(audit.auditFormId).pipe(
        map((result: [AuditForm, AuditFormSchema]) => [audit, result[0], result[1]] as [Audit, AuditForm, AuditFormSchema]),
      )),
    );
  }

  /**
   * Saves backup as a local audit. Updates local audit if already exists, or creates a new one.
   * @param backup
   */
  public restoreBackupAudit(backup: AuditBackup): Observable<Audit> {
    return this.getAuditsByUser(backup.data.userId, false).pipe(
      map(audits => audits.filter(audit => audit.auditSession.backupId === backup.id)),
      mergeMap((audits: Audit[]): Observable<Audit> => {
        this.logger.debug(`Audits found with backup id ${backup.id}`, audits.length);
        const audit: Audit = backup.data;
        audit.id = audits.length === 0 ? this.generateAuditId() : audits[0].id;
        this.logger.debug('Restored audit from cloud backup to id', audit.id);
        return this.saveAudit(audit).pipe(map(() => audit));
      }),
    );
  }

  public setAuditId(auditId: number | null) {
    this.auditId = auditId;
    this.auditId$.next(this.auditId);
  }

  public createAudit(auditForm: AuditForm, ward: Ward, auditor: User): Observable<Audit> {
    const session = new AuditSession(new Date());
    const audit = new Audit(this.generateAuditId(), auditForm.id, ward.id, auditor.id, session);
    return this.activateAudit(audit);
  }

  public activateAudit(audit: Audit): Observable<Audit> {
    return this.saveAudit(audit).pipe(
      tap(() => this.setAuditId(audit.id)),
      map(() => audit),
      tap(() => this.auditChanges$.next(audit)),
    );
  }

  /**
   * Create audit from prepopulated data
   * @param session Session object containing pre-populated observation data
   * @param userId currently logged-in user's id
   */
  public createPrepopulatedAudit(session: AuditSession, userId: number): Audit {
      if (session.audit_form === undefined || session.ward === undefined) {
        throw new Error('only instances of saved sessions can be passed into createPrepopulatedAudit');
      }
      return new Audit(this.generateAuditId(), session.audit_form, session.ward, userId, session, null, false, true);
  }

  public deleteAudit(audit: Audit): Observable<boolean> {
    return this.qipService.getAuditPhotoKeys(audit).pipe(
      mergeMap((keys: string[]) => {
        const observables: Observable<boolean>[] = keys.map((key: string) => this.storageService.removeItem(key));
        if (observables.length === 0) return of([]);
        else return forkJoin(...observables);
      }),
      mergeMap(() => this.storageService.removeItem(this.getAuditKey(audit.id))),
      tap(() => this.auditChanges$.next(audit)),
      tap(() => this.logger.debug('Deleted audit', audit)),
    );
  }

  /**
   * Creates a new observation for given audit form and initiates all fields with null values
   */
  public createObservation(audit: Audit, schema: AuditFormSchema): Observation {
    return {
      'ward': audit.wardId,
      'date': new Date(),
      'issues': [],
      'answer_comments': [],
    };
  }

  public createSubObservation(): SubObservation {
    return {
      'issues': [],
      'answer_comments': [],
    };
  }

  /**
   * Deletes audits create by user that have no observations
   */
  public deleteEmptyAudits(user: User): Observable<boolean[]> {
    return this.getAuditsByUser(user.id).pipe(
      map((audits: Audit[]) => audits.filter((audit: Audit) => audit.auditSession.observations.length === 0)),
      mergeMap((audits: Audit[]) => {
        if (audits.length === 0) return of([]);
        const deleteObservables = audits.map((audit) => this.deleteAudit(audit));
        return forkJoin(...deleteObservables);
      }),
    );
  }

  /**
   * Deletes all user audits
   */
  public deleteUserAudits(user: User): Observable<boolean[]> {
    return this.getAuditsByUser(user.id).pipe(
      mergeMap((audits: Audit[]) => {
        if (audits.length === 0) return of([]);
        const deleteObservables = audits.map((audit) => this.deleteAudit(audit));
        return forkJoin(...deleteObservables);
      }),
    );
  }

  /**
   * Retrieves field value from Observation, or multiple values if subform was filled multiple times
   * */
  public getFieldValue(fieldName: string, observation: Observation, schema: AuditFormSchema): Answer | Answer[] | null {
    if (schema.sub_forms.length > 0) {
      for (const subForm of schema.sub_forms) {
        const subObservation: SubObservation | SubObservation[] | undefined = observation[subForm.name] as SubObservation;
        if (subObservation instanceof Array) {
          const subObservations: SubObservation[] = subObservation;
          const answers: Answer[] = subObservations
            .map((obs: SubObservation) => obs[fieldName])
            .filter((answer: Answer | undefined) => !isNullOrUndefined(answer));
          if (answers.length > 0) return answers;
        } else if (subObservation !== undefined) {
          const answer = subObservation[fieldName] as Answer;
          if (answer !== undefined) return answer;
        }
      }
    } else {
      return observation[fieldName] as Answer;
    }
    return null;
  }

  /**
   * Retrieves field formatted value display from Observation
   * */
  public getFieldValueDisplay(fieldName: string, session: AuditSession, schema: AuditFormSchema): string {
    // Get first value
    let value: any = session.observations.map(
      (obs) => this.getFieldValue(fieldName, obs, schema)
    ).filter((v) => !!v)[0] || undefined;
    if (!value) return '';

    const field = this.auditFormService.getFieldSubformFromSchema(fieldName, schema)[0];
    if (!Array.isArray(value)) value = [value];
    const answers = value.map((v: any) => AuditFormService.getFieldAnswer(field, v));
    return answers.join(', ');
  }

  /**
   * Returns true if the audit has auto-cycle enabled and there is observations for all auto cycles choice options
   */
  public getAutoCycleComplete(audit: Audit, auditForm: AuditForm, schema: AuditFormSchema): boolean {
    const autoCycleField = schema.fields.filter(
      (field) => field.field_name === auditForm.config.auto_cycle_field
    )[0] || null;

    if (
      auditForm.observation_model !== CUSTOM_OBSERVATION_NAME ||
      !auditForm.config.auto_cycle_field ||
      autoCycleField == null
    ) return false;

    const actualValues = audit.auditSession.observations.map((obs, index) => {
      return this.getFieldValue(
        autoCycleField.field_name as string, audit.auditSession.observations[index], schema
      );
    });
    const expectedValues = autoCycleField.choices.map(({value}) => value).sort();

    return (
      actualValues.every(item => expectedValues.includes(item as any)) &&
      expectedValues.every(item => actualValues.includes(item))
    );
  }

  /**
   * Gets observable string term used to describe Issue in current form
   * @param {form} form
   * @param {boolean} plural whether the term should be in plural form
   * @param {boolean} capitalize whether the first letter should be uppercase
   * @param {boolean} sub_issue is this a sub issue label, or top level
   */
  public getIssueLabel(form: AuditForm, plural = false, capitalize = false, sub_issue = false): Observable<string> {
    if (form.config.terms === undefined) {
      return this.translateService.get(plural ? 'qip.issues' : 'qip.issue').pipe(
        map((issueLabel: string) => {
          return capitalize ? capitalizeFirstLetter(issueLabel) : issueLabel;
        })
      );
    }
    const term: Term = form.config.terms[sub_issue ? 'sub_issue' : 'issue'];
    const result = term[plural ? 'plural' : 'singular'];
    return of(capitalize ? capitalizeFirstLetter(result) : result);
  }

  /**
   * Updates the accordion answers cache with the new answer.
   * Propagates the updated answers to the subject.
   * @param {AccordionFieldAnswer} fieldAnswer
   */
  public addAccordionAnswer(fieldAnswer: AccordionFieldAnswer) {
      this.accordionAnswersCache = this.accordionAnswersCache.filter((answer: AccordionFieldAnswer) => {
        return `${answer.field_name}${answer.accordion_index}` !== `${fieldAnswer.field_name}${fieldAnswer.accordion_index}`;
      });
      this.accordionAnswersCache.push(fieldAnswer);
      this.propagateAccordionAnswers();
  }

  /**
   * Removes cleared answers from answers cache.
   * Propagates the updated answers to the subject.
   * @param {number} accordion_index
   */
  public clearAccordionAnswers(accordion_index: number) {
      this.accordionAnswersCache = this.accordionAnswersCache.filter((answer: AccordionFieldAnswer) => {
        return answer.accordion_index !== accordion_index;
      });
      this.propagateAccordionAnswers();
  }

  /**
   * Deletes accordion answers. This requires the index of some answers to be updated.
   * Propagates the updated answers to the subject.
   * @param {number} accordion_index
   */
  public deleteAccordionAnswers(accordion_index: number) {
      const unchanged = this.accordionAnswersCache.filter((answer: AccordionFieldAnswer) => answer.accordion_index < accordion_index);
      const updated = this.accordionAnswersCache.filter((answer: AccordionFieldAnswer) => answer.accordion_index > accordion_index)
        .map((answer: AccordionFieldAnswer) => {
          answer.accordion_index += 1;
          return answer;
        });
      this.accordionAnswersCache = unchanged.concat(updated);
      this.propagateAccordionAnswers();
  }

  /**
   * Removes all answers. To be called when an observation has been saved.
   */
  public resetAccordionAnswers() {
      this.accordionAnswersCache = [];
  }

  /**
   * Updates subject with all current answers in cache.
   */
  public propagateAccordionAnswers() {
      this.accordionAnswers$.next(this.accordionAnswersCache);
  }

  /**
   * A method for retrieving cached accordion answers.
   */
  public getCachedAccordionAnswers(): AccordionFieldAnswer[] {
    return this.accordionAnswersCache;
  }

  /**
   * Retrieves a brief string repr of observation based on app review fields
   * */
  public getObservationRepr(auditForm: AuditForm, session: AuditSession, schema: AuditFormSchema | null): string {
    const reviewFields: string[] = auditForm.config.app_review_fields || [];

    if (session.object_label) return session.object_label;
    if (!session.observations || session.observations.length === 0 || reviewFields.length === 0 || !schema) return '';


    const fieldsValues = reviewFields.map(
      (f) => f.split('.').pop() || ''
    ).map(
      (f) => this.getFieldValueDisplay(f, session, schema)
    ).filter(
      (v) => !!v
    );
    return fieldsValues.join(', ');
  }
}
