<mat-dialog-content class="mat-submission-picker-dialog-content" [ngClass]="{'loading': loading}">
  <mat-progress-spinner mode="indeterminate" *ngIf="loading" class="no-space center"></mat-progress-spinner>
  <div *ngIf="!loading && options.length">
    <mat-card *ngFor="let item of options;" class="mat-submission-picker-card">
      <mat-card-content>
        <div style="display: flex; align-items: center; width: 100%;">
          <span class="subtitle mat-submission-picker-type">{{item.isMultiStage ? ('audit-list.multi-stage-form' | translate)  : ('audit-list.saved-form' | translate) }}</span>
          <div class="mat-submission-picker-content">
            <div class="form-info">
              <mat-chip disabled class="subtitle mat-submission-picker-timestamp">{{item.auditSession.start_time | localizedDate }}</mat-chip>
              <div class="form-details">
                <span class="form-container">
                  <span class="form-name" *ngIf="item.formLabel">
                    {{item.formLabel}}
                  </span>
                  <span class="form-preview">
                    {{item.objectLabel }}
                  </span>
                </span>
                <span class="ward-name" *ngIf="item.wardLabel">{{item.wardLabel}}</span>
              </div>
            </div>
            <button mat-button type="button" (mousedown)="selectItem(item)">
              <mat-icon>file_download</mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <p *ngIf="!loading && options?.length === 0">{{'audit-list.no-submissions-in-progress' | translate}}</p>
</mat-dialog-content>
