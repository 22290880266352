import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';


export const DB_KEY_HINTS = 'in_app_hints';
export const DB_VALUE_HINTS_SHOWN = 'true';

export enum Hints {
  ObservationEdit = 'observation-edit',
  SubmitAudit = 'submit-audit',
  AddForm = 'add-form',
  CommonIssues = 'common-issues',
}

@Injectable({
  providedIn: 'root'
})
export class HintService {

  public hints = Hints;

  constructor(private storageService: StorageService) { }

  private createKey(hint: string): string {
    return `${DB_KEY_HINTS}-${hint}`;
  }

  public markShown(hint: Hints | string) {
    this.storageService.setString(this.createKey(hint), DB_VALUE_HINTS_SHOWN);
  }

  public wasShown(hint: Hints | string): boolean {
    return this.storageService.getString(this.createKey(hint)) === DB_VALUE_HINTS_SHOWN;
  }
}
