import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog, MatSidenav} from '@angular/material';
import {MenuItem} from './menu-item';
import {AccountService, PERMISSION_DOCS_MANAGE, PERMISSION_DOCS_VIEW} from '../accounts.service';
import {Router} from '@angular/router';
import {InformationService} from '../information.service';
import {AuditService} from '../audit-form/audit.service';
import {concat, Observable, of} from 'rxjs';
import {AnalyticsService} from '../analytics.service';
import {map, mergeMap} from 'rxjs/operators';
import {User} from '../api/models/user';
import {WalkthroughService} from '../walkthrough/walkthrough.service';
import {FeedbackDialogComponent} from '../feedback-dialog/feedback-dialog.component';
import {DASHBOARD_TOKEN_LOGIN, DOCUMENTS_MANAGE, DOCUMENTS_VIEW} from '../api/constants';
import {ACCOUNT_TYPE_PUBLIC} from '../api/models/auditor';
import {TranslateService} from '@ngx-translate/core';
import {Audit} from '../audit-form/audit';
import {openUrl} from '../utils/misc';
import {NGXLogger} from 'ngx-logger';
import {ModalService} from '../alert/service/modal.service';
import {ModalButton} from '../alert/modal-alert-data';
import {ApiService} from '../api/api.service';
import {tap} from 'rxjs/internal/operators/tap';
import {InstitutionService} from '../institution.service';
import {Institution} from '../api/models/institution';

@Component({
  selector: 'meg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css', '../../animations.css'],
})
export class MenuComponent implements OnInit {
  items = [] as MenuItem[];
  mode = new FormControl('over');
  @ViewChild('sideNav') sideNav: MatSidenav;
  menuOpenChanged = false;
  private allowMenuSwipe = false;
  private unavailableMenuPages = ['/loading', '/login'];
  public user$: Observable<User | null> = of(null);

  constructor(private accountService: AccountService,
              private router: Router,
              private informationService: InformationService,
              private analytics: AnalyticsService,
              private auditService: AuditService,
              private walkthroughService: WalkthroughService,
              private translateService: TranslateService,
              private dialog: MatDialog,
              private elementRef: ElementRef,
              private logger: NGXLogger,
              private modalService: ModalService,
              private apiService: ApiService,
              private institutionService: InstitutionService
              ) {
  }

  ngOnInit() {

    this.allowMenuSwipe = this.accountService.isAuthenticated() && this.unavailableMenuPages.indexOf(this.router.url) === -1;

    /**
     * subscribing to router.events when reloading page
     */
    this.router.events.subscribe((url: any ) => {
      this.logger.trace('events url', url);
      if ((url['url']) !== undefined) {
        this.allowMenuSwipe = this.accountService.isAuthenticated() && this.unavailableMenuPages.indexOf(url['url']) === -1;
      }
    });

    const hammertime = new Hammer(this.elementRef.nativeElement, {});
    hammertime.on('panright', (event: any) => {
      if (this.allowMenuSwipe && event.center.x < 30) {
        this.sideNav.open();
      }
    });
    hammertime.get('pan').set({ threshold: 200 });
    hammertime.on('panleft', () => {
      this.sideNav.close();
    });

    this.sideNav.openedChange.subscribe(
      (next: boolean) => this.menuOpenChanged = next,
    );

    this.user$ = this.accountService.currentUser$;

    this.initMenuItems();
  }

  /**
   * Observes number of audits in SavedAudits section.
   * Initially emits current audit count and emits a new value whenever an audit is saved/deleted.
   * Note: Number of audits are not unique - saving audit will also trigger emit.
   */
  private get auditCount$(): Observable<number> {
    return concat(
      // emit empty event before listening to changes to trigger fetching number of audits
      of(null),
      this.auditService.auditChanges$
    ).pipe(
      mergeMap((audit: Audit) => this.accountService.getUser()),
      mergeMap((user: User) => this.auditService.getAuditsByUser(user.id, true)),
      map((audits: Audit[]) => audits.length),
    );
  }

  private initMenuItems() {
    this.translateService.stream([
      'menu.dashboard',
      'menu.documents',
      'menu.messages',
      'menu.qip',
      'menu.saved-audits',
      'menu.restart-audit',
      'menu.profile',
      'menu.settings',
      'menu.calendar',
      'menu.about',
      'menu.contact-us',
      'menu.logout',
    ]).subscribe((translations: {[id: string]: string}) => {
      const dashboard = new MenuItem(translations['menu.dashboard'], () => {
        this.analytics.trackButtonClick('Dashboard');
        this.sideNav.toggle();
        this.openDashboard();
      }, false, 'launch');
      const qip = new MenuItem(translations['menu.qip'], () => {
        this.analytics.trackButtonClick('QIP');
        this.sideNav.toggle();
        this.router.navigate(['qip']);
      }, false, 'done');
      const messages = new MenuItem(translations['menu.messages'], () => {
        this.analytics.trackButtonClick('messages');
        this.sideNav.toggle();
        this.router.navigate(['channels']);
      }, false, 'message');
      const menuItemProfile = new MenuItem(translations['menu.profile'], () => {
        this.sideNav.toggle();
        this.router.navigateByUrl('/profile');
        this.analytics.trackButtonClick('Profile');
      }, true, 'person');
      const calendarMenuItem = new MenuItem(translations['menu.calendar'], () => {
        this.sideNav.toggle();
        this.router.navigateByUrl('/calendar');
        this.analytics.trackButtonClick('calendar');
      }, true, 'event');
      const documents = new MenuItem(translations['menu.documents'], () => {}, false, 'launch');

      this.accountService.currentUser$.subscribe((user: User | null) => {
        const userPermissions: string[] = user ? user.permissions || [] : [];
        dashboard.visible = userPermissions.indexOf('dashboard_widgets.view_dashboard') > -1;
        qip.visible = userPermissions.indexOf('qip.change_issue') > -1;
        if (user) {
          this.institutionService.getInstitutionOrNull(user.auditor.institution).subscribe((institution: Institution | null) => {
            if (institution && institution.megdocs_enabled) {
              this.accountService.hasPermissions(PERMISSION_DOCS_MANAGE).subscribe(
                (hasChangePermission: boolean) => {
                  if (hasChangePermission) {
                    documents.visible = hasChangePermission;
                    documents.action = () => {
                        this.analytics.trackButtonClick('MegDocs');
                        this.sideNav.toggle();
                        this.openDashboard(`docs/${institution.slug}${DOCUMENTS_MANAGE}`);
                    };
                  } else {
                    this.accountService.hasPermissions(PERMISSION_DOCS_VIEW).subscribe(
                      (hasViewPermission: boolean) => {
                        documents.visible = hasViewPermission;
                        documents.action = () => {
                            this.analytics.trackButtonClick('MegDocs');
                            this.sideNav.toggle();
                            this.openDashboard(`docs/${institution.slug}${DOCUMENTS_VIEW}`);
                        };
                      }
                    );
                  }
                },
              );
            }
          });
        }
        messages.visible = user !== null && user.messaging_enabled === true;
        const isPublicAccount = user === null || user.auditor.account_type === ACCOUNT_TYPE_PUBLIC;
        menuItemProfile.visible = !isPublicAccount;
        calendarMenuItem.visible = !isPublicAccount;
      });

      this.items = [
        new MenuItem(translations['menu.saved-audits'], () => {
          this.analytics.trackButtonClick('Saved Audits');
          this.sideNav.toggle();
          this.router.navigateByUrl('/draft-audits');
        }, true, 'list', this.auditCount$.pipe(
          map((numAudits: number) => numAudits === 0 ? null : numAudits.toString()),
        )),
        messages,
        qip,
        new MenuItem(translations['menu.restart-audit'], () => {
          this.analytics.trackButtonClick('New Audit');
          this.auditService.clearAuditFormId();
          this.sideNav.toggle();
          this.router.navigateByUrl('/');
        }, true, 'add'),
        calendarMenuItem,
        menuItemProfile,
        new MenuItem(translations['menu.settings'], () => {
          this.sideNav.toggle();
          this.router.navigate(['settings']);
          this.analytics.trackButtonClick('Settings');
        }, true, 'settings'),
        new MenuItem(translations['menu.about'], () => {
          this.analytics.trackButtonClick('About');
          this.sideNav.toggle();
          this.router.navigate(['about-app']);
        }, true, 'info'),
        new MenuItem(translations['menu.contact-us'], () => {
          this.analytics.trackButtonClick('Contact Us');
          this.sideNav.toggle();
          this.dialog.open(FeedbackDialogComponent, {
            width: '100%',
          }).afterClosed().subscribe((result: boolean) => {
            if (result) {
              alert(this.translateService.instant('contact.success-message'));
            }
          });
        }, true, 'message'),
        dashboard,
        documents,
        new MenuItem(translations['menu.logout'], () => {
            this.analytics.trackButtonClick('Log Out');
            this.sideNav.toggle();
            this.logOutConfirm();
          }, true, 'exit_to_app'
        ),
      ];
    });
  }

  /**
   * Opens back-end dashboard
   */
  private openDashboard(next: string | null = '') {
    const token = this.accountService.token;
    this.accountService.getUser().pipe(
      map((user: User) => user.username),
      mergeMap(username => this.apiService.buildUrl(
        `${DASHBOARD_TOKEN_LOGIN}?username=${username}&token=${token}${next ? '&next=' + next : ''}`)),
      tap(url => openUrl(url)),
    ).subscribe();
  }

  private logOut() {
    this.accountService.logOut(this.auditService, this.router);
  }

  private logOutConfirm() {
    this.modalService.openInfoModal(
      this.translateService.get('menu.logout-prompt'),
      new ModalButton(this.translateService.get('menu.logout'), () => this.logOut(), 'exit_to_app'),
      this.modalService.btnCancel(),
    );
  }

  toggleMenu(state: boolean) {
    this.sideNav.toggle();
  }

  walkthroughShowing() {
    return this.walkthroughService.showing;
  }
}
