<div class="heading">
  <h1 class="day-of-month">{{day.day_of_month}}</h1>
  <h2 class="day-of-week">{{day.day_of_week}}</h2>
  <h3 class="month">{{month.month_name}} {{month.year}}</h3>
</div>

<mat-dialog-content class="calendar-dialog-content">
  <mat-list>
    <mat-list-item  *ngFor="let item of events$ | async" class="calendar-{{ item.type }}">
      <mat-chip [selected]="true" [color]="item.type == 'schedule' ? 'primary' : 'accent'">{{ item.name }}</mat-chip>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
