/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./walkthrough.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i4 from "@angular/material/icon";
import * as i5 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./walkthrough.component";
import * as i12 from "./walkthrough.service";
import * as i13 from "@angular/router";
var styles_WalkthroughComponent = [i0.styles];
var RenderType_WalkthroughComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WalkthroughComponent, data: {} });
export { RenderType_WalkthroughComponent as RenderType_WalkthroughComponent };
function View_WalkthroughComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[2, "hidden", null], [2, "visible-from-right", null], [2, "hidden-to-left", null], [2, "visible-from-left", null], [2, "hidden-to-right", null]], [[null, "swipeleft"], [null, "swiperight"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("swipeleft" === en)) {
        var pd_0 = (_co.swipe(_v.context.index, $event.type) !== false);
        ad = (pd_0 && ad);
    } if (("swiperight" === en)) {
        var pd_1 = (_co.swipe(_v.context.index, $event.type) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "walkthrough-image"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "intro-txt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isCurrentPage(_v.context.$implicit); var currVal_1 = (_co.isCurrentPage(_v.context.$implicit) && (_co.action === _co.SWIPE_ACTION.LEFT)); var currVal_2 = (!_co.isCurrentPage(_v.context.$implicit) && (_co.action === _co.SWIPE_ACTION.LEFT)); var currVal_3 = (_co.isCurrentPage(_v.context.$implicit) && (_co.action === _co.SWIPE_ACTION.RIGHT)); var currVal_4 = (!_co.isCurrentPage(_v.context.$implicit) && (_co.action === _co.SWIPE_ACTION.RIGHT)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.imageSrc; _ck(_v, 1, 0, currVal_5); var currVal_6 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_6); var currVal_7 = _v.context.$implicit.text; _ck(_v, 6, 0, currVal_7); }); }
function View_WalkthroughComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "swipe-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "swipe-image"], ["src", "assets/images/swipe-indicator.png"], ["width", "100"]], null, null, null, null, null))], null, null); }
function View_WalkthroughComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "page-progress"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "page-progress"; var currVal_1 = ((_v.context.$implicit === _co.currentPage) ? "current" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_WalkthroughComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["keyboard_arrow_right"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
function View_WalkthroughComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i3.View_MatIcon_0, i3.RenderType_MatIcon)), i1.ɵdid(1, 638976, null, 0, i4.MatIcon, [i1.ElementRef, i4.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["check"]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_WalkthroughComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "walkthrough-content swipe-box"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WalkthroughComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WalkthroughComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 13, "div", [["class", "walkthrough-status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["id", "btn-skip"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.finish() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(9, 0, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "walkthrough-progress"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WalkthroughComponent_3)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 5, "button", [["id", "btn-next"], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(15, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WalkthroughComponent_4)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WalkthroughComponent_5)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "walkthrough-content swipe-box"; var currVal_1 = ((_co.pageNumber === 0) ? "first-page" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.walkthroughPages; _ck(_v, 3, 0, currVal_2); var currVal_3 = (_co.pageNumber === 0); _ck(_v, 5, 0, currVal_3); var currVal_7 = _co.walkthroughPages; _ck(_v, 13, 0, currVal_7); var currVal_10 = !_co.isLastPage; _ck(_v, 17, 0, currVal_10); var currVal_11 = _co.isLastPage; _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 8).disabled || null); var currVal_5 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("skip")); _ck(_v, 9, 0, currVal_6); var currVal_8 = (i1.ɵnov(_v, 15).disabled || null); var currVal_9 = (i1.ɵnov(_v, 15)._animationMode === "NoopAnimations"); _ck(_v, 14, 0, currVal_8, currVal_9); }); }
export function View_WalkthroughComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "meg-walkthrough", [], null, null, null, View_WalkthroughComponent_0, RenderType_WalkthroughComponent)), i1.ɵdid(1, 114688, null, 0, i11.WalkthroughComponent, [i12.WalkthroughService, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WalkthroughComponentNgFactory = i1.ɵccf("meg-walkthrough", i11.WalkthroughComponent, View_WalkthroughComponent_Host_0, {}, {}, []);
export { WalkthroughComponentNgFactory as WalkthroughComponentNgFactory };
