var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector, NgZone, OnInit } from '@angular/core';
import { Issue, IssuePhoto, Room } from '../../../api/models/issue';
import { MAX_IMAGE_SIZE, QipService } from '../../qip.service';
import { Audit } from '../../../audit-form/audit';
import { InstitutionService } from '../../../institution.service';
import { map, mergeMap, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { AuditFormService } from '../../../audit-form.service';
import { AudioDialogComponent } from '../../audio-dialog/audio-dialog.component';
import { FormControl } from '@angular/forms';
import { getFileExtension } from '../../../utils/misc';
import { CameraService } from '../../../camera.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '../../../base.component';
var IssueComponent = /** @class */ (function (_super) {
    __extends(IssueComponent, _super);
    function IssueComponent(qipService, institutionService, dialog, ngZone, auditFormService, cameraService, sanitizer, injector) {
        var _this = _super.call(this, injector) || this;
        _this.qipService = qipService;
        _this.institutionService = institutionService;
        _this.dialog = dialog;
        _this.ngZone = ngZone;
        _this.auditFormService = auditFormService;
        _this.cameraService = cameraService;
        _this.sanitizer = sanitizer;
        _this.injector = injector;
        _this.errors = null;
        _this.edit = new EventEmitter(false);
        _this.photos = [];
        _this.audio = null;
        _this.field = null;
        _this.rooms = [];
        _this.auditFormConfig = null;
        _this.roomsCtrl = new FormControl();
        _this.handlersCtrl = new FormControl();
        _this.dueDateCtrl = new FormControl();
        _this.roomsCtrl.valueChanges
            .pipe(map(function (searchString) { return searchString ? _this.filterRooms(searchString) : _this.rooms; })).subscribe(function (rooms) { return _this.filteredRooms = rooms; });
        _this.handlersCtrl.valueChanges
            .pipe(map(function (searchString) { return searchString ? _this.filterHandlers(searchString) : _this.issueHandlers; })).subscribe(function (handlers) { return _this.filteredHandlers = handlers; });
        _this.dueDateCtrl.valueChanges.subscribe(function (date) { return _this.issue.duedate = date; });
        return _this;
    }
    IssueComponent.prototype.filterRooms = function (value) {
        var filterValue = value.toLowerCase();
        return this.rooms.filter(function (room) { return room.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    IssueComponent.prototype.filterHandlers = function (value) {
        var filterValue = value.toLowerCase();
        return this.issueHandlers.filter(function (handler) { return handler.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    IssueComponent.prototype.getPhotoBase64 = function (photo) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(photo.photo);
    };
    IssueComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.issue) {
            this.dueDateCtrl.setValue(this.issue.duedate);
        }
        this.institutionService.getWardData(this.audit.wardId).pipe(tap(function (result) {
            if (result[2].rooms) {
                _this.rooms = result[2].rooms;
                _this.filteredRooms = result[2].rooms;
            }
        }), mergeMap(function (result) { return _this.qipService.getIssueHandlers(result[0].id, _this.audit.auditFormId); }), mergeMap(function (issueHandlers) { return _this.auditFormService.getAuditForm(_this.audit.auditFormId).pipe(tap(function (auditForm) { return _this.auditFormConfig = auditForm.config; }), map(function (auditForm) {
            if (auditForm.config.issue_handlers && auditForm.config.issue_handlers.length > 0) {
                return issueHandlers.filter(function (issueHandler) { return auditForm.config.issue_handlers.indexOf(issueHandler.id) > -1; });
            }
            return issueHandlers;
        })); })).subscribe(function (issueHandlers) {
            _this.issueHandlers = issueHandlers;
            _this.filteredHandlers = issueHandlers;
            _this.logger.debug(_this.issueHandlers);
            if (_this.issue) {
                issueHandlers.forEach(function (handler) {
                    if (handler.id === _this.issue.handler) {
                        _this.handlersCtrl.setValue(handler.name);
                    }
                });
            }
        });
        if (this.issue.field_name) {
            this.auditFormService.getFieldByName(this.audit.auditFormId, this.issue.field_name).subscribe(function (field) { return _this.field = field; });
        }
        this.updatePhotos();
    };
    IssueComponent.prototype.updatePhotos = function () {
        var _this = this;
        this.logger.debug('Issue photos will be updated...');
        this.qipService.getIssuePhotos(this.audit, this.issue).pipe(tap(function (value) { return _this.logger.debug('Updated photos display', value); })).subscribe(function (photos) { return _this.photos = photos; });
    };
    /**
     * Updates / adds room object in issue
     * Sets room to null if room number is empty
     * Creates new room object and adds it to issue otherwise.
     * @param {string} roomName room name entered by user
     */
    IssueComponent.prototype.updateRoom = function (roomName) {
        var room = roomName === '' ? null : new Room(roomName);
        this.issue.room = room;
        this.logger.debug("Updated issue \"" + this.issue + "\" room to " + roomName);
        this.onEdit();
    };
    IssueComponent.prototype.updateHandler = function (id) {
        this.issue.handler = id;
        this.logger.debug("Updated issue \"" + this.issue + "\" handler to " + id);
        this.onEdit();
    };
    /**
     * Called when user clicks photo capture button
     */
    IssueComponent.prototype.capturePhotoClicked = function () {
        this.openCamera();
    };
    /**
     * Opens relevant camera interface:
     * - for web app, open html view with web camera
     * - for native apps, opens cordova plugin camera
     */
    IssueComponent.prototype.openCamera = function () {
        var _this = this;
        var subscription = this.cameraService.takePhoto().pipe(tap(function (photo) { return _this.logger.debug('Received photo from camera service', photo); }), tap(function (photo) {
            var issuePhoto = new IssuePhoto(photo, null);
            _this.photos.push(issuePhoto);
        })).subscribe();
        this.subscriptions.push(subscription);
    };
    /**
     * Called when user selects a file to upload using file dialog
     */
    IssueComponent.prototype.onPhotoPicked = function (files) {
        var _this = this;
        var supportedFileTypes = ['jpeg', 'jpg', 'png'];
        var file = files.item(0);
        if (file !== null) {
            this.logger.debug('file selected', file);
            if (file.size > MAX_IMAGE_SIZE) {
                var errorMessage = this.translateService.instant('qip.incorrect-file-size', {
                    max_size: MAX_IMAGE_SIZE / 1024 / 1024,
                    file_size: Math.round(file.size / 1024 / 1024),
                });
                alert(errorMessage);
                return;
            }
            var fileExtension = getFileExtension(file);
            this.logger.debug("File extension: " + fileExtension);
            if (fileExtension === undefined || supportedFileTypes.indexOf(fileExtension.toLowerCase()) === -1) {
                var supportedFileTypeString = supportedFileTypes.join(', ');
                alert(this.translateService.instant('qip.incorrect-audio-file-type') + " (" + supportedFileTypeString + ")");
            }
            else {
                var fileReader_1 = new FileReader();
                fileReader_1.onloadend = function (event) {
                    var photo = new IssuePhoto(fileReader_1.result);
                    _this.photos.push(photo);
                };
                fileReader_1.readAsDataURL(file);
            }
        }
        else {
            this.logger.error('File is null');
        }
    };
    /**
     * Removes issue photo from database
     * If there are other photos, photo is removed from photo array and Issue photos updated.
     * Otherwise the whole issue photos array is removed
     * @param {IssuePhoto} photo photo to be removed
     */
    IssueComponent.prototype.removePhoto = function (photo) {
        this.photos = this.photos.filter(function (value) { return value.photo !== photo.photo; });
    };
    IssueComponent.prototype.openAudioDialog = function () {
        var _this = this;
        if (this.issue.audio === null || (confirm(this.translateService.instant('qip.audio-override-prompt')))) {
            this.dialog.open(AudioDialogComponent, {
                width: '100%',
            }).afterClosed().subscribe(function (result) {
                if (typeof result === 'string') {
                    _this.audio = result;
                    _this.onEdit();
                }
            });
        }
    };
    IssueComponent.prototype.removeAudio = function () {
        this.audio = null;
    };
    IssueComponent.prototype.onEdit = function () {
        this.edit.emit(this.issue);
    };
    Object.defineProperty(IssueComponent.prototype, "isAudioRecordingEnabled", {
        get: function () {
            var ableToRecord = (window.cordova !== undefined || window.MediaRecorder !== undefined);
            if (this.auditFormConfig && this.auditFormConfig.enable_audio_recording !== undefined) {
                return (ableToRecord && this.auditFormConfig.enable_audio_recording);
            }
            return ableToRecord;
        },
        enumerable: true,
        configurable: true
    });
    IssueComponent.prototype.updateMedia = function () {
        this.qipService.setIssuePhotos(this.audit, this.issue, this.photos).subscribe();
        this.issue.audio = this.audio;
        this.onEdit();
    };
    IssueComponent.prototype.blurRoomInput = function () {
        var node = document.querySelector('.room-input');
        node.blur();
    };
    IssueComponent.prototype.blurHandlerInput = function () {
        var node = document.querySelector('.handler-input');
        node.blur();
    };
    return IssueComponent;
}(BaseComponent));
export { IssueComponent };
