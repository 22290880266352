import { ElementRef } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { addNameToBase64, getBase64HeaderName, isNullOrUndefined } from '../../../utils/misc';
import { TranslateService } from '@ngx-translate/core';
import { CameraService } from '../../../camera.service';
import { tap } from 'rxjs/operators';
var FileFieldComponent = /** @class */ (function () {
    function FileFieldComponent(translateService, cameraService) {
        this.translateService = translateService;
        this.cameraService = cameraService;
        this.fileFormControl = new FormControl();
        this.multiple = false;
        this.value = [];
        this.fileNames = [];
        /**
         *  Callback function to call when the file field has changed
         * @param _ string the value to update the form control
         */
        this.onValueChange = function (_) { };
    }
    Object.defineProperty(FileFieldComponent.prototype, "valueLabel", {
        get: function () {
            return this.fileNames.join(', ');
        },
        enumerable: true,
        configurable: true
    });
    FileFieldComponent.prototype.setFormControl = function (value) {
        if (typeof value === 'string')
            value = [value];
        this.value = value;
        // @ts-ignore
        this.fileNames = value.map(getBase64HeaderName);
        if (this.multiple)
            this.onValueChange(value);
        else
            this.onValueChange(value.length === 0 ? '' : value[0]);
    };
    FileFieldComponent.prototype.addFile = function (fileBase64) {
        // if only one file is supported, replace file
        if (!this.multiple) {
            this.fileNames = [];
            this.value = [];
        }
        var name = getBase64HeaderName(fileBase64);
        if (name !== null)
            this.fileNames.push(name);
        var values = this.value.concat([fileBase64]);
        this.setFormControl(values);
    };
    FileFieldComponent.prototype.onNativeInputFileSelect = function (event) {
        var _this = this;
        var files = event.srcElement.files;
        if (files.length === 0)
            return;
        var _loop_1 = function (file) {
            var reader = new FileReader();
            reader.onload = function () {
                try {
                    var base64Str = addNameToBase64(reader.result, file.name);
                    _this.addFile(base64Str);
                }
                catch (_a) {
                    alert(_this.translateService.instant('audit-form.field.adding-file-error'));
                }
            };
            reader.readAsDataURL(file);
            // Don't read any more files if this widget accepts only one file
            if (!this_1.multiple)
                return { value: void 0 };
        };
        var this_1 = this;
        for (var _i = 0, files_1 = files; _i < files_1.length; _i++) {
            var file = files_1[_i];
            var state_1 = _loop_1(file);
            if (typeof state_1 === "object")
                return state_1.value;
        }
    };
    FileFieldComponent.prototype.selectFile = function () {
        this.nativeInputFile.nativeElement.click();
    };
    FileFieldComponent.prototype.takePhoto = function () {
        var _this = this;
        this.cameraService.takePhoto().pipe(tap(function (photo) {
            _this.addFile(photo);
        })).subscribe();
    };
    // ---------- ControlValueAccessor methods start ------------- //
    /**
     * Writes a new value to the element.
     * @param value: any
     */
    FileFieldComponent.prototype.writeValue = function (value) {
        if (isNullOrUndefined(value)) {
            this.setFormControl([]);
        }
        else {
            this.setFormControl(value);
        }
    };
    /**
     * Registers a callback function that is called when the control's value changes in the UI.
     * @param fn: any The callback function to register
     */
    FileFieldComponent.prototype.registerOnChange = function (fn) {
        this.onValueChange = fn;
    };
    /**
     * Registers a callback function is called by the forms API on initialization to update the form model on blur.
     * @param fn: any The callback function to register
     */
    FileFieldComponent.prototype.registerOnTouched = function (fn) { };
    return FileFieldComponent;
}());
export { FileFieldComponent };
