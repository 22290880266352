import {Widget} from '../../api/models/dashboard';
import {ChartConfiguration, ChartData, ChartDataSets} from 'chart.js';
import {BaseChart} from './base_chart';

export class LineChart extends BaseChart {

  public buildChart(widget: Widget): ChartConfiguration | null {
    const chartDataValues = this.chartCalculator.getFieldChartData(widget);
    const chartValues = this.buildChartData(chartDataValues);
    const dataSets: ChartDataSets[] = [{data: chartValues.data, borderColor: this.chartColors[0], fill: false}];
    const chartData: ChartData = {labels: chartValues.labels, datasets: dataSets};
    const options = {
      title: {
        display: true,
        text: this.getChartTitles(widget),
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          display: true
        }],
        yAxes: [{
          display: true
        }],
      }
    };
    return {type: 'line', data: chartData, options: options};
  }
}
