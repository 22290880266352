import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material';
import {SnackbarData} from './snackbar-data';

@Component({
  selector: 'meg-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css']
})
export class CustomSnackbarComponent implements OnInit {
  public message: string;
  public icon: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) data: SnackbarData) {
    this.message = data.message;
    this.icon = data.icon;
  }

  ngOnInit() {
  }

}
