import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/internal/Observable';
import {Channel, Message} from '../api/models/messaging';
import {PaginatedResponse} from '../api/responses/paginated-response';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {NGXLogger} from 'ngx-logger';
import {UserIdName} from '../api/models/basic-auditor';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  constructor(private api: ApiService, private logger: NGXLogger) {
  }

  public getChannels(): Observable<Channel[]> {
    return this.api.fetchChannels().pipe(
      map((response: PaginatedResponse<Channel>) => response.results),
    );
  }

  /**
   * Gets list of messages in the channel and marks channel as read at the point of last messages.
   * Returns messages in reverse order (newest last)
   */
  public getMessages(channelId: number, onlyUnread = false): Observable<Message[]> {
    const source = onlyUnread ? this.api.fetchChannelUnreadMessages(channelId) : this.api.fetchChannelMessages(channelId);
    return source.pipe(
      map(result => result.results),
      switchMap((messages: Message[]) => {
        if (messages.length === 0) return of(messages);
        return this.markMessagesAsRead(channelId, messages[0].created).pipe(
          map(() => messages),
        );
      }),
      map((result: Message[]) => result.reverse()),
    );
  }

  public getChannel(channelId: number): Observable<Channel> {
    return this.api.fetchChannel(channelId);
  }

  public markMessagesAsRead(channelId: number, date: Date | string): Observable<boolean> {
    this.logger.debug('Marking last message as read', date);
    return this.api.markMessagesAsRead(channelId, date).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  public sendMessage(channelId: number, message: string): Observable<boolean> {
    return this.api.sendChannelMessage(channelId, message).pipe(
      map(() => true),
    );
  }

  public sendP2pMessage(userId: number, message: string): Observable<Channel> {
    return this.api.sendP2pMessage(userId, message);
  }

  public getContacts(search: string): Observable<UserIdName[]> {
    if (search.length === 0) return of([]);
    return this.api.fetchUsers(search).pipe(
      map(result => result.results)
    );
  }
}
