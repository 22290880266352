import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {concat, Observable, Subject} from 'rxjs';
import {Language} from './api/models/language';

export const DB_KEY_AVAIL_LANGUAGES = 'available_languages';

/**
 * Stores available languages
 */
@Injectable()
export class LanguageService {
  private languageObservable: Subject<Language[]> = new Subject<Language[]>();

  constructor(private storageService: StorageService) {
  }

  public saveLanguages(languages: Language[]): Observable<boolean> {
    this.languageObservable.next(languages);
    return this.storageService.setItem(DB_KEY_AVAIL_LANGUAGES, languages);
  }

  /**
   * Subscribe to language choices.
   * Emits current language set and re-emits translated language choices
   * when they change.
   */
  public getAllLanguages(): Observable<Language[]> {
    return concat(
      this.storageService.getItem<Language[]>(DB_KEY_AVAIL_LANGUAGES),
      this.languageObservable,
    );
  }
}
