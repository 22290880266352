import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {InsightsService} from './insights/insights.service';
import {ApiService} from './api/api.service';
import {ModelNameType} from './api/models/analytics';

export const CATEGORY_UI = 'ui';
export const ACTION_CLICK = 'click';
export const ACTION_USER_ACTION = 'user';

@Injectable()
export class AnalyticsService {
  private gtag: any = (window as any).gtag;
  constructor(private router: Router,
              private logger: NGXLogger,
              private insights: InsightsService,
              private apiService: ApiService) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) this.trackPage(event.url);
    });
  }

  public trackPage(url: string) {
    this.logger.trace('Tracking url', url);
    this.gtag('config', 'G-ZREBQKQ46Y', { page_path: url });
    this.insights.trackPage(url);
  }

  public trackEvent(category: string, action: string, label?: string, value?: string) {
    this.logger.trace('Tracking event', action);
    this.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
    this.insights.logTrace(action, {
      category: category,
      label: label,
      value: value,
    });
  }

  public trackAction(action: string) {
    this.trackEvent(CATEGORY_UI, action);
  }

  public trackButtonClick(label: string) {
    this.trackEvent(CATEGORY_UI, ACTION_CLICK, label);
  }

  public trackUserAction(label: string, value?: string) {
    this.trackEvent(CATEGORY_UI, ACTION_USER_ACTION, label, value);
  }

  public trackModelAction(modelName: ModelNameType, modelObjectId: number) {
    this.apiService.submitAnalyticsEntry(modelName, modelObjectId).toPromise().then(() => {
      return true;
    });
  }
}
