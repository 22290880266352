import {Component, OnInit} from '@angular/core';
import {AuditService} from '../audit-form/audit.service';
import {Audit} from '../audit-form/audit';
import {BaseLoginRequiredComponent} from '../base';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '../accounts.service';
import {User} from '../api/models/user';
import {InstitutionService} from '../institution.service';
import {AuditFormService} from '../audit-form.service';
import {Observable} from 'rxjs';
import {AuditForm} from '../api/models/audit-form';
import {Ward} from '../api/models/ward';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {AnalyticsService} from '../analytics.service';
import {AuditContainer} from './audit-container';
import {forkJoin, of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CheckAuditFormService} from '../check-audit-form.service';
import {NGXLogger} from 'ngx-logger';
import {ApiService} from '../api/api.service';
import {validateSessionToken} from '../api/utils';
import {MatDialog} from '@angular/material';
import {SubmissionPickerComponent} from '../submission-picker/submission-picker.component';
import {ACCOUNT_TYPE_PUBLIC} from '../api/models/auditor';
import {DownloadService} from '../loading/download.service';

@Component({
  selector: 'meg-audit-list',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.css', '../../animations.css']
})
export class AuditListComponent extends BaseLoginRequiredComponent implements OnInit {
  public hideDownloadButton = true;
  public items: AuditContainer[];
  public dialogOpen = false;

  constructor(private auditService: AuditService, private institutionService: InstitutionService, public auditFormService: AuditFormService,
              private analytics: AnalyticsService, router: Router, route: ActivatedRoute, accountService: AccountService,
              private api: ApiService, private translateService: TranslateService, private checkAuditService: CheckAuditFormService,
              private dialog: MatDialog, private downloadService: DownloadService, logger: NGXLogger) {
    super(router, route, accountService, logger);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.accountService.isAuthenticated()) {
      validateSessionToken(this.api, this.translateService);
      this.auditService.setAuditId(null);
      this.loadAudits();
    }

    this.accountService.getUser().subscribe((user: User) => {
      this.hideDownloadButton = user.auditor.account_type === ACCOUNT_TYPE_PUBLIC;
    });
  }

  /**
   * Checks and downloads form update
   */
  private updateForm(formId: number): Observable<boolean> {
    return this.downloadService.checkAuditFormUpdate(formId).pipe(
      mergeMap((updateAvailable: boolean): Observable<boolean> => {
        if (updateAvailable) return this.downloadService.downloadAuditForm(formId, null);
        else return of(false);
      }),
      map(() => true),
    );
  }

  public onDownloadClick() {
    this.dialogOpen = true;
    this.dialog.open(SubmissionPickerComponent).afterClosed().pipe(
      mergeMap((audit: Audit) => {
        this.dialogOpen = false;
        if (audit === undefined) return of();

        // Check for form updates then open the form
        return this.updateForm(audit.auditFormId).pipe(
          mergeMap((result) => {
            this.logger.debug('Updated form ', result);
            return this.auditService.activateAudit(audit).pipe(
              tap((a: Audit) => {
              this.logger.debug('Selected submission', audit);
                this.onEditAudit(a);
              }),
            );
          }
        ));
      }),
    ).subscribe();
  }

  private loadAudits() {
    this.accountService.getUser().pipe(
      mergeMap((user: User) =>
        this.auditService.getAuditsByUser(user.id, true).pipe(
          mergeMap((audits: Audit[]): Observable<AuditContainer[]> => {
            // Bundle each audit with its audit form and ward objects
            const observables: Observable<AuditContainer | null>[] = audits.map((audit: Audit) => forkJoin(
              this.auditFormService.getAuditFormWithSchema(audit.auditFormId),
              this.institutionService.getWard(audit.wardId).pipe(
                catchError((e) => of(null)),
              ),
            ).pipe(
              map((([[form, schema], ward]) => new AuditContainer(
                audit,
                form,
                ward,
                this.auditService.getObservationRepr(form, audit.auditSession, schema)
                ))),
              catchError((e) => {
                // Expecting an error if user can no longer see the audit form or ward
                this.logger.error('Skipping audit', audit, e);
                return of(null);
              }),
            ));
            if (observables.length === 0) return of([]);
            return forkJoin(...observables).pipe(
              map((results: (AuditContainer | null)[]): AuditContainer[] => {
                return <AuditContainer[]>results.filter((result: AuditContainer | null) => result !== null);
              }),
            );
          }),
          tap((audits: AuditContainer[]) => this.items = audits),
        ))).subscribe(
      () => {},
      (error: any) => this.logger.error(error),
    );
  }


  public deleteAudit(audit: Audit) {
    this.analytics.trackButtonClick('Delete');
    this.analytics.trackAction('Audit delete');
    if (confirm(this.translateService.instant('audit-list.delete-audit-prompt'))) {
      this.auditService.deleteAudit(audit).subscribe(
        (result: boolean) => this.items = this.items.filter((item: AuditContainer) => item.audit !== audit)
      );
    }
  }

  public onEditAudit(audit: Audit) {
    this.analytics.trackAction('Audit edit');
    this.auditFormService.getAuditForm(audit.auditFormId).pipe(
      tap((auditForm: AuditForm) => this.checkAuditService.checkAuditFormUpdate(auditForm))
    ).subscribe((result: any) => {
        this.router.navigate(['audit-preview', audit.id ]);
    });
  }
}
