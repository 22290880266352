import {Component, Input, OnInit} from '@angular/core';
import {QipService} from '../qip.service';
import {AuditForm} from '../../api/models/audit-form';
import {SubFormSchema} from '../../api/models/audit-form-schema';
import {Issue} from '../../api/models/issue';
import {MatDialog, MatSnackBar} from '@angular/material';
import {IssuesDialogComponent} from './issues-dialog/issues-dialog.component';
import {IssueDialogArguments} from './issue-dialog-arguments';
import {CommonIssue} from '../../api/models/common-issue';
import {Audit} from '../../audit-form/audit';
import {AuditFormService} from '../../audit-form.service';
import {mergeMap} from 'rxjs/operators';
import {Errors} from '../../api/models/errors';
import {AuditService} from '../../audit-form/audit.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'meg-issues-widget',
  templateUrl: './issues-widget.component.html',
  styleUrls: ['./issues-widget.component.css']
})
export class IssuesWidgetComponent implements OnInit {
  /** Current observation or sub-observation */
  public auditForm: AuditForm | null = null;
  public commonIssues: string[] = [];
  @Input() observation: { [fieldName: string]: any, issues: Issue[] };
  @Input() audit: Audit;
  @Input() subForm: SubFormSchema | null;
  @Input() errors: Errors[];
  public issue_label: Observable<string>;
  public issue_label_plural: Observable<string>;

  constructor(private qipService: QipService, private dialog: MatDialog, private auditFormService: AuditFormService,
              private snackbar: MatSnackBar, private auditService: AuditService) {
  }

  ngOnInit() {
    this.auditFormService.getAuditForm(this.audit.auditFormId).pipe(
      mergeMap((auditForm: AuditForm) => this.qipService.getCommonIssueComments(auditForm, this.subForm)),
    ).subscribe((issue: string[]) => this.commonIssues = issue);
    this.auditFormService.getAuditForm(this.audit.auditFormId).subscribe(
      (auditForm: AuditForm) => {
        this.auditForm = auditForm;
        this.issue_label = this.auditService.getIssueLabel(this.auditForm);
        this.issue_label_plural = this.auditService.getIssueLabel(this.auditForm, true);
      }
    );
  }

  private isCommonIssue(issue: Issue): boolean {
    return this.commonIssues.indexOf(issue.comment) > -1;
  }

  get numCommonIssues(): number {
    return this.observation.issues.filter((issue: Issue) => this.isCommonIssue(issue)).length;
  }

  get numCustomIssues(): number {
    return this.observation.issues.filter((issue: Issue) => !this.isCommonIssue(issue)).length;
  }

  private getIssues(showNewIssueButton: Boolean = true, title: string = 'qip.issues', hideUnselectedCommonIssues: Boolean = false) {
    this.auditFormService.getAuditForm(this.audit.auditFormId).pipe(
      mergeMap((auditForm: AuditForm) => this.qipService.getCommonIssues(auditForm, this.subForm)),
    ).subscribe((commonIssues: CommonIssue[]) => {
      const dialogRef = this.dialog.open(IssuesDialogComponent, {
        height: '90%',
        data: new IssueDialogArguments(
          this.observation.issues,
          this.audit,
          commonIssues,
          null,
          this.errors,
          showNewIssueButton,
          title,
          hideUnselectedCommonIssues,
          this.issue_label,
          this.issue_label_plural,
        ),
      });
    });
  }

  public showIssuesDialog() {
    this.getIssues();
  }

  public reviewIssuesDialog() {
    this.getIssues(false, 'qip.action-plan', true);
  }
}
