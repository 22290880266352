import {Component, Injector, OnDestroy} from '@angular/core';
import {Hints, HintService} from './hint.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';
import {ModalService} from './alert/service/modal.service';
import {AlertType} from './alert/alert.component';
import {TooltipPosition} from './tooltip/tooltip.directive';
import {Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';

@Component({
  template: ''
})
export class BaseComponent implements OnDestroy {

  public alertType = AlertType;

  protected translateService: TranslateService;
  protected snackbar: MatSnackBar;
  protected modalService: ModalService;
  protected hintService: HintService;
  protected logger: NGXLogger;

  public hints = Hints;
  public tooltipPosition = TooltipPosition;

  public subscriptions: Subscription[] = []; // Holding all subscriptions in a component to then destroy

  constructor(private injectorObj: Injector) {
    this.translateService = injectorObj.get(TranslateService);
    this.snackbar = injectorObj.get(MatSnackBar);
    this.modalService = injectorObj.get(ModalService);
    this.hintService = injectorObj.get(HintService);
    this.logger = injectorObj.get(NGXLogger);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
