import {Injectable} from '@angular/core';
import {AuditForm, LayoutType} from './api/models/audit-form';
import {StorageService} from './storage.service';
import {forkJoin, Observable} from 'rxjs';
import {Institution} from './api/models/institution';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {AuditFormSchema, Choice, Field, SubFormSchema} from './api/models/audit-form-schema';
import {AuditFormSchemaWrapper} from './audit-form/audit';
import {Answer} from './api/models/observation';
import {LastModifiedService} from './last-modified.service';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {of} from 'rxjs/internal/observable/of';

export const DB_KEY_AUDIT_FORMS = 'audit-forms';

@Injectable()
export class AuditFormService {
  constructor(private storageService: StorageService, private lastModifiedService: LastModifiedService,
              private translateService: TranslateService, private logger: NGXLogger) {
  }

  /**
   * Formats answer to human-readable format. Returns label if field is a choice field.
   * It falls back to returning the passed value if field does not specify choices
   * @param field field context
   * @param value value selected by user
   */
  public static getFieldAnswer(field: Field, value: Answer): Answer {
    if (value !== undefined && value !== null && field.choices) {
      const choice = field.choices.find((c: Choice) => c.value === value);
      if (choice !== undefined) return choice.label;
    }
    return value;
  }

  private getAuditFormSchemaKey(auditFormId: number): string {
    const language = this.translateService.currentLang;
    return `audit-form-${auditFormId}-schema-${language}`;
  }

  private getLastModifiedSchemaKey(auditFormId: number): string {
    return this.lastModifiedService.getLastModifiedKey(this.getAuditFormSchemaKey(auditFormId));
  }

  private getLastModifiedKey(key: string): string {
    return this.lastModifiedService.getLastModifiedKey(key);
  }

  public saveAuditForms(auditForms: AuditForm[]): Observable<boolean> {
    return this.storageService.setItem(DB_KEY_AUDIT_FORMS, auditForms);
  }

  public saveAuditFormSchema(schema: AuditFormSchema, lastModified: string | null): Observable<boolean> {
    const key = this.getAuditFormSchemaKey(schema.id);
    this.logger.debug(`Writing audit form schema in ${key}`);
    return this.storageService.setItem(key, schema).pipe(
      tap(() => {
        const lastModifiedKey = this.getLastModifiedKey(key);
        if (lastModified === null) this.storageService.removeString(lastModifiedKey);
        else this.storageService.setString(lastModifiedKey, lastModified);
      }),
    );
  }

  /**
   * Checks whether audit form schema for specified audit form is stored in Indexed DB
   * @param {number} auditFormId
   * @returns {Observable<boolean>}
   */
  public isSchemaSaved(auditFormId: number): Observable<boolean> {
    const schemaKey = this.getAuditFormSchemaKey(auditFormId);
    return this.storageService.hasItem(schemaKey);
  }

  /**
   * Given a list of audit forms, returns only those whose schema is saved locally
   */
  public filterSavedSchemas(forms: AuditForm[]): Observable<AuditForm[]> {
    if (forms.length === 0) return of(forms);
    // Create individual observable for each form that returns the it coupled with boolean whether its saved
    const observables: Observable<[AuditForm, boolean]>[] = forms.map((form => this.isSchemaSaved(form.id).pipe(
      map((isSaved => [form, isSaved] as [AuditForm, boolean])),
    )));
    // Evaluate observables, filter out unsaved forms and turn into a list of forms
    return forkJoin(observables).pipe(
      map((items: [AuditForm, boolean][]) => items.filter(value => value[1]).map(value => value[0])),
    );
  }

  /**
   * Gets last modified header from the last response from audit form schema.
   * returns null if date is not saved, or schema is not saved.
   * @param {number} auditFormId
   * @returns {Observable<string | null>}
   */
  public getAuditFormSchemaLastModified(auditFormId: number): Observable<string | null> {
    return this.isSchemaSaved(auditFormId).pipe(
      map((exists: boolean) => {
        if (exists) return this.storageService.getString(this.getLastModifiedSchemaKey(auditFormId));
        else return null;
      }),
    );
  }

  public getAuditFormSchema(auditFormId: number): Observable<AuditFormSchema> {
    const key = this.getAuditFormSchemaKey(auditFormId);
    return <Observable<AuditFormSchema>>this.storageService.getItem(key).pipe(
      // fallback key without language.
      // Fixes issue for users who upgraded the app and still have schema stored under the old key
      catchError(() => this.storageService.getItem(`audit-form-${auditFormId}-schema`).pipe(
        tap(() => this.logger.warn(`Cannot find form schema under the default key "${key}". Loaded schema from fallback legacy key.`)),
        // save schema under the new key
        mergeMap((schema: AuditFormSchema) => this.saveAuditFormSchema(schema, null).pipe(
          map(() => schema),
        )),
      )),
    );
  }

  /**
   * Gets audit forms from database.
   * Filters by institution if specified
   * @param {Institution | null} institution institution to filter by, or all audit forms will be returned
   * @returns {Observable<AuditForm[]>}
   */
  public getAuditForms(institution: Institution | null = null): Observable<AuditForm[]> {
    let observable = this.storageService.getItem<AuditForm[]>(DB_KEY_AUDIT_FORMS);
    if (institution != null) {
      const institutionFilter = (form: AuditForm) => form.institution_ids.includes(institution.id);
      observable = observable.pipe(map((value: AuditForm[]) => value.filter(institutionFilter)));
    }
    return observable;
  }

  /**
   * Gets audit form by id
   * @param {number} id
   * @returns {Observable<AuditForm>}
   */
  public getAuditForm(id: number): Observable<AuditForm> {
    return this.getAuditForms().pipe(
      map((forms: AuditForm[]) => {
        const matching: AuditForm | undefined = forms.find(form => form.id === id);
        if (matching === undefined) {throw Error(`Cannot find audit forms with id ${id}`); }
        return matching;
      }),
    );
  }

  /**
   * Gets both audit form and schema in a single observable
   * @param {number} id
   * @returns {Observable<AuditFormSchemaWrapper>}
   */
  public getAuditFormWithSchema(id: number): Observable<[AuditForm, AuditFormSchema]> {
    return forkJoin(
      this.getAuditForm(id),
      this.getAuditFormSchema(id),
    );
  }

  public hasSubforms(schema: AuditFormSchema): boolean {
    return schema.sub_forms.length > 0;
  }

  public getFormLayoutType(auditForm: AuditForm): LayoutType {
    const config = auditForm.config;
    if (config === null) return LayoutType.Default;
    return config.form_layout;
  }

  /**
   * Gets all feilds, including subform fields into a flat list
   * @param {number} auditFormId
   */
  public getAllFields(auditFormId: number): Observable<Field[]> {
    return this.getAuditFormSchema(auditFormId).pipe(
      map((schema: AuditFormSchema) => {
        const fields: Field[] = [];
        fields.push(...schema.fields);
        schema.sub_forms.forEach((subform: SubFormSchema) => fields.push(...subform.fields));
        return fields;
      }),
    );
  }

  /**
   * Retrieves Field and SubFormSchema with given audit form id and field name
   * @param auditFormId
   * @param fieldName
   * @return Observable containing the Field and (SubFormSchema or null if not found)
   */
  public getFieldSubformByName(auditFormId: number, fieldName: string): Observable<[Field, SubFormSchema | null]> {
    return this.getAuditFormSchema(auditFormId).pipe(
      map((schema: AuditFormSchema) => this.getFieldSubformFromSchema(fieldName, schema)),
    );
  }

  public getFieldByName(auditFormId: number, fieldName: string): Observable<Field> {
    return this.getFieldSubformByName(auditFormId, fieldName).pipe(
      map((result) => result[0]),
    );
  }

  /**
   * Retrieves Field and SubFormSchema from given AuditFormSchema and fieldName
   * @param fieldName: name of the field to find
   * @param auditFormSchema: schema to find the field in
   * @return Field and SubFormSchema
   */
  public getFieldSubformFromSchema(fieldName: string, auditFormSchema: AuditFormSchema): [Field, SubFormSchema | null] {
    let field: Field | undefined = auditFormSchema.fields.find((f: Field) => f.field_name === fieldName);
    if (field !== undefined) return <[Field, SubFormSchema | null]>[field, null];
    else {
      for (const subForm of auditFormSchema.sub_forms) {
        field = subForm.fields.find((f) => f.field_name === fieldName);
        if (field !== undefined) return <[Field, SubFormSchema | null]>[field, subForm];
      }
      throw new Error(`Cannot find field ${fieldName}`);
    }
  }

  public updateAuditForm(auditForm: AuditForm): Observable<boolean> {
    return this.storageService.getItem<AuditForm[]>(DB_KEY_AUDIT_FORMS).pipe(
      mergeMap(
      (auditForms: AuditForm[]) => {
          const filteredAuditForms = auditForms.filter((value: AuditForm) => value.id !== auditForm.id);
          filteredAuditForms.push(auditForm);
          return this.storageService.setItem(DB_KEY_AUDIT_FORMS, filteredAuditForms);
        }
      )
    );
  }
}
