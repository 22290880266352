<div class="channels-container">
  <h1>{{'messaging.messages' | translate}} </h1>  
  <mat-spinner *ngIf="channels === undefined"></mat-spinner>
  <mat-nav-list>
    <a mat-list-item *ngFor="let channel of channels" routerLink="/channels/{{channel.id}}/messages">
      <mat-icon matListIcon>{{ getChannelIcon(channel)}}</mat-icon>
      {{channel.name}}
    </a>
  </mat-nav-list>
  <div class="fixed-bottom">
    <button mat-raised-button color="accent" (click)="showNewMessageDialog()"><mat-icon>comment</mat-icon> {{ 'messaging.new-message' | translate }}</button>
  </div>
</div>
