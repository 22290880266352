import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {WalkthroughPage} from './models';
import {WalkthroughService} from './walkthrough.service';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'meg-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.css'],
})
export class WalkthroughComponent implements OnInit {
  public pageNumber = 0;
  public currentPage: WalkthroughPage;
  public walkthroughPages: WalkthroughPage[] = [];
  public action: string | null = null;

  // constant for swipe action: left or right
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  constructor(private walkthroughService: WalkthroughService, private router: Router) {
  }

  ngOnInit() {
    this.walkthroughService.showing = true;
    this.walkthroughService.getAllPages().pipe(
      tap((pages: WalkthroughPage[]) => this.walkthroughPages = pages),
      tap(() => this.updatePage()),
    ).subscribe(
      () => {},
    );
  }

  private updatePage() {
    this.currentPage = this.walkthroughPages[this.pageNumber];
  }

  public nextPage() {
    this.swipe(this.pageNumber);
  }

  public finish() {
    this.walkthroughService.markShown();
    this.router.navigate(['login']);
    this.walkthroughService.showing = false;
  }

  public get isLastPage(): boolean {
    return (this.walkthroughPages.length - 1) === this.pageNumber;
  }

  public isCurrentPage(page: WalkthroughPage): boolean {
    return (this.currentPage === page);
  }

  // action triggered when user swipes
  swipe(currentIndex: number, action = this.SWIPE_ACTION.LEFT) {

    this.action = action;

    let nextIndex = currentIndex;
    // swipe right
    if (action === this.SWIPE_ACTION.RIGHT) {
      nextIndex -= 1;
      this.pageNumber = nextIndex > 0 ? nextIndex : 0;
    }

    // swipe left
    if (action === this.SWIPE_ACTION.LEFT) {
      nextIndex += 1;
      this.pageNumber =  nextIndex < this.walkthroughPages.length - 1 ? nextIndex : this.walkthroughPages.length - 1;
    }

    if (nextIndex >= this.walkthroughPages.length) {
      this.finish();
      return;
    }

    this.currentPage = this.walkthroughPages[this.pageNumber];
  }

}
